import React, { FC, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useLocation, generatePath } from 'react-router-dom';
import { AppRoutes } from '../../../../routes/routeConstants/appRoutes';
import CustomerService from '../../../../services/CustomerService/customer.service';
import TableComponent from '../../../../shared/components/TableComponent';
import { tableColumns } from '../tableColumns';
import NoCustomersImg from "../../../../assets/empty/customers.svg";
import { SortFilterModel } from '../../../../models/SortFilterModel/SortFilterModel.model';
import { StateProps } from '../../../../models/Meta/meta.model';

interface WaitlistedCustomersProps {
    setCount: React.Dispatch<React.SetStateAction<number>>
 }

const WaitlistedCustomers: FC<WaitlistedCustomersProps> = (props) => {

    const { setCount } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const stateValue = location.state as StateProps;
    const [sortOptions, setSortOptions] = useState<SortFilterModel>()
    const pageNumber = location.hash === "#waitlisted" ? stateValue?.pageNumber as number : null
    const [page, setPage] = useState(pageNumber ?? 1)
    const [search, setSearch] = useState("")
    const { loading, fetchWaitlistedCustomersList, customers, paginations } = CustomerService();
  
    const handlePageChange = (page:number) => {
      location.state = null
      setPage(page)
      fetchWaitlistedCustomersList({page, ...sortOptions, search})
    }
  
    useEffect(() => {
      location.hash === "#waitlisted" &&
      fetchWaitlistedCustomersList({page, ...sortOptions, search});
    }, [location?.hash]);
  
    useEffect(() => {
      setCount(paginations?.totalCount as number);
    }, [paginations]);

    useEffect(() => {
      fetchWaitlistedCustomersList({page, ...sortOptions, search})
    }, [])

    const waitlistColumns = tableColumns.filter(list => list.key !== 'retryCount')

    return (
        <div>
            <TableComponent
                paginationMeta={paginations}
                pageNumber={pageNumber ?? page}
                handlePageChange={handlePageChange}
                onChange={fetchWaitlistedCustomersList}
                onSort={(data)=>{
                  setSortOptions(data)
                }}
                filtersSorts={{page, search}}
                onRow={(record) =>
                    navigate({pathname:
                    generatePath(AppRoutes.WAITLISTED_CUSTOMERS_VIEW, {
                        customerId: record?.id?.toString(),
                    })},
                    {state: page}
                    )
                }
                search={{
                placeholder: "Search customer by name or national id or phone number",
                onSearch: (value) => {
                  setSearch(value)
                    fetchWaitlistedCustomersList({
                      search: value
                    });
                },
                }}
                loading={loading}
                columns={waitlistColumns}
                data={customers}
                empty={{
                img: NoCustomersImg,
                text: "No customer data",
                }}
            />
        </div>
    )
}

export default WaitlistedCustomers