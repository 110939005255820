import {Form, Formik, FormikProps, FormikValues} from "formik";
import React, {FC, useEffect, useRef, useState} from "react";
import ModalComponent from "../../../../shared/components/ModalComponent";
import {Col, Row, DatePicker} from "antd";
import DropdownField from "../../../../shared/components/DropdownField";
import ReportService from "../../../../services/ReportService/report.service";
import FreeInputListComponent from "../../../../shared/components/FreeInputListComponent";
import {reportValidationSchema} from "./ReportValidation";
import {ReportModel} from "../../../../models/Report/report.model";
import Notification from "../../../../shared/components/Notification";
import {NotificationTypes} from "../../../../enums/notificationTypes";
import moment, { Moment } from "moment";
import { RangePickerValueType } from "../../../../shared/types/rangePicker.type";
import { generateDateString } from "../../../../shared/utils/generateDateString";
import './reportForm.scss'
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { UserModel } from "../../../../models/User/user.model";

const { RangePicker } = DatePicker;

interface ReportFormProps {
    visible: boolean;
    closeHandler: () => void;
    successHandler: () => void;
}

const ReportForm: FC<ReportFormProps> = (props) => {
    const {visible, closeHandler} = props;
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const [initialValues, setInitialValues] = useState<any>();
    const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([null, null]);
    const [sendToAdmin, setSendToAdmin] = useState(false)
    const [reportType, setReportType] = useState<string>("")

    const {
        fetchReportNames,
        reportNames,
        reportDescription,
        loading,
        createReport
    } = ReportService();

    const user = LocalStorage.getItem('USER') as UserModel;
    const userEmail = user?.email;

    const onSubmit = (values: FormikValues) => {
        const {...rest} = values?.reportDetails;
        const reportModel = new ReportModel();
        reportModel.reportName = rest.reportName;
        reportModel.args = {startDate: rest.startDate, endDate: rest.endDate};
        createReport(reportModel, () => {
            closeHandler()
            resetFormAndClose();
            setSendToAdmin(false);
        })
    };

    const resetFormAndClose = () => {
        formRef?.current?.resetForm({values: {
            reportDetails:
            {
                reportName: reportNames[0]?.value,
                startDate: '',
                endDate: ''
            }
        }});
        setDateRange([null, null])
        closeHandler();
        setSendToAdmin(false);
    };

    useEffect(() => {
        if (reportNames && reportNames.length) {
            setReportType(reportDescription[0]?.questionnaire_report)
            setInitialValues({
                reportDetails:
                    {
                        reportName: reportNames[0]?.value,
                        startDate: '',
                        endDate: ''
                    }
            })
        }
    }, [reportNames]);

    useEffect(() => {
        fetchReportNames()
    }, []);


    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            innerRef={formRef}
            closeHandler={resetFormAndClose}
            validationSchema={reportValidationSchema}
            validateOnBlur={false}
            validateOnChange={true}
        >
            {({values, setFieldValue, handleSubmit, touched, errors}) => {
                return (
                    <Form >
                        <ModalComponent visible={visible}
                            title="Create report"
                            type="medium"
                            successProps={{
                                title: "Send Report",
                                clickHandler: handleSubmit,
                                loading: loading,
                                successDisabled: values?.reportDetails?.reportName === "" || values?.reportDetails?.startDate === "" || values?.reportDetails?.endDate === ""
                            }}
                            cancelProps={{
                                title: "Discard",
                                clickHandler: resetFormAndClose,
                            }}
                            closeHandler={closeHandler}
                            destroyOnClose={true}
                            className="report-form"
                        >
                            <Col span={24}>
                                <DropdownField
                                    label="Report type"
                                    placeholder="Select"
                                    name="reportDetails.reportName"
                                    value={values?.reportDetails?.reportName}
                                    options={reportNames}
                                    onChange={(value) => {
                                        setReportType(reportDescription[0][value])
                                        setFieldValue("reportDetails.reportName", value);
                                    }}
                                />
                            </Col>
                            {reportType && 
                                <Col span={24}>
                                    <label className="description">Description</label>
                                    <div>{reportType}</div>
                                </Col>
                            }
                            <Row>
                                <Col span={24}>
                                    <div className="input-field">
                                        <label style={{display: 'block'}}>{"Date Range"}</label>
                                        <RangePicker
                                        disabledDate={(date) => date.isAfter(moment())}
                                        value={dateRange}
                                        onChange={(values) => {
                                            if (values?.length) {
                                            setDateRange(values)
                                            const dataString = generateDateString(
                                                values as RangePickerValueType
                                            )?.split(" - ");
                                            setFieldValue("reportDetails.startDate", dataString?.[0]);
                                            setFieldValue("reportDetails.endDate", dataString?.[1]);
                                            }
                                        }}
                                        format={"DD/MM/YYYY"}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </ModalComponent>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ReportForm;
