import React, {FC, Fragment, useEffect, useState} from "react";
import IconComponent from "../../../../shared/components/IconComponent";
import {LateFeeForm, ProcessingFeeForm} from "../../Customers/CustomerList/CustomerListView/CustomerInfoCard";
import "./vendorConfiguration.scss";
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service';
import {ConfigurationProcessingFeeModel} from "../../../../models/Configuration/configuration.model";
import {serialize} from "serializr";
import {Col, Divider, Row} from "antd";
import CardSkeletonComponent from "../../../../shared/components/CardSkeletonComponent";
import {LocalStorage} from "../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../models/User/user.model";
import {BadgeTypes} from "../../../../enums/badgeTypes";
import ReorderListComponent from "../../../../shared/components/ReorderListComponent";
import ButtonComponent from "../../../../shared/components/ButtonComponent";

interface VendorConfigurationProps {
}

const VendorConfiguration: FC<VendorConfigurationProps> = (props) => {

    const [isProcessingFormShown, setIsProcessingFormShown] = useState(false);
    const [isLateFeeFormShown, setIsLateFeeFormShown] = useState(false);
    const {
        fetchConfigurationProcessingFee,
        fetchVendorListWithOrder,
        configurationProcessingFee,
        updateConfigurationProcessingFee,
        loading,
        vendorOrdered,
        setVendorOrdered,
        updateVendorOrder
    } = ConfigurationService();
    const user = LocalStorage.getItem('USER') as UserModel
    const showTest = LocalStorage.getItem("IS_TEST")
    const isAdmin = user?.role !== BadgeTypes.MANAGER
    useEffect(() => {
        fetchConfigurationProcessingFee();
        fetchVendorListWithOrder(showTest);
    }, [])

    const onSubmit = (value: any) => {
        let body = {
            "vendor_processing_fee_config": serialize(ConfigurationProcessingFeeModel, value)
        }
        updateConfigurationProcessingFee(body).then(() => {
            fetchConfigurationProcessingFee()
            setIsProcessingFormShown(false)
            setIsLateFeeFormShown(false)
        })

    }


    return (
        <Fragment>
            {
                loading ?
                    <CardSkeletonComponent rows={4}/> :
                    <div className="configuration-container">
                        <div className="vendor-configuration">
                            <LateFeeForm
                                visible={isLateFeeFormShown}
                                closeHandler={() => setIsLateFeeFormShown(false)}
                                submitHandler={onSubmit}
                                data={configurationProcessingFee}
                            />
                            <Row className="vendor-processing-fee">
                                <Col span={6}>
                                    <div className="label">Late Fee %</div>
                                    <div className="value">
                                        {configurationProcessingFee?.lateFee} %
                                        {isAdmin && <IconComponent name="icon-edit" onClick={() => {
                                            setIsLateFeeFormShown(true)
                                        }
                                        }/>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Divider/>
                        <div className="label">Vendor Configuration</div>
                        <div className="vendor-configuration">
                            <ProcessingFeeForm
                                visible={isProcessingFormShown}
                                closeHandler={() => setIsProcessingFormShown(false)}
                                submitHandler={onSubmit}
                                data={configurationProcessingFee}
                            />
                            <Row className="vendor-processing-fee">
                                <Col span={6}>
                                    <div className="label">Vendor Processing Fee %</div>
                                    <div className="value">
                                        {configurationProcessingFee?.processingFee}% {isAdmin &&
                                        <IconComponent name="icon-edit" onClick={() => {
                                            setIsProcessingFormShown(true)
                                        }
                                        }/>}
                                    </div>
                                </Col>
                            </Row>
                            <Col span={24}>
                                <div className="vendor-reorder-container label">Vendor Reorder</div>
                                <div className="value">
                                    <ReorderListComponent items={vendorOrdered}
                                                          onReorder={(updatedItems) => setVendorOrdered(updatedItems)}/>
                                    <ButtonComponent
                                        type="primary"
                                        htmlType="button"
                                        onClick={() => updateVendorOrder(vendorOrdered)}
                                        className="save-vendor-order-btn"
                                        size="medium"
                                        loading={loading}
                                    >
                                        Save Order
                                    </ButtonComponent>
                                </div>
                            </Col>
                        </div>
                    </div>
            }
        </Fragment>

    );
};

export default VendorConfiguration;
