import React, { useEffect, useState } from "react";
import { Button, Drawer, Menu } from "antd";
import AppIcon from "../../../assets/images/rise-logo-blue.png";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import IconComponent from "../IconComponent";
import { LocalStorage } from "../../utils/localStorageHelpers";
import { UserModel } from "../../../models/User/user.model";
import { BadgeTypes } from "../../../enums/badgeTypes";
import { MenuOutlined } from '@ant-design/icons';
import "./mobileNavbar.scss";
import useWindowWidth from "../../utils/useWindowWidth";
interface navbarItemsType {
  title: string;
  key: string;
  path: string;
  hash?: string;
  icon: JSX.Element;
  childrens?: { title: string, key: string; }[];
}

const MobileNavbar = () => {
  const navigate = useNavigate();
  const user = LocalStorage.getItem('USER') as UserModel
  const isManagerOrAdmin = ([BadgeTypes.ADMIN, BadgeTypes.MANAGER].some(role => user?.role?.includes(role)));
  const [visible, setVisible] = useState(false);
  const navbarItems = [
    {
      title: "Dashboard",
      key: "dashboard",
      path: isManagerOrAdmin ? AppRoutes.DASHBOARD : '',
      icon: <IconComponent name="icon-dashboard" />,
    },
    {
      title: "Customers",
      key: "customers",
      path: AppRoutes.CUSTOMERS,
      hash: "list",
      icon: <IconComponent name="icon-customers" />,
    },
    {
      title: "Vendors",
      key: "vendors",
      path: AppRoutes.VENDORS,
      hash: "list",
      icon: <IconComponent name="icon-vendors" />,
    },
    {
      title: "Purchases",
      key: "purchases",
      path: AppRoutes.PURCHASES,
      icon: <IconComponent name="icon-purchases" />,
    },
    {
      title: "Settlements",
      key: "settlements",
      hash: "pending",
      path: isManagerOrAdmin ? AppRoutes.SETTLEMENTS : '',
      icon: <IconComponent name="icon-settlements" />,
    },
    {
      title: "Payments",
      key: "payments",
      hash: "payment-logs",
      path: AppRoutes.PAYMENTS,
      icon: <IconComponent name="icon-payments" />,
    },
    {
      title: "Rise Users",
      key: "deall-users",
      path: user?.role === BadgeTypes.ADMIN ? AppRoutes.DEALL_USERS : '',
      icon: <IconComponent name="icon-user-config" />,
    },
    {
      title: "Configuration",
      key: "configuration",
      path: isManagerOrAdmin ? AppRoutes.CONFIGURATION : '',
      icon: <IconComponent name="icon-setting" />,
    },
    {
      title: "Monitoring",
      key: "monitoring",
      path: isManagerOrAdmin ? AppRoutes.MONITORING : '',
      icon: <IconComponent name="icon-dashboard" />,
    },
  ];

  const renderMenuItems = (menuData: navbarItemsType[]) => {
    return menuData.map((item) => {
      const disabled = !item.path ? 'disabled' : '';
      const onClick = !item.path ? {} : { onClick: () => { navigate({ pathname: item?.path, hash: item?.hash }); setVisible(false) } };
      const onTitleClick = !item.path ? {} : { onTitleClick: () => { navigate({ pathname: item?.path, hash: item?.hash }); setVisible(false) } };
      if (disabled) {
        return;
      }
      if (item?.childrens) {
        return (
          <Menu.SubMenu {...onTitleClick} className={"mobile-navbar-sub-item " + disabled} key={item.key} icon={item.icon} title={item.title}>
            {renderMenuItems(item.childrens as navbarItemsType[])}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item
          className={"mobile-navbar-item " + disabled}
          key={item?.key}
          {...onClick}
        >
          <div className="mobile-navbar-item__title">
            {item?.icon}
            {item?.title}
          </div>
        </Menu.Item>
      );
    });
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="mobile-navbar">
      <img src={AppIcon} className="navbar-app-logo__img" />
      <Button className="navbar-button" icon={<MenuOutlined />} onClick={showDrawer} />
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        key="left"
        className="mobile-drawer"
      >
        <Menu mode="vertical">
          <div className="navbar-menu-header">
            <img src={AppIcon} className="navbar-menu-app-logo__img" />
            <span className="navbar-menu_close" onClick={onClose}>X</span>
          </div>
          {renderMenuItems(navbarItems)}
        </Menu>
      </Drawer>
    </div>
  );
};

export default MobileNavbar;
