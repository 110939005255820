import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import { axiosInstance } from '../../interceptor/axiosInstance';
import { DashboardFilterModel } from '../../models/Dashboard/dashboard.model';
import { Payments } from '../../models/PaymentsMetrics/paymentsMetrics.model';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import { generateGraphData } from '../../shared/utils/generateGraphData';
import { graphDataType } from '../DasboardService/dashboard.service';


const MonitoringService = () => {

    const [loading, setLoading] = useState(false);
    const [cardPaymentKeyMetrics, setCardPaymentKeyMetrics] = useState<Payments>();
    const [walletPaymentKeyMetrics, setWalletPaymentKeyMetrics] = useState<Payments>();
    const [walletErrorsByType, setWalletErrorsByType] = useState<graphDataType>()
    const [cardErrorsByType, setCardErrorsByType] = useState<graphDataType>()

    const fetchCardPaymentKeyMetrics = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string,
        showTest?: boolean
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.CARD_PAYMENT_KEY_METRICS, {params})
            const data = deserialize(Payments, response.data?.payments)
            setCardPaymentKeyMetrics(data)
            setCardErrorsByType(generateGraphData(response.data?.card_errors))

        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const fetchWalletPaymentKeyMetrics = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string,
        showTest?: boolean
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.WALLET_PAYMENT_KEY_METRICS, {params})
            const data = deserialize(Payments, response.data?.payments)
            setWalletPaymentKeyMetrics(data)
            setWalletErrorsByType(generateGraphData(response.data?.wallet_errors))

        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    return {
        fetchCardPaymentKeyMetrics,
        fetchWalletPaymentKeyMetrics,
        cardPaymentKeyMetrics,
        walletPaymentKeyMetrics,
        walletErrorsByType,
        cardErrorsByType,
        loading
    };
}

export default MonitoringService;