
import {serializable, alias, object, list, primitive, custom} from 'serializr';
import { BadgeTypes } from '../../../enums/badgeTypes';
import { AttachmentModel } from '../../Attachment/attachment.model';
import { MetaModel } from '../../Meta/meta.model';

const { INACTIVE, APPROVED, PENDING_APPROVAL, REJECTED, ACTIVE } = BadgeTypes

export class TerminalIdModel {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('label', primitive()))
	label?: string;

	@serializable(alias('posType', primitive()))
	posType?: string;

	@serializable(alias('terminal_id', primitive()))
	terminalId?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('instore_id', primitive()))
	instoreId?: number | null;
}
export class VendorInstoreCreationModel { 

	@serializable(alias('fawry_terminals', list(object(TerminalIdModel))))
	fawryTerminalIds?: TerminalIdModel[];

	@serializable(alias('rise_terminals', list(object(TerminalIdModel))))
	riseTerminals?: TerminalIdModel[];

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('contact_first_name', primitive()))
	contactFirstName?: string;

	@serializable(alias('contact_last_name', primitive()))
	contactLastName?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('mobile_number', primitive()))
	mobileNumber?: string;

	@serializable(alias('address', primitive()))
	address?: string;

	@serializable(alias('lat', custom((lat) => lat, (lat) => parseFloat(lat))))
	lat?: number;

	@serializable(alias('country_code_id', primitive()))
	countryCodeId?: string;

	@serializable(alias('isd_code', primitive()))
	isdCode?: string;

	@serializable(alias('country_code', object(MetaModel)))
    countryCode?: MetaModel;

	@serializable(alias('long', custom((long) => long, (long) => parseFloat(long))))
	long?: number;

	@serializable(alias('status', primitive()))
	status?: typeof ACTIVE |typeof APPROVED|typeof REJECTED|typeof INACTIVE|typeof PENDING_APPROVAL;
	
	@serializable(alias('switch_status', primitive()))
	switchStatus?: boolean;

}

export class VendorInstoreModel extends VendorInstoreCreationModel {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('contact_full_name', primitive()))
	contactFullName?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('no_of_orders', primitive()))
	noOfOrders?: number;

	@serializable(alias('vendor_name', primitive()))
	vendorName?: string;

	@serializable(alias('logo_url', primitive()))
	logoUrl?: string;

	@serializable(alias('logo', object(AttachmentModel)))
	logo?: AttachmentModel;

	@serializable(alias('vendor_id', primitive()))
	vendorId?: number;
}