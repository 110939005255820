import { Divider } from 'antd';
import React, { FC, Fragment } from 'react'
import { currencyString } from '../../../..';
import { RefundTypes } from '../../../../../../enums/refundTypes';
import { PurchaseModel } from '../../../../../../models/Purchase/purchase.model';
import { RefundDetails } from '../../../../../../models/RefundDetails/refundDetails.model';
import ModalComponent from '../../../../../../shared/components/ModalComponent'
import IconComponent from "../../../../../../shared/components/IconComponent"
import "./calculationModal.scss"

interface CalculationModalProps {
    visible: boolean;
    closeHandler: () => void;
    purchaseRefund?: RefundDetails;
    purchase: PurchaseModel
}

const CalculationModal: FC<CalculationModalProps> = (props) => {

    const { visible, closeHandler, purchaseRefund, purchase } = props

    const pastPayments = purchaseRefund?.installmentDetails?.reduce((acc, curr)=> acc + (curr?.status === "paid" ? Number(curr?.updatedAmount) : 0), 0)
    const modifiedPurchaseAmount = Number(purchaseRefund?.oldPurchaseDetail?.purchaseAmount) - Number(purchaseRefund?.refundAmount)
    const modifiedPurchaseTotal = modifiedPurchaseAmount + Number(modifiedPurchaseAmount * 0.1)
    const modifiedOutstandingPayments = Math.round(modifiedPurchaseTotal - pastPayments!)

    const refundAmountProcessedByDeall = purchaseRefund?.cashRefundAmount ? purchaseRefund?.cashRefundAmount : purchaseRefund?.refundAmount

    return (
        <ModalComponent
            type="no-controllers-large"
            visible={visible}
            closeHandler={closeHandler}
            destroyOnClose={true}
        >
            <div className="calcualtion-modal-content">
                <h3>Refund Calculation</h3>
                <Divider />
                <div className="purchase-summary-card">
                    <div className="purchase-summary-list__container">
                        <div className="purchase-summary-sub-total__container">
                            <div className="label">
                                <h3>
                                    Modified purchase amount
                                </h3>
                                <h5>
                                    (Purchase amount before refund - Refund amount on the receipt)
                                </h5>
                            </div>
                            <div className="value">
                                <h3>
                                    {currencyString}
                                    {modifiedPurchaseAmount}
                                </h3>

                                <h5>
                                    ({Number(purchaseRefund?.oldPurchaseDetail?.purchaseAmount)} {"-"} {Number(purchaseRefund?.refundAmount)})
                                </h5>

                            </div>
                        </div>
                        <div className="purchase-summary-sub-total__container">
                            <div className="label">
                                <h3>
                                    Modified purchase total
                                </h3>
                                <h5>
                                    (Modified purchase amount + 10% Rise customer fee)
                                </h5>
                            </div>
                            <div className="value">
                                <h3>
                                    {currencyString}
                                    {modifiedPurchaseTotal}
                                </h3>
                            </div>
                        </div>
                        <div className="purchase-summary-sub-total__container">
                            <div className="label">
                                <h3>
                                    Modified outstanding payment
                                </h3>
                                <h5>
                                    (Modified purchase total - Past Payments done by customer)
                                </h5>
                            </div>
                            <div className="value">
                                <h3 className={Math.sign(modifiedOutstandingPayments) < 0 ? "negative" : "positive"}>
                                    {currencyString}
                                    {modifiedOutstandingPayments}
                                </h3>
                                <h5>
                                    ({modifiedPurchaseTotal} {"-"} {pastPayments})
                                </h5>
                            </div>
                        </div>

                    </div>
                </div>
                <Divider />
                <div className="purchase-summary-total__container">
                    <div className="label">Refund amount processed by Rise</div>
                    <div className="value">
                        {currencyString}
                        {refundAmountProcessedByDeall}
                    </div>
                </div>
                <div className="adjust-info">
                    <div className="content">
                        <p><IconComponent name="icon-info"/>  LE {refundAmountProcessedByDeall} is &nbsp;  {purchaseRefund?.refundType === RefundTypes.CASH_REFUND ? <Fragment>provided as <b>cashout</b> </Fragment> : <b> adjusted with the unpaid installment due </b>} </p>
                    </div>
                </div>
            </div>
        </ModalComponent>
    )
}

export default CalculationModal