import {serializable, alias, object, primitive} from 'serializr';

class KycConfigurations { 
	@serializable(alias('kyc_verification', primitive()))
	kycVerification?: string;
}

class ConfigJson { 
	@serializable(alias('threshold_credit_score', primitive()))
	thresholdCreditScore?: number;
}

export class RiseConfig { 
	@serializable(alias('config_json', object(ConfigJson)))
	configJson?: ConfigJson;
}

export  class CreditScoreConfig { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('kyc_configurations', object(KycConfigurations)))
	kycConfigurations?: KycConfigurations;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('configuration_identifier', primitive()))
	configurationIdentifier?: string;

	@serializable(alias('identifier_type', primitive()))
	identifierType?: string;

	@serializable(alias('config_json', object(ConfigJson)))
	configJson?: ConfigJson;

	@serializable(alias('config_class_name', primitive()))
	configClassName?: string;

}