
import {serializable, alias, primitive} from 'serializr';

export class RejectedBy { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;

	@serializable(alias('redirect_url', primitive()))
	redirectUrl?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('mobile_number', primitive()))
	mobileNumber?: string;

	@serializable(alias('role', primitive()))
	role?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('country_code_id', primitive()))
	countryCodeId?: number;

	@serializable(alias('login_attempt_count', primitive()))
	loginAttemptCount?: number;

	@serializable(alias('deactivation_reason', primitive()))
	deactivationReason?: string;

	@serializable(alias('seed', primitive()))
	seed?: string;

	@serializable(alias('factor', primitive()))
	factor?: string;

	@serializable(alias('mfa_type', primitive()))
	mfaType?: string;

	@serializable(alias('is_otp_verified', primitive()))
	isOtpVerified?: boolean;

}