import * as Yup from  "yup";
import { REGEX } from "../../../enums/Regex";

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      .required('Email is required.'),
    password: Yup.string()
      .matches(REGEX.PASSWORD.match,REGEX.PASSWORD.error)
      .min(8, 'Password has to be longer than 8 characters.')  
      .required('Password is required.')
  })