import { Divider, Tabs } from "antd";
import React, {FC, useEffect, useState} from "react";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import "./configuration.scss";
import CustomerPurchaseConfiguration from "./CustomerPurchaseConfiguration";
import PurchaseStatus from "./PurchaseStatus";
import VendorConfiguration from "./VendorConfiguration";
import IconImage from "./../../../assets/images/info-fill.svg";
import {UserModel} from "../../../models/User/user.model";
import {BadgeTypes} from "../../../enums/badgeTypes";
import {AppRoutes} from "../../../routes/routeConstants/appRoutes";
import {useNavigate} from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import CategoriesConfig from "./CategoriesConfig";
import CreditScoreConfiguration from "./CreditScoreConfiguration";

interface ConfigurationProps { }
const Configuration: FC<ConfigurationProps> = (props) => {
  const navigate = useNavigate();
  const user = LocalStorage.getItem('USER') as UserModel
  const { setAuthenticated } = AuthContext();
  const isAdminManager = user?.role !== BadgeTypes.VIEWER 

  useEffect(() => {
    if(user?.role === BadgeTypes.VIEWER){
      navigate(AppRoutes.CUSTOMERS);
    }
  }, []);

  const { TabPane } = Tabs;
  const [showTestData, setShowTestData] = useState(LocalStorage.getItem("IS_TEST"))
  const handleTabChange = (e: string) => {
    const isTest = e==="test";
    setShowTestData(isTest)
    setAuthenticated({...user, test: isTest})
    LocalStorage.setItem("IS_TEST", isTest)
  }
  return (
    <PageView
      title={{
        name: "Configuration",
        icon: <IconComponent name="icon-setting" />,
      }}
    >
      <div className="configuration">
        <Tabs className="antd-whole-tab" defaultActiveKey={LocalStorage.getItem("IS_TEST")?"test":"live"} onChange={handleTabChange}>
          <TabPane tab="Live Data" key="live" />
          <TabPane tab="Test Data" key="test" />
        </Tabs>
        <span className="info-text">
          <img className="icon-image" src={IconImage} alt="" /> The admin application displays {showTestData ? "test" : "real-time"} data
        </span>
        <Divider/>
        {isAdminManager && <PurchaseStatus />}
        <CustomerPurchaseConfiguration />
        {isAdminManager && <><VendorConfiguration /><Divider/></>}
        {isAdminManager && <><CategoriesConfig /><Divider/></>}
        {isAdminManager && <><CreditScoreConfiguration /><Divider/></>}
      </div>
    </PageView>
  );
};

export default Configuration;
