import React from "faker";
import { Col, Divider, Row, Spin } from "antd";
import { Form, Formik, FormikProps } from "formik";
import { FC, useRef, useState } from "react";
import { currencyString } from "../../../..";
import DatePickerField from "../../../../../../shared/components/DatePickerField";
import InputField from "../../../../../../shared/components/InputField";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import { MarkSettledPendingSettlement } from "../../../../../../models/PartnerFees/partnerFees.model";
import { settlementFormValidation } from "../../../PartnerFeesSettlements/PendingPartnerFeeSettlements/PartnerFeeSettlementForm/settlementFormValidation";
import PaymentcollectionService from "../../../../../../services/PaymentcollectionService/paymentcollection.service";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { PendingCollectionList } from "../../../../../../models/Collection/collection.model";
import IconComponent from "../../../../../../shared/components/IconComponent";
import { PartnerTypes } from "../../../../../../enums/partnerTypes";
import { formatDateOnly, getDate } from "../../../../../../shared/utils/formatDate";
import { MarkAsSettledDetails } from "../../../../../../models/Settlement/settlement.model";

interface SettlementFormProps {
    visible: boolean;
    closeHandler: () => void;
    refreshList: () => void;
    settlementData: PendingCollectionList[];
    selectedSettlements: React.Key[];
    callback: (showTest:boolean) => void;
    markAsSettledDetails?: MarkAsSettledDetails;
}
const SettlementForm: FC<SettlementFormProps> = (props) => {
    const { visible, closeHandler, settlementData, selectedSettlements, callback, refreshList, markAsSettledDetails } = props;
    const user = LocalStorage.getItem("USER")
    const { markPendingCollectionSettlements, loading} = PaymentcollectionService()
    const [initialValues, setInitialValues] =
        useState<MarkSettledPendingSettlement>({settledDate: "", partnerFeeSettlementId: "", id:[], adminId: user.id});
    const formRef = useRef<FormikProps<MarkSettledPendingSettlement>>(null);
    const showTest = LocalStorage.getItem("IS_TEST")

    const isFawry = markAsSettledDetails?.partner?.name === PartnerTypes.FAWRY
    const isPaybtabs = markAsSettledDetails?.partner?.name === PartnerTypes.PAYTABS

    const resetFormAndClose = () => {
        formRef?.current?.resetForm({ values: {id:[]} });
        closeHandler()
    }

    const dateRange = getDate(`${formatDateOnly(markAsSettledDetails?.transactionRange?.start!)} - ${formatDateOnly(markAsSettledDetails?.transactionRange?.end!)}`)

    const onSubmit = (values: MarkSettledPendingSettlement) => {
        values.id = selectedSettlements;
        values.partnerName = markAsSettledDetails?.partner?.name;
        values.adminId = user.id;
        markPendingCollectionSettlements(values, ()=>{
            callback(showTest)
            resetFormAndClose()
        })
        refreshList()
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            innerRef={formRef}
            validationSchema={settlementFormValidation}
        >
            {({ values, setFieldValue, handleSubmit }) => (
                <ModalComponent
                    visible={visible}
                    closeHandler={resetFormAndClose}
                    type="large"
                    successProps={{
                        title: "Confirm",
                        clickHandler: handleSubmit,
                        loading: loading,
                    }}
                    cancelProps={{
                        title: "Discard",
                        clickHandler: resetFormAndClose,
                    }}
                    className="partner-settlement-form-modal"
                >
                    <Form>
                        <div className="partner-settlement-form">
                            <Row gutter={16}>
                                <div className="partner-name-display-picture">
                                <IconComponent name="icon-customers" />
                                    <div>
                                        <h4>{markAsSettledDetails?.partner?.name}</h4>
                                    </div>
                                </div>
                                <div className="settlements-total__container">
                                    <div className="details">
                                        <div className="label">Transaction Time Period <span>({markAsSettledDetails?.noOfOrders} Payment{markAsSettledDetails?.noOfOrders === 1 ? '' : 's' })</span></div>
                                        <div className="value">{`${formatDateOnly(markAsSettledDetails?.transactionRange?.start!)} - ${formatDateOnly(markAsSettledDetails?.transactionRange?.end!)}`}</div>
                                    </div>
                                    <div className="details">
                                        <div className="label">Total Payment Amount</div>
                                        <div className="value">{markAsSettledDetails?.totalAmount?.toFixed(2)}</div>
                                    </div>
                                    {!isFawry && 
                                    <>
                                    <div className="details">
                                        <div className="label">Total Partner Fees <span>{`(1.5% of payment amount${isPaybtabs ? " + LE 1.5" : ""})`}</span></div>
                                        <div className="value">
                                            {loading ? <Spin/> :
                                            <span className={"payment-loss"}>
                                                {"-"}&nbsp;
                                                {markAsSettledDetails?.partnerProcessingFee?.toFixed(2)}
                                            </span>}
                                        </div>
                                    </div>
                                    <div className="details">
                                        <div className="label">Total VAT on Partner Fees <span>(14%)</span></div>
                                        <div className="value">
                                            {loading ? <Spin/> :
                                            <span className={"payment-loss"}>
                                                {"-"}&nbsp;
                                                {markAsSettledDetails?.partnerProcessingFeeVat?.toFixed(2)}
                                            </span>}
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                                <Divider />
                                <div className="settlements-amount__container">
                                    <div className="details">
                                        <div className="label">Collection Amount</div>
                                        {loading ? <Spin/> :
                                        <div className="value">
                                            &nbsp;
                                            {currencyString}
                                            {markAsSettledDetails?.collectionAmount?.toFixed(2)}
                                        </div>}
                                    </div>
                                </div>
                                <Divider />
                                <Col span={12}>
                                    <InputField
                                        label="Collection Settlement ID"
                                        name="partnerFeeSettlementId"
                                        placeholder="Enter"
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePickerField
                                        format="DD MMM YYYY"
                                        value={values?.settledDate}
                                        onChange={(date,dateString) => setFieldValue("settledDate", dateString)}
                                        label="Settled Date"
                                        name="settledDate"
                                        placeholder="Select"
                                        dateRange={dateRange}
                                    />
                                </Col>
                                {isFawry && 
                                <div className="collection-info">
                                    <IconComponent name="icon-info"/> <span className="text">Payment partner fees for "Fawry" are calculated in the partner fee section</span>
                                </div>
                                }
                                <Divider />
                            </Row>
                        </div>
                    </Form>
                </ModalComponent>
            )}
        </Formik>
    );
};

export default SettlementForm;
