import React, { FC } from 'react'
import assetDetails from "../../../../../assets/images/asset-details.svg"
import copyIcon from "../../../../../assets/images/copy-icon.svg"
import Notification from '../../../../../shared/components/Notification'
import { NotificationTypes } from '../../../../../enums/notificationTypes'
import "./assetdetails.scss"

interface AssetDetailsProps { 
    imeiNumber: string
}

const AssetDetails: FC<AssetDetailsProps> = (props) => {

    const { imeiNumber } = props

    return (
        <div className="assetdetails-card">
            <div className='header'><img src={assetDetails} /><span className='label'>Asset details</span></div>
            <div className='imei'>
                <span className='label'>IMEI number</span>
                <span className='value'>{imeiNumber} <img className='icon' src={copyIcon} onClick={() => {
                          navigator.clipboard.writeText(imeiNumber)
                          Notification({
                            message:"IMEI number Copied",
                            description: "",
                            type: NotificationTypes.INFO
                })}} /> </span>
            </div>
        </div>
    )
}

export default AssetDetails