import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import { axiosInstance } from "../../interceptor/axiosInstance";
import { PaginationModel } from "../../models/pagination.model";
import {
  PastSettlementListModel,
  SettlementModel,
  PendingSettlementModel,
  MarkSettledPendingSettlement,
  PendingSettlementId,
  MarkAsSettledDetails,
} from "../../models/Settlement/settlement.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

export const SettlementService = () => {
  const [pendingSettlements, setPendingSettlements] = useState<
    PendingSettlementModel[]
  >([]);
  const [pendingReturnSettlements, setPendingReturnSettlements] = useState<
  PendingSettlementModel[]
>([]);
  const [pendingSettlementIds, setPendingSettlementIds] = useState<
  PendingSettlementId
>();
  const [pastSettlements, setPastSettlements] = useState<
    PastSettlementListModel[]
  >([]);
  const [markAsSettledDetails, setMarkAsSettledDetails] = useState<MarkAsSettledDetails>();
  const [settlement, setSettlement] = useState<SettlementModel>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [markAsSettledLoading, setMarkAsSettledLoading] = useState(false);

  const [paginations, setPaginations] = useState<PaginationModel>()
  const [returnsPaginations, setReturnsPaginations] = useState<PaginationModel>()

  const fetchPendingSettlementList = (params?: SortFilterModel) => {
    setLoading(true);
    const schema = Object.assign(new SortFilterModel(params), {...params})
    const _params = params && serialize(schema)
    setPendingReturnSettlements([])
    axiosInstance
      .get(ApiRoutes.PENDING_SETTLEMENT_LIST, { params: _params })
      .then((response) => {
        const data = deserialize(
          PendingSettlementModel,
          response?.data?.vendor_settlements
        ) as PendingSettlementModel[];
        const meta = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(meta);
        setPendingSettlements(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPastSettlementList = (params?:SortFilterModel) => {
    const schema = Object.assign(new SortFilterModel(params), {...params})
    const _params = params && serialize(schema)
    setLoading(true);
    setPastSettlements([])
    axiosInstance
      .get(ApiRoutes.PAST_SETTLEMENT_LIST, { params: _params })
      .then((response) => {
        const data = deserialize(
          PastSettlementListModel,
          response?.data?.vendor_settlements
        ) as PastSettlementListModel[];
        const meta = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(meta);
        setPastSettlements(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSettlement = (settlementId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.SETTLEMENT_DETAILS, {
      settlementId,
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          SettlementModel,
          response?.data?.vendor_settlement
        );
        setSettlement(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const markPendingSettlements = (
    params: SortFilterModel,
    data: MarkSettledPendingSettlement,
    onSuccess: Function
  ) => {
    const _params = params && serialize(SortFilterModel, params)
    const requestPayload = {
      customer_purchases: serialize(MarkSettledPendingSettlement, data),
    };
    setMarkAsSettledLoading(true);
    return axiosInstance
    .post(ApiRoutes.PENDING_SETTLEMENT_MARK_SETTLED, requestPayload, { params: _params })
    .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        setError(error);
        return error;
      })
      .finally(() => {
        setMarkAsSettledLoading(false);
      });
  };

  const updatePastSettlementDetails = (
    settlement: PastSettlementListModel,
    onSuccess?: () => void,
    onError?: Function,
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.SETTLEMENT_DETAILS, {
      settlementId: settlement?.id?.toString(),
    });
    const serializedPayload = serialize(PastSettlementListModel, settlement);
    delete serializedPayload["id"];
    const requestPayload = { settlement: serializedPayload };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then(onSuccess)
      .catch((error) => {
        setError(error);
        onError && onError()
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchAllPendingSettlementIdForVendor = (params?: SortFilterModel) => {
    setDataLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    axiosInstance
      .get(ApiRoutes.PENDING_SETTLEMENT_SPECIFIC_VENDOR, { params: _params })
      .then((response) => {
        const data = deserialize(
          PendingSettlementId,
          response?.data
        )
        const meta = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(meta);
        setPendingSettlementIds(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const fetchPendingReturnsList = (params?: SortFilterModel) => {
    setLoading(true);
    const schema = Object.assign(new SortFilterModel(params), {...params})
    const _params = params && serialize(schema)
    axiosInstance
      .get(ApiRoutes.PENDING_RETURNS_SETTLEMENT_LIST, { params: _params })
      .then((response) => {
        const data = deserialize(
          PendingSettlementModel,
          response?.data?.vendor_settlements
        ) as PendingSettlementModel[];
        const meta = deserialize(PaginationModel,response?.data["meta"])
        setReturnsPaginations(meta);
        setPendingReturnSettlements(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const markPendingSettlementsDetails = (
    params: SortFilterModel,
  ) => {
    const requestPayload = params && serialize(SortFilterModel, params)
    setMarkAsSettledLoading(true);
    return axiosInstance
    .post(ApiRoutes.PENDING_SETTLEMENT_MARK_SETTLED_DETAILS, requestPayload)
    .then((response) => {
      const data = deserialize(MarkAsSettledDetails, response.data)
      setMarkAsSettledDetails(data)
      })
      .catch((error) => {
        setError(error);
        return error;
      })
      .finally(() => {
        setMarkAsSettledLoading(false);
      });
  };

  return {
    loading,
    error,
    pendingSettlements,
    pastSettlements,
    settlement,
    fetchPendingSettlementList,
    fetchPastSettlementList,
    fetchSettlement,
    paginations,
    markPendingSettlements,
    markAsSettledLoading,
    updatePastSettlementDetails,
    fetchAllPendingSettlementIdForVendor,
    dataLoading,
    pendingSettlementIds,
    fetchPendingReturnsList,
    pendingReturnSettlements,
    returnsPaginations,
    markPendingSettlementsDetails,
    markAsSettledDetails
  };
};
