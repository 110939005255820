import React, { FC, Fragment } from "react";
import CardComponent from "../CardComponent";
import "./documentsCard.scss";
import IconComponent from "../IconComponent";
import MenuComponent from "../MenuComponent";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import CardSkeletonComponent from "../CardSkeletonComponent";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { DownloadOutlined } from '@ant-design/icons'
import { Col, Divider, Row, Spin, Tooltip } from "antd";
import { DocumentTypes } from "../../../enums/documentTypes";
import completedIcon from "./../../../assets/images/completed.png"
import rejectedIcon from "./../../../assets/images/cross.png"
import BadgeComponent from "../BadgeComponent";
import { BadgeTypes } from "../../../enums/badgeTypes";
import { AttachmentModel } from "../../../models/Attachment/attachment.model";
import { CustomerModel } from "../../../models/Customer/customer.model";
import { Vendor } from "../../../models/Vendor/vendor.model";

interface DocumentsCardProps {
  isUpload?: boolean;
  uploadHandler?: (file: RcFile, fileUrl: string) => void;
  className?: string;
  data?: DocumentItemCardProps[];
  loading?: boolean;
  uploading?: boolean;
  detailsExpanded?: boolean;
  handleChange?: (info: UploadChangeParam) => void;
  accept?: string;
  customer?: CustomerModel;
  vendor?: Vendor;
}
interface DocumentItemCardProps {
  filename?: string;
  fileType?: string;
  label?: string;
  isUpload?: boolean;
  uploadHandler?: (file: RcFile, fileUrl: string) => void;
  handleChange?: (info: UploadChangeParam) => void;
  accept?: string;
  uploading?: boolean;
  attachmentStatus?: string;
  attachmentType?: DocumentTypes;
  customerAttachmentId?: string;
  vendorAttachmentId?: number;
  customer?: CustomerModel;
  s3Key?: string;
  s3Url?: string;
  document?: unknown;
  vendor?: Vendor;
}

export const DocumentItemCard: FC<DocumentItemCardProps> = ({
  isUpload,
  uploadHandler,
  handleChange,
  uploading,
  fileType,
  s3Key,
  filename,
  s3Url,
  accept,
  attachmentType,
  attachmentStatus,
  customerAttachmentId,
  vendorAttachmentId,
  customer,
  vendor
}) => {
  const navigate = useNavigate()
  const simulateDownload = (fileUrl: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.click();
  }
  return (
    <Row className="document-item-card" gutter={10}>
      {
        uploading ?
          <Spin /> :
          <Fragment>
            <Col span={4}>
              <div
                className="document-card-icon"
                onClick={() => {
                  navigate(generatePath(AppRoutes.FILE_VIEW, { fileId: `${customerAttachmentId ?? vendorAttachmentId}` }), { state: { s3Url, filename, fileType, attachmentType, customerStatus: customer?.status, from: customer ? 'customer' : vendor ? 'vendor' : '' } })
                }}
              >
                <IconComponent name="icon-document-blue" />
              </div>
            </Col>
            <Col span={12}>
              <Tooltip title={filename}>
              <p
                className="document-item-title"
                onClick={() => {
                  navigate(generatePath(AppRoutes.FILE_VIEW, { fileId: `${customerAttachmentId ?? vendorAttachmentId}` }), { state: { s3Url, filename, fileType, attachmentType, customerStatus: customer?.status, from: customer ? 'customer' : vendor ? 'vendor' : '' } })
                }}
              >
                {filename}
              </p>
              </Tooltip>
            </Col>
            {((attachmentType === DocumentTypes.VEHICLE_CARD || attachmentType === DocumentTypes.SPORTS_CLUB_MEMBERSHIP || attachmentType === DocumentTypes.BANK_STATEMENT || attachmentType === DocumentTypes.PAYSLIP || attachmentType === DocumentTypes.CREDIT_CARD_STATEMENT || attachmentType === DocumentTypes.EMPLOYMENT_LETTER || attachmentType === DocumentTypes.PAYSLIP_BANK_STATEMENT || attachmentType === DocumentTypes.EMPLOYMENT_LETTER_MEDICAL_CARD || attachmentType === DocumentTypes.MEDICAL_CARD || attachmentType === DocumentTypes.STUDENT_CARD) && attachmentStatus) ? (
                attachmentStatus === BadgeTypes.PENDING_VERIFICATION ?
                  <Col span={2}><BadgeComponent type={attachmentStatus} /></Col> :
                  (attachmentStatus === "approved" || attachmentStatus === "rejected") &&
                  <img src={attachmentStatus === "approved" ? completedIcon : attachmentStatus === "rejected" ? rejectedIcon : ""} className="document-status-icon" />
              ) :
            <Col span={1}>
              {isUpload && (
                <MenuComponent
                  dropdownClassName="document-reupload"
                  menu={[
                    {
                      title: "Re-Upload",
                      icon: <IconComponent name="icon-upload" />,
                      upload: {
                        handleChange,
                        accept: accept ?? "application/pdf",
                      },
                    },
                    {
                      title: "Download",
                      icon: <DownloadOutlined />,
                      clickHandler: () => {
                        simulateDownload(s3Url as string)
                      }
                    },
                  ]}
                />
              )}
              {((attachmentType === DocumentTypes.PROOF_OF_INCOME || attachmentType === DocumentTypes.PROOF_OF_ASSET) && attachmentStatus) && (
                attachmentStatus === BadgeTypes.PENDING_VERIFICATION ?
                  <BadgeComponent type={attachmentStatus} /> :
                  (attachmentStatus === "approved" || attachmentStatus === "rejected") &&
                  <img src={attachmentStatus === "approved" ? completedIcon : attachmentStatus === "rejected" ? rejectedIcon : ""} className="document-status-icon" />
              )}
            </Col>
            }
          </Fragment>
      }
    </Row>
  );
};

const DocumentsCard: FC<DocumentsCardProps> = (props) => {
  const { isUpload, uploadHandler, className, data, loading, uploading, detailsExpanded, handleChange, accept, customer, vendor } = props;
  return loading ? (
    <CardSkeletonComponent />
  ) : (
    <CardComponent className={`documents-card ${className} ${detailsExpanded && "max-height"}`}>
      {data?.map((value, index) => {
        return (
          !!(value?.document as AttachmentModel[])?.length &&
          <div>
            <p className="document-item-card--label">{value?.label}</p>
            {(value?.document as AttachmentModel[])?.map((doc) => {
              return (
                <DocumentItemCard
                  {...doc}
                  isUpload={isUpload}
                  uploadHandler={uploadHandler}
                  uploading={value?.uploading}
                  handleChange={value?.handleChange}
                  accept={value?.accept}
                  customer={customer}
                  vendor={vendor}
                />
              )
            })}
            <Divider />
          </div>
        );
      })}
    </CardComponent>
  );
};

export default DocumentsCard;
