import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import { axiosInstance } from "../../interceptor/axiosInstance";
import { PaginationModel } from "../../models/pagination.model";
import {
  PurchaseListFilterModel,
  PurchaseListModel,
  PurchaseModel,
} from "../../models/Purchase/purchase.model";
import { RefundDetails } from "../../models/RefundDetails/refundDetails.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";

const PurchaseService = () => {
  const [purchases, setPurchases] = useState<PurchaseListModel[]>([]);
  const [purchase, setPurchase] = useState<PurchaseModel>();
  const [purchaseRefund, setPurchaseRefund] = useState<RefundDetails>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const [paginations, setPaginations] = useState<PaginationModel>()

  const fetchPurchases = (params?: SortFilterModel) => {
    const schema = Object.assign(new SortFilterModel(params), {...params})
    const _params = params && serialize(schema)
    setPurchases([])
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.PURCHASE_LIST, { params: _params })
      .then((response) => {
        const data = deserialize(
          PurchaseListModel,
          response?.data?.customer_purchases
        ) as PurchaseListModel[];
        const _paginations = deserialize(PaginationModel,response?.data["meta"])
        setPurchases(data);
        setPaginations(_paginations)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPurchase = (purchaseId: string) => {
    const API_URL = generatePath(ApiRoutes.PURCHASE_DETAILS, {
      purchaseId,
    });
    setLoading(true);
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          PurchaseModel,
          response?.data?.customer_purchase
        );
        setPurchase(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createPurchaseRefund = (purchaseId: string, refundData: RefundDetails, onSucess: Function) => {
    const API_URL = generatePath(ApiRoutes.CREATE_REFUND, {
      purchaseId,
    });
    const data = serialize(RefundDetails, refundData)
    setLoading(true);
    axiosInstance
      .post(API_URL, {refund: data})
      .then((response) => {
        Notification({
          message: response?.data?.message,
          description: "", 
          type: NotificationTypes.SUCCESS
        })
        onSucess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editPurchaseRefund = (purchaseId: string, refundData: RefundDetails, onSucess: Function) => {
    const API_URL = generatePath(ApiRoutes.EDIT_REFUND, {
      purchaseId,
    });
    const data = serialize(RefundDetails, refundData)
    axiosInstance
      .put(API_URL, {refund: data})
      .then((response) => {
        Notification({
          message: response?.data?.message,
          description: "", 
          type: NotificationTypes.SUCCESS
        })
        onSucess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const viewPurchaseRefund = (purchaseId: string, onSuccess: Function) => {
    const API_URL = generatePath(ApiRoutes.VIEW_REFUND, {
      purchaseId,
    });
    setLoading(true);
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          RefundDetails,
          response?.data?.refund_details
        );
        setPurchaseRefund(data);
        onSuccess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const waiveLateFee = (installmentId: string, onSucess: Function) => {
    const API_URL = generatePath(ApiRoutes.LATE_FEE_WAIVE_OFF);
    axiosInstance
      .post(API_URL, {customer_installment_id: installmentId})
      .then(() => {
        onSucess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addImeiNumber = (id: string, imeiNUmber: string, onSucess: Function) => {
    const API_URL = generatePath(ApiRoutes.ADD_IMEI_NUMBER, { id });
    axiosInstance
      .put(API_URL, {imei_number: imeiNUmber})
      .then((response) => {
        response?.data?.message && (
        Notification({
          message: response?.data?.message,
          description: "", 
          type: NotificationTypes.SUCCESS
        }))
        onSucess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    purchases,
    purchase,
    fetchPurchases,
    fetchPurchase,
    paginations,
    createPurchaseRefund,
    viewPurchaseRefund,
    purchaseRefund,
    editPurchaseRefund,
    waiveLateFee,
    addImeiNumber
  };
};

export default PurchaseService;
