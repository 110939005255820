
import {serializable, alias, object, list, primitive} from 'serializr';

export class TransactionRange { 

	@serializable(alias('start', primitive()))
	start?: string;

	@serializable(alias('end', primitive()))
	end?: string;

}