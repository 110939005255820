import React, { useState, useEffect } from "react";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import "./deallUsers.scss";
import { BadgeTypes } from "../../../enums/badgeTypes";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../shared/components/TableComponent";
import BadgeComponent from "../../../shared/components/BadgeComponent";
import { Form, Formik, FormikValues } from "formik";
import { Col, Row } from "antd";
import DropdownField from "../../../shared/components/DropdownField";
import DeallUserForm from "./DeallUserForm";
import DeleteAlert from "../../../shared/components/DeleteAlert";
import DeallUserService from "../../../services/DeallUsersService/dealluser.service";
import { BadgeTypesDefinition } from "../../../definitions/badgeTypesDefinition";
import { tableSorter } from "../../../shared/utils/tableSorter";
import { SortFilterModel } from "../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../shared/types/SortColumn.type";
import { DeallUsersModel } from "../../../models/DeallUsers/deallusers.model";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import { UserModel } from "../../../models/User/user.model";
import { objectHasKeys } from "../../../shared/utils/objectHasKeys";

const DeallUsers = () => {
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [isDeallUserFormShown, setIsDeallUserFormShown] = useState(false);
  const [isDeallUserAlertShown, setIsDeallUserAlertShown] = useState(false);
  const [selectVal, setSelectedVal] = useState<DeallUsersModel>({});
  const { loading, fetchDeallUserList, deallUsers, deleteDeallUser, paginations } = DeallUserService();
  const [deallUser, setDeallUser] = useState({});
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const [filters, setFilters] = useState<SortFilterModel>({});
  const [deallUserList, setDeallUserList] = useState<DeallUsersModel[]>([]);
  const navigate = useNavigate()

  const user = LocalStorage.getItem('USER') as UserModel
  if(user?.role !== BadgeTypes.ADMIN){
    navigate(AppRoutes.CUSTOMERS);
  }

  useEffect(() => {
    setDeallUserList([
      ...deallUsers
    ])
  }, [deallUsers])
  useEffect(() => {
    fetchDeallUserList({},(data) => {
      setDeallUserList(data)
    })
  }, [])

  useEffect(() => {
    objectHasKeys(filters) &&
    fetchDeallUserList({...filters, ...sortOptions}, ()=>{});
  }, [filters]);

  const handleFilterSubmit = (values: SortFilterModel) => {
    setFilters(prev => ({ ...prev, ...values }));
    handleFilterClose();
  };
  const filterInitialValues = {
    role: undefined,
    status: undefined,
  };
  const handleFilterClose = () => {

    setIsFilterShown(false);
  };
  const filter = (
    <TableFilterDropdownComponent

      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              handleFilterClose();
              setFilters(() => ({ ...filterInitialValues, search: filters?.search }));
              resetForm({});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <DropdownField
                    label="Role"
                    options={[
                      {
                        value: BadgeTypes.ADMIN,
                        label: BadgeTypesDefinition[BadgeTypes.ADMIN],
                      },
                      {
                        value: BadgeTypes.VIEWER,
                        label: BadgeTypesDefinition[BadgeTypes.VIEWER],
                      },
                    ]}
                    name="role"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("role", value)}
                    value={values?.role}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    label="Status"
                    options={[
                      {
                        value: BadgeTypes.APPROVED,
                        label: BadgeTypesDefinition[BadgeTypes.ACTIVE],
                      },
                      {
                        value: BadgeTypes.INACTIVE,
                        label: BadgeTypesDefinition[BadgeTypes.INACTIVE],
                      },
                    ]}
                    name="status"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("status", value)}
                    value={values?.status}
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  const handleRefresh = () => {
    fetchDeallUserList();
  };

  const handlePageChange = (page:number) => {
    setPage(page)
    fetchDeallUserList({page, ...sortOptions, ...filters, search}, ()=>{})
  }

  return (
    <PageView
      title={{
        name: "Rise Users",
        icon: <IconComponent name="icon-user-config" />,
      }}
    >
      <div className="deall-users">
        <DeallUserForm
          deallUser={deallUser}
          onUpdate={() => fetchDeallUserList({}, (data) => {
            setDeallUserList(data)
          })}
          visible={isDeallUserFormShown}
          closeHandler={() => setIsDeallUserFormShown(false)}
        />
        <DeleteAlert
          title="Are you sure you want to delete the user ?"
          visible={isDeallUserAlertShown}
          cancelHandler={() => setIsDeallUserAlertShown(false)}
          sucessHandler={() => {
            deleteDeallUser(selectVal?.id, () => {
              fetchDeallUserList({}, (data) => {
                setDeallUserList(data)
              })
            })
            setIsDeallUserAlertShown(false)
          }}
        />
        <TableComponent
        onChange={fetchDeallUserList}
        onSort={(data)=>{
         setSortOptions(data)
        }}
        filtersSorts={{page, ...filters, ...sortOptions, search}}
        paginationMeta={paginations}
        handlePageChange={handlePageChange}
          loading={loading}
          data={deallUserList}
          search={{
            placeholder: "Search rise users by name or id",
            onSearch: (text) => {
              setSearch(text)
              handleFilterSubmit({ search: text })
            },
          }}
          newBtn={{
            clickHandler: () => {
              setDeallUser({})
              setIsDeallUserFormShown(true);
            },
          }}
          filter={filter}
          columns={[
            {
              title: "User ID",
              dataIndex: "id",
              key: SortColumnTypes.USER_ID,
              sorter: true
            },
            {
              title: "User Name",
              dataIndex: "fullName",
              key: SortColumnTypes.USER_NAME,
              sorter: true
            },
            {
              title: "Role",
              dataIndex: "role",
              key: SortColumnTypes.USER_ROLE,
              render: (text: BadgeTypes) => BadgeTypesDefinition[text],
              sorter: true
            },
            {
              title: "Phone Number",
              dataIndex: "mobileNumber",
              key: "mobileNumber",
              render: (text, record) => <span>{record?.countryCode?.isdCode ?? ""} {text}</span>
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Status",
              dataIndex: "status",
              key: SortColumnTypes.USER_STATUS,
              render: (text) => <BadgeComponent type={text===BadgeTypes.APPROVED?BadgeTypes.ACTIVE:BadgeTypes.INACTIVE} />,
              sorter: true
            },
            {
              title: "",
              key: "action",
              render: (data) => {

                return (
                  <div className="deall-users-actions">
                    <IconComponent
                      name="icon-edit"
                      onClick={() => {
                        setDeallUser(data)
                        setIsDeallUserFormShown(true);
                      }}
                    />
                    <IconComponent
                      name="icon-trash"
                      onClick={() => {
                        setSelectedVal(data)
                        setIsDeallUserAlertShown(true);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </PageView>
  );
};

export default DeallUsers;
