import { Radio } from "antd";
import { ErrorMessage, Field } from "formik";
import React, {FC, Fragment, useState} from "react";
import Error from "../Error";
import "./radioComponent.scss";

interface RadioComponentProps {
  label?: string;
  onChange: (value: any) => void;
  value: any;
  data: {
    value: string;
    label: string;
    subText?: string;
  }[];
  name: string;
  className?: string;
}

const RadioComponent: FC<RadioComponentProps> = (props) => {
  const { label, onChange, value, data, name, className } = props;

  return (
    <Fragment>
      <Field name={name}>
        {({ field, form: { touched, errors }, meta }: any) => (
          <div className={`radio-component ${className}`}>
            {label ? <label className="label">{label}</label> : ""}
            <Radio.Group name={name} value={value}  onChange={onChange}>
              {data?.map((value, index) => {
                  return <>
                      <Radio value={value?.value}>{value?.label}</Radio>
                      {value?.label &&
                          <span className="radio-sub-text">{value?.subText}</span>
                      }
                  </>
              })}
            </Radio.Group>
          </div>
        )}
      </Field>
      <ErrorMessage name={name}>
        {(message: string) => <Error message={message} />}
      </ErrorMessage>
    </Fragment>

  );
};

export default RadioComponent;
