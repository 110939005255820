import React, { FC, useEffect, useState } from "react";
import "./vendorDetailsCard.scss";
import faker from "faker";
import IconComponent from "../../../../../../../shared/components/IconComponent";
import MenuComponent from "../../../../../../../shared/components/MenuComponent";
import { VendorModel } from "../../../../../../../models/Vendor/vendor.model";
import CardSkeletonComponent from "../../../../../../../shared/components/CardSkeletonComponent";
import { BadgeTypesDefinition } from "../../../../../../../definitions/badgeTypesDefinition";
import { BadgeTypes } from "../../../../../../../enums/badgeTypes";
import {LocalStorage} from "../../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../../models/User/user.model";
import { ProcessingFeeForm } from "../../../../../Customers/CustomerList/CustomerListView/CustomerInfoCard";
import VendorService from "../../../../../../../services/VendorService/vendor.service";
import { convertJsonToFormData } from "../../../../../../../shared/utils/convertJsonToFormData";
import { serialize } from "serializr";
import { CustomerModel } from "../../../../../../../models/Customer/customer.model";
import { removeUndefined } from "../../../../../../../shared/utils/removeUndefined";
import { values } from "lodash";
import ModalComponent from "../../../../../../../shared/components/ModalComponent";
import ConfigurationService from "../../../../../../../services/ConfigurationService/configuration.service";

interface VendorDetailsCardProps {
  vendorType: "approved" | "not-approved";
  editHandler?: () => void;
  blacklistHandler?: () => void;
  loading?: boolean;
  vendor: VendorModel | undefined;
  refreshList?: () => void;
}


const VendorDetailsCard: FC<VendorDetailsCardProps> = ({
  vendorType,
  editHandler,
  blacklistHandler,
  loading,
  vendor,
  refreshList
}) => {
  const user = LocalStorage.getItem('USER') as UserModel
  const [showProcessingFeeForm, setProcessingFeeFormVisibility] = useState(false);
  const [resetProcessingFee, setResetProcessingFee] = useState(false);
  const isAdminManager = user?.role !== BadgeTypes.VIEWER

  const { updateVendor } = VendorService();
  const { fetchConfigurationProcessingFee, configurationProcessingFee,  } = ConfigurationService()
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdate = (values: VendorModel) => {
    const formData = convertJsonToFormData({ vendor: {vendor_details: removeUndefined(serialize(VendorModel, values))} });
    if (vendor?.id)
      updateVendor(
        vendor?.id,
        formData,
        () => {
          setProcessingFeeFormVisibility(false);
          refreshList && refreshList();
        },
        () => {},
        () => {
          setIsSubmitting(false);
        }
      );
  };

  useEffect(() => {
    fetchConfigurationProcessingFee();
}, [])

  return loading ? (
    <CardSkeletonComponent />
  ) : (
    <>
    <ModalComponent
        type="alert"
        alert={{
          title: "Are you sure you want to reset the processing fee ?",
          buttonType: "negative"
        }}
        visible={resetProcessingFee}
        closeHandler={() => setResetProcessingFee(false)}
        successProps={{
          title: "Reset",
          loading: loading,
          clickHandler: () => {
            handleUpdate({ processingFee: configurationProcessingFee?.processingFee });
            setResetProcessingFee(false);
          },
        }}
        cancelProps={{
          title: "Cancel",
          clickHandler: () => setResetProcessingFee(false),
        }}
      />
      <ProcessingFeeForm
        visible={showProcessingFeeForm}
        closeHandler={() => setProcessingFeeFormVisibility(false)}
        submitHandler={(values) => {
          handleUpdate({ processingFee: values?.processingFee });
        }}
        loading={isSubmitting}
        data={vendor as CustomerModel}
      />
      <div className="vendor-details-card">
        <div className="item">
          <div className="title">
            Monthly Trans. Volume
          </div>
          <div className="value">{vendor?.monthlyTransactionVolume}</div>
        </div>
        <div className="item">
          <div className="title">Max Credit / Order</div>
          <div className="value">{vendor?.maxCreditPerOrder}</div>
        </div>
        <div className="item">
          <div className="title">
            Processing Fee %
          </div>
          <div className="value">
            {vendor?.processingFee} {"%"}
            {isAdminManager &&
            <>
              <IconComponent
                  onClick={() => setProcessingFeeFormVisibility(true)}
                  name="icon-edit"
                />
              <span className="reset" onClick={() => setResetProcessingFee(true)}>Reset</span>
            </>
            }
          </div>
        </div>
      </div>
    </>
  );
};
// TODO needed for edit vendor logic
// {vendorType === "approved" && isAdminManager && (
//   <MenuComponent
//     dropdownClassName="vendor-details-card-menu"
//     menu={[
//       {
//         title: "Edit Vendor Details",
//         clickHandler: editHandler,
//         icon: <IconComponent name="icon-edit" />,
//       },
//       {
//         title: `${vendor?.status==="inactive" ? "Activate" : "Deactivate"} Vendor`,
//         clickHandler: blacklistHandler,
//         icon: <IconComponent name="icon-blacklist" />,
//       },
//     ]}
//   />
// )}

export default VendorDetailsCard;
