import React, { FC, useEffect, useState } from "react";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../../shared/components/TableComponent";
import { Form, Formik } from "formik";
import { Col, DatePicker, Row } from "antd";
import InputField from "../../../../../shared/components/InputField";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import { SettlementService } from "../../../../../services/SettlementService/settlement.service";
import PaymentcollectionService from "../../../../../services/PaymentcollectionService/paymentcollection.service";
import {
  PastSettlementListModel,
} from "../../../../../models/Settlement/settlement.model";
import NoTransactionsImg from "../../../../../assets/empty/transactions.svg";
import moment, { Moment } from "moment";
import { removeUndefined } from "../../../../../shared/utils/removeUndefined";
import { SortFilterModel } from "../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../shared/types/SortColumn.type";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { generateDateString } from "../../../../../shared/utils/generateDateString";
import { RangePickerValueType } from "../../../../../shared/types/rangePicker.type";
import DropdownField from "../../../../../shared/components/DropdownField";
import { TransactionRange } from "../../../../../models/TransactionRange/transactionRange.model";
import { PastPaymentCollectionList } from "../../../../../models/Collection/collection.model";
import { PartnerTypes } from "../../../../../enums/partnerTypes";
import { PartnerTypesDefinition } from "../../../../../definitions/partnerTypesDefinitions";
import { objectHasKeys } from "../../../../../shared/utils/objectHasKeys";
import { getTimezoneISOString } from "../../../../../shared/utils/getTimezoneISOString";
import { StateProps } from "../../../../../models/Meta/meta.model";
import { parseDate } from "../../../../../shared/utils/formatDate";
interface PaymentCollectionPastSettlementsProps {}

const { RangePicker } = DatePicker;

const PaymentCollectionPastSettlements: FC<PaymentCollectionPastSettlementsProps> = (props) => {

  const [isFilterShown, setIsFilterShown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isPastSettlement = location.hash === "#past-settlements"
  const stateValue = location?.state as StateProps;
  const pageNumber = isPastSettlement ? stateValue?.pageNumber as number : null;
  let filterOptions = isPastSettlement ? stateValue?.filterOptions as SortFilterModel : null
  const [page, setPage] = useState(pageNumber ?? 1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const { pastPaymentCollection, fetchPastPaymentCollectionList, loading, paginations} = PaymentcollectionService()
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const startDate = parseDate(filterOptions?.startDate)
  const endDate = parseDate(filterOptions?.endDate)
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([startDate ?? null, endDate ?? null]);
  const filterInitialValues = filterOptions ?? new SortFilterModel();
  const showTest = LocalStorage.getItem("IS_TEST")

  const handleFilterSubmit = (values: SortFilterModel) => {
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  useEffect(() => {
    objectHasKeys(filters) &&
    fetchPastPaymentCollectionList({page: 1, ...filters, ...sortOptions, showTest});
  }, [filters]);
  
  useEffect(() => {
    location.hash === "#past-settlements" &&
    fetchPastPaymentCollectionList({page, ...filters, ...sortOptions, showTest})
  }, [location.hash])
  
  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              location.state = null;
              filterOptions = null;
              setPage(1);
              handleFilterClose();
              setDateRange([null, null])
              setFilters(() => ({
                ...filterOptions,
                search: filters?.search,
              }));
              resetForm({values: {}});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                    <DropdownField
                        label="Partner Name"
                        placeholder="Select"
                        name="gender"
                        value={values?.partnerName}
                        options={[
                          {
                            value: PartnerTypes.FAWRY,
                            label: PartnerTypesDefinition[PartnerTypes.FAWRY],
                          },
                          {
                            value: PartnerTypes.PAYTABS,
                            label: PartnerTypesDefinition[PartnerTypes.PAYTABS],
                          },
                          {
                            value: PartnerTypes.PAYMOB,
                            label: PartnerTypesDefinition[PartnerTypes.PAYMOB],
                          },
                        ]}
                        onChange={(value) => setFieldValue("partnerName", value)}
                    />
                </Col>
                <Col span={12}/>
                <Col span={12}>
                  <InputField
                    name="transactionId"
                    placeholder="Enter"
                    label="Collection Settlement ID"
                  />
                </Col>
                <Col span={12}>
                  <div className="input-field">
                    <label>{"Settled Date Range"}</label>
                    <RangePicker
                      disabledDate={(date) => date.isAfter(moment())}
                      value={dateRange}
                      onChange={(values) => {
                        if(values?.length){
                          setDateRange(values)
                          const dataString = generateDateString(values as RangePickerValueType)?.split(' - ')
                          setFieldValue("startDate", dataString?.[0]??null)
                          setFieldValue("endDate", dataString?.[1]??null)
                        }
                      }}
                      format={'DD/MM/YYYY'} />
                  </div>
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  const handlePageChange = (page:number) => {
    location.state = null;
    setPage(page)
    fetchPastPaymentCollectionList({...filters,page, ...sortOptions, search, showTest})
  } 
  return (
    <div className="past-settlements">
      <TableComponent
      paginationMeta={paginations}
      pageNumber={pageNumber ?? page}
      handlePageChange = {handlePageChange}
      onChange={() => fetchPastPaymentCollectionList({...filters,page, ...sortOptions, search, showTest})}
        onSort={(data)=>{
         setSortOptions(data)
        }}
        filtersSorts={{page, ...filters, ...sortOptions, showTest}}
        search={{
          placeholder: "Search by collection settlement ID",
          onSearch: (value) => {
            setSearch(value)
            handleFilterSubmit({ search: value });
          },
        }}
        empty={{
          text: "No past settlements",
          img: NoTransactionsImg,
        }}
        filter={filter}
        rowClassName={() => "cursor-pointer"}
        onRow={(data) =>
          navigate(
            {pathname: generatePath(AppRoutes.COLLECTION_PAST_SETTLEMENTS_VIEW, {
              settlementId: data?.id,
            })}, {state: {pageNumber: page, filterOptions: filters}}
          )
        }
        columns={[
          {
            title: "Partner Name",
            dataIndex: "partnerName",
            key: SortColumnTypes.PAST_PAYMENT_PARTNER_NAME,
            sorter: true
          },
          {
            title: "Settled Date",
            dataIndex: "settledDate",
            key: SortColumnTypes.PAST_PAYMENT_SETTLED_DATE,
            render: (settledDate, obj) => moment(settledDate ?? obj.createdAt)?.format("DD MMM YYYY"),
            sorter: true,
          },

          {
            title: "Collection Settlement ID",
            dataIndex: "transactionId",
            key: "transactionId",
          },
          {
            title: "# Of Payments",
            dataIndex: "noOfOrders",
            key: SortColumnTypes.PAST_PAYMENT_ORDERS,
            sorter: true,
          },
          {
            title: "Payments Time Period",
            dataIndex: "purchaseTimePeriod",
            key: SortColumnTypes.SETTLED_AT,
            render: (text, record: PastPaymentCollectionList) => `${moment(record?.transactionRange?.start).format("DD MMM YYYY")} - ${moment(record?.transactionRange?.end).format("DD MMM YYYY")}`,
          },
          {
            title: "Collection Amount",
            dataIndex: "collectionAmount",
            key: SortColumnTypes.PAST_PAYMENT_COLLECTION_AMOUNT,
            render: (text, record) => (
              <span>
                {text?.toFixed(2)}
              </span>
            ),
            sorter: true,
          },
        ]}
        data={pastPaymentCollection}
        loading={loading}
      />
    </div>
  );
};

export default PaymentCollectionPastSettlements;
