
import {serializable, alias, object, list, primitive} from 'serializr';
import { TransactionRange } from '../TransactionRange/transactionRange.model';
import { UserModel } from '../User/user.model';

export class CustomerPayments { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('customer_purchase_id', primitive()))
	customerPurchaseId?: number;

	@serializable(alias('transaction_type', primitive()))
	transactionType?: string;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('payment_mode', primitive()))
	paymentMode?: string;

	@serializable(alias('payment_status', primitive()))
	paymentStatus?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('instore_payment_option', primitive()))
	instorePaymentOption?: string;

	@serializable(alias('customer_account_detail_id', primitive()))
	customerAccountDetailId?: number;

	@serializable(alias('late_fee_amount', primitive()))
	lateFeeAmount?: number;

	@serializable(alias('customer_installment_id', primitive()))
	customerInstallmentId?: number;

	@serializable(alias('pos_type', primitive()))
	posType?: string;

	@serializable(alias('partner_id', primitive()))
	partnerId?: number;

	@serializable(alias('payment_redirection_url', primitive()))
	paymentRedirectionUrl?: string;

	@serializable(alias('paymob_ewallet_process_state', primitive()))
	paymobEwalletProcessState?: string;

	@serializable(alias('external_payment_id', primitive()))
	externalPaymentId?: string;

	@serializable(alias('partner_settlement_id', primitive()))
	partnerSettlementId?: string;

	@serializable(alias('partner_processing_fee', primitive()))
	partnerProcessingFee?: number;

	@serializable(alias('partner_processing_fee_vat', primitive()))
	partnerProcessingFeeVat?: number;

	@serializable(alias('partner_settlement_status', primitive()))
	partnerSettlementStatus?: string;

	@serializable(alias('collection_status', primitive()))
	collectionStatus?: string;

	@serializable(alias('collection_amount', primitive()))
	collectionAmount?: number;

	@serializable(alias('payment_collection_id', primitive()))
	paymentCollectionId?: number;

}

export class PaymentCollection { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('settled_date', primitive()))
	settledDate?: string;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('collection_amount', primitive()))
	collectionAmount?: number;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('total_partner_fees', primitive()))
	totalPartnerFees?: number;

	@serializable(alias('total_vat', primitive()))
	totalVat?: number;

	@serializable(alias('transaction_range', object(TransactionRange)))
	transactionRange?: TransactionRange;

	@serializable(alias('no_of_orders', primitive()))
	noOfOrders?: number;

	@serializable(alias('customer_payments', list(object(CustomerPayments))))
	customerPayments?: CustomerPayments[] = [];

	@serializable(alias('admin', object(UserModel)))
	admin?: UserModel;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

}

export class PendingCollectionList { 

	@serializable(alias('id', primitive()))
	key?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('partner_id', primitive()))
	partnerId?: number;

	@serializable(alias('external_purchase_id', primitive()))
	externalPurchaseId?: string;

	@serializable(alias('external_payment_id', primitive()))
	externalPaymentId?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('payment_mode', primitive()))
	paymentMode?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('partner_processing_fee', primitive()))
	partnerProcessingFee?: number;

	@serializable(alias('partner_processing_fee_vat', primitive()))
	partnerProcessingFeeVat?: number;

	@serializable(alias('collection_amount', primitive()))
	collectionAmount?: number;

	@serializable(alias('collection_status', primitive()))
	collectionStatus?: string;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: number;

	@serializable(alias('customer_purchase_id', primitive()))
	customerPurchaseId?: number;
}

export class PastPaymentCollectionList { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('settled_date', primitive()))
	settledDate?: string;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('collection_amount', primitive()))
	collectionAmount?: number;

	@serializable(alias('total_partner_fees', primitive()))
	totalPartnerFees?: number;

	@serializable(alias('total_vat', primitive()))
	totalVat?: number;

	@serializable(alias('transaction_range', object(TransactionRange)))
	transactionRange?: TransactionRange;

	@serializable(alias('no_of_orders', primitive()))
	noOfOrders?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

}