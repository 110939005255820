
import {serializable, alias, object, list, primitive} from 'serializr';
import { PurchasesPayment } from '../PurchasesPayment/purchasesPayment.model';
import { CustomerModel } from '../Customer/customer.model';

export class PaymentDetail{
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('customer_purchase_id', primitive()))
	customerPurchaseId?: number;

	@serializable(alias('transaction_type', primitive()))
	transactionType?: string;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('payment_mode', primitive()))
	paymentMode?: string;

	@serializable(alias('payment_status', primitive()))
	paymentStatus?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('instore_payment_option', primitive()))
	instorePaymentOption?: string;

	@serializable(alias('customer_account_detail_id', primitive()))
	customerAccountDetailId?: number;

	@serializable(alias('late_fee_amount', primitive()))
	lateFeeAmount?: number;

	@serializable(alias('customer_installment_id', primitive()))
	customerInstallmentId?: number;

	@serializable(alias('pos_type', primitive()))
	posType?: string;

	@serializable(alias('payment_redirection_url', primitive()))
	paymentRedirectionUrl?: string;

	@serializable(alias('paymob_ewallet_process_state', primitive()))
	paymobEwalletProcessState?: string;

	@serializable(alias('external_payment_id', primitive()))
	externalPaymentId?: string;

	@serializable(alias('partner_id', primitive()))
	partnerId?: number;

	@serializable(alias('partner_processing_fee', primitive()))
	partnerProcessingFee?: number;

	@serializable(alias('partner_processing_fee_vat', primitive()))
	partnerProcessingFeeVat?: number;

	@serializable(alias('partner_settlement_status', primitive()))
	partnerSettlementStatus?: string;

	@serializable(alias('collection_status', primitive()))
	collectionStatus?: string;

	@serializable(alias('collection_amount', primitive()))
	collectionAmount?: number;

	@serializable(alias('payment_collection_id', primitive()))
	paymentCollectionId?: string;

	@serializable(alias('failure_reason', primitive()))
	failureReason?: string;
}

export class PaymentDetails { 

	@serializable(alias("payment", object(PaymentDetail)))
	payment?: PaymentDetail;

	@serializable(alias("purchases", list(object(PurchasesPayment))))
	purchases?: PurchasesPayment[] = [];

	@serializable(alias("customer", object(CustomerModel)))
	customer?: CustomerModel;

}