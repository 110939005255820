import { Col, Row } from "antd";
import React, { FC, Fragment, useState } from "react";
import SectionCardComponent from "../../../../../../../shared/components/SectionCardComponent";
import faker from "faker";
import "./vendorInfoCard.scss";
import IconComponent from "../../../../../../../shared/components/IconComponent";
import { ProcessingFeeForm } from "../../../../../Customers/CustomerList/CustomerListView/CustomerInfoCard";
import { ContactPersonDetails, VendorModel } from "../../../../../../../models/Vendor/vendor.model";
import VendorService from "../../../../../../../services/VendorService/vendor.service";
import { convertJsonToFormData } from "../../../../../../../shared/utils/convertJsonToFormData";
import { serialize } from "serializr";
import { removeUndefined } from "../../../../../../../shared/utils/removeUndefined";
import { CustomerModel } from "../../../../../../../models/Customer/customer.model";
import { VendorTypesDefinition } from "../../../../../../../definitions/vendorTypesDefinition";
import { VendorTypes } from "../../../../../../../enums/vendorTypes";
import {LocalStorage} from "../../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../../models/User/user.model";
import {BadgeTypes} from "../../../../../../../enums/badgeTypes";

interface VendorInfoCardProps {
  vendorType: "approved" | "not-approved";
  loading?: boolean;
  vendor: VendorModel | undefined;
  refreshList?: () => void;
  contactPerson: ContactPersonDetails;
}

const VendorInfoCard: FC<VendorInfoCardProps> = ({
  vendorType,
  vendor,
  loading,
  refreshList,
  contactPerson
}) => {
  const [showProcessingFeeForm, setProcessingFeeFormVisibility] =
    useState(false);
  const user = LocalStorage.getItem('USER') as UserModel

  const { updateVendor } = VendorService();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdate = (values: VendorModel) => {
    const formData = convertJsonToFormData({ vendor: {vendor_details: removeUndefined(serialize(VendorModel, values))} });
    if (vendor?.id)
      updateVendor(
        vendor?.id,
        formData,
        () => {
          setProcessingFeeFormVisibility(false);
          refreshList && refreshList();
        },
        () => {},
        () => {
          setIsSubmitting(false);
        }
      );
  };

  const setHttp = (link: string) => {
    return (link?.search(/^http[s]?\:\/\//) == -1) ? 'http://'+link : link;
  }

  return (
    <Fragment>
      <ProcessingFeeForm
        visible={showProcessingFeeForm}
        closeHandler={() => setProcessingFeeFormVisibility(false)}
        submitHandler={(values) => {
          handleUpdate({ processingFee: values?.processingFee });
        }}
        loading={isSubmitting}
        data={vendor as CustomerModel}
      />
      <SectionCardComponent
        loading={loading}
        className="vendor-info-card"
        section={
          <></>
        }
        content={
          <div className="vendor-info-card-content">
            <div className="item">
              <p className="title">Business Phone</p>
              <p className="value">{vendor?.countryCode?.isdCode} {vendor?.mobileNumber}</p>
            </div>
            <div className="item">
              <p className="title">Business Email</p>
              <p className="value">{vendor?.email}</p>
            </div>
            <div className="item">
              <p className="title">Contact Person</p>
              <p className="value">{contactPerson?.firstName}</p>
            </div>
            <div className="item">
              <p className="title">Contact Phone</p>
              <p className="value">{contactPerson?.countryCode?.isdCode} {contactPerson?.mobileNumber}</p>
            </div>
            <div className="item">
              <p className="title">Contact Email</p>
              <p className="value">{contactPerson?.email}</p>
            </div>
            <div className="item">
              <p className="title">Vendor ID</p>
              <p className="value">{vendor?.id}</p>
            </div>
            <div className="item category">
              <p className="title">Category</p>
              <span className="text-right">
                {
                  vendor?.categories?.map((category, index)=>(
                    <span className="value">{category?.label} {vendor?.categories?.length !== (index + 1) && ", "}</span>
                  ))
                }
              </span>
            </div>
            {/* TODO: required for next phase */}
            {/* <div className="sub-category">
              <p className="title">{t("sub_category")}</p>
              <p className="value">{vendor?.subCategory ?? "-"}</p>
            </div> */}
            <div className="item type">
              <p className="title">Type</p>
              <p className="value text-capitalize">{vendor?.type}</p>
            </div>
            <div className="item website">
              <p className="title">Website</p>
              <a className="value" href={setHttp(vendor?.websiteUrl??"")} target={"_blank"}>
                {vendor?.websiteUrl
                  ? vendor?.websiteUrl?.replace(/www.|https|http|[://]/gi, "")
                  : ""}
              </a>
            </div>
            <div className="item">
              <p className="title"># of instores</p>
              <p className="value">{vendor?.noOfInstores}</p>
            </div>
          </div>
        }
      />
    </Fragment>
  );
};

export default VendorInfoCard;
