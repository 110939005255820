import { Col, Row } from "antd";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import { currencyString } from "../../../../..";
import { VendorPurchaseConfigModel } from "../../../../../../../models/Vendor/PurchaseConfig/purchaseConfig.model";
import VendorService from "../../../../../../../services/VendorService/vendor.service";
import InputField from "../../../../../../../shared/components/InputField";
import ModalComponent from "../../../../../../../shared/components/ModalComponent";
import { vendorPurchaseConfigurationSchema } from "../../../../../Configuration/CustomerPurchaseConfiguration/configurationSchema";
import "./vendorPurchaseConfigForm.scss";

interface VendorPurchaseConfigFormProps {
  visible: boolean;
  closeHandler: () => void;
  successHandler: () => void;
  data: VendorPurchaseConfigModel | undefined;
}

const VendorPurchaseConfigForm: FC<VendorPurchaseConfigFormProps> = (props) => {
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { visible, closeHandler, data, successHandler } = props;
  const [initialValues, setInitialValues] = useState<VendorPurchaseConfigModel>(
    {}
  );
  const { updateVendorPurchaseConfig, loading } = VendorService();

  const onSubmit = (values: VendorPurchaseConfigModel) => {
    updateVendorPurchaseConfig(values, () => {
      successHandler();
    });
  };

  const resetFormAndClose = () =>{
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }

  useEffect(() => {
    setInitialValues({
      ...data
    });
  }, [data, visible]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={vendorPurchaseConfigurationSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <ModalComponent
          visible={visible}
          closeHandler={resetFormAndClose}
          type="small"
          title="Vendor Based Purchase Config"
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading,
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          className="vendor-purchase-config-form-modal"
        >
          <Form>
            {" "}
            <div className="vendor-purchase-config-form">
              <Row gutter={16}>
                <Col span={24}>
                  <InputField
                    label="Internal Score"
                    name="internalScore"
                    placeholder="Enter"
                    disabled={true}
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    label="Purchasing Power"
                    name="purchasePower"
                    placeholder="Enter"
                    prefix={currencyString}
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    label="Installments"
                    name="installments"
                    placeholder="Enter"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default VendorPurchaseConfigForm;
