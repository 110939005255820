import { Row, Col } from 'antd'
import { Formik, Form, FormikProps, FormikValues } from 'formik'
import React, { FC, Fragment, useRef, useState } from 'react'
import { MobilePurchaseDetails, PurchaseModel } from '../../../../../models/Purchase/purchase.model'
import InputField from '../../../../../shared/components/InputField'
import ModalComponent from '../../../../../shared/components/ModalComponent'
import AddIMEIConfimationModal from './AddIMEIConfimationModal'
import { addImeiFormValidation } from './addImeiFormValidation'
import "./addimeinumber.scss"

interface AddIMEINumberFormProps {
    visible: boolean;
    closeHandler: () => void;
    purchase?: PurchaseModel;
    refreshHandler?: Function
 }

const AddIMEINumberForm: FC<AddIMEINumberFormProps> = (props) => {

    const { visible, closeHandler, purchase, refreshHandler} = props
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const resetFormAndClose = () => {
        formRef?.current?.resetForm();
        closeHandler()
    }

    const [imeiNumber, setImeiNumber] = useState('');
    const [openIMEIConfirmationModal, setOpenIMEIConfirmationModal] = useState<boolean>(false);

    const openConfirmationModal = () => setOpenIMEIConfirmationModal(true);
    const closeConfirmationModal = () => setOpenIMEIConfirmationModal(false);

    const onSubmit = (values: MobilePurchaseDetails) => {
        setImeiNumber(values?.imeiNumber!)
        closeHandler()
        resetFormAndClose()
        openConfirmationModal()
      };

    return (
        <Fragment>
        <Formik
          initialValues={{imeiNumber: ''}}
          enableReinitialize
          onSubmit={onSubmit}
          innerRef={formRef}
          validationSchema={addImeiFormValidation}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => {
            return (
              <Form>
                <ModalComponent
                  visible={visible}
                  closeHandler={resetFormAndClose}
                  type="medium"
                  title="Add IMEI number"
                  description="This purchase will be considered as mobile purchase and will be available under customer assets"
                  successProps={{
                    title: "Confirm",
                    clickHandler: handleSubmit,
                  }}
                  cancelProps={{
                    title: "Close",
                    clickHandler: resetFormAndClose,
                  }}
                  className="create-refund-form-modal"
                >
                  <div className="create-refund-form">
                    <Row gutter={16}>
                      <Col span={24}>
                        <InputField
                          label="Enter IMEI number"
                          name="imeiNumber"
                          placeholder="Enter IMEI number"
                        />
                      </Col>
                    </Row>
                  </div>
                </ModalComponent>
              </Form>
            )
          }}
        </Formik>
        <AddIMEIConfimationModal purchase={purchase} imeiNumber={imeiNumber} refreshHandler={refreshHandler} closeHandler={closeConfirmationModal} visible={openIMEIConfirmationModal} />
      </Fragment>
    )
}

export default AddIMEINumberForm