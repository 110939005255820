import {useState} from "react";
import {deserialize, serialize} from "serializr";
import { axiosInstance } from "../../interceptor/axiosInstance";
import {ApiRoutes} from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {ReportModel} from "../../models/Report/report.model";
import { MetaModel } from "../../models/Meta/meta.model";

const ReportService = () => {
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(false);
    const [reportNames, setReportNames] = useState<MetaModel[]>([]);
    const [reportDescription, setReportDescription] = useState<{[key: string]: string}[]>([])

    const fetchReportNames = () => {
        setLoading(true);
        setReportNames([])
        return axiosInstance
            .get(ApiRoutes.GET_REPORT_TYPES)
            .then((response) => {
            const transformedData = response?.data?.map((report: { name: string; description: string }) => ({
                id: report.name,
                name: report.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
            }));

            const descriptionData = response?.data?.reduce((accumulator: Record<string, string>, currentValue: { name: string; description: string }) => {
                accumulator[currentValue.name] = currentValue.description;
                return accumulator;
              }, {});
              
            const description: { [key: string]: string }[] = [descriptionData];
            setReportDescription(description)
            const meta = deserialize(
                MetaModel,
                transformedData
            ) as MetaModel[];
            setReportNames(meta);
            
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createReport = (
        data: ReportModel,
        onSuccess: Function
    ) => {
        const requestPayload = serialize(ReportModel, data);
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.CREAT_REPORT, requestPayload)
            .then((response) => {
                Notification({
                    description: "Report has been created successfully.",
                    type: NotificationTypes.SUCCESS,
                });
                onSuccess();
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return {
        loading,
        error,
        fetchReportNames,
        reportNames,
        reportDescription,
        createReport
    };
};

export default ReportService;
