import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { FieldArray, Form, Formik, FormikValues } from 'formik'
import { MetaModel } from '../../../../models/Meta/meta.model'
import MetaService from '../../../../services/MetaService/meta.service'
import ButtonComponent from '../../../../shared/components/ButtonComponent'
import CardComponent from '../../../../shared/components/CardComponent'
import CardSkeletonComponent from '../../../../shared/components/CardSkeletonComponent'
import IconComponent from '../../../../shared/components/IconComponent'
import InputField from '../../../../shared/components/InputField'
import "./categoriesConfig.scss"
import { categoriesConfigValidation } from './categoriesConfigValidation'
import minusIcon from "./../../../../assets/images/minus-icon.png"
import { LocalStorage } from '../../../../shared/utils/localStorageHelpers'
import { BadgeTypes } from '../../../../enums/badgeTypes'

const CategoriesConfig = () => {

    const { categories, fetchCategoryList, loading, updateCategory } = MetaService()

    const [isEdit, setIsEdit] = useState(false)

    const user = LocalStorage.getItem('USER');
    const isAdmin = user?.role === BadgeTypes.ADMIN

    const onSubmit = (data: FormikValues) => {
        updateCategory(data?.categories as MetaModel[], ()=>fetchCategoryList())
        setIsEdit(false)
    }

    useEffect(() => {
        fetchCategoryList()
    }, [])


    return (
        <div className="instore-form-view categories-config">
            <Formik
                initialValues={{ categories }}
                enableReinitialize
                onSubmit={onSubmit}
                validationSchema={categoriesConfigValidation}
            >
                {({ values, handleSubmit }) => (
                    <Form>
                        <div className="header">
                            <Row className='header-row' align="middle" justify="space-between">
                                <Col span={15}>
                                    <h5 className='title'>
                                        Store Categories
                                        {isAdmin && !isEdit && <IconComponent name="icon-edit-blue" onClick={() => setIsEdit(state => !state)} />}
                                    </h5>
                                </Col>
                                <Col span={9}>
                                    {isAdmin && isEdit && <div className="controllers">
                                        <ButtonComponent
                                            type="default"
                                            additionalTypes="secondary"
                                            size="small"
                                            onClick={() => setIsEdit(state => !state)}
                                        >
                                            Cancel
                                        </ButtonComponent>
                                        <ButtonComponent
                                            type={"primary"}
                                            htmlType={"submit"}
                                            size="small"
                                            loading={loading}
                                            onClick={handleSubmit}
                                        >
                                            Update
                                        </ButtonComponent>
                                    </div>}
                                </Col>
                            </Row>
                        </div>

                        <div className="content">
                            {
                                loading ?
                                    <CardSkeletonComponent className='purchase-status-skeleton' rows={14} /> :
                                    <CardComponent title='' className='category-section'>
                                        <div className="vendor-category-container">
                                            <FieldArray name='categories'
                                                render={(arrayHelpers) => (
                                                    <div className="">
                                                        {/* TODO: Needed for reference */}
                                                        {/* {
                                                            isEdit &&
                                                            <div className="add-category-container">
                                                                <p className='add-btn' onClick={() => arrayHelpers.unshift({ label: "" })}>+ Add new</p>
                                                            </div>
                                                        } */}
                                                        {values?.categories?.map((category, index) => (
                                                            <Row className={`input-row ${!isEdit && "disabled"}`}>
                                                                <Col span={20}>
                                                                    <InputField name={`categories.${index}.label`} placeholder='Enter' />
                                                                </Col>
                                                                <Col offset={1} span={2}>
                                                                    {
                                                                        !category?.value &&
                                                                        <p className='remove-icon' onClick={() => arrayHelpers.remove(index)}><img src={minusIcon} alt="minus" className='minus-icon' /></p>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </div>
                                                )} />
                                        </div>
                                    </CardComponent>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CategoriesConfig