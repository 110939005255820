import { Row, Col } from "antd";
import React, { FC } from "react";
import { Payments } from "../../../../models/PaymentsMetrics/paymentsMetrics.model";
import CardSkeletonComponent from "../../../../shared/components/CardSkeletonComponent";
import './paymentMetrics.scss'

interface PaymentMetricsProps {
  cardData?: Payments;
  walletData?: Payments;
}

const PaymentMetrics: FC<PaymentMetricsProps> = ({ cardData, walletData }) => {
  const loading = false;
  const success = cardData?.success ?? 0;
  const failure = cardData?.failure ?? 0;
  const initiated = cardData?.initiated ?? 0;

  const totalPayments = success + failure;
  const errorPercentage = totalPayments ? (failure / totalPayments) * 100 : 0;

  const walletSuccess = walletData?.success ?? 0;
  const walletFailure = walletData?.failure ?? 0;
  const walletInitiated = walletData?.initiated ?? 0;

  const walletTotalPayments = walletSuccess + walletFailure;
  const walletErrorPercentage = walletTotalPayments ? (walletFailure / walletTotalPayments) * 100 : 0;

  return (
    <div className="payment-metrics-component">
      <div className="dashboard-header">
        <h2 className="dashboard-title">Payment Metrics</h2>
      </div>
      {loading ? (
        <CardSkeletonComponent rows={14} />
      ) : (
        <div className="content">
          <Row>
            <Col className="title" span={24}>Credit Card Metrics</Col>
            <Col span={24} className="independent-kpi">
              <Row>
                <Col span={5}>
                  <div className="first">
                    <p className="label">Total # of payments</p>
                    <h3 className="value">{totalPayments}</h3>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="second">
                    <p className="label">Total # of initiated payments</p>
                    <h3 className="value">{initiated}</h3>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="second">
                    <p className="label">Total # of success payments</p>
                    <h3 className="value">{success}</h3>
                  </div>
                </Col>
                <Col span={3}>
                  <div className="second">
                    <p className="label">Total # errors</p>
                    <h3 className="value">{failure}</h3>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="second">
                    <p className="label">
                      Percentage of errors from total payments made
                    </p>
                    <h3 className="value">{Math.round(errorPercentage)}%</h3>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="title" span={24}> E-Wallet Metrics</Col>
            <Col span={24} className="independent-kpi">
              <Row>
                <Col span={5}>
                  <div className="first">
                    <p className="label">Total # of payments</p>
                    <h3 className="value">{walletTotalPayments}</h3>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="second">
                    <p className="label">Total # of initiated payments</p>
                    <h3 className="value">{walletInitiated}</h3>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="second">
                    <p className="label">Total # of success payments</p>
                    <h3 className="value">{walletSuccess}</h3>
                  </div>
                </Col>
                <Col span={3}>
                  <div className="second">
                    <p className="label">Total # errors</p>
                    <h3 className="value">{walletFailure}</h3>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="second">
                    <p className="label">
                      Percentage of errors from total payments made
                    </p>
                    <h3 className="value">{Math.round(walletErrorPercentage)}%</h3>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default PaymentMetrics;
