
import {serializable, alias, object, list, primitive} from 'serializr';

export class RewardsHistory { 

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('is_inbound', primitive()))
	isInbound?: boolean;

	@serializable(alias('origin_id', primitive()))
	originId?: number;

	@serializable(alias('origin_name', primitive()))
	originName?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

}