/* eslint-disable jsx-a11y/alt-text */
import React, { FC } from "react";
import TableComponent from "../../../../../shared/components/TableComponent";
import "./purchaseSettlementsCard.scss";
import { currencyString } from "../../..";
import {
  PurchaseModel,
  PurchaseSettlementsModel,
} from "../../../../../models/Purchase/purchase.model";
import moment from "moment";
import IconComponent from "../../../../../shared/components/IconComponent";
import { generatePath } from "react-router-dom";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import refundTransactionIcon from "../../../../../assets/images/refund_transaction_icon.svg";
import { Col, Row } from "antd";
import SettlementsImage from "../../../../../assets/images/settlements-title.png"

interface PurchaseSettlementsCardProps {
  purchase: PurchaseModel | undefined;
  loading: boolean;
}

const PurchaseSettlementsCard: FC<PurchaseSettlementsCardProps> = (props) => {
  const { purchase, loading } = props;

  const renderSettlementId = (
    text: string,
    record: PurchaseSettlementsModel
  ) => {
    if (text && record.id) {
      const path =
        record?.settlement_type === "Partner Fee"
          ? AppRoutes.PARTNER_PAST_SETTLEMENTS_VIEW
          : record?.settlement_type === "Payment Collection"
          ? AppRoutes.COLLECTION_PAST_SETTLEMENTS_VIEW
          : record?.settlement_type === "Vendor Settlements"
          ? AppRoutes.PAST_SETTLEMENTS_VIEW
          : undefined;
      if (path) {
        return (
          <Link
            to={generatePath(path, {
              settlementId: record?.id.toString(),
            })}
          >
            <span className="settlement-id">{text}</span>
          </Link>
        );
      } else {
        return <span>{text}</span>;
      }
    } else {
      return <span>-</span>;
    }
  };

  return (
    <div className="purchase-settlements-card purchase-card-table">
      <div className="purchase-card-title"></div>
      <Row className="purchase-card-title" align="middle" gutter={12}>
        <Col>
          <img src={SettlementsImage} alt="" />
        </Col>
        <Col>
          <span className="text">Settlements</span>
        </Col>
      </Row>
      <TableComponent
        columns={[
          {
            title: "Settlement",
            dataIndex: "settlement_type",
            key: "settlement_type",
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Type",
            dataIndex: "transaction_type",
            key: "transaction_type",
            render: (text) => (
              <span>
                {["Refund", "Partial Refund"].includes(text) ? (
                  <img
                    className="refund-transaction-icon"
                    src={refundTransactionIcon}
                  />
                ) : (
                  <IconComponent
                    name={
                      text === "Purchase" ? "icon-purchases" : "icon-payments"
                    }
                  />
                )}{" "}
                {text}
              </span>
            ),
          },
          {
            title: "Payment Id",
            dataIndex: "external_payment_id",
            key: "external_payment_id",
            render: (text) => (text ? <span>{text}</span> : <span>-</span>),
          },
          {
            title: "Settlement Amount",
            dataIndex: "settlement_amount",
            key: "settlement_amount",
            render: (text, record) =>
              ["Purchase", "Payment", "Cashout"].includes(
                record?.transaction_type
              ) ? (
                <span>
                  &nbsp; &nbsp; {currencyString}
                  {text}
                </span>
              ) : (
                <span className={"payment-loss"}>
                  {"-"} &nbsp; {currencyString}
                  {text}
                </span>
              ),
          },
          {
            title: "Settlement ID",
            dataIndex: "settlement_id",
            key: "settlement_id",
            render: (text, record) => renderSettlementId(text, record),
          },
          {
            title: "Settled Date",
            dataIndex: "settled_date",
            key: "settled_date",
            render: (text) => moment(text)?.format("DD MMM YYYY"),
          },
        ]}
        data={purchase?.settlements}
        loading={loading}
      />
    </div>
  );
};

export default PurchaseSettlementsCard;
