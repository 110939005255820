import { alias, custom, list, object, primitive, serializable } from "serializr";

export class VendorPurchaseConfigModel {

        @serializable(alias('id', primitive()))
        id?: number;
    
        @serializable(alias('deall_score_range', list(primitive())))
        deallScoreRange?: number[] = [];

        @serializable(alias('deall_score_range', custom((value) => value, (value) => `${value?.[0]} - ${value?.[9]}`)))
        internalScore?: string;
    
        @serializable(alias('purchase_power', primitive()))
        purchasePower?: number;
    
        @serializable(alias('installments', primitive()))
        installments?: number;
    
        @serializable(alias('vendor_id', primitive()))
        vendorId?: number;

}