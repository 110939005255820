
import { Answer } from '../Answer/answer.model';
import {Answers} from '../Answers/answers.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class Questions { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('question', object(Answer)))
	question?: Answer;

	@serializable(alias('order', primitive()))
	order?: number;

	@serializable(alias('question_type', primitive()))
	questionType?: string;

	@serializable(alias('image_category', primitive()))
	imageCategory?: string;

	@serializable(alias('answers', list(object(Answers))))
	answers?: Answers[] = [];

}