
import { ChartData } from 'chart.js'
import React, { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import "./barChartComponent.scss"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { BarChartOptions } from '../../types/BarChartOptions.type';
import DashboardFilterMenu from '../DashboardFilterMenu';
import CardSkeletonComponent from '../CardSkeletonComponent';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface BarChartComponentProps {
    options: BarChartOptions,
    classname?: string,
    title: string,
    backgroundColor?: string,
    loading?: boolean,
    onChange?: Function,
    data?: {
        labels: string[];
        datasets: {
            data: number[];
            fill?: boolean;
            backgroundColor: string | string[];
            borderColor?: string;
        }[];
    }
 }

const BarChartComponent: FC<BarChartComponentProps> = ({title, data, options, loading, classname, backgroundColor, onChange }) => {

    return (
        <div className="bar-chart-component">
            <div className="dashboard-header space-between">
                <h3 className='title'>{title}</h3>
            </div>
            {
                loading ?
                    <CardSkeletonComponent className="transaction-per-customer__skeleton" rows={3} /> :
                    data && <Bar options={options} data={data} />
            }
        </div>
    )
}

export default BarChartComponent