
import {serializable, alias, object, list, primitive} from 'serializr';

export class OnboardingDetail { 

	@serializable(alias('account_created_at', primitive()))
	accountCreatedAt?: string;

	@serializable(alias('onboarding_started_at', primitive()))
	onboardingStartedAt?: string;

	@serializable(alias('verification_started_at', primitive()))
	verificationStartedAt?: string;

	@serializable(alias('verification_last_retry_at', primitive()))
	verificationLastRetryAt?: string;

	@serializable(alias('verification_last_failed_at', primitive()))
	verificationLastFailedAt?: string;

	@serializable(alias('onboarded_at', primitive()))
	onboardedAt?: string;

	@serializable(alias('rejected_at', primitive()))
	rejectedAt?: string;

	@serializable(alias('approved_by', primitive()))
	approvedBy?: string;

	@serializable(alias('retries_left', primitive()))
	retriesLeft?: number;

	@serializable(alias('digified_comments', primitive()))
	digifiedComments?: string;

	@serializable(alias('digify_error_code', primitive()))
	digifiedErrorCode?: string;

	@serializable(alias('admin_comments', primitive()))
	adminComments?: string;

	@serializable(alias('admin_name', primitive()))
	adminName?: string;

}