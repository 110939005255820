import { Col, Row, Skeleton } from "antd";
import React, { FC } from "react";
import faker from "faker";
import "./vendorPersonalDetailsCard.scss";
import IconComponent from "../../../../../../../shared/components/IconComponent";
import {
  Vendor,
  VendorModel,
} from "../../../../../../../models/Vendor/vendor.model";
import CardSkeletonComponent from "../../../../../../../shared/components/CardSkeletonComponent";
import bankIcon from "./../../../../../../../assets/images/bank-icon.png";
import walletIcon from "./../../../../../../../assets/images/wallet-icon.png";
import scrollIcon from "./../../../../../../../assets/images/scroll.png";
import pencilIcon from "./../../../../../../../assets/images/pencil-edit.png";
interface VendorPersonalDetailsCardProps {
  vendor: Vendor | undefined;
  loading: boolean;
  editHandler?: () => void;
}
const VendorPersonalDetailsCard: FC<VendorPersonalDetailsCardProps> = ({
  vendor,
  loading,
  editHandler,
}) => {
  return loading ? (
    <CardSkeletonComponent className="card-wide-skeleton-component" rows={3} />
  ) : (
    <div className="vendor-personal-details-card">
      <Row gutter={[18, 50]}>
        <Col span={12}>
          <div className="vendor-personal-details-card-item">
            <Row>
              <Col className="image__container">
                <div className="icon-container">
                  <img src={bankIcon} alt="bank" className="bank-icon" />
                </div>
              </Col>
              <Col span={21}>
                <div className="label">BANK DETAILS</div>
                <div className="header">
                  {vendor?.paymentDetails?.accountNumber
                    ?.match(/.{1,4}/g)
                    ?.join(" ")}
                </div>
                <div>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Name
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.accountHolderName}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Address
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.address}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Swift code
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.swiftCode}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Branch
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.branch}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      IBAN
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.iban}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div className="vendor-personal-details-card-item">
            <Row>
              <Col className="image__container">
                <div className="icon-container">
                  <img src={scrollIcon} alt="bank" className="bank-icon" />
                </div>
              </Col>
              <Col span={21}>
                <div className="label">TAX REGISTRATION</div>
                <div className="header">
                  {vendor?.vendorDetails?.taxRegistrationNumber ??
                    (editHandler ? (
                      <span className="update-alert">
                        Update tax registration number{" "}
                        <img
                          src={pencilIcon}
                          className="edit-icon"
                          alt="edit"
                          onClick={editHandler}
                        />
                      </span>
                    ) : (
                      "---"
                    ))}
                </div>
                <div>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Name on Invoice
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.vendorDetails?.taxRegistrationName ?? "---"}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Governorate
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.vendorDetails?.governate ?? "---"}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Region/City
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.vendorDetails?.regionCity ?? "---"}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Street
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.vendorDetails?.street ?? "---"}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Building No.
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.vendorDetails?.buildingNumber ?? "---"}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Postal code
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.vendorDetails?.postalCode ?? "---"}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div className="vendor-personal-details-card-item">
            <Row>
              <Col className="image__container">
                <div className="icon-container">
                  <img src={walletIcon} alt="bank" className="bank-icon" />
                </div>
              </Col>
              <Col span={21}>
                <div className="label">MOBILE WALLET</div>
                <div className="header">
                  {vendor?.paymentDetails?.walletNumber}
                </div>
                <div>
                  <Row className="details">
                    <Col span={6} className="details-label">
                      Type
                    </Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.walletType?.name ?? "-"}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VendorPersonalDetailsCard;
