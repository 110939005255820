import { Form, Formik } from 'formik'
import React, { FC } from 'react'
import { BadgeTypes } from '../../../../../enums/badgeTypes'
import { CustomerDocumentModel } from '../../../../../models/Customer/customer.model'
import InputField from '../../../InputField'
import ModalComponent from '../../../ModalComponent'
import * as Yup from "yup";
import { Col, Row } from 'antd'

interface FileViewControllerModalProps {
    visible: boolean,
    handleUpdate: (data: CustomerDocumentModel) => void,
    closeHandler: () => void,
    attachmentStatus: BadgeTypes
    successProps: {
        title: string;
        clickHandler?: (() => void) | undefined;
        loading?: boolean | undefined;
    },
    cancelProps: {
        title: string;
        clickHandler: () => void;
    }
    alert: {
        title: string;
        description?: string | undefined;
        buttonType?: "affirmative" | "negative" | "primary" | undefined;
    }
    fieldLabel: string;
    fieldLabel1?: string;
}


const FileViewControllerModal: FC<FileViewControllerModalProps> = (props) => {
    const { visible, handleUpdate, closeHandler, successProps, cancelProps, alert, attachmentStatus, fieldLabel, fieldLabel1 } = props
    
    const fileViewValidationSchema = Yup.object().shape({
        adminComments: Yup.string().when("attachmentStatus", {
            is: BadgeTypes.REJECTED,
            then: Yup.string().required('Rejection reason is required'),
            otherwise: Yup.string(),
        }) 
    })

    return (
        <div className="file-view-controller-modal">
            <Formik
                initialValues={{ adminComments: "", attachmentStatus, riskModelComment: "" }}
                enableReinitialize
                onSubmit={handleUpdate}
                validationSchema={fileViewValidationSchema}
            >
                {({ handleSubmit, setFieldValue, values }) => (
                    <ModalComponent
                        type="alert-large"
                        alert={alert}
                        visible={visible}
                        closeHandler={closeHandler}
                        successProps={{...successProps, clickHandler: handleSubmit}}
                        cancelProps={cancelProps}
                    >
                        <Form>
                            <Row>
                            {fieldLabel1 && 
                                <>
                                <Col span={24}>
                                    <InputField textArea type="text" name="riskModelComment" label={fieldLabel1} placeholder="Enter" />
                                </Col>
                                <Col span={24}>
                                    <div>Fill this information in order to help our risk model improve</div>
                                </Col>
                                </>
                            }
                                <Col span={24}>
                                    <InputField textArea type="text" name="adminComments" label={fieldLabel} placeholder="Enter" />
                                </Col>
                            </Row>                            
                        </Form>
                    </ModalComponent>
                )}
            </Formik>
        </div>
    )
}

export default FileViewControllerModal