import { Col, Divider, Dropdown, Row } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import IconComponent from "../../IconComponent";
import "./profileDetails.scss";
import faker from "faker";
import ProfileEditForm from "./ProfileEditForm";
import PasswordChangeForm from "./PasswordChangeForm";
import UserService from "../../../../services/AuthService/auth.service";
import { LocalStorage } from "../../../utils/localStorageHelpers";
import { UserModel } from "../../../../models/User/user.model";
import TwoFactorAuthenticationForm from "./TwoFactorAuthenticationForm";


const ProfileDetails = () => {
  const [showProfileEditForm, setProfileEditFormVisibility] = useState<boolean>(false);
  const [showPasswordChangeForm, setPasswordChangeFormVisibility] = useState<boolean>(false);
  const [showTwoFactor, setTwoFactorFormVisibility] = useState<boolean>(false);
  const { logoutUser } = UserService();
  const user = LocalStorage.getItem('USER') as UserModel
  return (
    <Fragment>
      <ProfileEditForm
        visible={showProfileEditForm}
        closeHandler={() =>
          setProfileEditFormVisibility((visibility) => !visibility)
        }
      // profile={}
      />
      <TwoFactorAuthenticationForm
        visible={showTwoFactor}
        closeHandler={() =>
            setTwoFactorFormVisibility((visibility) => !visibility)
        }
      />
      <PasswordChangeForm
        visible={showPasswordChangeForm}
        closeHandler={() =>
          setPasswordChangeFormVisibility((visibility) => !visibility)
        }
      />
      <Dropdown
        className="profile-details"
        trigger={["click"]}
        overlay={
          <div className="profile-details__overlay">
            <Row className="profile-details-name__container" align="middle">
              <Col span={6}>
                <IconComponent name="icon-user-profile1"/>
              </Col>
              <Col>
                <p className="name">{user?.fullName || "Rise Admin"}</p>
                <p className="role">{user?.role}</p>
              </Col>
            </Row>
            <div className="profile-details-contact__container">
              <p className="phone">
                <IconComponent name="icon-phone" />
                {user?.countryCode?.isdCode} {user?.mobileNumber || faker.phone.phoneNumber()}
              </p>
              <p className="email">
                <IconComponent name="icon-email" />
                {user?.email || faker.internet.exampleEmail()}
              </p>
            </div>
            <Divider />
            <div className="profile-details-options__container">
              <div
                  className="edit-profile"
                  onClick={() => setProfileEditFormVisibility(true)}
              >
                <IconComponent name="icon-edit"/>
                Edit Profile
              </div>
              <div
                  className="two-factor-authentication"
                  onClick={() => setTwoFactorFormVisibility(true)}
              >
                <IconComponent name="icon-guard"/>
                Two-Factor Authentication
              </div>
              <div
                  className="change-password"
                  onClick={() => setPasswordChangeFormVisibility(true)}
              >
                <IconComponent name="icon-password"/>
                Change Password
              </div>
              <div
                  className="logout"
                  onClick={(e) => {
                    logoutUser()
                    e.stopPropagation()
                  }}
              >
                <IconComponent name="icon-logout"/>
                Logout
              </div>
            </div>
          </div>
        }
      >
        <div>
          <IconComponent name="icon-user-profile1"/>
        </div>
      </Dropdown>
    </Fragment>
  );
};

export default ProfileDetails;
