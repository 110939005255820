import { Row, Col } from 'antd'
import React, { FC, useState } from 'react'
import { DashboardFilterModel } from '../../../../../models/Dashboard/dashboard.model'
import DashboardService from '../../../../../services/DasboardService/dashboard.service'
import DashboardFilterMenu from '../../../../../shared/components/DashboardFilterMenu'
import SettlementWidget from '../../../../../shared/components/SettlementWidget'
import "./settlementcomponent.scss"
import { DashboardDateFilterTypes } from '../../../../../shared/types/DashboardDateFilterTypes'

interface SettlementComponentProps {
    params: DashboardFilterModel,
    setIsDasboardSettlementsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isDasboardSettlementsLoading: boolean,
    isDasboardSettlementsVisible: boolean
}

const SettlementComponent: FC<SettlementComponentProps> = (props) => {
    const {params, setIsDasboardSettlementsLoading, isDasboardSettlementsLoading, isDasboardSettlementsVisible} = props
    const { dashboardVendorSettlement, dashboardPartnerSettlement, dashboardPaymentSettlement, fetchDashboardVendorSettlements, fetchDashboardPartnerSettlements, fetchDashboardPaymentSettlements, dashboardVendorSettlementLoading, dashboardPartnerSettlementLoading, dashboardPaymentSettlementLoading } = DashboardService()
    return (
        <div className="settlementcomponent">
            <div className="dashboard-header">
                <h2 className='dashboard-title'>Settlements</h2>
            </div>
            <Row>
                <Col span={8}><SettlementWidget setIsDasboardSettlementsLoading={setIsDasboardSettlementsLoading} isDasboardSettlementsLoading={isDasboardSettlementsLoading} isDasboardSettlementsVisible={isDasboardSettlementsVisible} vendorType="vendor" settlementType="vendor" onChange={fetchDashboardVendorSettlements} data={dashboardVendorSettlement} params={params} header="Vendor Settlements" loading={dashboardVendorSettlementLoading} /></Col>
                <Col span={8}><SettlementWidget setIsDasboardSettlementsLoading={setIsDasboardSettlementsLoading} isDasboardSettlementsLoading={isDasboardSettlementsLoading} isDasboardSettlementsVisible={isDasboardSettlementsVisible} vendorType="partner" settlementType="partner" onChange={fetchDashboardPartnerSettlements} data={dashboardPartnerSettlement} params={params} header="Partner Fees" loading={dashboardPartnerSettlementLoading}  /></Col>
                <Col span={8}><SettlementWidget setIsDasboardSettlementsLoading={setIsDasboardSettlementsLoading} isDasboardSettlementsLoading={isDasboardSettlementsLoading} isDasboardSettlementsVisible={isDasboardSettlementsVisible} vendorType="partner" settlementType="payment" onChange={fetchDashboardPaymentSettlements} data={dashboardPaymentSettlement} params={params} header="Payment Collection" loading={dashboardPaymentSettlementLoading} /></Col>
            </Row>
        </div>
    )
}

export default SettlementComponent