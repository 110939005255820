import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useState } from "react";
import { SettlementModel } from "../../../../../models/Settlement/settlement.model";
import PartnerfeeService from "../../../../../services/PartnerfeeService/partnerfee.service";
import { SettlementService } from "../../../../../services/SettlementService/settlement.service";
import PaymentcollectionService from "../../../../../services/PaymentcollectionService/paymentcollection.service";
import DatePickerField from "../../../../../shared/components/DatePickerField";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import "./editVendorSettlementDate.scss";
import { Moment } from "moment";
import { settlementDateValidation } from "./settlementDateValidation";

interface EditVendorSettlementDateProps {
  settlement?: SettlementModel,
  visible: boolean;
  closeHandler: () => void;
  handleRefresh: () => void;
  type?: "Vendor" | "Partner Fee" | "Collection";
  dateRange?: Moment;
}

const EditVendorSettlementDate: FC<EditVendorSettlementDateProps> = (props) => {
  const { visible, closeHandler, handleRefresh, settlement, type, dateRange } = props;
  const { updatePastSettlementDetails, loading } = SettlementService();
  const { updatePastPartnerSettlementDetails, loading: partnerLoading } = PartnerfeeService();
  const { updatePastCollectionDetails, loading: collectionLoading } = PaymentcollectionService()
  const [initialValues, setInitialValues] = useState({
    settledDate: settlement?.settledDate
  });
  const onSubmit = (values: FormikValues) => {
    switch (type) {
      case "Vendor":
        updatePastSettlementDetails({...values, id: settlement?.id}, ()=>{
          closeHandler()
        })
        break;
      case "Partner Fee":
        updatePastPartnerSettlementDetails({...values, id: settlement?.id}, ()=>{
          closeHandler()
        })
        break;
        case "Collection":
          updatePastCollectionDetails({...values, id: settlement?.id}, ()=>{
            closeHandler()
          })
          break;
      default:
        break;
    }
    handleRefresh()
  };

  useEffect(() => {
    setInitialValues({
      settledDate: settlement?.settledDate
    })
  }, [settlement])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={settlementDateValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <ModalComponent
          type="small"
          visible={visible}
          closeHandler={closeHandler}
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading || partnerLoading || collectionLoading
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: closeHandler,
          }}
          title={"Edit Settled Date"}
        >
          <div className="edit-vendor-settlement">
            <Form>
              <DatePickerField
                format="DD MMM YYYY"
                value={values?.settledDate}
                onChange={(date, dateString) => setFieldValue("settledDate", dateString)}
                label="Settled Date"
                name="settledDate"
                placeholder="Select"
                dateRange={dateRange}
              />
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default EditVendorSettlementDate;
