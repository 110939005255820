import React from "react";
import { Checkbox, Col, Row } from "antd";
import { Field, Form, Formik, FormikProps, FormikValues } from "formik";
import { FC, useRef } from "react";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import { createRefundConfirmModalValidation, } from "./createRefundConfirmationModalValidation";
import alertIcon from "./../../../../../../assets/images/alert-for-refund-creation.svg"
import "./createRefundConfirmationModal.scss"
import { RefundDetails } from "../../../../../../models/RefundDetails/refundDetails.model";
import PurchaseService from "../../../../../../services/PurchaseService/purchase.service";
import Error from "../../../../../../shared/components/Error";

interface CreateRefundConfirmationModalProps {
    visible: boolean;
    closeHandler: () => void;
    refundDetails: RefundDetails;
    refreshHandler?: Function
}
const CreateRefundConfirmationModal: FC<CreateRefundConfirmationModalProps> = (props) => {
    const { visible, closeHandler, refundDetails, refreshHandler } = props;
    const { createPurchaseRefund, loading } = PurchaseService() 
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const resetFormAndClose = () => {
        formRef?.current?.resetForm({ values: {} });
        closeHandler()
    }


    const onSubmit = (values: FormikValues) => {
        createPurchaseRefund(refundDetails?.purchaseId as string, {...refundDetails}, ()=>{
            closeHandler()
            refreshHandler && refreshHandler()
        })
    };

    return (
        <Formik
            initialValues={{ confirmCreateRefund: false }}
            enableReinitialize
            onSubmit={onSubmit}
            innerRef={formRef}
            validationSchema={createRefundConfirmModalValidation}
        >
            {({ values, setFieldValue, handleSubmit, errors }) => (
                <Form className="create-refund-confirmation-form">
                    <ModalComponent
                        visible={visible}
                        closeHandler={resetFormAndClose}
                        type="small"
                        title=""
                        successProps={{
                            title: "Confirm",
                            clickHandler: handleSubmit,
                            loading,
                        }}
                        cancelProps={{
                            title: "Cancel",
                            clickHandler: resetFormAndClose,
                        }}
                        className="create-refund-confirmation-modal"
                    >
                        <div className="alert-info">
                            <img src={alertIcon} alt="alert-refund" />
                            <h3>Are you sure to create a refund?</h3>
                            <p>Customer purchase payments will be calculated upon confirmation</p>
                        </div>
                        <Row className="refund-data">
                            <Col span={8}>
                                <p className="refund-data-value">
                                    LE {refundDetails?.refundAmount}
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className="refund-data-value">
                                    {refundDetails?.returnedDate}
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className="refund-data-value last">
                                    {refundDetails?.receiptId}
                                </p>
                            </Col>
                        </Row>
                        <div className="checkbox-action">
                            <Checkbox name="confirmCreateRefund" checked={values?.confirmCreateRefund} onChange={(e)=>setFieldValue("confirmCreateRefund",e.target.checked)}>
                                <p className="checkbox-action-title">Check the box to confirm the action</p>
                            </Checkbox>
                            <Error message={errors?.confirmCreateRefund as string} name={"confirmCreateRefund"} />
                        </div>
                    </ModalComponent>
                </Form>
            )}
        </Formik>
    );
};

export default CreateRefundConfirmationModal;
