import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { AllPlanDetails } from '../../models/UpcomingPlan/upcomingPlan.model';
import { ActiveAndUpcomingPlan } from '../../models/ActivePlan/activePlan.model';
import { ReferralRewardsMetrics } from '../../models/ReferralRewardsMetrics/referralRewardsMetrics.model';

const PurchasingStatusService = () => {

    const [purchasingStatusloading, setPurchasingStatusloading] = useState<boolean>(false)
    const [referralRewardsMetricsloading, setReferralRewardsMetricsloading] = useState<boolean>(false)

    const [allPlanDetails, setAllPlanDetails] = useState<AllPlanDetails>()
    const [activeAndUpcomingPlan, setActiveAndUpcomingPlan] = useState<ActiveAndUpcomingPlan>()
    const [referralRewardMetrics, setReferralRewardMetrics] = useState<ReferralRewardsMetrics>()

    const fetchAllPlanDetails = async (customerId: number) => {
        try {
            setPurchasingStatusloading(true)
            const API_URL = generatePath(ApiRoutes.ALL_PLAN_DETAILS, { customerId: customerId.toString() })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(AllPlanDetails, response.data);
            setAllPlanDetails(data)
        } catch (error) {
            setAllPlanDetails([] as AllPlanDetails)
        } finally {
            setPurchasingStatusloading(false)
        }
    }
    
    const fetchActiveAndUpcomingPlan = async (customerId: number) => {
        try {
            setPurchasingStatusloading(true)
            const API_URL = generatePath(ApiRoutes.ACTIVE_AND_UPCOMING_PLAN, { customerId: customerId.toString() })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(ActiveAndUpcomingPlan, response.data);
            setActiveAndUpcomingPlan(data)
        } catch (error) {
            setAllPlanDetails({})
        } finally {
            setPurchasingStatusloading(false)
        }
    }

    const fetchReferralRewardMetrics = async (customerId: string) => {
        try {
            setReferralRewardsMetricsloading(true)
            const API_URL = generatePath(ApiRoutes.REFERRAL_REWARDS_METRICS, { customerId: customerId })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(ReferralRewardsMetrics, response.data);
            setReferralRewardMetrics(data)
        } catch (error) {
            setReferralRewardMetrics({})
        } finally {
            setReferralRewardsMetricsloading(false)
        }
    }

    return {
        purchasingStatusloading,
        fetchAllPlanDetails,
        allPlanDetails,
        fetchActiveAndUpcomingPlan,
        activeAndUpcomingPlan,
        referralRewardsMetricsloading,
        fetchReferralRewardMetrics,
        referralRewardMetrics
    }
}

export default PurchasingStatusService