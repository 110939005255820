import { axiosInstance } from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { generatePath, useNavigate } from "react-router-dom";
import { NotificationModel } from "../../models/notification.model";

const NotificationService = () => {
	const navigate = useNavigate();

	const [error, setError] = useState<Error>();

	const [loading, setLoading] = useState(false);

    const [notifications, setNotifications] = useState<NotificationModel[]>([])
    const [notification, setNotification] = useState<NotificationModel>()

	const getNotifications = () => {
		setLoading(true);
		return axiosInstance
			.get(ApiRoutes.NOTIFICATIONS)
			.then((response) => {
				const _notifications:any = deserialize(NotificationModel, response.data["notifications"]);
        setNotifications(_notifications)
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

    const updateNotificationIsSeen = (notificationId:string) => {
        setLoading(true);
        const API_URL = generatePath(ApiRoutes.NOTIFICATION,{notificationId});
        return axiosInstance
          .put(API_URL)
          .then((response) => {
            const _notification=deserialize(NotificationModel,response?.data["notification"])
            setNotification(_notification);
          })
          .catch((error) => {
            setError(error);
          })
          .finally(() => {
            setLoading(false);
          });
    };

    const deleteNotification = (notificationId:string) => {
        setLoading(true)
        const API_URL = generatePath(ApiRoutes.NOTIFICATION,{notificationId});
        return axiosInstance
          .delete(API_URL)
          .then(() => {
          })
          .catch((error) => {
            setError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      };

	return {
		error,
		loading,
		getNotifications,
        notifications,
        updateNotificationIsSeen,
        notification,
        deleteNotification
	};
};

export default NotificationService;
