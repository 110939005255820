import { serializable, alias, object, list, primitive } from 'serializr';
import { BadgeTypes } from '../../enums/badgeTypes';
import { DocumentTypes } from '../../enums/documentTypes';

const {BANK_STATEMENT, PROFILE_PIC, NATIONAL_ID_BACK, NATIONAL_ID_FRONT, EMPLOYMENT_LETTER, TAX_CARD, COMMERCIAL_REGISTRATION} = DocumentTypes

export class AttachmentModel {

    @serializable(alias("file_type", primitive()))
    fileType?: string;

    @serializable(alias("created_at", primitive()))
    createdAt?: string;

    @serializable(alias("updated_at", primitive()))
    updatedAt?: string;

    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("filename", primitive()))
    filename?: string;

    @serializable(alias("s3_key", primitive()))
    s3Key?: string;

    @serializable(alias("s3_url", primitive()))
    s3Url?: string;

    @serializable(alias("attachment_status", primitive()))
    attachmentStatus?: string;
    
    @serializable(alias("attachment_type", primitive()))
    attachmentType?: DocumentTypes;

    @serializable(alias("customer_attachment_id", primitive()))
    customerAttachmentId?: string;

    @serializable(alias("vendor_attachment_id", primitive()))
    vendorAttachmentId?: number;
}