import React, { FC, Fragment, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomerService from "../../../services/CustomerService/customer.service";
import CardSkeletonComponent from "../CardSkeletonComponent";
import FileViewer from "../FileViewer";
import ApproveDocumentsControllers from "./FileViewControllers";
import "./fileView.scss";
import { Col, Row } from "antd";
import ButtonComponent from "../ButtonComponent";
import BadgeComponent from "../BadgeComponent";
import { BadgeTypes } from "../../../enums/badgeTypes";
import backIcon from "./../../../assets/images/back-icon.png"
import { formatDate } from "../../utils/formatDate";
import { DocumentTypesDefinition } from "../../../definitions/documentTypesDefinition";
import { DocumentTypes } from "../../../enums/documentTypes";
import PurchasingStatusService from "../../../services/PurchasingStatusService/purchasingStatus.service";
import { displayDash } from "../../utils/displayDash";
import { currencyString } from "../../../views/Home";
import { dashboardFraction } from "../../utils/dashboardFraction";
import helpImage from "../../../assets/images/help-blue.png"
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { SortFilterModel } from "../../../models/SortFilterModel/SortFilterModel.model";
import VendorService from "../../../services/VendorService/vendor.service";

interface FileViewStateType {
  filename: string,
  fileType: string,
  s3Url: string,
  attachmentType: string,
  customerStatus?: BadgeTypes,
  page?: string,
  filterOptions?: SortFilterModel,
  from?: 'customer' | 'vendor' | '';
}

const FileView: FC = () => {
  const { fileId: documentId } = useParams();
  const { fetchCustomerDocument, loading, customerDocument, fetchCustomerDetails, customer, } = CustomerService();
  const { fetchVendorDocument, vendorDocument } = VendorService();
  const { fetchActiveAndUpcomingPlan, activeAndUpcomingPlan } = PurchasingStatusService();
    // TODO: Revert blob change
  // const [fileBlob, setFileBlob] = useState("")

  const DocumentsToApprove = 
  [ DocumentTypes.UTILITY_BILL, 
    DocumentTypes.BANK_STATEMENT, 
    DocumentTypes.EMPLOYMENT_LETTER, 
    DocumentTypes.PROOF_OF_INCOME, 
    DocumentTypes.PROOF_OF_ASSET, 
    DocumentTypes.VEHICLE_CARD, 
    DocumentTypes.SPORTS_CLUB_MEMBERSHIP, 
    DocumentTypes.PAYSLIP, 
    DocumentTypes.PROPERTY_CONTRACT,
    DocumentTypes.CREDIT_CARD_STATEMENT,
    DocumentTypes.PAYSLIP_BANK_STATEMENT,
    DocumentTypes.EMPLOYMENT_LETTER_MEDICAL_CARD,
    DocumentTypes.MEDICAL_CARD,
    DocumentTypes.STUDENT_CARD]
  
  const navigate = useNavigate()
  const location = useLocation();
  const state: FileViewStateType = location?.state as FileViewStateType;
  const isCustomer = state?.from === 'customer'
  const isVendor = state?.from === 'vendor'
  const backRouteURL = `${AppRoutes.CUSTOMERS}#approve-documents`
  // TODO: Revert blob change
  // const fetchData = async () => {
  //   const data = await convertS3urlTOBlob(customerDocument?.attachmentDetails?.s3Url as string)
  //   setFileBlob(data)
  // }

  useEffect(() => {
    isCustomer && fetchCustomerDocument(documentId as string)
    isVendor && fetchVendorDocument(documentId as string)
  }, [])

  useEffect(() => {
    if (customerDocument?.customerId) {
      fetchCustomerDetails(customerDocument?.customerId.toString());
      fetchActiveAndUpcomingPlan(customerDocument?.customerId)
    }
  }, [customerDocument])
  // TODO: Revert blob change
  // useEffect(() => {
  //   customerDocument && fetchData()
  // }, [customerDocument])

  return (
    <Fragment>
      {loading ? (
        <div className="skeleton__wrapper">
          <CardSkeletonComponent rows={20} className="document__skeleton" />
        </div>
      ) : (
        <div className="approve-documents-view">
          <Row className="approve-documents-view__header">
            <Col xs={4} sm={4} lg={1}>
              <span className="back-icon" onClick={() => {
                state.page ? navigate({pathname: generatePath(backRouteURL)}, {state: {page: state.page, filterOptions: state?.filterOptions}}) : navigate(-1)
              }}>
                <img src={backIcon} alt="back" className="back-icon-image" />
              </span>
            </Col>
            {isVendor && <>
              <Col xs={16} sm={16} lg={19}>
                <div className="filename">
                  <h2>{vendorDocument?.attachment?.filename}</h2>
                </div>
              </Col>
            </>}
            {isCustomer && <>
              <Col xs={16} sm={16} lg={19}>
                <div className="filename">
                  <h2>{DocumentTypesDefinition[customerDocument?.attachmentType as DocumentTypes]} <span><BadgeComponent type={customerDocument?.attachmentStatus as BadgeTypes} className="fill" /></span> </h2>
                </div>
                <div className="approve-document-info">
                  <Fragment>
                    <Row className="details" gutter={10}>
                      <Col className="label" lg={4} sm={2}>Customer</Col>
                      <Col className="value" lg={20} sm={22}> <img src={customerDocument?.profilePic?.s3Url} className="customer-image" alt="" />{customerDocument?.customerName} <span className="info-icon"><img alt="info" onClick={() => navigate(generatePath(AppRoutes.CUSTOMERS_LIST_VIEW, {customerId: customerDocument?.customerId?.toString()}))} src={helpImage}></img></span></Col>
                    </Row>
                    <Row className="details" gutter={10}>
                      <Col className="label" lg={4} sm={1}>Uploaded on</Col>
                      <Col className="value" lg={20} sm={23}>{customerDocument?.submittedOn}</Col>
                    </Row>
                  </Fragment>
                  {
                    customerDocument?.attachmentStatus === BadgeTypes.APPROVED &&
                    <Fragment>
                      {customerDocument?.admin && <Row className="details" gutter={10}>
                        <Col className="label" lg={4} sm={2}>Approved by</Col>
                        <Col className="value" lg={20} sm={22}>{customerDocument?.admin}</Col>
                      </Row>}
                      {customerDocument?.approvedOn && <Row className="details" gutter={10}>
                        <Col className="label" lg={4} sm={2}>Approved on</Col>
                        <Col className="value" lg={20} sm={22}>{customerDocument?.approvedOn}</Col>
                      </Row>}
                      {customerDocument?.adminComments && <Row className="details" gutter={10}>
                        <Col className="label" lg={4} sm={2}>Comment</Col>
                        <Col className="value" lg={20} sm={22}>{customerDocument?.adminComments}</Col>
                      </Row>}
                    </Fragment>
                  }
                  {
                    customerDocument?.attachmentStatus === BadgeTypes.REJECTED &&
                    <Fragment>
                      <Row className="details" gutter={10}>
                        <Col className="label" lg={4} sm={2}>Rejected by</Col>
                        <Col className="value" lg={20} sm={22}>{customerDocument?.admin}</Col>
                      </Row>
                      <Row className="details" gutter={10}>
                        <Col className="label" lg={4} sm={2}>Rejected on</Col>
                        <Col className="value" lg={20} sm={22}>{formatDate(customerDocument?.rejectedOn as string)}</Col>
                      </Row>
                      <Row className="details" gutter={10}>
                        <Col className="label" lg={4} sm={2}>Reason</Col>
                        <Col className="value" lg={20} sm={22}>{customerDocument?.adminComments}</Col>
                      </Row>
                    </Fragment>
                  }
                </div>
              </Col>
              <Col lg={4}>
                {
                  (customerDocument?.customerStatus === BadgeTypes.APPROVED || customerDocument?.customerStatus === BadgeTypes.PENDING_APPROVAL) && DocumentsToApprove.includes(customerDocument?.attachmentType as DocumentTypes) &&
                    <ApproveDocumentsControllers
                      attachmentStatus={customerDocument?.attachmentStatus}
                      documentId={customerDocument?.id as number}
                      attachmentType={customerDocument?.attachmentType as DocumentTypes}
                    />
                }
              </Col>
            </>}
          </Row>
          <Row>
          <Col xs={24} sm={24} lg={isCustomer ? 16 : 24}>
          {isCustomer &&
            <div className="document__container">
              <FileViewer fileType={customerDocument?.attachmentDetails?.fileType?.toLowerCase()} uri={customerDocument?.attachmentDetails?.s3Url as string} />
            </div>
          }
          {isVendor && 
            <div className="document__container">
              <FileViewer fileType={vendorDocument?.attachment?.fileType?.toLowerCase()} uri={vendorDocument?.attachment?.s3Url as string} />
            </div>
          }
          </Col>
          {isCustomer && 
            <Col xs={24} sm={24} lg={8}>
              <div className="customer-details">
                <div className="customer-details-label">
                  <p className="label">Customer details</p>
                  <p className="value">X</p>
                </div>
                <div className="customer-info-card-content">
                <div className="purchase-status">
                  <p className="title">Purchasing status</p>
                  <p className="value">{displayDash(activeAndUpcomingPlan?.activePlan?.name)}</p>
                </div>
                <div className="max-installaments">
                  <p className="title">Max installments</p>
                  <p className="value">{displayDash(activeAndUpcomingPlan?.activePlan?.totalInstallments)}</p>
                </div>
                <div className="purchasing-power">
                  <p className="title">Purchasing power</p>
                  <p className="value">{currencyString}{dashboardFraction(activeAndUpcomingPlan?.activePlan?.purchasingPower?.toString() ?? "0")}</p>
                </div>
                <div className="processing-fee">
                  <p className="title">Processing fee %</p>
                  <p className="value">{activeAndUpcomingPlan?.activePlan?.customerDetails?.processingFee} {"%"}</p>
                </div>
                <div className="national-id">
                  <p className="title">National ID</p>
                  <p className="value">{displayDash(customer?.nationalIdNumber)}</p>
                </div>
                <div className="full-name">
                  <p className="title">Name (Customer provided name)</p>
                  <p className="value">{displayDash(customer?.fullName)}</p>
                </div>
                <div className="name-from-id">
                  <p className="title">Name (Captured from ID)</p>
                  <p className="value">{displayDash(customer?.nameFromId)}</p>
                </div>
                <div className="dob">
                  <p className="title">DOB</p>
                  <p className="value">{displayDash(customer?.dateOfBirth)}</p>
                </div>
                <div className="address">
                  <p className="title">Address</p>
                  <p className="value">{displayDash(customer?.address)}</p>
                </div>
                <div className="job-title">
                  <p className="title">Job Title</p>
                  <p className="value">{displayDash(customer?.jobTitle)}</p>
                </div>
                <div className="job-place">
                  <p className="title">Job Place</p>
                  <p className="value">{displayDash(customer?.jobPlace)}</p>
                </div>
                <div className="marital-status">
                  <p className="title">Marital Status</p>
                  <p className="value">{displayDash(customer?.maritalStatus)}</p>
                </div>
                <div className="religion">
                  <p className="title">Religion</p>
                  <p className="value">{displayDash(customer?.religion)}</p>
                </div>
                <div className="sex">
                  <p className="title">Sex</p>
                  <p className="value">{displayDash(customer?.gender)}</p>
                </div>
                <div className="approved-by">
                  <p className="title">Approved by</p>
                  <p className="value">{displayDash(customer?.onboardingDetail?.adminName)}</p>
                </div>
                <div className="approved-on">
                  <p className="title">Approved on</p>
                  <p className="value">
                    {customer?.onboardingDetail?.onboardedAt &&
                      formatDate(customer?.onboardingDetail?.onboardedAt)}
                  </p>
                </div>
                <div className="comment">
                  <p className="title">Comment</p>
                  <p className="comment-value">
                    {displayDash(customer?.onboardingDetail?.adminComments)}
                  </p>
                </div>
                </div>
              </div>
            </Col>
          }
          </Row>
        </div>
      )}
    </Fragment>
  );
};

export default FileView;
