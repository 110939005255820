import React, { FC, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { BarChartOptions } from '../../types/BarChartOptions.type';
import CardSkeletonComponent from '../CardSkeletonComponent';
import './barChartLegendComponent.scss';
import { Col, Row } from 'antd';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartLegendComponentProps {
  options: BarChartOptions;
  classname?: string;
  title: string;
  backgroundColor?: string;
  loading?: boolean;
  onChange?: Function;
  data?: {
    labels: string[];
    datasets: {
      data: number[];
      fill?: boolean;
      backgroundColor: string | string[];
      borderColor?: string;
    }[];
  };
}

const BarChartLegendComponent: FC<BarChartLegendComponentProps> = ({
  title,
  data,
  options,
  loading
}) => {
  const [chartHeight, setChartHeight] = useState(300);

  useEffect(() => {
    if (data && data.labels.length) {
      const legendItemHeight = 30;
      const newHeight = Math.max(250, data.labels.length * legendItemHeight);
      setChartHeight(newHeight);
    }
  }, [data]);

  return (
    <div className="bar-chart-legend-component">
      <div className="dashboard-header">
        <h3 className="title">{title}</h3>
      </div>
      {loading ? (
        <CardSkeletonComponent className="transaction-per-customer__skeleton" rows={3} />
      ) : (
        data && (
          <Row gutter={16}>
            <Col span={15} className="chart-container" style={{ height: `${chartHeight}px` }}>
              <Bar options={options} data={data} />
            </Col>
            <Col span={9} className="chart-legend">
              {data.labels.map((label, index) => (
                <div key={index} className="legend-item">
                  <span
                    className="legend-color"
                    style={{ backgroundColor: (data.datasets[0].backgroundColor as string[])[index] }}
                  ></span>
                  {label}
                </div>
              ))}
            </Col>
          </Row>
        )
      )}
    </div>
  );
};

export default BarChartLegendComponent;
