import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import { axiosInstance } from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Dashboard, DashboardFilterModel, TransactionsPerCustomer, VendorSettlements } from '../../models/Dashboard/dashboard.model';
import { generateGraphData } from '../../shared/utils/generateGraphData';

export type graphDataType = {
    labels: string[];
    datasets: {
        data: number[];
        backgroundColor: string | string[];
        fill?: boolean,
        borderColor?: string 
    }[];
}

const DashboardService = () => {

    const [loading, setLoading] = useState<boolean>(false)
    const [dashboardVendorSettlementLoading, setDashboardVendorSettlementLoading] = useState<boolean>(false)
    const [dashboardPartnerSettlementLoading, setDashboardPartnerSettlementLoading] = useState<boolean>(false)
    const [dashboardPaymentSettlementLoading, setDashboardPaymentSettlementLoading] = useState<boolean>(false)

    const [dashboardKeyMetrics, setDashboardKeyMetrics] = useState<Dashboard | null>()
    const [dashboardTransactionValues, setDashboardTransactionValues] = useState<graphDataType>()
    const [dashboardTransactionCounts, setDashboardTransactionCounts] = useState<graphDataType>()
    const [dashboardTransactionPerCustomer, setDashboardTransactionPerCustomer] = useState<graphDataType>()
    const [dashboardTransactionPerVendor, setDashboardTransactionPerVendor] = useState<graphDataType>()
    const [dashboardTransactionValuePerVendor, setDashboardTransactionValuePerVendor] = useState<graphDataType>()
    const [dashboardPaymentsKeyMetrics, setDashboardPaymentsKeyMetrics] = useState<Dashboard | null>()
    const [dashboardPayments, setDashboardPayments] = useState<graphDataType>()
    const [dashboardPaymentMethods, setDashboardPaymentMethods] = useState<graphDataType>()
    const [dashboardVendorSettlement, setDashboardVendorSettlement] = useState<VendorSettlements | null>()
    const [dashboardPartnerSettlement, setDashboardPartnerSettlement] = useState<VendorSettlements | null>()
    const [dashboardPaymentSettlement, setDashboardPaymentSettlement] = useState<VendorSettlements | null>()

    const generateDoughnutGraphData = (rawData: TransactionsPerCustomer[], backgroundColor = "", borderColor = "", fill?: boolean): graphDataType => {
        const data = rawData.map(data => data.noOfCustomers)
        const labels = rawData.map(data => `${data.noOfTransaction} Transaction${Number(data.noOfTransaction) > 1 ? 's' : ''}`)
        return {
            labels,
            datasets:[{
                data,
                backgroundColor,
                borderColor,
                fill
            }]
        }
    }

    const fetchDashboardKeyMetrics = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string,
        showTest?: boolean
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_KEY_METRICS, { params })
            const data = deserialize(Dashboard, response.data)
            setDashboardKeyMetrics(data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardTransactionValues = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_TRANSACTION_VALUE, { params })
            setDashboardTransactionValues(generateGraphData(response.data["transaction_value"], _params?.dateFilterKey === "annual", "rgba(14, 156, 255, .2)", "#0E9CFF", true))
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardTransactionCounts = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_TRANSACTION_COUNT, { params })
            setDashboardTransactionCounts(generateGraphData(response.data["transaction_count"], _params?.dateFilterKey === "annual", "rgba(255, 187, 69, .2)", "#FFBB45", true))
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardTransactionPerCustomer = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_TRANSACTION_PER_CUSTOMER, { params })
            const responseData = deserialize(TransactionsPerCustomer, response.data["transactions_per_customer"]) as unknown as TransactionsPerCustomer[]
            setDashboardTransactionPerCustomer(generateDoughnutGraphData(responseData))
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardTransactionPerVendor = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_TRANSACTIONS_PER_VENDOR, { params })
            setDashboardTransactionPerVendor(generateGraphData(response.data["transactions_per_vendor"]))
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardTransactionValuePerVendor = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_TRANSACTIONS_VALUE_PER_VENDOR, { params })
            setDashboardTransactionValuePerVendor(generateGraphData(response.data["transactions_value_per_vendor"]))
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardPaymentKeyMetrics = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_PAYMENT_KEY_METRICS, { params })
            const data = deserialize(Dashboard, response.data["due_payments"])
            setDashboardPaymentsKeyMetrics(data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardPaymentMethods = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_PAYMENT_METHODS, { params })
            const allowedMethods = ['cash', 'credit_and_debit_card', 'mobile_wallet'];
            const filteredPaymentMethods = Object.fromEntries(
            Object.entries(response.data["payment_methods"]).filter(([key]) => allowedMethods.includes(key))
            );
            setDashboardPaymentMethods(generateGraphData(filteredPaymentMethods))
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardPayments = async (_params?: {
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_PAYMENT_VALUE, { params })
            setDashboardPayments(generateGraphData(response.data["payment_value"], _params?.dateFilterKey === "annual", "rgba(255, 114, 158, 0.2)", "#FF729E", true))
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDashboardVendorSettlements = async (_params?: {
        vendorId?: number,
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string,
        showTest: boolean
    }) => {
        try {
            setDashboardVendorSettlementLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_VENDOR_SETTLEMENTS, { params })
            const data = deserialize(VendorSettlements, response.data["vendor_settlements"])
            setDashboardVendorSettlement(data)
        } catch (error) {

        } finally {
            setDashboardVendorSettlementLoading(false)
        }
    }

    const fetchDashboardPartnerSettlements = async (_params?: {
        partnerId?: number,
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string,
        showTest: boolean
    }) => {
        try {
            setDashboardPartnerSettlementLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_PARTNER_FEES, { params })
            const data = deserialize(VendorSettlements, response.data["partner_fees"])
            setDashboardPartnerSettlement(data)
        } catch (error) {

        } finally {
            setDashboardPartnerSettlementLoading(false)
        }
    }

    const fetchDashboardPaymentSettlements = async (_params?: {
        partnerId?: number,
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string,
        showTest: boolean
    }) => {
        try {
            setDashboardPaymentSettlementLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_PAYMENT_COLLECTION, { params })
            const data = deserialize(VendorSettlements, response.data["payment_collection"])
            setDashboardPaymentSettlement(data)
        } catch (error) {

        } finally {
            setDashboardPaymentSettlementLoading(false)
        }
    }

    return {
        loading,
        dashboardKeyMetrics,
        fetchDashboardKeyMetrics,
        fetchDashboardTransactionValues,
        dashboardTransactionValues,
        fetchDashboardTransactionCounts,
        dashboardTransactionCounts,
        fetchDashboardTransactionPerCustomer,
        dashboardTransactionPerCustomer,

        fetchDashboardTransactionPerVendor,
        dashboardTransactionPerVendor,
        fetchDashboardTransactionValuePerVendor,
        dashboardTransactionValuePerVendor,

        fetchDashboardPaymentKeyMetrics,
        dashboardPaymentsKeyMetrics,

        fetchDashboardPaymentMethods,
        dashboardPaymentMethods,

        fetchDashboardPayments,
        dashboardPayments,

        fetchDashboardVendorSettlements,
        dashboardVendorSettlement,
        dashboardVendorSettlementLoading,

        fetchDashboardPartnerSettlements,
        dashboardPartnerSettlement,
        dashboardPartnerSettlementLoading,

        fetchDashboardPaymentSettlements,
        dashboardPaymentSettlement,
        dashboardPaymentSettlementLoading,
    }
}

export default DashboardService