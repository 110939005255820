import { Checkbox, Col, Divider, Row, Select } from "antd";
import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import { serialize } from "serializr";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import { DocumentTypes } from "../../../../../enums/documentTypes";
import {
  SupportDocuments,
  Vendor,
  VendorModel,
} from "../../../../../models/Vendor/vendor.model";
import MetaService from "../../../../../services/MetaService/meta.service";
import S3AttachmentService from "../../../../../services/S3UploadService/s3upload.service";
import VendorService from "../../../../../services/VendorService/vendor.service";
import ColorPickerComponent from "../../../../../shared/components/ColorPickerComponent";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import RadioComponent from "../../../../../shared/components/RadioComponent";
import SwitchComponent from "../../../../../shared/components/SwitchComponent";
import UploadComponent from "../../../../../shared/components/UploadComponent";
import {
  countryCodesInitialValue,
  generateCodeOptions,
  getCountryIsdCode,
} from "../../../../../shared/utils/generateCountryCodeOptions";
import "./vendorForm.scss";
import { vendorFormValidation } from "./vendorFormValidation";
import CheckboxComponent from "../../../../../shared/components/CheckboxComponent";
import { hideBrowserSuggestions } from "../../../../../shared/utils/hideBrowserSuggestions";

interface VendorFormProps {
  data?: Vendor;
  visible: boolean;
  closeHandler: () => void;
  successHandler: (values?: Vendor) => void;
}

const VendorForm: FC<VendorFormProps> = (props) => {
  const { visible, closeHandler, data, successHandler } = props;
  const [initialValues, setInitialValues] = useState<any>();
  const formRef = useRef<any>();
  const { loading, createVendor, updateVendor } = VendorService();
  const {
    uploadAttachment,
    uploadingCommercialRegistration,
    uploadingTaxCard,
    loading: attachmentLoading,
  } = S3AttachmentService();
  const {
    fetchBankList,
    fetchCategoryList,
    fetchBranchList,
    fetchSubCategoryList,
    categories,
    getWalletTypesMeta,
    walletTypesMeta,
    getCountryCodesMeta,
    countryCodesMeta,
  } = MetaService();
  const onSubmit = (values: FormikValues) => {
    let payload = { ...values };
    delete payload["coverPicUrl"];
    delete payload["logoUrl"];
    delete payload["commercialRegistrationUrl"];
    delete payload["taxCardUrl"];
    delete payload["bank"];
    delete payload["branch"];

    const jsonData: any = {
      vendor: {
        ...serialize(Vendor, payload),
      },
    };

    if (data?.vendorDetails?.id) {
      updateVendor(data?.vendorDetails?.id, jsonData, () => {
        successHandler(payload);
      });
    } else {
      createVendor(jsonData, () => {
        successHandler(payload);
        formRef.current.resetForm({ values: {} });
      });
    }
  };

  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler();
  };

  useEffect(() => {
    if (data) {
      setInitialValues({
        ...data,
        paymentDetails: {
          ...data?.paymentDetails,
          walletTypeId: data?.paymentDetails?.walletType?.id,
        },
        vendorDetails: {
          ...data?.vendorDetails,
          logoId: data?.vendorDetails?.logo?.id,
          coverPicId: data?.vendorDetails?.coverPic?.id,
          maxCreditPerOrder: data?.vendorDetails?.maxCreditPerOrder ?? 0,
        },
        supportDocuments: {
          ...data?.supportDocuments,
          commercialRegistrationId:
            data?.supportDocuments?.commercialRegistration?.id,
          taxCardId: data?.supportDocuments?.taxCard?.id,
        },
      });
    } else {
      setInitialValues({
        ...new Vendor(),
        vendorDetails: {
          name: "",
          email: "",
          mobileNumber: "",
          categoryId: [],
          type: "offline",
          monthlyTransactionVolume: "",
          maxCreditPerOrder: 0,
          taxRegistrationNumber: "",
          countryCodeId: countryCodesInitialValue(countryCodesMeta),
          isdCode: "+20",
          countryCode: {
            isdCode: "+20",
          },
        },
        contactPersonDetails: {
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          password: "",
          countryCodeId: countryCodesInitialValue(countryCodesMeta),
          isdCode: "+20",
          countryCode: {
            isdCode: "+20",
          },
        },
        paymentDetails: {
          accountHolderName: "",
          address: "",
          accountNumber: "",
          iban: "",
          swiftCode: "",
          branch: "",
          walletNumber: "",
          walletTypeId: "",
        },
      });
    }
    if (data?.vendorDetails?.bankId) {
      fetchBranchList(data?.vendorDetails?.bankId);
    }
  }, [visible, data]);

  useEffect(() => {
    fetchBankList();
    fetchCategoryList();
    getWalletTypesMeta();
    getCountryCodesMeta();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={vendorFormValidation}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, setFieldValue, handleSubmit, touched, errors }) => {
        return (
          <Form onScroll={hideBrowserSuggestions}>
            <ModalComponent
              visible={visible}
              closeHandler={resetFormAndClose}
              type="large"
              successProps={{
                title: data ? "Update" : "Create",
                clickHandler: handleSubmit,
                loading: loading || attachmentLoading,
              }}
              cancelProps={{
                title: "Discard",
                clickHandler: resetFormAndClose,
              }}
              className="vendor-form-modal"
              destroyOnClose={true}
            >
              <div className="vendor-form">
                <UploadComponent
                  fileId={
                    values?.vendorDetails?.coverPicId ??
                    values?.vendorDetails?.coverPic?.id
                  }
                  fileType={values?.vendorDetails?.coverPic?.fileType}
                  fileName={values?.vendorDetails?.coverPic?.fileName}
                  label={values?.vendorDetails?.coverPic?.fileName}
                  accept={"image/png, image/jpg, image/jpeg"}
                  fileUrl={values?.vendorDetails?.coverPic?.s3Url}
                  type="cover-picture"
                  title={
                    <>
                      <div>Cover Picture</div>
                      <div>(recommended size: 355 * 155 px)</div>
                    </>
                  }
                  onRemove={() => {
                    setFieldValue("vendorDetails.coverPic.s3Url", "");
                    setFieldValue("vendorDetails.coverPicId", "");
                    setFieldValue("vendorDetails.coverPic", {});
                    setFieldValue("vendorDetails.coverPicUrl", "");
                  }}
                  onUpload={async (file, fileUrl) => {
                    setFieldValue("vendorDetails.coverPic.s3Url", fileUrl);
                    const _cover = await uploadAttachment(file);
                    setFieldValue("vendorDetails.coverPicId", _cover);
                  }}
                />
                <div className="vendor-form-details__container">
                  <div className="vendor-display-picture-name__container">
                    <UploadComponent
                      fileId={
                        values?.vendorDetails?.logoId ??
                        values?.vendorDetails?.logo?.id
                      }
                      fileType={values?.vendorDetails?.logo?.fileType}
                      fileName={values?.vendorDetails?.logo?.fileName}
                      label={values?.vendorDetails?.coverPic?.fileName}
                      accept={"image/png, image/jpg, image/jpeg"}
                      fileUrl={values?.vendorDetails?.logo?.s3Url}
                      type="logo"
                      title={"Logo"}
                      onUpload={async (file, fileUrl) => {
                        setFieldValue("vendorDetails.logo.s3Url", fileUrl);
                        const _logo = await uploadAttachment(file);
                        setFieldValue("vendorDetails.logoId", _logo);
                      }}
                      onRemove={() => {
                        setFieldValue("vendorDetails.logo.s3Url", undefined);
                        setFieldValue("vendorDetails.logoId", undefined);
                      }}
                      error={(errors?.vendorDetails as VendorModel)?.logoId}
                    />
                    <div className="">
                      <InputField
                        name="vendorDetails.name"
                        className="vendor-name__input"
                        placeholder="Vendor Name"
                      />
                    </div>
                  </div>
                  <h4>Contact Person Details</h4>
                  <Row gutter={16}>
                    <Col span={12}>
                      <InputField
                        label="First Name"
                        placeholder="Enter"
                        name="contactPersonDetails.firstName"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        label="Last Name"
                        placeholder="Enter"
                        name="contactPersonDetails.lastName"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        label="Email"
                        placeholder="Enter"
                        name="contactPersonDetails.email"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        addonBefore={
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            value={
                              values?.contactPersonDetails?.countryCodeId ?? ""
                            }
                            onSelect={(
                              value: string | number | undefined,
                              options: {
                                value: string | number | undefined;
                                label: string;
                              }
                            ) => {
                              if (typeof value !== "string") {
                                setFieldValue(
                                  "contactPersonDetails.countryCode.isdCode",
                                  getCountryIsdCode(countryCodesMeta, value)
                                );
                                setFieldValue(
                                  "contactPersonDetails.countryCodeId",
                                  value
                                );
                              }
                              setFieldValue(
                                "contactPersonDetails.mobileNumber",
                                undefined
                              );
                            }}
                            options={generateCodeOptions(countryCodesMeta)}
                          />
                        }
                        name="contactPersonDetails.mobileNumber"
                        label="Phone Number"
                        placeholder="Enter"
                      />
                      {/* TODO: needed for next phase */}
                      {/* <InputField
                        label="Phone Number"
                        placeholder="Enter"
                        name="contactPersonDetails.mobileNumber"
                      /> */}
                    </Col>
                    <Col span={12}>
                      <InputField
                        name="contactPersonDetails.password"
                        label="Password"
                        placeholder="Enter"
                        type="text"
                        className="password-field"
                      />
                    </Col>
                  </Row>
                  <Divider />
                  <h4>Vendor Details</h4>
                  <Row gutter={16}>
                    <Col span={12}>
                      <InputField
                        label="Email"
                        placeholder="Enter"
                        name="vendorDetails.email"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        addonBefore={
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            value={values?.vendorDetails?.countryCodeId ?? ""}
                            onSelect={(
                              value: string | number | undefined,
                              options: {
                                value: string | number | undefined;
                                label: string;
                              }
                            ) => {
                              if (typeof value !== "string") {
                                setFieldValue(
                                  "vendorDetails.countryCode.isdCode",
                                  getCountryIsdCode(countryCodesMeta, value)
                                );
                                setFieldValue(
                                  "vendorDetails.countryCodeId",
                                  value
                                );
                              }
                              setFieldValue(
                                "vendorDetails.mobileNumber",
                                undefined
                              );
                            }}
                            options={generateCodeOptions(countryCodesMeta)}
                          />
                        }
                        name="vendorDetails.mobileNumber"
                        label="Phone Number"
                        placeholder="Enter"
                      />
                      {/* TODO: Needed for next phase */}
                      {/* <InputField
                        label="Phone Number"
                        placeholder="Enter"
                        name="vendorDetails.mobileNumber"
                      /> */}
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        mode="multiple"
                        maxTagCount="responsive"
                        optionFilterProp="label"
                        allowClear
                        label="Category"
                        placeholder="Select"
                        name="vendorDetails.categoryId"
                        value={values?.vendorDetails?.categoryId}
                        options={categories}
                        onChange={(value) => {
                          setFieldValue("vendorDetails.categoryId", value);
                          if (value !== values?.vendorDetails?.categoryId) {
                            setFieldValue(
                              "vendorDetails.subCategoryId",
                              undefined
                            );
                          }
                          fetchSubCategoryList(value);
                        }}
                      />
                    </Col>
                    {/* TODO: required for next phase */}
                    {/* <Col span={12}>
                      <DropdownField
                        label="Sub Category"
                        placeholder="Select"
                        name="vendorDetails.subCategoryId"
                        value={values?.vendorDetails?.subCategoryId}
                        options={subCategories}
                        onChange={(value) =>
                          setFieldValue("vendorDetails.subCategoryId", value)
                        }
                      />
                    </Col> */}
                    <Col span={24}>
                      <RadioComponent
                        name="vendorDetails.type"
                        label="Type"
                        value={values?.vendorDetails?.type}
                        data={[
                          { value: "online", label: "Online" },
                          { value: "offline", label: "Instore" },
                          { value: "both", label: "Both" },
                        ]}
                        onChange={(event) => setFieldValue("vendorDetails.type", event?.target?.value)}
                      />
                    </Col>
                    {(values?.vendorDetails?.type == "both" ||
                      values?.vendorDetails?.type == "online") && (
                      <Col span={12}>
                        <InputField
                          label="Website URL"
                          placeholder="Enter"
                          name="vendorDetails.websiteUrl"
                        />
                      </Col>
                    )}

                    {(values?.vendorDetails?.type == "both" ||
                      values?.vendorDetails?.type == "instore") && (
                      <Col span={12}>
                        <InputField
                          label="# of Physical Stores"
                          placeholder="Enter"
                          name="vendorDetails.noOfPhysicalStores"
                        />
                      </Col>
                    )}

                    <Col span={12}>
                      <InputField
                        label="Monthly Transaction Volume"
                        placeholder="Enter"
                        name="vendorDetails.monthlyTransactionVolume"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        label="Max Credit per Order"
                        placeholder="Enter"
                        name="vendorDetails.maxCreditPerOrder"
                      />
                    </Col>
                    <Col span={12}>
                      <ColorPickerComponent
                        label="Brand Colour"
                        value={values?.vendorDetails?.brandColour ?? "#0b0a10"}
                        onChange={(hexCode) =>
                          setFieldValue("vendorDetails.brandColour", hexCode)
                        }
                      />
                    </Col>
                  </Row>
                  <Divider />
                  <h4>Vendor Payment Details</h4>
                  <Row gutter={16}>
                    <Col className="filter-field" span={24}>
                      <InputField
                        placeholder="Enter"
                        label="Account Holder Name"
                        name="paymentDetails.accountHolderName"
                      />
                    </Col>
                    <Col className="filter-field" span={24}>
                      <InputField
                        placeholder="Enter"
                        label="Account Holder Address"
                        name="paymentDetails.address"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Account Number"
                        name="paymentDetails.accountNumber"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="IBAN"
                        prefix="EG"
                        name="paymentDetails.iban"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Swift Code"
                        name="paymentDetails.swiftCode"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Branch"
                        name="paymentDetails.branch"
                      />
                    </Col>
                    {/* TODO - Will be used when E-wallets are introduced */}
                    {/* <Col className="filter-field" span={12}>
                      <InputField onChange={(value)=>{
                        setFieldValue("paymentDetails.walletNumber", value)
                        !value && setFieldValue("paymentDetails.walletTypeId", "")
                      }} placeholder='Enter' label='Mobile Wallet Number' name='paymentDetails.walletNumber' />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <DropdownField
                        disabled={!values?.paymentDetails?.walletNumber}
                        label="Wallet Type"
                        options={walletTypesMeta}
                        name="paymentDetails.walletTypeId"
                        placeholder="Select"
                        onChange={(value) => setFieldValue("paymentDetails.walletTypeId", value)}
                        value={values?.paymentDetails?.walletTypeId??values?.paymentDetails?.walletType?.id??null}
                      />
                    </Col> */}
                  </Row>
                  <Divider />
                  <h4>Tax Registration</h4>
                  <Row gutter={16}>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Tax Registration Number"
                        name="vendorDetails.taxRegistrationNumber"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Name on Invoice"
                        name="vendorDetails.taxRegistrationName"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Governorate"
                        name="vendorDetails.governate"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Region/ City"
                        name="vendorDetails.regionCity"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Street"
                        name="vendorDetails.street"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Building No."
                        name="vendorDetails.buildingNumber"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        placeholder="Enter"
                        label="Postal code"
                        name="vendorDetails.postalCode"
                      />
                    </Col>
                  </Row>
                  <Divider />
                  <h4>Support Documents</h4>
                  <Row>
                    <Col span={12}>
                      <UploadComponent
                        isUploading={uploadingCommercialRegistration}
                        fileId={
                          values?.supportDocuments?.commercialRegistrationId ??
                          values?.supportDocuments?.commercialRegistration?.id
                        }
                        fileType={
                          values?.supportDocuments?.commercialRegistration
                            ?.fileType
                        }
                        fileName={
                          values?.supportDocuments?.commercialRegistration
                            ?.filename
                        }
                        accept={
                          "image/png, image/jpg, image/jpeg, application/pdf"
                        }
                        fileUrl={
                          values?.supportDocuments?.commercialRegistration
                            ?.s3Url
                        }
                        onUpload={async (file, fileUrl) => {
                          setFieldValue(
                            "supportDocuments.commercialRegistration.s3Url",
                            fileUrl
                          );
                          setFieldValue(
                            "supportDocuments.commercialRegistration.filename",
                            file?.name
                          );
                          const _commercial = await uploadAttachment(
                            file,
                            DocumentTypes.COMMERCIAL_REGISTRATION
                          );
                          setFieldValue(
                            "supportDocuments.commercialRegistrationId",
                            _commercial
                          );
                        }}
                        title="Upload Document"
                        type="document"
                        label="Commercial Registration"
                        error={
                          (errors?.supportDocuments as SupportDocuments)
                            ?.commercialRegistrationId
                        }
                      />
                    </Col>
                    <Col span={12} />
                    <Col span={12}>
                      <UploadComponent
                        isUploading={uploadingTaxCard}
                        fileId={
                          values?.supportDocuments?.taxCardId ??
                          values?.supportDocuments?.taxCard?.id
                        }
                        fileType={values?.supportDocuments?.taxCard?.fileType}
                        fileName={values?.supportDocuments?.taxCard?.filename}
                        accept={
                          "image/png, image/jpg, image/jpeg, application/pdf"
                        }
                        fileUrl={values?.supportDocuments?.taxCard?.s3Url}
                        onUpload={async (file, fileUrl) => {
                          setFieldValue(
                            "supportDocuments.taxCard.s3Url",
                            fileUrl
                          );
                          setFieldValue(
                            "supportDocuments.taxCard.filename",
                            file?.name
                          );
                          const _tax = await uploadAttachment(
                            file,
                            DocumentTypes.TAX_CARD
                          );
                          setFieldValue("supportDocuments.taxCardId", _tax);
                        }}
                        title="Upload Document"
                        type="document"
                        label="Tax Card"
                        error={
                          (errors?.supportDocuments as SupportDocuments)
                            ?.taxCardId
                        }
                      />
                    </Col>
                    <Col span={12} />
                    <Divider />
                    <Col span={12}>
                      <SwitchComponent
                        value={
                          values?.vendorDetails?.status ==
                            BadgeTypes.APPROVED ?? false
                        }
                        onSwitch={(value) =>
                          setFieldValue(
                            "vendorDetails.status",
                            value ? BadgeTypes.APPROVED : BadgeTypes.INACTIVE
                          )
                        }
                        label={"Status"}
                        checkedTitle={"Active"}
                        unCheckedTitle={"Inactive"}
                      />
                    </Col>
                    <Divider />
                    <Col span={24}>
                      <CheckboxComponent value={values?.vendorDetails?.testVendor} title="Test Vendor" label="Check this box to mark the vendor as test vendor" onChecked={(value) => setFieldValue("vendorDetails.testVendor", value?.target?.checked)} />
                    </Col>
                  </Row>
                </div>
              </div>
            </ModalComponent>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VendorForm;
