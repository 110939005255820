import React, { FC } from 'react'
import { currencyString } from '../../../..';
import moment from "moment";
import { BadgeTypes } from '../../../../../../enums/badgeTypes';
import { RefundDetails } from '../../../../../../models/RefundDetails/refundDetails.model';
import BadgeComponent from '../../../../../../shared/components/BadgeComponent';
import ModalComponent from '../../../../../../shared/components/ModalComponent'
import TableComponent from '../../../../../../shared/components/TableComponent';
import "./paymentAdjustmentModal.scss"

interface PaymentAdjustmentModalProps {
    visible: boolean;
    closeHandler: () => void;
    purchaseRefund?: RefundDetails
}

const PaymentAdjustmentModal: FC<PaymentAdjustmentModalProps> = (props) => {

    const { visible, closeHandler, purchaseRefund } = props

    return (
        <ModalComponent
            type="no-controllers-large"
            visible={visible}
            closeHandler={closeHandler}
            destroyOnClose={true}
        >
            <div className="payment-adjustment-content">
                <h3>Payment Adjustment comparison</h3>
                <p>{currencyString}{purchaseRefund?.refundAmount} (the refund amount processed by Rise) is adjusted with the unpaid installment due</p>
                <TableComponent
                    columns={[
                        {
                            title: "DUE ON",
                            dataIndex: "dueDate",
                            key: "dueDate",
                            render: (text) => moment(text)?.format("DD MMM YYYY")
                        },
                        {
                            title: "OLD MINIMUM DUE",
                            dataIndex: "oldAmount",
                            key: "oldAmount",
                            render: (text, record) => (
                                <div className="due-on__container">
                                    <span className='old-data'>{text}</span>
                                    {
                                        record?.status === "paid" &&
                                        <BadgeComponent type={BadgeTypes.PAID} />
                                    }
                                </div>
                            ),
                        },
                        {
                            title: "UPDATED MINIMUM DUE",
                            dataIndex: "updatedAmount",
                            key: "updatedAmount",
                            render: (text, record) => (
                                <div className="due-on__container">
                                    <span>{text}</span>
                                    {
                                        record?.status === "paid" &&
                                        <BadgeComponent type={BadgeTypes.PAID} />
                                    }
                                </div>
                            ),
                        },
                    ]}
                    data={purchaseRefund?.installmentDetails}
                    loading={false}
                />
            </div>
        </ModalComponent>
    )
}

export default PaymentAdjustmentModal