import { Col, Divider, Row, Space } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { Dashboard } from '../../../../models/Dashboard/dashboard.model'
import DashboardService from '../../../../services/DasboardService/dashboard.service'
import CardSkeletonComponent from '../../../../shared/components/CardSkeletonComponent'
import DashboardFilterMenu from '../../../../shared/components/DashboardFilterMenu'
import TooltipComponent from '../../../../shared/components/TooltipComponent'
import { dashboardFraction } from '../../../../shared/utils/dashboardFraction'
import "./paymentKpiComponent.scss"

interface PaymentKpiComponentProps {
    paymentKeyMetricsLoading: boolean, 
    dashboardPaymentsKeyMetrics: Dashboard
 }

const PaymentKpiComponent: FC<PaymentKpiComponentProps> = (props) => {

    const { paymentKeyMetricsLoading, dashboardPaymentsKeyMetrics } = props

    return (
        <div className="payment-kpi-component">
            <div className="dashboard-header">
                <h2 className='dashboard-title'>Payments</h2>
            </div>
            {
                paymentKeyMetricsLoading ?
                    <CardSkeletonComponent className="north-start-kpi__skeleton" rows={3} /> :
                    <div className="content">
                        <Row>
                            <Col span={6} className="total-installments">
                                <div className="total-installments-realized">
                                    <p className='header'>TOTAL INSTALLMENTS REALIZED <TooltipComponent content='SUM of all installments realized' /></p>
                                    <h3 className='header-value'>LE {dashboardFraction(dashboardPaymentsKeyMetrics?.totalInstallmentsRealizedValue)} <span className='header-value-info'>({dashboardPaymentsKeyMetrics?.totalInstallmentsRealizedCount} installments)</span></h3>
                                </div>
                                <div className="total-installments-value">
                                    <p className='header'>TOTAL INSTALLMENTS <TooltipComponent content='SUM of all installments (both outstanding and realized) in the selected time frame' /></p>
                                    <h3 className='header-value'>LE {dashboardFraction(dashboardPaymentsKeyMetrics?.totalInstallmentsValue)} <span className='header-value-info'>({dashboardPaymentsKeyMetrics?.totalInstallmentsCount} installments)</span> </h3>
                                </div>
                            </Col>
                            <Divider type="vertical" />
                            <Col span={8} className="late-installments" offset={1}>
                                <div className="late-installments-value">
                                    <p className='header'>LATE INSTALLMENTS - OUTSTANDING <TooltipComponent content='SUM of installments outstanding that are over due (0 to 90 days)' /></p>
                                    <h3 className='header-value'>LE {dashboardFraction(dashboardPaymentsKeyMetrics?.valueOfOverdues?.toString())}</h3>
                                </div>
                                <div className="late-installments-split">
                                    <div className="text">
                                        <p className="label"># of outstanding late installments</p>
                                        <p className="value">{dashboardPaymentsKeyMetrics?.numberOfOverdues}</p>
                                    </div>
                                    <div className="text">
                                        <p className="label">% of outstanding late installments</p>
                                        <p className="value">{dashboardPaymentsKeyMetrics?.percentOfOverdues}</p>
                                    </div>
                                    <div className="text">
                                        <p className="label">Average late installments payment day <TooltipComponent content='Average day on which the late installments are paid' /></p>
                                        <p className="value">{dashboardFraction(dashboardPaymentsKeyMetrics?.averageLateInstallmentPaymentDay)}</p>
                                    </div>
                                </div>
                            </Col>
                            <Divider type="vertical" />
                            <Col span={7} className="default-installments" offset={1}>
                                <div className="default-installments-value">
                                    <p className='header'>DEFAULT INSTALLMENTS <TooltipComponent content='SUM of pending installments that are over due over 90 days' /></p>
                                    <h3 className='header-value'>LE {dashboardFraction(dashboardPaymentsKeyMetrics?.valueOfNonPayments?.toString())}</h3>
                                </div>
                                <div className="default-installments-split">
                                    <div className="text">
                                        <p className="label"># of default installments</p>
                                        <p className="value">{dashboardPaymentsKeyMetrics?.numberOfNonPayments}</p>
                                    </div>
                                    <div className="text">
                                        <p className="label">% of default installments</p>
                                        <p className="value">{dashboardPaymentsKeyMetrics?.percentOfNonPayments}</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
            }
        </div>
    )
}

export default PaymentKpiComponent