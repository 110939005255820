import React, { FC } from 'react'
import { Line } from 'react-chartjs-2'
import "./lineChartComponent.scss"
import Chart from 'chart.js/auto';

import { CategoryScale, Filler } from 'chart.js';
import { range } from 'lodash';
import DashboardFilterMenu from '../DashboardFilterMenu';
import { LineChartOptions } from '../../types/LineChartOptions.type';
import CardSkeletonComponent from '../CardSkeletonComponent';

interface LineChartComponentProps {
    classname?: string,
    options: LineChartOptions,
    title: string,
    backgroundColor?: string,
    borderColor?: string,
    loading?: boolean,
    onChange?: Function,
    data?: {
        labels: string[];
        datasets: {
            data: number[];
            fill?: boolean;
            backgroundColor: string | string[];
            borderColor?: string;
        }[];
    }
}


const LineChartComponent: FC<LineChartComponentProps> = ({ classname, options, title, data, loading, onChange }) => {
    Chart.register(CategoryScale, Filler);


    return (
        <div className={`line-chart-component ${classname}`} >
            <div className="dashboard-header space-between">
                <h3 className='title'>{title}</h3>
            </div>
            {
                loading ?
                    <CardSkeletonComponent className="transaction-value-kpi__skeleton" rows={3} /> :
                    data &&
                    <Line data={data} options={options} />
            }
        </div>
    )
}

export default LineChartComponent