import React, { FC } from 'react'
import { Slider } from 'antd'
import 'antd/dist/antd.css';
import "./slidercomponent.scss"

interface SliderComponentProps { 
    score: number;
}

const SliderComponent: FC<SliderComponentProps> = (props) => {

    const { score } = props
    const marks = {
        0: ' Min 0',
        1000: 'Max 1000',
      }

    return (
        <div className="slidercomponent">
               <Slider
                    min={0}
                    max={1000}
                    value={score}
                    marks={marks}
                    step={1}
                    style={{ width: '200px', height: '7px', flexShrink: 0, }}
                />
        </div>
    )
}

export default SliderComponent;