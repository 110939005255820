import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";
import { BadgeTypes } from "../../enums/badgeTypes";
import { VendorTypes } from "../../enums/vendorTypes";
import { AttachmentModel } from "../Attachment/attachment.model";
import { Installment } from "../Installment/installment.model";
import { MetaModel } from "../Meta/meta.model";
import { RefundDetails } from "../RefundDetails/refundDetails.model";
import { VendorInstoreModel } from "../Vendor/Instore/instore.model";
import { Vendor, VendorModel } from "../Vendor/vendor.model";

const { BOTH, ONLINE, INSTORE } = VendorTypes;

class PurchaseListVendorModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("logo_url", primitive()))
  logoUrl?: string;

  @serializable(alias("logo", object(AttachmentModel)))
  logo?: AttachmentModel;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("mode", primitive()))
  mode?: string;

  @serializable(alias("instore", object(VendorInstoreModel)))
  instore?: VendorInstoreModel;
}

export class PurchaseListFilterModel {
  @serializable(alias("vendor_id", primitive()))
  vendorId?: string;

  @serializable(alias("vendor_name", primitive()))
  vendorName?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("customer_payment_status", primitive()))
  customerPaymentStatus?: string;

  @serializable(alias("purchase_status", primitive()))
  purchaseStatus?: string;

  @serializable(alias("national_id", primitive()))
  nationalId?: number;

  @serializable(alias("search", primitive()))
  search?: string;

  @serializable(alias("page", primitive()))
  page?: number;

  @serializable(alias("show_test", primitive()))
  showTest?: boolean;
}

export class PurchaseListModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("purchase_id", primitive()))
  purchaseId?: string;

  @serializable(alias("customer_reference_number", primitive()))
  customerReferenceNumber?: string;

  @serializable(alias("pos_type", primitive()))
  posType?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("vendor", object(PurchaseListVendorModel)))
  vendor?: PurchaseListVendorModel;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;

  @serializable(alias("customer_processing_fee", primitive()))
  customerProcessingFee?: number;
  
  @serializable(alias("cash_discount", primitive()))
  cashDiscount?: number = 0;
  
  @serializable(alias("promotion_discount", primitive()))
  promotionDiscount?: number = 0;

  @serializable(alias("customer_payment_status", primitive()))
  customerPaymentStatus?: string;

  @serializable(alias("purchase_status", primitive()))
  purchaseStatus?: string;
  
  @serializable(alias("total_late_fee", primitive()))
  totalLateFee?: number;
}

export class PurchaseItemModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("amount", primitive()))
  amount?: number;

  @serializable(alias("no_of_items", primitive()))
  noOfItems?: number;
}
export class PurchasePaymentLogModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("payment_mode", primitive()))
  paymentMode?: string;

  @serializable(alias("transaction_id", primitive()))
  transactionId?: string;

  @serializable(alias("amount", primitive()))
  amount?: number;

  @serializable(alias("is_amount_negetive", primitive()))
  isAmountNegative?: boolean;

  @serializable(alias("transaction_type", primitive()))
  transactionType?: string;

  @serializable(alias("external_payment_id", primitive()))
  externalPaymentId?: string;

  @serializable(alias("payment_status", primitive()))
  paymentStatus?: string;

  @serializable(alias("failure_reason", primitive()))
  failureReason?: string;

  @serializable(alias("installments", list(object(Installment))))
  installments?: Installment[] = [];

}

export class PurchaseVendorSettlementModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("amount", primitive()))
  amount?: number;

  @serializable(alias("purchase_status", primitive()))
  purchaseStatus?: BadgeTypes;

  @serializable(alias("purchase_id", primitive()))
  purchaseId?: string;

  @serializable(alias("mode", primitive()))
  mode?: typeof BOTH | typeof ONLINE | typeof INSTORE;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("is_amount_negetive", primitive()))
  isAmountNegative?: boolean;

  @serializable(alias("transaction_id", primitive()))
  transactionId?: string;

  @serializable(alias("is_amount_negetive", primitive()))
  isAmountNegetive?: boolean;

  @serializable(alias("vendor", object(VendorModel)))
  vendor?: VendorModel;
}

export class PurchaseCustomerModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("nation_id_number", primitive()))
  nationalIdNumber?: number;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("nation_id_number", primitive()))
  nationIdNumber?: string;

  @serializable(alias("profile_pic", object(AttachmentModel)))
  profilePic?: AttachmentModel;

  @serializable(alias("customer_payment_status", primitive()))
  customerPaymentStatus?:
    | BadgeTypes.PENDING
    | BadgeTypes.PARTIAL_RETURN
    | BadgeTypes.OUTSTANDING_PAYMENT;

  @serializable(alias("country_code", object(MetaModel)))
  countryCode?: MetaModel;
}

export class PurchaseInstoreModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("address", primitive()))
  address?: string;

  @serializable(
    alias(
      "lat",
      custom(
        (lat) => lat,
        (lat) => parseFloat(lat)
      )
    )
  )
  lat?: number;

  @serializable(
    alias(
      "long",
      custom(
        (long) => long,
        (long) => parseFloat(long)
      )
    )
  )
  long?: number;
}

export class PurchaseVendorModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("logo_url", primitive()))
  logoUrl?: string;

  @serializable(alias("logo", object(AttachmentModel)))
  logo?: AttachmentModel;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("mode", primitive()))
  mode?: typeof BOTH | typeof ONLINE | typeof INSTORE;

  @serializable(alias("vendor_settlment_status", primitive()))
  vendorSettelmentStatus?: BadgeTypes;

  @serializable(alias("instore", object(PurchaseInstoreModel)))
  instore?: PurchaseInstoreModel;

  @serializable(alias("vendor_settlement_status", primitive()))
  vendorSettlementStatus?: BadgeTypes;

  @serializable(alias("vendor_processing_fee", primitive()))
  vendorProcessingFee?: number;

  @serializable(alias("vat", primitive()))
  vat?: number;

  @serializable(alias("vendor_settlement_amount", primitive()))
  vendorSettlementAmount?: number;

  @serializable(alias("is_mobile_vendor", primitive()))
  isMobileVendor?: boolean;
}

export class PurchaseOutstandingPaymentModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("due_date", primitive()))
  dueDate?: string;

  @serializable(alias("min_amount", primitive()))
  minimumDue?: number;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;

  @serializable(alias("no_of_installments_paid", primitive()))
  noOfInstallmentsPaid?: number;

  @serializable(alias("max_amount", primitive()))
  maxAmount?: number;

  @serializable(alias("is_over_due", primitive()))
  isOverDue?: string;

  @serializable(alias("late_fee", primitive()))
  lateFee?: number;
}

export class PurchaseSettlementsModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("element_type", primitive()))
  element_type?: string;

  @serializable(alias("settlement_type", primitive()))
  settlement_type?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("transaction_type", primitive()))
  transaction_type?: string;

  @serializable(alias("external_payment_id", primitive()))
  external_payment_id?: string;

  @serializable(alias("settlement_amount", primitive()))
  settlement_amount?: number;

  @serializable(alias("settlement_id", primitive()))
  settlement_id?: string;

  @serializable(alias("settled_date", primitive()))
  settled_date?: string;
}

export class PurchaseReturnSummaryModel {
  @serializable(alias("return_recipt", object(AttachmentModel)))
  returnReceipt?: AttachmentModel;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;

  @serializable(alias("return_items", list(object(PurchaseItemModel))))
  returnItems?: PurchaseItemModel[] = [];

  @serializable(alias("created_at", primitive()))
  createdAt?: string;
}

export class MobilePurchaseDetails{
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("purchase_id", primitive()))
  purchaseId?: string;

  @serializable(alias("imei_number", primitive()))
  imeiNumber?: string;

  @serializable(alias("device_purchase_status", primitive()))
  devicePurchaseStatus?: string;

  @serializable(alias("customer_payment_status", primitive()))
  customerPaymentStatus?: string;
}

export class PurchaseModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("purchase_id", primitive()))
  purchaseId?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("customer", object(PurchaseCustomerModel)))
  customer?: PurchaseCustomerModel;

  @serializable(alias("vendor", object(PurchaseVendorModel)))
  vendor?: PurchaseVendorModel;

  @serializable(alias("purchase_items", list(object(PurchaseItemModel))))
  purchaseItems?: PurchaseItemModel[] = [];

  @serializable(
    alias("outstanding_payments", list(object(PurchaseOutstandingPaymentModel)))
  )
  outstandingPayments?: PurchaseOutstandingPaymentModel[] = [];

  @serializable(alias("customer_processing_fee", primitive()))
  customerProcessingFee?: number;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;
  
  @serializable(alias("cash_discount", primitive()))
  cashDiscount?: number;
  
  @serializable(alias("promotion_discount", primitive()))
  promotionDiscount?: number;

  @serializable(alias("purchase_status", primitive()))
  purchaseStatus?: string;

  @serializable(alias("refund_availed", primitive()))
  refundAvailed?: boolean;

  @serializable(alias("payment_logs", list(object(PurchasePaymentLogModel))))
  paymentLogs?: PurchasePaymentLogModel[] = [];

  @serializable(
    alias("vendor_settlements", list(object(PurchaseVendorSettlementModel)))
  )
  vendorSettlements?: PurchaseVendorSettlementModel[] = [];

  @serializable(alias("return_summary", object(PurchaseReturnSummaryModel)))
  returnSummary?: PurchaseReturnSummaryModel;

  @serializable(alias("customer_fee_vat", primitive()))
  customerFeeVat?: number;

  @serializable(alias("refund_details", object(RefundDetails)))
  refundDetails?: RefundDetails;

  @serializable(alias("settlements", list(object(PurchaseSettlementsModel))))
  settlements?: PurchaseSettlementsModel[] = [];

  @serializable(alias("customer_processing_fee_before_vat", primitive()))
  customerProcessingFeeBeforeVat?: number;

  @serializable(alias("total_late_fee", primitive()))
  totalLateFee?: number;

  @serializable(alias("mobile_purchase_details", object(MobilePurchaseDetails)))
  mobilePurchaseDetails?: MobilePurchaseDetails;
}
