import { Row, Col, Divider } from 'antd'
import { Form, Formik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import MetaService from '../../../services/MetaService/meta.service'
import { LocalStorage } from '../../utils/localStorageHelpers'
import DropdownField from '../DropdownField'
import IconComponent from '../IconComponent'
import "./settlementwidget.scss"
import { DashboardFilterModel, VendorSettlements } from '../../../models/Dashboard/dashboard.model'
import { VendorTypes } from '../../types/vendorTypes'
import CardSkeletonComponent from '../CardSkeletonComponent'
import { dashboardFraction } from '../../utils/dashboardFraction'

interface SettlementWidgetProps { 
    vendorType: "vendor" | "partner"
    settlementType: "vendor" | "partner" | "payment"
    onChange: Function
    params: DashboardFilterModel | undefined
    header: string
    data?: VendorSettlements | null
    loading?: boolean
    setIsDasboardSettlementsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isDasboardSettlementsLoading: boolean,
    isDasboardSettlementsVisible: boolean
}

const SettlementWidget: FC<SettlementWidgetProps> = ({ vendorType, settlementType, onChange, data, params, header, loading, setIsDasboardSettlementsLoading, isDasboardSettlementsLoading, isDasboardSettlementsVisible }) => {

    const showTest = LocalStorage.getItem("IS_TEST")
    const { fetchVendorsMeta, vendorsMeta, fetchPartnersMeta, partnersMeta } = MetaService() 
    const vendorOptions = [{value:'', label:`All ${vendorType}s`}, ...vendorsMeta]
    const partnerOptions = [{value:'', label:`All ${vendorType}s`}, ...partnersMeta]
    const [vendorId, setvendorId] = useState<number>()
    const [partnerId, setPartnerId] = useState<number>()
    const [paymentId, setPaymentId] = useState<number>()
    const isVendor = vendorType === 'vendor'
    const isPayment = settlementType === "payment"

    useEffect(() => {
        if(isDasboardSettlementsVisible){
            fetchVendorsMeta({showTest, pagination: false})
            fetchPartnersMeta({showTest, pagination: false})
        }
    }, [isDasboardSettlementsVisible])

    useEffect(() => {
        setIsDasboardSettlementsLoading(true)
        if(isDasboardSettlementsVisible){
            switch (settlementType){
                case VendorTypes.VENDOR:
                    onChange({vendorId, ...params})
                    break;
                case VendorTypes.PARTNER_FEE:
                    onChange({partnerId, ...params})
                    break;
                case VendorTypes.PAYMENT_COLLECTION:
                    onChange({partnerId: paymentId, ...params})
                    break;
            }
        }
    }, [params, vendorId, partnerId, paymentId, isDasboardSettlementsVisible])

    useEffect(() => {
        data && setIsDasboardSettlementsLoading(false)
    }, [data])
    

    return (
        <div className="settlementwidget">
            <div className="settlement-widget__container">
                <Formik initialValues={{vendorType: `All ${vendorType}s`}} onSubmit={() => {}}>
                    {({values, setFieldValue}) => (
                    <Form>
                        {loading ? <CardSkeletonComponent rows={15} className="settlementwidget_skeleton" /> :
                        <Row>
                        <Col span={24} className="select-field">
                            <Row>
                                <Col span={24}><h3>{header}</h3></Col>
                                <Col span={24}>
                                    <IconComponent name="icon-vendors" />
                                    <DropdownField
                                        label=""
                                        options={isVendor ? vendorOptions : partnerOptions}
                                        name="vendorType"
                                        placeholder="Select"
                                        onChange={(value) =>{
                                            setFieldValue("vendorType", value)
                                            switch (settlementType){
                                                case VendorTypes.VENDOR:
                                                    setvendorId(value)
                                                    break;
                                                case VendorTypes.PARTNER_FEE:
                                                    setPartnerId(value)
                                                    break;
                                                case VendorTypes.PAYMENT_COLLECTION:
                                                    setPaymentId(value)
                                                    break;
                                            }
                                        }}
                                        value={values?.vendorType}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} className='pending__container'>
                            <Row>
                                <Col span={24}><div className='title'>PENDING SETTLEMENTS</div></Col>
                                <Col span={24}><div className='value'>LE {dashboardFraction(data?.pendingSettlementAmount?.toString()) ?? "00.00"}</div></Col>
                                <Col span={24}>
                                <div className='data'>
                                {isVendor && 
                                    <div className='pending-settlement-item'>
                                        <span className="label">Purchase Amount</span>
                                        <span className="value">{dashboardFraction(data?.pendingPurchaseAmount?.toString()) ?? "0.0"}</span>
                                    </div>
                                }
                                {isPayment && 
                                    <div className='pending-settlement-item'>
                                        <span className="label">Payment Amount</span>
                                        <span className="value">{data?.pendingPaymentAmount ? dashboardFraction(data?.pendingPaymentAmount?.toString()) : "0.0"}</span>
                                    </div>
                                }
                                    <div className='pending-settlement-item'>
                                        <span className="label">{isVendor? "Vendor Fee" : "Partner Fee"}</span>
                                        <span className="value">{(isVendor) ? `- ${data?.pendingVendorFee ? dashboardFraction(data?.pendingVendorFee?.toString()) : "0.0"}` : `${isPayment ? '-' : ''} ${data?.pendingProcessingFee ? dashboardFraction(data?.pendingProcessingFee?.toString()) : "0.0"}`}</span>
                                    </div>
                                    <div className='pending-settlement-item'>
                                        <span className="label">VAT</span>
                                        <span className="value">{(isVendor || isPayment) && "-"} {data?.pendingVat ? dashboardFraction(data?.pendingVat?.toString()) : "0.0"}</span>
                                    </div>
                                    {isVendor && 
                                        <div className='pending-settlement-item'>
                                            <span className="label">Returns</span>
                                            <span className="value">-{dashboardFraction(data?.pendingTotalRefundAmount?.toString()) ?? "0.0"}</span>
                                        </div>
                                    }
                                </div>
                                </Col>
                            </Row>
                        </Col>
                        <Divider />
                        <Col span={24} className='pending__container'>
                            <Row>
                                <Col span={24}><div className='title'>PAST SETTLEMENTS</div></Col>
                                <Col span={24}><div className='value'>LE {dashboardFraction(data?.pastSettlementAmount?.toString()) ?? "00.00"}</div></Col>
                                <Col span={24}>
                                <div className='data'>
                                {isVendor && 
                                    <div className='pending-settlement-item'>
                                        <span className="label">Purchase Amount</span>
                                        <span className="value">{dashboardFraction(data?.pastPurchaseAmount?.toString()) ?? "0.0"}</span>
                                    </div>
                                }
                                {isPayment && 
                                    <div className='pending-settlement-item'>
                                        <span className="label">Payment Amount</span>
                                        <span className="value">{data?.pastPaymentAmount ? dashboardFraction(data?.pastPaymentAmount?.toString()) : "0.0"}</span>
                                    </div>
                                }
                                    <div className='pending-settlement-item'>
                                        <span className="label">{isVendor ? "Vendor Fee" : "Partner Fee"}</span>
                                        <span className="value">{isVendor ? `- ${data?.pastVendorFee ? dashboardFraction(data?.pastVendorFee?.toString()) : "0.0"}` : `${isPayment ? '-' : ''} ${dashboardFraction(data?.pastProcessingFee?.toString())}`}</span>
                                    </div>
                                    <div className='pending-settlement-item'>
                                        <span className="label">VAT</span>
                                        <span className="value">{(isVendor || isPayment) && "-"} {dashboardFraction(data?.pastVat?.toString()) ?? "0.0"}</span>
                                    </div>
                                </div>
                                </Col>
                            </Row>
                        </Col>
                        </Row>
                        }
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default SettlementWidget
