import React, { useEffect, useRef, useState } from "react";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../../shared/components/TableComponent";
import "./pendingSettlements.scss";
import { Form, Formik } from "formik";
import { Col, DatePicker, Row } from "antd";
import DropdownField from "../../../../../shared/components/DropdownField";
import { SettlementService } from "../../../../../services/SettlementService/settlement.service";
import { PendingSettlementModel } from "../../../../../models/Settlement/settlement.model";
import NoTransactionsImg from "../../../../../assets/empty/transactions.svg";
import moment, { Moment } from "moment";
import { removeUndefined } from "../../../../../shared/utils/removeUndefined";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import SettlementForm from "./SettlementForm";
import { SortFilterModel } from "../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../shared/types/SortColumn.type";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { generateDateString } from "../../../../../shared/utils/generateDateString";
import { RangePickerValueType } from "../../../../../shared/types/rangePicker.type";
import MetaService from "../../../../../services/MetaService/meta.service";
import { convertToTwoDecimals } from "../../../../../shared/utils/convertToTwoDecimals";
import { HandleSelectionProps, handleSelection } from "../../../../../shared/utils/handleSelection";
import { objectHasKeys } from "../../../../../shared/utils/objectHasKeys";
import { StateProps } from "../../../../../models/Meta/meta.model";
import { parseDate } from "../../../../../shared/utils/formatDate";

const { RangePicker } = DatePicker;

const PendingSettlements = () => {
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const isLocationPending = location.hash === "#pending";
  const from = isLocationPending ? stateValue?.from as string : null;
  const pageNumber = (isLocationPending && from === "pending-settlements") ? stateValue?.pageNumber as number : null;
  const returnsPageNumber = (isLocationPending && from === "pending-settlements-returns") ? stateValue?.pageNumber as number : null;
  let filterOptions = isLocationPending ? stateValue?.filterOptions as SortFilterModel : null;
  const navigate = useNavigate();
  const [page, setPage] = useState(pageNumber ?? 1);
  const [returnsPage, setReturnsPage] = useState(returnsPageNumber ?? 1);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState<SortFilterModel>();
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const [isFilterShown, setIsFilterShown] = useState(false);

  const {
    fetchPendingSettlementList,
    loading,
    pendingSettlements,
    paginations,
    fetchAllPendingSettlementIdForVendor,
    dataLoading,
    pendingSettlementIds,
    fetchPendingReturnsList,
    pendingReturnSettlements,
    returnsPaginations,
    markPendingSettlementsDetails,
    markAsSettledDetails
  } = SettlementService();
  const { fetchVendorsMeta, vendorsMeta } = MetaService();
  const vendorOptions = [{value:'', label:`All Vendors`}, ...vendorsMeta]
  const [settlementsModalVisible, setSettlementsModalVisible] =
    useState<boolean>(false);
  const [selectedSettlements, setSelectedSettlements] = useState<number[]>(
    []
  );
  const [selectedSettlementsPaymentIds, setSelectedSettlementsPaymentIds] =
    useState<number[]>([]);
  const [selectedSettlementsData, setSelectedSettlementsData] = useState<
    PendingSettlementModel[]
  >([]);
  const [selectedReturns, setSelectedReturns] = useState<React.Key[]>([]);
  const [selectedReturnData, setSelectedReturnData] = useState<PendingSettlementModel[]>([]);
  const [selectAll, setSelectAll] = useState(false)
  const startDate = parseDate(filterOptions?.purchaseStartDate)
  const endDate = parseDate(filterOptions?.purchaseEndDate)
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([startDate ?? null, endDate ?? null]);
  const dropdownRef = useRef()
  
  const filterInitialValues = filterOptions ?? new SortFilterModel();
  const showTest = LocalStorage.getItem("IS_TEST");

  const handleCloseSetlementModal = () => setSettlementsModalVisible(false);
  const handleOpenSetlementModal = () => setSettlementsModalVisible(true);

  const handleFilterSubmit = (values: SortFilterModel) => {
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  useEffect(() => {
    if(objectHasKeys(filters)){
      fetchPendingSettlementList({page:1, ...filters, ...sortOptions, showTest });
      fetchPendingReturnsList({page:1, ...filters, ...sortOptions, showTest })
    }
  }, [filters]);

  useEffect(() => {
    fetchVendorsMeta({ showTest, pagination: false });
    if(isLocationPending) {
      fetchPendingSettlementList({page, ...filters, ...sortOptions, showTest });
      fetchPendingReturnsList({ page, ...filters, ...sortOptions, showTest })
    }
  }, [location.hash]);

  useEffect(() => {
      const returnData = selectedReturns.map(selection => selection) as number[]
      const settlementData = selectedSettlements.map(key => key) as number[]
      setSelectedSettlementsPaymentIds([...settlementData, ...returnData])

  }, [selectedSettlements, selectedReturns])

  useEffect(() => {
    if(pendingSettlementIds?.purchaseIds) {
      setSelectedSettlements(pendingSettlementIds?.purchaseIds)
      setSelectedSettlementsPaymentIds(pendingSettlementIds?.purchaseIds)
    }
    pendingSettlementIds?.returnIds && setSelectedReturns(pendingSettlementIds?.returnIds)
  }, [pendingSettlementIds])

  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const redirectToPurchaseScreen = (record: PendingSettlementModel) =>
    navigate(
      generatePath(AppRoutes.PURCHASES_VIEW, {
        purchaseId: record?.id?.toString(),
      })
    );

  const refreshList = () => {
    setSelectedSettlements([]);
    setSelectedSettlementsData([]);
    setSelectedSettlementsPaymentIds([]);
    setSelectedReturns([]);
    setSelectedReturnData([]);
  };

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[0] !== "vendorId" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              location.state = null;
              filterOptions = null;
              setPage(1);
              handleFilterClose();
              setDateRange([null, null])
              setFilters(() => ({
                ...filterOptions,
                search: filters?.search
              }));
              resetForm({values: {}});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  {/* TODO: needed for reference */}
                  {/* <DatePickerField
                    name="createdAt"
                    placeholder="Enter"
                    label="Purchase Date Range"
                    value={values?.createdAt}
                    onChange={(date, dateString) => {
                      setFieldValue("createdAt", dateString);
                    }}
                  /> */}
                  <div className="input-field">
                    <label>{"Purchase Date Range"}</label>
                    <RangePicker
                      disabledDate={(date) => date.isAfter(moment())}
                      value={dateRange}
                      onChange={(values) => {
                        if (values?.length) {
                          setDateRange(values)
                          const dataString = generateDateString(
                            values as RangePickerValueType
                          );
                          setFieldValue(
                            "purchaseStartDate",
                            dataString?.split(" - ")[0]
                          );
                          setFieldValue(
                            "purchaseEndDate",
                            dataString?.split(" - ")[1]
                          );
                        }
                      }}
                      format={"DD/MM/YYYY"}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  const filterBy = ( 
    <Formik initialValues={{vendorId: filterOptions?.vendorId ?? 'All Vendors'}} onSubmit={() => {}}>
      {({ values, setFieldValue }) => (          
        <DropdownField
        label=""
        options={vendorOptions}
        name="vendorId"
        placeholder="Select"
        onChange={(value, record) => {
          setFieldValue("vendorId", value)
          handleFilterSubmit({vendorId: value})
        }}            
        value={values?.vendorId}
        className="header-dropdown"
        />
      )}
    </Formik>
  )

  const selectionDetails = (
    <div className="selection-data">
      <span className="label">Purchase Transaction Selected <span className="value">{selectedSettlements.length}</span></span>
      <span className="label">Return Transactions Selected <span className="value">{selectedReturns.length}</span></span>
      {(selectedSettlements.length > 0 || selectedReturns.length > 0) && !selectAll &&
        <span className="label-select" onClick={() => {
          fetchPendingSettlementList({ ...sortOptions, page, ...filters, showTest });
          fetchPendingReturnsList({ ...sortOptions, page: returnsPage, ...filters, showTest })
          fetchAllPendingSettlementIdForVendor({vendorId: (selectedSettlementsData[0]?.vendor?.id || selectedReturnData[0]?.vendor?.id), showTest})
          setSelectAll(true)
        }}>Select all transactions of {selectedSettlementsData[0]?.vendor?.name || selectedReturnData[0]?.vendor?.name}</span>
      }
      {selectAll && 
      <span className="label-deselect" onClick={() => {
        setSelectAll(false)
        refreshList()
        fetchPendingSettlementList({ ...sortOptions, page, ...filters, showTest });
        fetchPendingReturnsList({ ...sortOptions, page: returnsPage, ...filters, showTest })
      }}>Deselect All</span>
      }
    </div>
  )

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchPendingSettlementList({ ...sortOptions, page, ...filters, showTest }); 
  };
  const handleReturnsPageChange = (page: number) => {
    setReturnsPage(page);
    fetchPendingReturnsList({ ...sortOptions, page: returnsPage, ...filters, showTest }) 
  };

  const handleMarkAsSettled = () => {
    markPendingSettlementsDetails({pendingSettlementIds: selectedSettlementsPaymentIds})
    handleOpenSetlementModal()
  }

  return (
    <>
      <div className="pending-settlements">
        <TableComponent
          title="Purchase Transactions"
          isSettlements={true}
          selectionDetails={(selectedSettlements.length > 0 || selectedReturns.length > 0) ? selectionDetails : <></>}
          onChange={fetchPendingSettlementList}
          onSort={(data) => {
            setSortOptions(data);
          }}
          filtersSorts={{ page, ...filters, ...sortOptions, search, showTest }}
          rowSelection={{
            selectedRowKeys: selectedSettlements,
            type: "checkbox",
            columnTitle: <></>,
            getCheckboxProps: (record) => ({
              disabled:
              ((!!selectedSettlementsData?.length &&
                record?.vendor?.id != selectedSettlementsData[0]?.vendor?.id) || 
                (!!selectedReturnData?.length &&
                  record?.vendor?.id != selectedReturnData[0]?.vendor?.id)
                ),
              name: record.id,
            }),
            onSelect: (record, selected, selectedRows) => {
              handleSelection(
                {record, selected, selectedRows, selectedSettlements, selectedSettlementsData, setSelectedSettlements, setSelectedSettlementsData} as HandleSelectionProps<PendingSettlementModel>)
            },
          }}
          onRow={(record) => navigate({pathname: generatePath(AppRoutes.PURCHASES_VIEW, { purchaseId: record?.id?.toString()})}, {state: {page, from: 'pending-settlements', filters}})}
          newBtn={{
            text: "Mark as Settled",
            clickHandler: handleMarkAsSettled,
            disabled: !selectedSettlements?.length && !selectedReturns?.length,
          }}
          paginationMeta={paginations}
          handlePageChange={handlePageChange}
          search={{
            placeholder: "Search by external purchase ID",
            onSearch: (value) => {
              setSearch(value);
              handleFilterSubmit({ search: value });
            },
          }}
          filter={filter}
          filterBy={filterBy}
          columns={[
            {
              title: "Vendor",
              dataIndex: "vendor",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_NAME,
              render: (
                text: PendingSettlementModel["vendor"],
                record: PendingSettlementModel
              ) => (
                <span
                  className="vendor__name"
                >
                  <img src={record?.vendor?.logo?.s3Url} />
                  {text?.name}
                </span>
              ),
              sorter: true,
            },
            {
              title: "External Purchase ID",
              dataIndex: "customerReferenceNumber",
              key: "customerReferenceNumber",
              render: (text, record: PendingSettlementModel) => (
                <span 
                >
                  {text}
                </span>
              ),
            },
            {
              title: "Purchase Date",
              dataIndex: "createdAt",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_PURCHASE_DATE,
              render: (text) => moment(text)?.format("DD MMM YYYY"),
              sorter: true,
            },
            {
              title: "Purchase Amount",
              dataIndex: "totalAmount",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_PURCHASE_AMOUNT,
              sorter: true,
            },
            {
              title: "Vendor Fee",
              dataIndex: "vendorProcessingFee",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_FEE,
              render: (text, record) =>
                record?.paymentTransactionType === "return"
                  ? "-"
                  : convertToTwoDecimals(+text),
              sorter: true,
            },
            {
              title: "VAT",
              dataIndex: "vat",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_FEE_VAT,
              render: (text, record) =>
                record?.paymentTransactionType === "return"
                  ? "-"
                  : convertToTwoDecimals(+text),
              sorter: true,
            },
            {
              title: "Vendor Settlement Amount",
              dataIndex: "vendorSettlementAmount",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_SETTLEMENT_AMOUNT,
              render: (text, record) => (
                <span className={record?.isAmountNegative && "payment-loss"}>
                  {record?.isAmountNegative && "-"}
                  {convertToTwoDecimals(+text)}
                </span>
              ),
              sorter: true,
              align: "right",
              width: 200,
            },
          ]}
          loading={loading}
          dataLoading={dataLoading}
          empty={{
            text: "No pending settlements",
            img: NoTransactionsImg,
          }}
          data={pendingSettlements}
        />
      </div>
      {pendingReturnSettlements?.length > 0 && 
        <div className="pending-settlements returns">
        <TableComponent
          title="Return Transactions"
          isSettlements={true}
          onChange={fetchPendingReturnsList}
          onSort={(data) => {
            setSortOptions(data);
          }}
          filtersSorts={{ page: returnsPage, ...filters, ...sortOptions, search, showTest }}
          rowSelection={{
            selectedRowKeys: selectedReturns,
            type: "checkbox",
            columnTitle: <></>,
            getCheckboxProps: (record) => ({
              disabled:
                ((!!selectedSettlementsData?.length &&
                record?.vendor?.id != selectedSettlementsData[0]?.vendor?.id) || 
                (!!selectedReturnData?.length &&
                  record?.vendor?.id != selectedReturnData[0]?.vendor?.id)
                ),
              name: record.id,
            }),
            onSelect: (record, selected, selectedRows) => {
              const key = record.key
              if(selected){
                const selectedReturnsSet = new Set([...selectedReturns, key])
                const selectedReturnDataSet = new Set([...selectedReturnData, ...selectedRows])
                setSelectedReturns(Array.from(selectedReturnsSet));
                setSelectedReturnData(Array.from(selectedReturnDataSet).filter(data => data !== undefined));
              } else {
                setSelectedReturns(selectedReturns.filter(select => select !== key))
                setSelectedReturnData(selectedReturnData.filter(data => data.key !== key))
              }
            }, 
          }}
          onRow={(record) => navigate({pathname: generatePath(AppRoutes.PURCHASES_VIEW, { purchaseId: record?.id?.toString()})}, {state: {page: returnsPage, from: 'pending-settlements-ruturns', filters}})}
          paginationMeta={returnsPaginations}
          handlePageChange={handleReturnsPageChange}
          columns={[
            {
              title: "Vendor",
              dataIndex: "vendor",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_NAME,
              render: (
                text: PendingSettlementModel["vendor"],
                record: PendingSettlementModel
              ) => (
                <span
                  className="vendor__name"
                  onClick={() => redirectToPurchaseScreen(record)}
                >
                  <img src={record?.vendor?.logo?.s3Url} />
                  {text?.name}
                </span>
              ),
              sorter: true,
            },
            {
              title: "External Purchase ID",
              dataIndex: "customerReferenceNumber",
              key: "customerReferenceNumber",
              render: (text, record: PendingSettlementModel) => (
                <span onClick={() => redirectToPurchaseScreen(record)}>
                  {text}
                </span>
              ),
            },
            {
              title: "Refund Receipt ID",
              dataIndex: "refundReceiptId",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_REFUND_RECEIPT_ID,
              sorter: true,
            },
            {
              title: "Returned Date",
              dataIndex: "returnedDate",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_VENDOR_RETURNED_DATE,
              render: (text) => moment(text)?.format("DD MMM YYYY"),
              sorter: true,
            },
            {
              title: "Refund Amount on the Receipt",
              dataIndex: "refundAmount",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_REFUND_AMOUNT,
              render: (text, record) =>
                record?.paymentTransactionType === "return"
                  ? "-"
                  : convertToTwoDecimals(+text),
              sorter: true,
            },
            {
              title: "Vendor Settlement Amount",
              dataIndex: "vendorSettlementAmount",
              key: SortColumnTypes.PENDING_VENDOR_SETTLEMENTS_REFUND_VENDOR_SETTLEMENT_AMOUNT,
              render: (text, record) => (
                <span className={record?.isAmountNegative && "payment-loss"}>
                  {record?.isAmountNegative && "-"}
                  {convertToTwoDecimals(+text)}
                </span>
              ),
              sorter: true,
              align: "right",
              width: 200,
            },
          ]}
          loading={loading}
          dataLoading={dataLoading}
          empty={{
            text: "No pending returns",
            img: NoTransactionsImg,
          }}
          data={pendingReturnSettlements}
        />
        </div>
      }
      <SettlementForm
        callback={(isTest) => fetchPendingSettlementList({ showTest: isTest })}
        visible={settlementsModalVisible}
        closeHandler={handleCloseSetlementModal}
        settlementData={[...selectedSettlementsData, ...selectedReturnData]}
        selectedSettlements={[...selectedSettlements, ...selectedReturns]}
        selectedSettlementsPaymentIds={selectedSettlementsPaymentIds}
        markAsSettledDetails={markAsSettledDetails}
        refreshList={refreshList}
      />
    </>
  );
};

export default PendingSettlements;
