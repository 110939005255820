

import {serializable, alias, object, list, primitive} from 'serializr';
import { BadgeTypes } from '../../enums/badgeTypes';
import { VendorTypes } from '../../enums/vendorTypes';
import { AttachmentModel } from '../Attachment/attachment.model';
import { UserModel } from '../User/user.model';
import { RefundTypes } from '../../enums/refundTypes';
import { TransactionRange } from '../TransactionRange/transactionRange.model';
import { Partner } from '../PartnerFees/partnerFees.model';

const {BOTH, INSTORE, ONLINE} = VendorTypes
export class SettlementInstoreModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

}

export class SettlementVendorModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('logo_url', primitive()))
	logoUrl?: string;

	@serializable(alias('logo', object(AttachmentModel)))
	logo?: AttachmentModel;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('mode', primitive()))
	mode?: typeof BOTH|typeof ONLINE|typeof INSTORE;

	@serializable(alias('vendor_settelment_status', primitive()))
	vendorSettelmentStatus?: string;

	@serializable(alias('instore', object(SettlementInstoreModel)))
	instore?: SettlementInstoreModel;

	@serializable(alias('processing_fee', primitive()))
	processingFee?: string;
}

export class PendingSettlementModel { 

	@serializable(alias('payment_id', primitive()))
	key?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('payment_id', primitive()))
	paymentId?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('vendor', object(SettlementVendorModel)))
	vendor?: SettlementVendorModel;

	@serializable(alias('customer_processing_fee', primitive()))
	customerProcessingFee?: number = 0;
	
	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: number = 0;
	
	@serializable(alias('vat', primitive()))
	vat?: number = 0;
	
	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: number = 0;
	
	@serializable(alias('customer_reference_number', primitive()))
	customerReferenceNumber?: number = 0;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number = 0;

	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: string;
	
	@serializable(alias('payment_transaction_type', primitive()))
	paymentTransactionType?: string;

	@serializable(alias('is_amount_negetive', primitive()))
	isAmountNegative?: boolean;

	@serializable(alias('refund_recipt_id', primitive()))
	refundReceiptId?: string;

	@serializable(alias('refund_amount', primitive()))
	refundAmount?: number;

	@serializable(alias('returned_date', primitive()))
	returnedDate?: string;

	@serializable(alias('refund_status', primitive()))
	refundStatus?: string;

	@serializable(alias('refund_type', primitive()))
	refundType?: RefundTypes.FULL_PAYMENT_ADJUSTED | RefundTypes.PARTIAL_PAYMENT_ADJUSTED | RefundTypes.CASH_REFUND;

}


export class PastSettlementListModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('settled_date', primitive()))
	settledDate?: string;

	@serializable(alias('vendor', object(SettlementVendorModel)))
	vendor?: SettlementVendorModel;

	@serializable(alias('no_of_orders', primitive()))
	noOfOrders?: number;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('maximum_purchase_date', primitive()))
	maximumPurchaseDate?: string;

	@serializable(alias('minimum_purchase_date', primitive()))
	minimumPurchaseDate?: string;

	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: number = 0;

}

export class SettlementCustomerPurchaseModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('vendor', object(SettlementVendorModel)))
	vendor?: SettlementVendorModel;

	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: BadgeTypes;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('is_amount_negetive', primitive()))
	isAmountNegative?: number;

	@serializable(alias('vat', primitive()))
	vat?: number;

	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: number;

	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: number;

	@serializable(alias('external_purchase_id', primitive()))
	externalPurchaseId?: string;

	@serializable(alias('refund_recipt_id', primitive()))
	refundReceiptId?: string;

	@serializable(alias('refund_amount', primitive()))
	refundAmount?: number;

	@serializable(alias('returned_date', primitive()))
	returnedDate?: string;

	@serializable(alias('refund_status', primitive()))
	refundStatus?: string;

	@serializable(alias('refund_type', primitive()))
	refundType?: RefundTypes.FULL_PAYMENT_ADJUSTED | RefundTypes.PARTIAL_PAYMENT_ADJUSTED | RefundTypes.CASH_REFUND;

	@serializable(alias('refunded_transactions', primitive()))
	refundedTransactions?: number;

	@serializable(alias('invoice_issued', primitive()))
	invoiceIssued?: boolean;

	@serializable(alias('invoice_uuid', primitive()))
	invoiceUuid?: string;

	@serializable(alias('invoice_public_url', primitive()))
	invoicePublicUrl?: string;

}

export class SettlementModel extends PastSettlementListModel {
	
	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: number;

	@serializable(alias('vat', primitive()))
	vat?: number;

	@serializable(alias('refunded_transactions', primitive()))
	refundedTransactions?: number;

	@serializable(alias('settlement_amount', primitive()))
	settlementAmount?: number;

	@serializable(alias('customer_purchases', list(object(SettlementCustomerPurchaseModel))))
	customerPurchases?: SettlementCustomerPurchaseModel[] = [];

	@serializable(alias('returns', list(object(SettlementCustomerPurchaseModel))))
	returns?: SettlementCustomerPurchaseModel[] = [];

	@serializable(alias('created_by', object(UserModel)))
	createdBy?: UserModel;

	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: number;

}

export class MarkSettledPendingSettlement {
	@serializable(alias('id', list(primitive())))
	id: React.Key[] = [];
	
	@serializable(alias('payment_id', list(primitive())))
	paymentId?: number[] = [];

	@serializable(alias('vendor_id', primitive()))
	vendorId?: number;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('settled_date', primitive()))
	settledDate?: string;

	@serializable(alias('ids', list(primitive())))
	pendingSettlementIds?: number[] = [];
}

export class PastSettlementFilterModel {

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias("vendor_id", primitive()))
	vendorId?: string;
  
	@serializable(alias("vendor_name", primitive()))
	vendorName?: string;

	@serializable(alias("search", primitive()))
	search?: string;
}

export class PendingSettlementFilterModel extends PastSettlementFilterModel {
	
	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: string;

}

export class PendingSettlementId {
	@serializable(alias('purchase_ids', list(primitive())))
	purchaseIds: number[] = [];
	
	@serializable(alias('return_ids', list(primitive())))
	returnIds?: number[] = [];
}

export class MarkAsSettledDetails { 

	@serializable(alias('no_of_orders', primitive()))
	noOfOrders?: number;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('purchase_count', primitive()))
	purchaseCount?: number;

	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: number;

	@serializable(alias('total_vat', primitive()))
	totalVat?: number;

	@serializable(alias('total_settlement_amount', primitive()))
	totalSettlementAmount?: number;

	@serializable(alias('settlement_amount', primitive()))
	settlementAmount?: number;

	@serializable(alias('refunded_count', primitive()))
	refundedCount?: number;

	@serializable(alias('refunded_amount', primitive()))
	refundedAmount?: number;

	@serializable(alias('date_range', object(TransactionRange)))
	transactionRange?: TransactionRange;

	@serializable(alias('partner_processing_fee', primitive()))
	partnerProcessingFee?: number;

	@serializable(alias('partner_processing_fee_vat', primitive()))
	partnerProcessingFeeVat?: number;

	@serializable(alias('collection_amount', primitive()))
	collectionAmount?: number;

	@serializable(alias('vendor', object(SettlementVendorModel)))
	vendor?: SettlementVendorModel;

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('partner', object(Partner)))
	partner?: Partner;
}

