import React, { FC } from 'react'
import { DeallPurchaseConfig } from '../../../../../models/DeallPurchaseConfig/deallPurchaseConfig.model'
import SwitchComponent from '../../../../../shared/components/SwitchComponent'
import "./purchaseStatusCard.scss"

interface PurchaseStatusCardProps {
    onStatusChange: Function,
    status: boolean,
    purchaseConfig: DeallPurchaseConfig
}

const PurchaseStatusCard: FC<PurchaseStatusCardProps> = ({onStatusChange, status, purchaseConfig}) => {

    const purchaseStatus = purchaseConfig?.purchaseStatus

    return (
        <div className={`purchase-status-card ${purchaseStatus ? "active" : "inactive"}`}>
            <div className="content">
                <div className="purchase-status__header">
                    <h2>{purchaseStatus ? "Active" : "Deactivated"}</h2>
                    <SwitchComponent
                        value={purchaseStatus}
                        onSwitch={(value) => onStatusChange(value)}
                    />
                </div>
                <div className="text">
                    <p>{purchaseStatus ? "RISE is allowing purchases with all active vendors" : "All new RISE purchases are currently paused"}</p>
                </div>
            </div>
        </div>
    )
}

export default PurchaseStatusCard