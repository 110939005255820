
import {serializable, alias, object, list, primitive} from 'serializr';

export class PurchasePayment { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('customer_id', primitive()))
	customerId?: number;

	@serializable(alias('purchase_type', primitive()))
	purchaseType?: string;

	@serializable(alias('instore_id', primitive()))
	instoreId?: number;

	@serializable(alias('vendor_id', primitive()))
	vendorId?: number;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: string;

	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: string;

	@serializable(alias('customer_payment_status', primitive()))
	customerPaymentStatus?: string;

	@serializable(alias('vendor_settlement_status', primitive()))
	vendorSettlementStatus?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('customer_processing_fee', primitive()))
	customerProcessingFee?: number;

	@serializable(alias('return_payment_mode', primitive()))
	returnPaymentMode?: string;

	@serializable(alias('return_receipt', primitive()))
	returnReceipt?: string;

	@serializable(alias('customer_return_payment_status', primitive()))
	customerReturnPaymentStatus?: string;

	@serializable(alias('return_purchase_status', primitive()))
	returnPurchaseStatus?: string;

	@serializable(alias('reason_for_failure', primitive()))
	reasonForFailure?: string;

	@serializable(alias('customer_reference_number', primitive()))
	customerReferenceNumber?: number;

	@serializable(alias('pos_type', primitive()))
	posType?: string;

	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: number;

	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: number;

	@serializable(alias('vat', primitive()))
	vat?: number;

	@serializable(alias('partner_id', primitive()))
	partnerId?: number;

	@serializable(alias('partner_settlement_status', primitive()))
	partnerSettlementStatus?: string;

	@serializable(alias('partner_processing_fee', primitive()))
	partnerProcessingFee?: number;

	@serializable(alias('partner_processing_fee_vat', primitive()))
	partnerProcessingFeeVat?: number;

	@serializable(alias('invoice_issued', primitive()))
	invoiceIssued?: boolean;

	@serializable(alias('invoice_uuid', primitive()))
	invoiceUuid?: string;

	@serializable(alias('invoice_long_id', primitive()))
	invoiceLongId?: string;

	@serializable(alias('invoice_public_url', primitive()))
	invoicePublicUrl?: string;

}