
import {serializable, alias, object, list, primitive} from 'serializr';
import { InAppNotificationTypes } from '../enums/inAppNotificationTypes';
import {VendorModel} from "./../models/Vendor/vendor.model"

const {INSTALLMENT_REMAINDER,INSTORE_STATUS,RETURN_STATUS, PURCHASE_STATUS} = InAppNotificationTypes

export class VendorUser { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('mobile_number', primitive()))
	mobileNumber?: string;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('role', primitive()))
	role?: string;

	@serializable(alias('status', primitive()))
	status?: string;

}
export class Receiver { 

	@serializable(alias('type', primitive()))
	receiverType?: string;

	@serializable(alias('vendor_user', object(VendorUser)))
	vendorUser?: VendorUser;

}

export class PurchaseItem { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('no_of_items', primitive()))
	noOfItems?: number;

	@serializable(alias('amount', primitive()))
	amount?: number;

}

export class Instore { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('address', primitive()))
	address?: string;

	@serializable(alias('lat', primitive()))
	lat?: string;

	@serializable(alias('long', primitive()))
	long?: string;

}
export class CustomerPurchase { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('vendor', object(VendorModel)))
	vendor?: VendorModel;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('purchase_items', list(object(PurchaseItem))))
	purchaseItems?: PurchaseItem[] = [];

	@serializable(alias('total_count', primitive()))
	totalCount?: number;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('customer_payment_status', primitive()))
	customerPaymentStatus?: string;

}

export class Data { 

	@serializable(alias('type', primitive()))
	dataType?: string;

	@serializable(alias('customer_purchase', object(CustomerPurchase)))
	customerPurchase?: CustomerPurchase;

	@serializable(alias('vendor', object(VendorModel)))
	vendor?: VendorModel;

}

export class Admin { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('role', primitive()))
	role?: string;

	@serializable(alias('mobile_number', primitive()))
	mobileNumber?: string;

	@serializable(alias('status', primitive()))
	status?: string;

}

export class Sender { 

	@serializable(alias('type', primitive()))
	senderType?: string;

	@serializable(alias('admin', object(Admin)))
	admin?: Admin;

}

export class NotificationModel { 

	@serializable(alias('id', primitive()))
	id: number = 0;

	@serializable(alias('no_of_unread_notifications', primitive()))
	noOfUnreadNotifications?: number;

	@serializable(alias('is_seen', primitive()))
	isSeen?: boolean;

	@serializable(alias('body', primitive()))
	body?: string;

	@serializable(alias('notification_type', primitive()))
	notificationType?: typeof INSTALLMENT_REMAINDER|typeof INSTORE_STATUS|typeof RETURN_STATUS|typeof PURCHASE_STATUS;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('sender', object(Sender)))
	sender?: Sender;

	@serializable(alias('receiver', object(Receiver)))
	receiver?: Receiver;

	@serializable(alias('data', object(Data)))
	data?: Data;

}