import * as Yup from "yup"
import { RefundModeTypes } from "../../../../../enums/refundTypes";

export const createRefundFormValidation = (totalAmount: number) => Yup.object().shape({
  receiptId: Yup.string()
    .when("receiptNotToBePresent", {
      is: false,
      then: Yup.string().required('Refund receipt id is required')
    }),
  receiptNotToBePresent: Yup.bool(),
  returnedDate: Yup.string().required('Returned date is required'),
  refundAmount: Yup.number().integer("Refund amount should be a whole number")
  .required('Refund amount is required')
  .when("refundMode", {
    is: RefundModeTypes.FULL_REFUND,
    then: Yup.number(),
    otherwise: Yup.number().max(totalAmount, `Refund amount should be less than the purchase amount E£ ${totalAmount}`),
  }),
});