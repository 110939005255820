import {alias, list, object, primitive, serializable} from "serializr";


class ArgsModel {
    @serializable(alias('start_date', primitive()))
    startDate?: string;

    @serializable(alias('end_date', primitive()))
    endDate?: string;
}

export class ReportModel {
    @serializable(alias('report_name', primitive()))
    reportName?: string;

    @serializable(alias('args', object(ArgsModel)))
    args?: ArgsModel;

    @serializable(alias('recipients', list(primitive())))
    recipients?: string[];
}
