import React, { FC, Fragment, useEffect, useState } from "react";
import PageView from "../../../../../shared/components/PageView";
import "./approveVendorsView.scss";
import faker from "faker";
import { Col, Row } from "antd";
import VendorDetailsCard from "../../VendorList/VendorListView/VendorDetails/VendorDetailsCard";
import VendorInfoCard from "../../VendorList/VendorListView/VendorDetails/VendorInfoCard";
import DocumentsCard from "../../../../../shared/components/DocumentsCard";
import VendorPersonalDetailsCard from "../../VendorList/VendorListView/VendorDetails/VendorPersonalDetailsCard";
import ApproveVendorAlert from "../ApproveVendorAlert";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import VendorService from "../../../../../services/VendorService/vendor.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContactPersonDetails, VendorModel } from "../../../../../models/Vendor/vendor.model";
import { convertJsonToFormData } from "../../../../../shared/utils/convertJsonToFormData";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import DeleteAlert from "../../../../../shared/components/DeleteAlert";
import {LocalStorage} from "../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../models/User/user.model";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";

interface ApproveVendorsViewProps {}
interface ApproveVendorViewControllersProps {
  successHandler: () => void;
  vendor: VendorModel | undefined;
}
const ApproveVendorViewControllers: FC<ApproveVendorViewControllersProps> = ({
  successHandler,
  vendor,
}) => {
  const vendorId=useParams()
  const [isApproveVendorAlertShown, setIsApproveVendorAlertShown] =
    useState(false);
  const [isRejectVendorAlertShown, setIsRejectVendorAlertShown] =
    useState(false);
  const { loading, updateVendor } = VendorService();
  const user = LocalStorage.getItem('USER') as UserModel;

  return (
    <div className="approve-vendors-view-controllers">
      <ApproveVendorAlert
        visible={isApproveVendorAlertShown}
        loading={loading}
        closeHandler={() => setIsApproveVendorAlertShown(false)}
        successHandler={() => {
          const formData = convertJsonToFormData({
            vendor: {
              vendor_details :{
                status: BadgeTypes.APPROVED 
              }},
          });
          if (vendor?.id||vendorId)
            updateVendor(vendorId?.vendorId??"", formData, () => successHandler());
        }}
      />
      <DeleteAlert
        visible={isRejectVendorAlertShown}
        successBtnText={"Reject"}
        isDeleting={loading}
        cancelHandler={() => {
          setIsRejectVendorAlertShown(false);
        }}
        sucessHandler={() => {
          const formData = convertJsonToFormData({
            vendor: {
              vendor_details: {
                status: BadgeTypes.REJECTED,
              }
            },
          });
          if (vendor?.id)
            updateVendor(vendor?.id, formData, () => successHandler());
        }}
        title={"Are you sure you want to reject the vendor ?"}
      />
      {user?.role !== BadgeTypes.VIEWER && (
          <Fragment>
            <ButtonComponent
                size="medium"
                additionalTypes="negative"
                onClick={() => setIsRejectVendorAlertShown(true)}
            >
                Reject
            </ButtonComponent>
            <ButtonComponent
              size="medium"
              additionalTypes="affirmative"
              onClick={() => setIsApproveVendorAlertShown(true)}
            >
                Approve
            </ButtonComponent>
          </Fragment>
      )}
    </div>
  );
};

const ApproveVendorsView: FC<ApproveVendorsViewProps> = (props) => {
  const { loading, fetchVendor, vendor } = VendorService();
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pageNumber = location.state as number;
  useEffect(() => {
    if (vendorId) fetchVendor(vendorId);
  }, [vendorId]);

  const handleRefresh = () => {
    if (vendorId) fetchVendor(vendorId);
  };
  return (
    <PageView type="back-only" title={{ name: vendor?.vendorDetails?.name ?? "" }} vendor={vendor?.vendorDetails as VendorModel} pageNumber={pageNumber} backRoute={`${AppRoutes.VENDORS}##approve-vendors`}>
      <div className="approve-vendors-view">
        <ApproveVendorViewControllers
          vendor={vendor?.vendorDetails}
          successHandler={() => {
            navigate(-1);
          }}
        />
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <VendorInfoCard
              refreshList={handleRefresh}
              vendorType="not-approved"
              loading={loading}
              vendor={vendor?.vendorDetails}
              contactPerson={vendor?.contactPersonDetails as ContactPersonDetails}
            />
          </Col>
          <Col span={6}>
            <DocumentsCard
              loading={loading}
              data={[
                {
                  label: "COMMERCIAL REGISTRATION",
                  document: [vendor?.supportDocuments?.commercialRegistration]
                },
                {
                  label: "TAX CARD",
                  document: [vendor?.supportDocuments?.taxCard]
                },
              ]}
              className="approve-vendors-view-documents"
            />
          </Col>
          <Col span={24}>
            <VendorPersonalDetailsCard loading={loading} vendor={vendor} />
          </Col>
        </Row>
      </div>
    </PageView>
  );
};

export default ApproveVendorsView;
