import React, { FC, ReactNode } from "react";
import CardSkeletonComponent from "../CardSkeletonComponent";
import "./cardComponent.scss";

interface CardComponentProps {
  title?: string;
  className?: string;
  loading?: boolean;
  icon?: ReactNode
}

const CardComponent: FC<CardComponentProps> = (props) => {
  const { title, className, children, loading, icon } = props;

  return loading ? (
    <CardSkeletonComponent />
  ) : (
    <div className={`card-component ${className}`}>
      {title && <p className="title">{title}{icon && <span className="card-icon">{icon}</span>}</p>}
      <div className="card-component-content">{children}</div>
    </div>
  );
};

export default CardComponent;
