import React, { FC, useRef } from "react";
import "./mapsAutocompleteComponent.scss";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { ErrorMessage } from "formik";
import Error from "../Error";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY ?? "");
Geocode.enableDebug();

interface MapsAutocompleteComponentProps {
  onSearch: (position: { lat: number; lng: number }) => void;
  address?: string;
  error?: string;
}

const MapsAutocompleteComponent: FC<MapsAutocompleteComponentProps> = (
  props
) => {
  const { onSearch, address, error } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={`maps-autocomplete-component ${error && "error"}`}>
      <Autocomplete
        defaultValue={address}
        ref={inputRef}
        onPlaceSelected={(data) => {
          if (
            data?.geometry?.location?.lat() &&
            data?.geometry?.location?.lng()
          ) {
            Geocode.fromLatLng(
              data?.geometry?.location?.lat()?.toString(),
              data?.geometry?.location?.lng()?.toString()
            ).then((response) => {
              onSearch({...response?.results?.[0]?.geometry?.location, address: inputRef?.current?.value });
            });
          }
        }}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        options={{
          types: [],
          componentRestrictions: { country: "eg" },
        }}
      />
      {error &&
      <ErrorMessage name={"address"}>
          {(message: string) => <Error message={error} />}
      </ErrorMessage>
      }
    </div>
  );
};

export default MapsAutocompleteComponent;
