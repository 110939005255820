import { Col, Dropdown, Row } from 'antd'
import React from 'react'
import "./settlementInfo.scss"
import helpImage from "./../../../../../assets/images/help-blue.png"

const SettlementInfo = () => {

    const data = ["Multi-select pending orders of same vendor from the table", "Click on the mark as settled button to provide the transaction date and transaction ID of the settlement", "Settlement will be completed and the entry can be found in past settlements"]

    const settlementInfoContent = (
        <div className="settlement-info-container">
            <h3>HOW TO MAKE A SETTLEMENT</h3>
            <div className="content">
                {
                    data?.map((info, index) => (
                        <Row>
                            <Col span={1}>
                                <p className='number'>{index + 1}</p>
                            </Col>
                            <Col offset={1} span={22}>
                                <p className='text'>{info}</p>
                            </Col>
                        </Row>
                    ))
                }
            </div>
        </div>
    )
    return (
        <Dropdown
            className="settlement-info"
            trigger={["click"]}
            overlay={settlementInfoContent}
        >
            <img src={helpImage}></img>
        </Dropdown>
    )
}

export default SettlementInfo