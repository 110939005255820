import React, { FC, Fragment, useEffect, useState } from 'react'
import { Spin } from 'antd';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

interface FileViewerProps {
  uri: string,
  fileType?: string
}

const FileViewer: FC<FileViewerProps> = (props) => {
  const { uri, fileType } = props
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [fileError, setFileError] = useState(false)

  const checkIfDocumentIsPasswordProtected = async (uri: string) => {
    try {
      const response = await fetch(uri);
      const data = await response.arrayBuffer();
      const text = new TextDecoder().decode(data);
      setIsPasswordProtected(text.includes('/Encrypt'));
    } catch (error) {
      console.error('Error checking if document is password protected:', error);
      setFileError(true)
    }
  };

  useEffect(() => {
    if (uri) {
      checkIfDocumentIsPasswordProtected(uri);
    }
  }, [uri]);
  return (
    <Fragment>
      {uri && !isPasswordProtected ? (
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          style={{
            minHeight: 500,
            minWidth: 350,
            marginLeft: -140
          }}
          config={{
            header: {
              disableFileName: true,
            },
          }}
          documents={[
            {
              uri,
              fileType,
            },
          ]}
        />
      ) : !uri ?
        <div className="spin-container">
          <Spin />
        </div> :
        (
          <div>{isPasswordProtected ? 'The document is password protected.' : fileError ? "Error loading the document" : 'No document to display.'}</div>
        )}
    </Fragment>
  )
}

export default FileViewer