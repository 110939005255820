import { BadgeTypes } from "../../enums/badgeTypes"
import { CustomerModel } from "../../models/Customer/customer.model"

export const checkDocApprovalStatus = (customer: CustomerModel) => {
    const isbankStatementPending = customer?.bankStatement?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const isvehicleCardPending = customer?.vehicleCard?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const issportsClubMembershipPending = customer?.sportsClubMembership?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const ispaySlipPending = customer?.paySlip?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const isCreditCardStatementPending = customer?.creditCardStatement?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const isPropertyContractPending = customer?.propertyContract?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const isEmploymentLetterPending = customer?.employmentLetter?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const isEmploymentLetterMedicalCard = customer?.employmentLetterMedicalCard?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const isPaySlipBankStatement = customer?.paySlipBankStatement?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const isMedicalCardPending = customer?.medicalCard?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION
    const isStudentCardPending = customer?.studentCard?.[0]?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION


    return isbankStatementPending || isvehicleCardPending || issportsClubMembershipPending || ispaySlipPending || isCreditCardStatementPending || isPropertyContractPending || isEmploymentLetterPending || isEmploymentLetterMedicalCard || isPaySlipBankStatement || isMedicalCardPending || isStudentCardPending
}