import { Col, Progress, Row } from 'antd'
import { Form, Formik } from 'formik'
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import { DeallPurchaseConfig } from '../../../../../models/DeallPurchaseConfig/deallPurchaseConfig.model'
import ConfigurationService from '../../../../../services/ConfigurationService/configuration.service'
import IconComponent from '../../../../../shared/components/IconComponent'
import InputField from '../../../../../shared/components/InputField'
import ModalComponent from '../../../../../shared/components/ModalComponent'
import ButtonComponent from '../../../../../shared/components/ButtonComponent'
import "./monthlyLoanLimitCard.scss"
import { monthlyLoanLimitSchema } from './monthlyLoanLimitSchema'
import { ref } from 'yup'

interface MonthlyLoanLimitCardProps {
    purchaseConfig: DeallPurchaseConfig,
    refreshHandler: Function
}

const MonthlyLoanLimitCard: FC<MonthlyLoanLimitCardProps> = ({purchaseConfig, refreshHandler}) => {

    const { updatePurchaseConfig } = ConfigurationService()
    const [loanLimitEditModalVisible, setLoanLimitEditModalVisible] = useState(false)
    const {monthlyLimit , totalUtilisedThisMonth} = purchaseConfig || {}
    const [totalUtilisedPercentThisMonth, setTotalUtilisedPercentThisMonth] = useState(0)

    const closeModal = () => setLoanLimitEditModalVisible(false)

    const buttonRef = useRef<HTMLButtonElement>(null)
    
    useEffect(() => {
        monthlyLimit && totalUtilisedThisMonth &&
        setTotalUtilisedPercentThisMonth((totalUtilisedThisMonth / monthlyLimit) * 100)
    }, [purchaseConfig])
    

    return (
        <Fragment>
            <Formik
                initialValues={{monthlyLoanLimit: monthlyLimit, totalUtilisedThisMonth}}
                enableReinitialize
                validationSchema={monthlyLoanLimitSchema}
                onSubmit={(values)=>{
                    updatePurchaseConfig({monthlyLimit: Number(values?.monthlyLoanLimit)}).then(()=>{
                        closeModal()
                        refreshHandler()
                    })
                }}
            >
                {({ handleSubmit, errors, values }) => (
                    <ModalComponent
                        visible={loanLimitEditModalVisible}
                        type="small"
                        title="Edit monthly loan limit"
                        successProps={{
                            title: "Update",
                            clickHandler: () => buttonRef.current?.click(),
                            loading: false
                        }}
                        cancelProps={{
                            title: "Discard",
                            clickHandler: closeModal,
                        }}
                        closeHandler={closeModal}
                    >
                        <Form>
                            <InputField
                                placeholder="Enter"
                                label="Monthly loan limit"
                                name="monthlyLoanLimit"
                            />
                            <ButtonComponent htmlType='submit' hidden buttonRef={buttonRef} />
                        </Form>
                    </ModalComponent>
                )}
            </Formik>
            <div className="monthly-loan-limit-card">
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <div className="monthly-loan-limit-value-card">
                            <h3 className="title">MONTHLY LOAN LIMIT</h3>
                            <div className="value">
                                <p>LE {monthlyLimit?.toLocaleString()} <IconComponent
                                    onClick={() => setLoanLimitEditModalVisible(true)}
                                    name="icon-edit"
                                /> </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className="utilised-monthly-loan-limit-card">
                            <h4 className="title">Loan amount utilised this month</h4>
                            <div className="value">
                                <p>LE {totalUtilisedThisMonth?.toLocaleString()} <span>{totalUtilisedPercentThisMonth?.toFixed(2)}%</span> </p>
                            </div>
                            <div className="progress">
                                <Progress strokeColor={totalUtilisedPercentThisMonth > 100 ? "#D21314" : "#2461D8" } trailColor="#DAE6FF"  percent={totalUtilisedPercentThisMonth} showInfo={false} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}

export default MonthlyLoanLimitCard