
import {serializable, alias, object, list, primitive} from 'serializr';

export class Answer { 

	@serializable(alias('original', primitive()))
	original?: string;

	@serializable(alias('en', primitive()))
	en?: string;

	@serializable(alias('ar', primitive()))
	ar?: string;

}