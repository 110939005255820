import { Form, Formik, FormikValues } from "formik";
import React, { FC, useState } from "react";
import UserService from "../../../../../services/AuthService/auth.service";
import InputField from "../../../InputField";
import ModalComponent from "../../../ModalComponent";
import { changePasswordFormValidation } from "./changePasswordFormValidation";
import "./passwordChangeForm.scss";

interface PasswordChangeFormProps {
  visible: boolean;
  closeHandler: () => void;
}

const PasswordChangeForm: FC<PasswordChangeFormProps> = (props) => {
  const { visible, closeHandler } = props;
  const { changePassword, loading, error } = UserService()
  const [initialValues, setInitialValues] = useState<any>({
    password: "",
    confirmPassword: ""
  });
  const onSubmit = (values: FormikValues) => {
    changePassword(values,()=>{
      closeHandler()
    })
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={changePasswordFormValidation}
    >
      {({handleSubmit}) => (
        <ModalComponent
          type="small"
          visible={visible}
          closeHandler={closeHandler}
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: closeHandler,
          }}
          title={"Change Password"}
        >
          <div className="profile-edit-form">
            <Form>
              <InputField
                name="password"
                label="New Password"
                placeholder="Enter"
                type="password"
              />
              <InputField
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Enter"
                type="password"
              />
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default PasswordChangeForm;
