import React, { FC, useState, useEffect } from "react";
import IconComponent from "../../../../../../shared/components/IconComponent";
import TableComponent from "../../../../../../shared/components/TableComponent";
import "./vendorPurchaseConfig.scss";
import { useParams } from "react-router-dom";
import faker from "faker";
import VendorPurchaseConfigForm from "./VendorPurchaseConfigForm";
import VendorService from "../../../../../../services/VendorService/vendor.service";
import { VendorPurchaseConfigModel } from "../../../../../../models/Vendor/PurchaseConfig/purchaseConfig.model";
interface VendorPurchaseConfigProps {}

const VendorPurchaseConfig: FC<VendorPurchaseConfigProps> = (props) => {
  const {} = props;
  const [isVendorPurchaseConfigFormShown, setIsVendorPurchaseConfigFormShown] =
    useState(false);
  const { loading, fetchVendorPurchaseConfigList, vendorPurchaseConfig } =
    VendorService();
  const [currentPurchaseConfig, setCurrentPurchaseConfig] =
    useState<VendorPurchaseConfigModel>();
  const { vendorId } = useParams();
  useEffect(() => {
    if (vendorId) fetchVendorPurchaseConfigList(vendorId);
  }, []);

  return (
    <div className="vendor-purchase-config">
      <VendorPurchaseConfigForm
        data={currentPurchaseConfig}
        visible={isVendorPurchaseConfigFormShown}
        successHandler={() => {
          if (vendorId) fetchVendorPurchaseConfigList(vendorId);
          setIsVendorPurchaseConfigFormShown(false);
        }}
        closeHandler={() => setIsVendorPurchaseConfigFormShown(false)}
      />
      <TableComponent
        data={vendorPurchaseConfig}
        loading={loading}
        columns={[
          {
            dataIndex: "deallScoreRange",
            key: "deallScoreRange",
            title: "Internal Score",
            render: (text) => `${text?.[0]} - ${text?.[text?.length - 1]}`,
          },
          {
            dataIndex: "purchasePower",
            key: "purchasePower",
            title: "Purchasing Power",
          },
          {
            dataIndex: "installments",
            key: "installments",
            title: "Installments",
          },
          {
            title: "",
            key: "action",
            render: (_, record) => (
              <div className="vendor-purchase-config-action">
                <IconComponent
                  name="icon-edit"
                  onClick={() => {
                    setCurrentPurchaseConfig(record);
                    setIsVendorPurchaseConfigFormShown(true);
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default VendorPurchaseConfig;
