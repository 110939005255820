/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Col, Divider, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import "./pastSettlementView.scss";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { SettlementService } from "../../../../services/SettlementService/settlement.service";
import PageView from "../../../../shared/components/PageView";
import CardSkeletonComponent from "../../../../shared/components/CardSkeletonComponent";
import { currencyString } from "../..";
import TableComponent from "../../../../shared/components/TableComponent";
import { SettlementCustomerPurchaseModel } from "../../../../models/Settlement/settlement.model";
import IconComponent from "../../../../shared/components/IconComponent";
import EditVendorSettlement from "./EditVendorSettlement";
import EditVendorSettlementDate from "./EditVendorSettlementDate";
import { formatDate, getDate } from "../../../../shared/utils/formatDate";
import { convertToTwoDecimals } from "../../../../shared/utils/convertToTwoDecimals";
import { getPastVendorDateRange } from "../../../../shared/utils/geDateRange";
import copyIcon from "./../../../../assets/images/copy-icon.png"
import cross from "./../../../../assets/images/cross.png"
import redirectIcon from "./../../../../assets/images/redirect.png"
import tick from "./../../../../assets/images/tick.png"
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import { StateProps } from "../../../../models/Meta/meta.model";

interface PastSettlementViewProps {}

const PastSettlementView: FC<PastSettlementViewProps> = (props) => {
  const { settlement, fetchSettlement, loading } = SettlementService();
  const { settlementId } = useParams();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = stateValue?.pageNumber as number;
  const filterOptions = stateValue?.filterOptions;
  const [settlementIdEditVisible, setSettlementIdEditVisible] = useState(false);
  const [settlementDateEditVisible, setSettlementDateEditVisible] =
    useState(false);
  const refundedTransactions = settlement?.customerPurchases?.reduce(
    (acc, curr) =>
      curr?.isAmountNegative ? acc + curr?.vendorSettlementAmount! : acc,
    0
  );

  const dateRange =
    settlement?.customerPurchases &&
    getDate(getPastVendorDateRange(settlement?.customerPurchases));

  useEffect(() => {
    if (settlementId) fetchSettlement(settlementId);
  }, [settlementId, settlementIdEditVisible, settlementDateEditVisible]);

  const handleRefresh = () => {
    if (settlementId) fetchSettlement(settlementId);
  };

  return (
    <PageView type="back-only" pageNumber={pageNumber} filterOptions={filterOptions} backRoute={`${AppRoutes.SETTLEMENTS_VENDOR}#past-settlements`}>
      <div className="past-settlement-view">
        {loading ? (
          <Row gutter={[20, 20]}>
            <Col span={10}>
              <CardSkeletonComponent
                className="vendor-details__container-skeleton"
                rows={4}
              />
            </Col>
            <Col span={14}>
              <CardSkeletonComponent
                className="vendor-details__container-skeleton"
                rows={4}
              />
            </Col>
          </Row>
        ) : (
          <Row className="vendor-details__container" gutter={[20, 20]}>
            <Col span={10}>
              <div className="vendor-details-card__container">
                <div className="vendor-name-display-picture">
                  <img src={settlement?.vendor?.logo?.s3Url} />
                  <div className="name">
                    <h4>{settlement?.vendor?.name}</h4>
                    <p>{settlement?.vendor?.id}</p>
                  </div>
                </div>
                <div className="item">
                  <div className="label">Vendor Settlement ID</div>
                  <div className="value">
                    {settlement?.transactionId}{" "}
                    <IconComponent
                      name="icon-edit"
                      onClick={() => setSettlementIdEditVisible(true)}
                    />{" "}
                  </div>
                </div>
                <div className="item">
                  <div className="label">Settled Date</div>
                  <div className="value">
                    {moment(
                      settlement?.settledDate ?? settlement?.createdAt
                    ).format("DD MMM YYYY")}{" "}
                    <IconComponent
                      name="icon-edit"
                      onClick={() => setSettlementDateEditVisible(true)}
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="label">Settlement Approved by</div>
                  <div className="value">
                    {settlement?.createdBy?.fullName ?? "-"}
                  </div>
                </div>
                <div className="item">
                  <div className="label">Settlement Approved on</div>
                  <div className="value">
                    {settlement?.createdAt && formatDate(settlement?.createdAt)}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <div className="vendor-details-fee__container">
                <div className="purchase-summary-sub-total__container">
                  <div className="label">
                    <h3>Total Purchase Amount</h3>
                  </div>
                  <div className="value">
                    <h3>{settlement?.totalAmount}</h3>
                  </div>
                </div>
                <div className="purchase-summary-sub-total__container">
                  <div className="label">
                    <h3>
                      Total Rise Vendor Fee{" "}
                      <span>({settlement?.vendor?.processingFee}% of purchase amount)</span>
                    </h3>
                  </div>
                  <div className="value">
                    <h3 className="payment-loss">
                      {"- "}
                      {settlement?.vendorProcessingFee
                        ? convertToTwoDecimals(settlement?.vendorProcessingFee)
                        : 0.0}
                    </h3>
                  </div>
                </div>
                <div className="purchase-summary-sub-total__container">
                  <div className="label">
                    <h3>
                      Total VAT on Rise Vendor Fee <span>(14%)</span>
                    </h3>
                  </div>
                  <div className="value">
                    <h3 className="payment-loss">
                      {"- "}
                      {settlement?.vat
                        ? convertToTwoDecimals(settlement.vat)
                        : 0.0}
                    </h3>
                  </div>
                </div>
                <div className="purchase-summary-sub-total__container">
                  <div className="label">
                    <h3>Refunded Transactions</h3>
                  </div>
                  <div className="value">
                    <h3 className="payment-loss">
                      {"- "}
                      {settlement?.refundedTransactions}
                    </h3>
                  </div>
                </div>
                <Divider dashed />
                <div className="purchase-summary-total__container">
                  <div className="label">Total vendor settlement amount</div>
                  <div className="value">
                    {currencyString}
                    {settlement?.vendorSettlementAmount
                      ? convertToTwoDecimals(settlement.vendorSettlementAmount)
                      : 0.0}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <div className="settlements-summary-list__container">
          <div className="title">Purchases Settled</div>
          <TableComponent
            loading={loading}
            columns={[
              {
                dataIndex: "externalPurchaseId",
                key: "externalPurchaseId",
                title: "External Purchase ID",
              },
              {
                dataIndex: "createdAt",
                key: "createdAt",
                title: "Purchase Date",
                render: (text) => moment(text)?.format("DD MMM YYYY"),
              },
              {
                title: "Purchase Amount",
                dataIndex: "amount",
              },
              {
                title: "Vendor Fee",
                dataIndex: "vendorProcessingFee",
                render: (text, reord) => (
                  <span>{text ? convertToTwoDecimals(text) : 0.0}</span>
                ),
              },
              {
                title: "VAT",
                dataIndex: "vat",
                render: (text, reord) => (
                  <span>{text ? convertToTwoDecimals(text) : 0.0}</span>
                ),
              },
              {
                title: "Settlement Amount",
                dataIndex: "vendorSettlementAmount",
                render: (text, record: SettlementCustomerPurchaseModel) => (
                  <span
                    className={`items-total ${
                      record?.isAmountNegative && "payment-loss"
                    }`}
                  >
                    {record?.isAmountNegative && "- "}
                    {text ? convertToTwoDecimals(text) : 0.0}
                  </span>
                ),
              },
              {
                title: "Invoice Issued",
                dataIndex: "invoiceIssued",
                render: (text) => (<img className="icon-style" src={text ? tick : cross}></img>)
              },
              {
                title: "Unique ID",
                dataIndex: "invoiceUuid",
                render: (text) => (<img className={`icon-style ${text ? '' : 'disabled-pointer'}`} src={copyIcon} onClick={() => {
                  if(text){
                    navigator.clipboard.writeText(text)
                    Notification({
                      message:"Unique ID Copied",
                      description: "",
                      type: NotificationTypes.INFO
                  })
                  }
                }}></img>)
              },
              {
                title: "Public Url",
                dataIndex: "invoicePublicUrl",
                render: (text) => (<img className={`icon-style ${text ? '' : 'disabled-pointer'}`} src={redirectIcon} onClick={() => {text && window.open(text, '_blank');}}></img>)
              },
            ]}
            data={settlement?.customerPurchases}
          />
        </div>

        <div className="settlements-summary-list__container">
          <div className="title">Returns Settled</div>
          <TableComponent
            loading={loading}
            columns={[
              {
                dataIndex: "externalPurchaseId",
                key: "externalPurchaseId",
                title: "External Purchase ID",
              },
              {
                dataIndex: "refundReceiptId",
                key: "refundReceiptId",
                title: "Refund Receipt ID",
              },
              {
                title: "Returned Date",
                dataIndex: "returnedDate",
                render: (text) => moment(text)?.format("DD MMM YYYY"),
              },
              {
                title: "Refund Amount on the Receipt",
                dataIndex: "refundAmount",
                render: (text, reord) => (
                  <span>{text ? convertToTwoDecimals(text) : 0.0}</span>
                ),
              },
              {
                title: "Vendor Settlement Amount",
                dataIndex: "vendorSettlementAmount",
                render: (text, record: SettlementCustomerPurchaseModel) => (
                  <span
                    className={`items-total ${
                      record?.isAmountNegative && "payment-loss"
                    }`}
                  >
                    {record?.isAmountNegative && "- "}
                    {text ? convertToTwoDecimals(text) : 0.0}
                  </span>
                ),
              },
            ]}
            data={settlement?.returns}
          />
        </div>
      </div>
      <EditVendorSettlement
        visible={settlementIdEditVisible}
        closeHandler={() => setSettlementIdEditVisible(false)}
        handleRefresh={handleRefresh}
        settlement={settlement}
        type="Vendor"
      />
      <EditVendorSettlementDate
        visible={settlementDateEditVisible}
        closeHandler={() => setSettlementDateEditVisible(false)}
        handleRefresh={handleRefresh}
        settlement={settlement}
        dateRange={dateRange}
        type="Vendor"
      />
    </PageView>
  );
};

export default PastSettlementView;
