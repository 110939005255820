import React from "react";
import { Menu } from "antd";
import "./navbar.scss";
import AppIcon from "../../../assets/images/rise-logo-blue.png";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import IconComponent from "../IconComponent";
import {LocalStorage} from "../../utils/localStorageHelpers";
import {UserModel} from "../../../models/User/user.model";
import {BadgeTypes} from "../../../enums/badgeTypes";
import { AuthContext } from "../../../context/AuthContext";
interface navbarItemsType {
    title: string;
    key: string;
    path: string;
    hash?: string;
    icon: JSX.Element;
    childrens?: {title: string, key: string;}[];
}

const Navbar = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const firstRoute = location?.pathname?.split("/")[1]
  const secondRoute = location?.pathname?.split("/")[2]
  const isSettlements = firstRoute === "settlements"
  const _user = LocalStorage.getItem('USER') as UserModel
  const { user } = AuthContext();
  const isTest = user?.test || LocalStorage.getItem("IS_TEST")
  const isManagerOrAdmin = ([BadgeTypes.ADMIN, BadgeTypes.MANAGER].some(role => _user?.role?.includes(role)));
  const navbarItems = [
    {
      title: "Dashboard",
      key: "dashboard",
      path: isManagerOrAdmin ? AppRoutes.DASHBOARD : '',
      icon: <IconComponent name="icon-dashboard" />,
    },
    {
      title: "Customers",
      key: "customers",
      path: AppRoutes.CUSTOMERS,
      hash: "list",
      icon: <IconComponent name="icon-customers" />,
    },
    {
      title: "Vendors",
      key: "vendors",
      path: AppRoutes.VENDORS,
      hash: "list",
      icon: <IconComponent name="icon-vendors" />,
    },
    {
      title: "Purchases",
      key: "purchases",
      path: AppRoutes.PURCHASES,
      icon: <IconComponent name="icon-purchases" />,
    },
    {
      title: "Settlements",
      key: "settlements",
      hash: "pending",
      path: isManagerOrAdmin ? AppRoutes.SETTLEMENTS : '',
      icon: <IconComponent name="icon-settlements" />,
      childrens: [
        {
          title: "Vendors",
          key: "vendor",
          hash: "pending",
          path: AppRoutes.SETTLEMENTS_VENDOR,
        },
        {
          title: "Partner Fees",
          key: "partner-fee",
          hash: "pending",
          path: AppRoutes.PARTNER_FEE_SETTLEMENTS,
        },
        {
          title: "Payment Collection",
          key: "payment-collection",
          hash: "pending",
          path: AppRoutes.SETTLEMENTS_PAYMENT_COLLECTION
        }
      ]
    },
    {
      title: "Payments",
      key: "payments",
      hash: "payment-logs",
      path: AppRoutes.PAYMENTS,
      icon: <IconComponent name="icon-payments" />,
    },
    {
      title: "Rise Users",
      key: "rise-users",
      path: user?.role === BadgeTypes.ADMIN ? AppRoutes.DEALL_USERS : '',
      icon: <IconComponent name="icon-user-config" />,
    },
    {
      title: "Configuration",
      key: "configuration",
      path: isManagerOrAdmin ? AppRoutes.CONFIGURATION : '',
      icon: <IconComponent name="icon-setting" />,
    },
    {
      title: "Monitoring",
      key: "monitoring",
      path: isManagerOrAdmin ? AppRoutes.MONITORING : '',
      icon: <IconComponent name="icon-dashboard" />,
    },
  ];

  const renderChildren = (item: navbarItemsType) => navigate({ pathname: item?.path, hash: item?.hash })
  const renderMenuItems = (menuData:navbarItemsType[]) => {
    return menuData.map((item) => {
      const disabled = !item.path ? 'disabled' : '';
      const onClick = !item.path ? {} : {onClick: () => renderChildren(item)};
      const onTitleClick = !item.path ? {} : {onTitleClick: () => renderChildren(item)};
      if(disabled) {
        return;
      }
      if (item?.childrens) {
        return (
          <Menu.SubMenu {...onTitleClick} className={"navbar-sub-item " + disabled} key={item.key} icon={item.icon} title={item.title}>
            {renderMenuItems(item.childrens as navbarItemsType[])}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item
          className={"navbar-item " + disabled}
          key={item?.key}
          {...onClick}
        >
          <div className="navbar-item__title">
            {item?.icon}
            {item?.title}
          </div>
        </Menu.Item>
      );
    });
  };


  return (
    <div className="navbar__container">
      <img src={AppIcon} className="navbar-app-logo__img" />
      <Menu
        className="navbar"
        mode="inline"
        openKeys={isSettlements?['settlements']:[]}
        selectedKeys={isSettlements ? [firstRoute, secondRoute] : [firstRoute]}
      >
        {renderMenuItems(navbarItems)}
      </Menu>
      {
        isTest &&
        <div className="navbar-footer">
          <p>You’re viewing test data</p>
        </div>
      }
    </div>
  );
};

export default Navbar;
