
import {Answer} from '../Answer/answer.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class Answers { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('answer', object(Answer)))
	answer?: Answer;

	@serializable(alias('answer_type', primitive()))
	answerType?: string;

}