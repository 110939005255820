import { Row, Col, Checkbox } from 'antd'
import { Formik, Form, FormikProps, FormikValues } from 'formik'
import React, { FC, useRef } from 'react'
import { PurchaseModel } from '../../../../../../models/Purchase/purchase.model'
import ModalComponent from '../../../../../../shared/components/ModalComponent'
import alertIcon from "./../../../../../../assets/images/alert-for-refund-creation.svg"
import Error from '../../../../../../shared/components/Error'
import "./addimeiconfimationmodal.scss"
import { currencyString } from '../../../..'
import IconComponent from '../../../../../../shared/components/IconComponent'
import PurchaseService from '../../../../../../services/PurchaseService/purchase.service'

interface AddIMEIConfimationModalProps {
    visible: boolean;
    closeHandler: () => void;
    imeiNumber: string;
    purchase?: PurchaseModel;
    refreshHandler?: Function
 }

const AddIMEIConfimationModal: FC<AddIMEIConfimationModalProps> = (props) => {

    const { visible, closeHandler, imeiNumber, purchase, refreshHandler} = props;
    const { addImeiNumber, loading } = PurchaseService();
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const resetFormAndClose = () => {
        formRef?.current?.resetForm({ values: {} });
        closeHandler()
    }


    const onSubmit = () => {
        addImeiNumber(purchase?.id?.toString()!, imeiNumber, ()=>{
            closeHandler()
            refreshHandler && refreshHandler()
        } )
    };

    return (
        <Formik
        initialValues={{ confirmAddIMEINumber: false }}
        enableReinitialize
        onSubmit={onSubmit}
        innerRef={formRef}
    >
        {({ values, setFieldValue, handleSubmit, errors }) => (
            <Form className="add-imei-form">
                <ModalComponent
                    visible={visible}
                    closeHandler={resetFormAndClose}
                    type="medium"
                    title=""
                    successProps={{
                        title: "Confirm",
                        clickHandler: handleSubmit,
                        successDisabled: !values.confirmAddIMEINumber,
                        loading,
                    }}
                    cancelProps={{
                        title: "Cancel",
                        clickHandler: resetFormAndClose,
                    }}
                    className="add-imei-confirmation-modal"
                >
                    <div className="alert-info">
                        <img src={alertIcon} alt="alert-refund" />
                        <h3>Are you sure to add IMEI number to the purchase?</h3>
                        <p>Upon confirming the purchase would be considered as mobile purchase with the mentioned IMEI number</p>
                    </div>
                    <Row className="mobile-purchase-data">
                        <Col span={12}>
                            <div className='header'>CUSTOMER</div>
                            <div className='customer-name'>{purchase?.customer?.fullName}</div>
                            <div className='mobile-no'> <IconComponent name="icon-phone-grey" /> <span className='number'>{purchase?.customer?.countryCode?.isdCode}{purchase?.customer?.mobileNumber}</span></div>
                        </Col>
                        <Col span={12}>
                            <div  className='header'>PURCHASE DETAILS</div>
                            <div className='detail'><span className='label'>Total</span> <span className='value'>{currencyString}{purchase?.totalAmount}</span></div>
                            <div className='detail'><span className='label'>IMEI</span> <span className='value'>{imeiNumber}</span></div>
                        </Col>
                    </Row>
                    <div className="checkbox-action">
                        <Checkbox name="confirmAddIMEINumber" checked={values?.confirmAddIMEINumber} onChange={(e)=>setFieldValue("confirmAddIMEINumber",e.target.checked)}>
                            <p className="checkbox-action-title">Check the box to confirm the action</p>
                        </Checkbox>
                        <Error message={errors?.confirmAddIMEINumber as string} name={"confirmAddIMEINumber"} />
                    </div>
                </ModalComponent>
            </Form>
        )}
    </Formik>
    )
}

export default AddIMEIConfimationModal