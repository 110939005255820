import { GraphColors } from "../../../enums/graphColors";

export const vendorTransactionsValueConfig = {
    responsive: true,
    aspectRatio: 3/2,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
        },
    },
    scales: {
        y: {
            title: {
                display: true,
                text: 'LE VALUE OF PURCHASES',
                color: GraphColors.YAXESCOLOR
            },
            grid: {
                color: GraphColors.PAYMENTGRIDCOLOR,
            },
            min: 0,
            ticks: {
                precision: 0
            }
        },
        x: {
            title: {
                display: true,
                text: 'VENDOR',
                color: GraphColors.YAXESCOLOR
            },
            grid: {
                color: GraphColors.PAYMENTGRIDCOLOR,
            },
        }
    },
    borderRadius: 10,
    barThickness: 20,
    colours: [{ fillColor: GraphColors.PRIMARYBLACK }]
}