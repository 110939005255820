import React, {FC, useEffect, useState} from 'react'
import "./customeradditionalinfo.scss"
import {useParams} from 'react-router-dom'
import TableComponent from "../../../../../../shared/components/TableComponent";
import CustomerContactsStatistics from "../CustomerContactsStatistics";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import {Button} from "antd";


const CustomerAdditionalInfo: FC = () => {
    interface CustomerSmsAnalysisRecord {
        id: number;
        parameter: string;
        answer: string;
        justification: string;
    }

    const {
        customerContactStat,
        loading,
        fetchCustomerContactsStatistics,
        customerSmsAnalysis,
        fetchCustomerSmsAnalysis
    } = CustomerService();
    const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});

    const {customerId} = useParams();

    useEffect(() => {
        if (customerId) {
            fetchCustomerContactsStatistics(customerId);
            fetchCustomerSmsAnalysis(customerId);
        }
    }, [])
    const toggleEllipsis = (record: CustomerSmsAnalysisRecord) => {
        setExpandedRows(prevState => ({
            ...prevState,
            [record.id]: !prevState[record.id]
        }));
    }

    const showCustomerStatics = !!customerContactStat?.Approved || !!customerContactStat?.['Related Contacts With Late Payment'] || !!customerContactStat?.['Related Contacts With Overdue']
    return (
        <div className="customer-additional-info">

           {showCustomerStatics && <CustomerContactsStatistics data={customerContactStat}/> }
            <TableComponent
                loading={false}
                rowClassName={() => "question-text"}
                columns={[
                    {
                        title: "Parameter",
                        dataIndex: "parameter",
                        key: "parameter"
                    },
                    {
                        title: "Answer",
                        dataIndex: "answer",
                        key: "answer",
                        ellipsis: true,
                    },
                    {
                        title: "Elaboration",
                        dataIndex: "justification",
                        key: "justification",
                        render: (text, record) => {
                            const isEllipsisActive = !expandedRows[record.id];

                            return (
                                <div>
                                    <span className={isEllipsisActive ? 'ellipsis' : ''}>{text}</span>
                                    {text.length > 20 && (
                                        <Button
                                            onClick={() => toggleEllipsis(record as CustomerSmsAnalysisRecord)}
                                            type="link"
                                            style={{padding: 0, color: 'blue'}}
                                        >
                                            {isEllipsisActive ? 'View More' : 'View Less'}
                                        </Button>
                                    )}
                                </div>
                            );
                        }
                    }
                ]}
                data={customerSmsAnalysis}
                empty={{
                    text: "No SMS analysis data to present",
                }}
            />
        </div>
    )
}

export default CustomerAdditionalInfo