import * as Yup from "yup"
import { REGEX } from "../../../../../../../enums/Regex";

export const vendorInstoreFormValidation = Yup.object().shape({
  contactFirstName: Yup.string()
    .matches(REGEX.NAME.match, REGEX.NAME.error)
    .required("First Name is required."),
  contactLastName: Yup.string()
    .matches(REGEX.NAME.match, REGEX.NAME.error)
    .required("Last Name is required."),
  email: Yup.string().email('Enter a valid email').required("Email is required."),
  mobileNumber: Yup.string()
    .required('Phone number is required.')
    .when("countryCode.isdCode", {
      is: "+20",
      then: Yup.string()
        .matches(REGEX.PHONE.match, REGEX.PHONE.error)
    })
    .when("countryCode.isdCode", {
      is: "+972",
      then: Yup.string()
        .matches(REGEX.PHONE_ISRAEL.match, REGEX.PHONE_ISRAEL.error)
    })
    .when("countryCode.isdCode", {
      is: "+91",
      then: Yup.string()
        .matches(REGEX.PHONE_INDIA.match, REGEX.PHONE_INDIA.error)
    })
    .when("countryCode.isdCode", {
      is: "+1",
      then: Yup.string()
        .matches(REGEX.PHONE_US.match, REGEX.PHONE_US.error)
    }),
  address: Yup.string().required("Address is required."),
  name: Yup.string().required("Name is required."),
  fawryTerminalIds: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().max(30, "Label should be of maximum 30 characters").required("Label is required").typeError("Label should be a string"),
        terminalId: Yup.string().required("Value is required").typeError("Value should be a string"),
      })
    )
});