import { Col, Divider, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import PaymentcollectionService from "../../../../../../services/PaymentcollectionService/paymentcollection.service";
import PageView from "../../../../../../shared/components/PageView";
import CardSkeletonComponent from "../../../../../../shared/components/CardSkeletonComponent";
import { currencyString } from "../../../..";
import TableComponent from "../../../../../../shared/components/TableComponent";
import { SortColumnTypes } from "../../../../../../shared/types/SortColumn.type";
import IconComponent from "../../../../../../shared/components/IconComponent";
import EditVendorSettlement from "../../../PastSettlementView/EditVendorSettlement";
import EditVendorSettlementDate from "../../../PastSettlementView/EditVendorSettlementDate";
import './pastCollectiontView.scss'
import { PaymentModeTypes } from "../../../../../../enums/paymentModeTypes";
import { PaymentModeTypesDefinition } from "../../../../../../definitions/paymentModeTypesDefinition";
import { PartnerTypes } from "../../../../../../enums/partnerTypes";
import { TransactionTypes } from "../../../../../../enums/transactionTypes";
import { TransactionTypesDefinition } from "../../../../../../definitions/transactionTypesDefinition";
import { getDate } from "../../../../../../shared/utils/formatDate";
import { getPastPaymentDateRange } from "../../../../../../shared/utils/geDateRange";
import { AppRoutes } from "../../../../../../routes/routeConstants/appRoutes";
import { StateProps } from "../../../../../../models/Meta/meta.model";

interface PastPaymentCollectionViewProps { }

const PastPaymentCollectionView: FC<PastPaymentCollectionViewProps> = (props) => {
  const { fetchPastCollection, paymentCollection, loading } = PaymentcollectionService()
  const { settlementId } = useParams();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = stateValue?.pageNumber;
  const filterOptions = stateValue?.filterOptions;
  const [settlementIdEditVisible, setSettlementIdEditVisible] = useState(false)
  const [settlementDateEditVisible, setSettlementDateEditVisible] = useState(false)

  const isPaybtabs = paymentCollection?.partnerName === PartnerTypes.PAYTABS
  const isPaymob = paymentCollection?.partnerName === PartnerTypes.PAYMOB
  const isFawry = paymentCollection?.partnerName === PartnerTypes.FAWRY

  const dateRange = paymentCollection?.customerPayments && getDate(getPastPaymentDateRange(paymentCollection?.customerPayments))

  useEffect(() => {
    handleRefresh()
  }, [settlementId]);

  const handleRefresh = () => {
    if (settlementId) fetchPastCollection(settlementId);
  };

  return (
    <PageView type="back-only" pageNumber={pageNumber} filterOptions={filterOptions} backRoute={`${AppRoutes.SETTLEMENTS_PAYMENT_COLLECTION}#past-settlements`}>
      <div className="past-collection-view">
        {loading ? (
          <Row gutter={[20, 20]}>
            <Col span={10}>
              <CardSkeletonComponent
                className="partner-details__container-skeleton"
                rows={4}
              />
            </Col>
            <Col span={14}>
              <CardSkeletonComponent
                className="partner-details__container-skeleton"
                rows={4}
              />
            </Col>
          </Row>
        ) : (
          <Row className="partner-details__container" gutter={[20, 20]}>
            <Col span={10}>
              <div className="partner-details-card__container">
                <div className="partner-name-display-picture">
                  <IconComponent name="icon-customers" />
                  <div className="name">
                    <h4>{paymentCollection?.partnerName}</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="label">Settled Date</div>
                  <div className="value">
                    {moment(paymentCollection?.settledDate).format("DD MMM YYYY")} <IconComponent name="icon-edit" onClick={()=>setSettlementDateEditVisible(true)}/>
                  </div>
                </div>
                <div className="item">
                  <div className="label">Collection Settlement ID</div>
                  <div className="value">{paymentCollection?.transactionId} <IconComponent name="icon-edit" onClick={()=>setSettlementIdEditVisible(true)}/> </div>
                </div>
                <div className="item">
                  <div className="label">Settlement Approved by</div>
                  <div className="value">
                    {paymentCollection?.admin?.fullName ?? "-"}
                  </div>
                </div>
                <div className="item">
                  <div className="label">Settlement Approved on</div>
                  <div className="value">
                    {moment(paymentCollection?.createdAt).format("DD MMM YYYY, HH:mm")}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <div className="partner-details-fee__container">
              <div className="purchase-summary-sub-total__container">
                  <div className="label">
                    <h3>
                      Total Payment Amount
                    </h3>
                  </div>
                  <div className="value">
                    <h3>
                      {paymentCollection?.totalAmount?.toFixed(2)}
                    </h3>
                  </div>
                </div>
                {(isPaybtabs || isPaymob) && <>
                  <div className="purchase-summary-sub-total__container">
                    <div className="label">
                      <h3>
                        Total Partner Fees <span>{`(1.5% of payment amount${isPaybtabs ? " + LE 1.5" : ""})`}</span>
                      </h3>
                    </div>
                    <div className="value">
                      <h3>
                        {paymentCollection?.totalPartnerFees?.toFixed(2) ?? "-"}
                      </h3>
                    </div>
                  </div>
                  <div className="purchase-summary-sub-total__container">
                    <div className="label">
                      <h3>
                        Total VAT on Partner Fees <span>(14%)</span>
                      </h3>
                    </div>
                    <div className="value">
                      <h3>
                        {paymentCollection?.totalVat?.toFixed(2) ?? "-"}
                      </h3>
                    </div>
                  </div>
                </>}
                <Divider dashed />
                <div className="purchase-summary-total__container">
                  <div className="label">Collection Amount</div>
                  <div className="value">
                    {currencyString}
                    {paymentCollection?.collectionAmount?.toFixed(2) ?? 0}
                  </div>
                </div>
                {isFawry && 
                  <div className="collection-info">
                      <IconComponent name="icon-info"/> <span className="text">Payment partner fees for "Fawry" are calculated in the partner fee section</span>
                  </div>
                }
              </div>
            </Col>
          </Row>
        )}
        <div className="settlements-summary-list__container">
          <div className="title">Payments Settled</div>
          <TableComponent
            loading={loading}
            columns={[
              {
                dataIndex: "transactionId",
                key: "transactionId",
                title: "Transaction ID",
              },
              {
                dataIndex: "createdAt",
                title: "Transaction Date",
                render: (text) => moment(text)?.format("DD MMM YYYY"),
              },
              {
                dataIndex: "transactionType",
                key: "transactionType",
                title: "Transaction Type",
                render: (text: TransactionTypes) => <span>{text === TransactionTypes.RETURN ? TransactionTypesDefinition[TransactionTypes.REFUND] : TransactionTypesDefinition[text]}</span>

              },
              {
                title: "Payment Mode",
                dataIndex: "paymentMode",
                render: (text: PaymentModeTypes) => PaymentModeTypesDefinition[text]
              },
              {
                title: "Transaction Amount",
                dataIndex: "amount",
                key: "amount",
              },
              {
                title: "Partner Fees",
                dataIndex: "partnerProcessingFee",
                key: "partnerProcessingFee",
                render: (text) => !isFawry ? text?.toFixed(2) : "-"
              },
              {
                title: "VAT",
                dataIndex: "partnerProcessingFeeVat",
                render: (text) => !isFawry ? text?.toFixed(2) : "-"
              },
              {
                title: "Total Fees",
                dataIndex: "collectionAmount",
                render: (text) => text?.toFixed(2) ?? "-",
              },
            ]}
            data={paymentCollection?.customerPayments}
          />
        </div>
      </div>
      <EditVendorSettlement visible={settlementIdEditVisible} closeHandler={()=>setSettlementIdEditVisible(false)} handleRefresh={handleRefresh} settlement={paymentCollection} type="Collection" />
      <EditVendorSettlementDate visible={settlementDateEditVisible} closeHandler={()=>setSettlementDateEditVisible(false)} handleRefresh={handleRefresh} settlement={paymentCollection} type="Collection" dateRange={dateRange} />
    </PageView>
  );
};

export default PastPaymentCollectionView;
