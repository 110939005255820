import {serializable, alias, object, list, primitive} from 'serializr';

class Purchase {
	@serializable(alias('key', primitive()))
	key?: string;
}

export class DashboardFilterModel { 

	@serializable(alias('date_filter_key', primitive()))
	dateFilterKey?: string;

	@serializable(alias('start_date', primitive()))
	startDate?: string;

	@serializable(alias('end_date', primitive()))
	endDate?: string;

	@serializable(alias("show_test", primitive()))
  	showTest?: boolean;

	@serializable(alias('vendor_id', primitive()))
	vendorId?: number;

	@serializable(alias('partner_id', primitive()))
	partnerId?: number;

	@serializable(alias('payment_id', primitive()))
	paymentId?: number;

}
export class Dashboard { 

	@serializable(alias('value_of_outstanding_transactions', primitive()))
	valueOfOutstandingTransactions?: string;

	@serializable(alias('number_of_absolute_transactions', primitive()))
	numberOfAbsoluteTransactions?: string;

	@serializable(alias('average_transaction_value', primitive()))
	averageTransactionValue?: string;

	@serializable(alias('number_of_locations', primitive()))
	numberOfLocations?: string;

	@serializable(alias('consumer_fee', primitive()))
	consumerFee?: string;

	@serializable(alias('vendor_fee', primitive()))
	vendorFee?: string;

	@serializable(alias('late_fee', primitive()))
	lateFee?: string;
	
	@serializable(alias('consumer_fee_outstanding', primitive()))
	consumerFeeOutstanding?: string;

	@serializable(alias('vendor_fee_outstanding', primitive()))
	vendorFeeOutstanding?: string;

	@serializable(alias('late_fee_outstanding', primitive()))
	lateFeeOutstanding?: string;

	@serializable(alias('total_fees', primitive()))
	totalFees?: string;
	
	@serializable(alias('total_fees_outstanding', primitive()))
	totalFeesOutstanding?: string;
	
	@serializable(alias('partner_fee', primitive()))
	partnerFee?: string;
	
	@serializable(alias('partner_fee_outstanding', primitive()))
	partnerFeeOutstanding?: string;

	@serializable(alias('percent_of_overdues', primitive()))
	percentOfOverdues?: string;

	@serializable(alias('value_of_overdues', primitive()))
	valueOfOverdues?: number;

	@serializable(alias('percent_of_non_payments', primitive()))
	percentOfNonPayments?: string;

	@serializable(alias('value_of_non_payments', primitive()))
	valueOfNonPayments?: number;

	@serializable(alias('no_of_active_instores', primitive()))
	numberOfActiveInstores?: number;

	@serializable(alias('no_of_refunds', primitive()))
	noOfRefunds?: number;

	@serializable(alias('total_installments_value', primitive()))
	totalInstallmentsValue?: string;
	
	@serializable(alias('total_installments_count', primitive()))
	totalInstallmentsCount?: string;
	
	@serializable(alias('total_installments_realized_value', primitive()))
	totalInstallmentsRealizedValue?: string;
	
	@serializable(alias('total_installments_realized_count', primitive()))
	totalInstallmentsRealizedCount?: string;
	
	@serializable(alias('average_late_installment_payment_day', primitive()))
	averageLateInstallmentPaymentDay?: string;
	
	@serializable(alias('number_of_overdues', primitive()))
	numberOfOverdues?: string;
	
	@serializable(alias('number_of_non_payments', primitive()))
	numberOfNonPayments?: string;

	@serializable(alias('no_of_customer_signups', primitive()))
	noOfCustomerSignups?: number;

	@serializable(alias('unused_availed_credit', primitive()))
	unusedAvailedCredit?: number;

	@serializable(alias('no_of_customer_declined', primitive()))
	noOfCustomerDeclined?: number;

	@serializable(alias('percent_of_approved_signups', primitive()))
	percentOfApprovedSignups?: number;
}

export class TransactionsPerCustomer {
	@serializable(alias('no_of_transaction', primitive()))
	noOfTransaction: number = 0;

	@serializable(alias('no_of_customers', primitive()))
	noOfCustomers: number = 0;

	@serializable(alias('percent_of_customer', primitive()))
	percentOfCustomer: number = 0;
}

export class VendorSettlements { 

	@serializable(alias('pending_settlement_amount', primitive()))
	pendingSettlementAmount?: number;

	@serializable(alias('pending_purchase_amount', primitive()))
	pendingPurchaseAmount?: number;

	@serializable(alias('pending_vendor_fee', primitive()))
	pendingVendorFee?: number;

	@serializable(alias('pending_processing_fee', primitive()))
	pendingProcessingFee?: number;

	@serializable(alias('pending_total_refund_amount', primitive()))
	pendingTotalRefundAmount?: number;

	@serializable(alias('pending_vat', primitive()))
	pendingVat?: number;

	@serializable(alias('past_settlement_amount', primitive()))
	pastSettlementAmount?: number;

	@serializable(alias('past_purchase_amount', primitive()))
	pastPurchaseAmount?: number;

	@serializable(alias('past_vendor_fee', primitive()))
	pastVendorFee?: number;

	@serializable(alias('past_processing_fee', primitive()))
	pastProcessingFee?: number;

	@serializable(alias('past_vat', primitive()))
	pastVat?: number;

	@serializable(alias('pending_payment_amount', primitive()))
	pendingPaymentAmount?: number;

	@serializable(alias('past_payment_amount', primitive()))
	pastPaymentAmount?: number;

}

