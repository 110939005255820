import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Col, Divider, Drawer, Row } from 'antd'
import { Form, Formik, FormikErrors, FormikProps, FormikValues } from 'formik'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { GenderTypesDefinition } from '../../../../../definitions/genderTypesDefinition'
import { MaritalStatusTypesDefinition } from '../../../../../definitions/maritalStatusTypesDefinition'
import { DocumentTypes } from '../../../../../enums/documentTypes'
import { GenderTypes } from '../../../../../enums/genderTypes'
import { MaritalStatusTypes } from '../../../../../enums/maritalStatusTypes'
import { CustomerEditModel, CustomerModel } from '../../../../../models/Customer/customer.model'
import CustomerService from '../../../../../services/CustomerService/customer.service'
import S3AttachmentService from '../../../../../services/S3UploadService/s3upload.service'
import ButtonComponent from '../../../../../shared/components/ButtonComponent'
import DatePickerField from '../../../../../shared/components/DatePickerField'
import DropdownField from '../../../../../shared/components/DropdownField'
import InputField from '../../../../../shared/components/InputField'
import ModalComponent from '../../../../../shared/components/ModalComponent'
import PageView from '../../../../../shared/components/PageView'
import UploadComponent from '../../../../../shared/components/UploadComponent'
import { approvalFormValidationSchema, customerDetailsFormValidation } from '../../CustomerDetailsForm/customerDetailsFormValidation'
import "./customerEditView.scss"
import { hideBrowserSuggestions } from '../../../../../shared/utils/hideBrowserSuggestions'
import CheckboxComponent from '../../../../../shared/components/CheckboxComponent'
import MetaService from '../../../../../services/MetaService/meta.service'
import { AcquisitionTypes } from '../../../../../enums/acquisitionTypes'
import { AcquisitionDefinitionTypes } from '../../../../../definitions/acquisitionTypes'
import { BadgeTypes } from '../../../../../enums/badgeTypes'
import { AppRoutes } from '../../../../../routes/routeConstants/appRoutes'
import Approvaldrawer from '../../../../../shared/components/ApprovalDrawer'
import { NeighbourhoodModel, RegionsModel } from '../../../../../models/Meta/meta.model'
import crossIcon from "../../../../../assets/images/cross-icon.svg"
import MenuComponent from '../../../../../shared/components/MenuComponent'
import addIcon from "../../../../../assets/images/add.svg"
import { zoneRankOptions } from '../../../../../enums/zoneScoreOptions'
import Notification from '../../../../../shared/components/Notification'
import { NotificationTypes } from '../../../../../enums/notificationTypes'


interface EditCustomerStateType {
    customer: CustomerModel,
    isApprove: boolean,
    isSave: boolean,
    redirection?: string
}

const CustomerEditView = () => {

    const location = useLocation();
    const state: EditCustomerStateType = location?.state as EditCustomerStateType;
    const navigate = useNavigate()
    const { loading, manualApproveCustomer, updatePendingCustomerDetails, fetchNeighbourhoodList, neighbourhood, fetchRegions, regions, fetchCities, cities, createNeighbourhood, fetchProfessionalGroupList, professionalGroup } = CustomerService();
    const { fetchSuspicionReasonList, suspicionReasonList } = MetaService()
    const { uploadAttachment, loading: attachmentLoading } = S3AttachmentService()
    const [initialValues, setInitialValues] = useState<CustomerEditModel>({});
    const [changedValues, setChangedValues] = useState<CustomerEditModel>({});
    const [isMobile, setIsMobile] = useState(false);
    const [dataIncomplete, setDataIncomplete] = useState(false);
    const [approveDrawerVisible, setApproveDrawerVisible] = useState(false);
    const [city, setCity] = useState<number>();
    const [region, setRegion] = useState<number>();
    const [regionsList, setRegionsList] = useState<RegionsModel[]>([]);
    const [neighbourhoodList, setneighbourhoodList] = useState<NeighbourhoodModel[]>([]);
    const [showNationalId, setShowNationalId] = useState(false);
    const [showAddNewNeighbourhood, setShowAddNewNeighbourhood] = useState(false)
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const cityId = state?.customer?.city?.value
    const regionId = state?.customer?.region?.value
    const regionsForCityId = cities?.find(city => city.value === cityId)?.regions as RegionsModel[]
    const neighbourhoodsForRegion = regionsForCityId?.find(region => region.value === regionId)?.neighbourhoods

    const openDrawer = () => setApproveDrawerVisible(true);
    const closeDrawer = () => setApproveDrawerVisible(false);

    const handleManualApproval = (values: FormikValues) => {
        manualApproveCustomer({ ...values, id: (state as EditCustomerStateType)?.customer?.id }, values?.id?.toString() ?? "", () => {
            navigate(-1)
        })
    }

    const saveCustomerDetails = (values: FormikValues) => {
        updatePendingCustomerDetails({ ...values, id: (state as EditCustomerStateType)?.customer?.id }, () => {
            navigate(-1)})
    }
    
    const handleSaveAndApprove = (values: FormikValues) => {
    updatePendingCustomerDetails({ ...values, id: state?.customer?.id });
    setApproveDrawerVisible(true);
    }

    const verifyCustomerDetails = (values: FormikValues) => {
        setChangedValues(values)
        const detailNotFilled = !values?.jobPlace || !values?.jobTitle || !values?.gender || !values?.maritalStatus || !values?.religion
        detailNotFilled ? setDataIncomplete(true) : state.redirection === 'approval' ? handleSaveAndApprove(values) : handleManualApproval(values)
    }

    useEffect(() => {
        state?.customer && setInitialValues({
            ...state?.customer,
            profilePic: state?.customer?.profilePic?.[0],
            nationalIdFront: state?.customer?.nationalIdFront?.[0],
            profilePicId: state?.customer?.profilePic?.[0]?.id,
            nationalIdBackId: state?.customer?.nationalIdBack?.[0]?.id,
            nationalIdFrontId: state?.customer?.nationalIdFront?.[0]?.id,
            nationalIdBack: state?.customer?.nationalIdBack?.[0],
            selfiePic: state?.customer?.selfiePic?.[0],
            selfiePicId: state?.customer?.selfiePic?.[0]?.id,
            neighbourhoodId: state?.customer?.neighbourhoodId,
            cityId: state?.customer?.city?.value,
            regionId: state?.customer?.region?.value,
            professionalGroupId: state?.customer.professionalGroup?.value
                ? Number(state?.customer.professionalGroup.value)
                : undefined,
            outOfScope: state?.customer?.outOfScope
        })
    }, [state?.customer])

    useEffect(() => {
        fetchSuspicionReasonList();
        fetchProfessionalGroupList();
        fetchCities();
        setIsMobile(window.innerWidth <= 420)
    }, [])

    useEffect(() => {
        const getRegions = async () => {
          if (city) {
            const fetchedRegions: RegionsModel[] = await fetchRegions(city.toString());
            setRegionsList(fetchedRegions);
          }
        };
      
        getRegions();
    }, [city]);
      
    useEffect(() => {
    if (region) {
        fetchNeighbourhoodList(region.toString()).then(fetchedNeighbourhoods => {
        setneighbourhoodList(fetchedNeighbourhoods);
        });
    }
    }, [region]);

    useEffect(() => {
    regionsForCityId && setRegionsList(regionsForCityId)
    neighbourhoodsForRegion && setneighbourhoodList(neighbourhoodsForRegion)
    }, [regionsForCityId, neighbourhoodsForRegion])

    return (
        <>
        <div className='quick-show-docs'>
            <img className='image-style' src={state?.customer?.nationalIdFront?.[0]?.s3Url} alt="" onClick={() => setShowNationalId(true)} />
        </div>
        {showNationalId && (
            <div className='overlay'>
                <div className='preview-container'>
                <img className='image-style' src={state?.customer?.nationalIdFront?.[0]?.s3Url} alt="" />
                <img className='image-style' src={state?.customer?.nationalIdBack?.[0]?.s3Url} alt="" />
                
                <div className='close-icon' onClick={() => setShowNationalId(false)}>
                    <img src={crossIcon} alt="Close" />
                </div>
                </div>
            </div>
            )}
        <PageView
            type="back-only"
            title={{
                name: "Customer Details"
            }}
        >
            <div className="customer-edit-view">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={() => { }}
                    innerRef={formRef}
                    validationSchema={customerDetailsFormValidation(showAddNewNeighbourhood)}
                    validateOnBlur={true}
                >
                    {({ values, setFieldValue, handleSubmit, touched, errors, dirty, validateForm }) => {
                        return (
                            <Form onScroll={hideBrowserSuggestions}>
                                <div className="customer-details-form">
                                    <div className="customer-form-details__container">
                                        <Row gutter={16}>
                                            <Col className="filter-field" span={24}>
                                                <div className="profile-pic-holder">
                                                    <UploadComponent
                                                        fileId={values?.profilePicId ?? values?.profilePic?.id}
                                                        fileType={values?.profilePic?.fileType}
                                                        fileName={values?.profilePic?.fileName}
                                                        label={values?.profilePic?.fileName}
                                                        accept={"image/png, image/jpg, image/jpeg"}
                                                        fileUrl={values?.profilePic?.s3Url}
                                                        type="logo-preview"
                                                        title="Profile Picture"
                                                        onUpload={async (file, fileUrl) => {
                                                            setFieldValue("profilePic.s3Url", fileUrl);
                                                            const _profile = await uploadAttachment(file);
                                                            setFieldValue("profilePicId", _profile);
                                                        }}
                                                        onRemove={() => {
                                                            setFieldValue("profilePic.s3Url", undefined);
                                                            setFieldValue("profilePic.logoId", undefined);
                                                        }}
                                                        error={errors?.profilePicId}
                                                    />
                                                </div>

                                            </Col>
                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                <InputField placeholder='Enter' label='Email' name='email' />
                                            </Col>
                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                <InputField placeholder='Enter' label='National ID' name='nationalIdNumber' required />
                                            </Col>
                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                <InputField placeholder='Enter' label='First Name (customer provided name)' name='firstName' required />
                                            </Col>
                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                <InputField placeholder='Enter' label='Last Name (customer provided name)' name='lastName' required />
                                            </Col>
                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                <InputField placeholder='Enter' label='Name (Captured from ID)' name='nameFromId' required />
                                            </Col>
                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                <DatePickerField
                                                    format="DD MMM YYYY"
                                                    value={values?.dateOfBirth}
                                                    required
                                                    onChange={(date, dateString) => setFieldValue("dateOfBirth", dateString)}
                                                    label="Date of Birth"
                                                    name="dateOfBirth"
                                                    placeholder="Select"
                                                />
                                            </Col>
                                            <Col className="gender" xs={24} sm={24} lg={12}>
                                                <DropdownField
                                                    label="Sex"
                                                    required
                                                    placeholder="Select"
                                                    name="gender"
                                                    value={values?.gender}
                                                    options={[
                                                        {
                                                            value: GenderTypes.MALE,
                                                            label: GenderTypesDefinition[GenderTypes.MALE],
                                                        },
                                                        {
                                                            value: GenderTypes.FEMALE,
                                                            label: GenderTypesDefinition[GenderTypes.FEMALE],
                                                        },
                                                    ]}
                                                    onChange={(value) => setFieldValue("gender", value)}
                                                />
                                            </Col>
                                            <Col className="marital-status" xs={24} sm={24} lg={12}>
                                                <DropdownField
                                                    label="Marital Status"
                                                    placeholder="Select"
                                                    name="maritalStatus"
                                                    required
                                                    value={values?.maritalStatus}
                                                    options={[
                                                        {
                                                            value: MaritalStatusTypes.SINGLE,
                                                            label: MaritalStatusTypesDefinition[MaritalStatusTypes.SINGLE],
                                                        },
                                                        {
                                                            value: MaritalStatusTypes.MARRIED,
                                                            label: MaritalStatusTypesDefinition[MaritalStatusTypes.MARRIED],
                                                        },
                                                        {
                                                            value: MaritalStatusTypes.WIDOWED,
                                                            label: MaritalStatusTypesDefinition[MaritalStatusTypes.WIDOWED],
                                                        },
                                                        {
                                                            value: MaritalStatusTypes.DIVORCED,
                                                            label: MaritalStatusTypesDefinition[MaritalStatusTypes.DIVORCED],
                                                        },
                                                    ]}
                                                    onChange={(value) => setFieldValue("maritalStatus", value)}
                                                />
                                            </Col>
                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                <InputField placeholder='Enter' label='Religion' name='religion' required />
                                            </Col>
                                            <Divider />
                                            <Col span={24} className="section-header">Professional Details</Col>
                                            <Col className="filter-field" xs={24} sm={24} lg={12}>
                                                <InputField placeholder='Enter' required label='Job Title' name='jobTitle' />
                                            </Col>
                                            <Col xs={24} sm={24} lg={12}>
                                                <DropdownField
                                                    label="Professional Group"
                                                    placeholder="Select"
                                                    name="professionalGroupId"
                                                    required
                                                    value={values?.professionalGroupId}
                                                    options={professionalGroup ?? []}
                                                    onChange={(value) => {
                                                        setFieldValue("professionalGroupId", value)
                                                    }}
                                                />
                                                </Col>
                                            <Divider />
                                            <Col span={24}  className="section-header">Address Details</Col>
                                            <Col className="filter-field" span={24}>
                                                <InputField textArea={true} placeholder='Enter' required label='Address (captured from ID)' name='address' />
                                            </Col>
                                            {!values?.outOfScope && <>
                                            <Col xs={24} sm={24} lg={12}>
                                                <DropdownField
                                                    label="City (captured from ID)"
                                                    placeholder="Select"
                                                    name="cityId"
                                                    required
                                                    value={values?.cityId}
                                                    options={cities ?? []}
                                                    onChange={(value) => {
                                                    setFieldValue("cityId", value);
                                                    setCity(value);
                                                    setFieldValue("regionId",undefined);
                                                    setFieldValue("neighbourhoodId",undefined);
                                                    setFieldValue("neighbourhood",undefined);
                                                    }}
                                                />
                                                </Col>
                                                <Col xs={24} sm={24} lg={12}>
                                                <DropdownField
                                                    label="Region (captured from ID)"
                                                    placeholder="Select"
                                                    required
                                                    name="regionId"
                                                    disabled={!values?.cityId}
                                                    value={values?.regionId}
                                                    options={regionsList ?? []}
                                                    onChange={(value) => {
                                                    setFieldValue("regionId", value);
                                                    setRegion(value);
                                                    setFieldValue("neighbourhoodId",undefined);
                                                    setFieldValue("neighbourhood",undefined);
                                                    }}
                                                />
                                            </Col>
                                            {!showAddNewNeighbourhood &&
                                            <Col xs={20} sm={20} lg={12}>
                                                <DropdownField
                                                required
                                                label="Neighbourhood (captured from ID)"
                                                placeholder="Select"
                                                name="neighbourhoodId"
                                                value={values?.neighbourhoodId}
                                                options={neighbourhoodList}
                                                onChange={(value) => {
                                                    setFieldValue("neighbourhoodId", value);
                                                    setFieldValue("neighbourhood", value);
                                                }}
                                                />
                                            </Col>}
                                            {showAddNewNeighbourhood &&
                                                <Col className="filter-field" xs={20} sm={20} lg={12}>
                                                    <InputField required placeholder='Enter neighbourhood' label='Neighbourhood' name='zoneKeyword' />
                                                </Col>
                                            }
                                            <Col xs={4} sm={4} lg={2}>  
                                                <MenuComponent
                                                    dropDownPlacement="bottomCenter"
                                                    dropdownClassName="add-neighbourhood-menu"
                                                    menu={[
                                                    {
                                                        title: showAddNewNeighbourhood ? 'Select neighbourhood' : `Add New Neighbourhood`,
                                                        clickHandler: () => showAddNewNeighbourhood ? setShowAddNewNeighbourhood(false) : !showAddNewNeighbourhood ? setShowAddNewNeighbourhood(true) : "" ,
                                                        icon: showAddNewNeighbourhood ? <></> : <img className="refund-icon" src={addIcon} />,
                                                    }
                                                    ]}
                                                />
                                            </Col>
                                            {showAddNewNeighbourhood &&
                                            <Col className="neighbourhoodScore" xs={24} sm={24} lg={12}>
                                            <DropdownField
                                                label="Neighbourhood Score"
                                                placeholder="Select"
                                                name="zoneScore"
                                                required
                                                value={values?.zoneScore}
                                                options={zoneRankOptions}
                                                onChange={(value) => setFieldValue("zoneScore", value)}
                                            />
                                            </Col>}</>}
                                            <Col span={24}>
                                                <CheckboxComponent title='Others (Out of scope)' value={values?.outOfScope} onChecked={(value) => {
                                                    setFieldValue("outOfScope", value?.target?.checked)
                                                    setFieldValue('cityId', undefined)
                                                    setFieldValue('regionId', undefined)
                                                    setFieldValue("neighbourhoodId", undefined);
                                                    setFieldValue("neighbourhood", undefined);
                                                }} />
                                            </Col>  
                                            <Divider />
                                            <Col span={24} className="section-header">Other Details</Col>
                                            <Col lg={18} xs={24} sm={24}>
                                                <DropdownField
                                                    label="Acquisition channel"
                                                    placeholder="Select"
                                                    name="acquisitionChannel"
                                                    defaultValue="Organic"
                                                    value={values?.acquisitionChannel}
                                                    options={[
                                                        {
                                                            value: AcquisitionTypes.ORGANIC,
                                                            label: AcquisitionDefinitionTypes[AcquisitionTypes.ORGANIC],
                                                        },
                                                        {
                                                            value: AcquisitionTypes.RISE_REFERRAL_PROGRAM,
                                                            label: AcquisitionDefinitionTypes[AcquisitionTypes.RISE_REFERRAL_PROGRAM],
                                                        },
                                                        {
                                                            value: AcquisitionTypes.USHER,
                                                            label: AcquisitionDefinitionTypes[AcquisitionTypes.USHER],
                                                        },
                                                        {
                                                            value: AcquisitionTypes.AMBASSADOR,
                                                            label: AcquisitionDefinitionTypes[AcquisitionTypes.AMBASSADOR],
                                                        },
                                                        {
                                                            value: AcquisitionTypes.CORPORATE_DEAL,
                                                            label: AcquisitionDefinitionTypes[AcquisitionTypes.CORPORATE_DEAL],
                                                        },
                                                    ]}
                                                    onChange={(value) => setFieldValue("acquisitionChannel", value)}
                                                />
                                            </Col>
                                            <Divider />
                                            <Col span={24} className="section-header">Customer Documents</Col>
                                            <Col lg={18} xs={24} sm={24}>
                                                <UploadComponent
                                                    isUploading={false}
                                                    canRotate={true}
                                                    fileId={values?.nationalIdFrontId ?? values?.nationalIdFront?.id}
                                                    fileType={values?.nationalIdFront?.fileType}
                                                    fileName={values?.nationalIdFront?.filename}
                                                    accept={"image/png, image/jpg, image/jpeg"}
                                                    fileUrl={values?.nationalIdFront?.s3Url}
                                                    onUpload={async (file, fileUrl) => {
                                                        setFieldValue("nationalIdFront.s3Url", fileUrl);
                                                        setFieldValue("nationalIdFront.filename", file?.name);
                                                        const _commercial = await uploadAttachment(file, DocumentTypes.NATIONAL_ID_FRONT);
                                                        setFieldValue("nationalIdFrontId", _commercial);
                                                    }}
                                                    title="Upload Document"
                                                    type="document-preview"
                                                    label="National ID - Front"
                                                    error={errors?.nationalIdFrontId}
                                                />
                                            </Col>
                                            <Col lg={18} xs={24} sm={24}>
                                                <UploadComponent
                                                    isUploading={false}
                                                    canRotate={true}
                                                    fileId={values?.nationalIdBackId ?? values?.nationalIdBack?.id}
                                                    fileType={values?.nationalIdBack?.fileType}
                                                    fileName={values?.nationalIdBack?.filename}
                                                    accept={"image/png, image/jpg, image/jpeg"}
                                                    fileUrl={values?.nationalIdBack?.s3Url}
                                                    onUpload={async (file, fileUrl) => {
                                                        setFieldValue("nationalIdBack.s3Url", fileUrl);
                                                        setFieldValue("nationalIdBack.filename", file?.name);
                                                        const _commercial = await uploadAttachment(file, DocumentTypes.NATIONAL_ID_FRONT);
                                                        setFieldValue("nationalIdBackId", _commercial);
                                                    }}
                                                    title="Upload Document"
                                                    type="document-preview"
                                                    label="National ID - Back"
                                                    error={errors?.nationalIdBackId}
                                                />
                                            </Col>
                                            <Col lg={18} xs={24} sm={24}>
                                                <UploadComponent
                                                    isUploading={false}
                                                    canRotate={true}
                                                    canReupload={false}
                                                    fileId={values?.selfiePicId ?? values?.selfiePic?.id}
                                                    fileType={values?.selfiePic?.fileType}
                                                    fileName={values?.selfiePic?.filename}
                                                    accept={"image/png, image/jpg, image/jpeg"}
                                                    fileUrl={values?.selfiePic?.s3Url}
                                                    onUpload={async (file, fileUrl) => {
                                                        setFieldValue("selfiePic.s3Url", fileUrl);
                                                        setFieldValue("selfiePic.filename", file?.name);
                                                        const _commercial = await uploadAttachment(file, DocumentTypes.NATIONAL_ID_FRONT);
                                                        setFieldValue("selfiePicId", _commercial);
                                                    }}
                                                    title="Upload Document"
                                                    type="document-preview"
                                                    label="Selfie Pic"
                                                    error={errors?.selfiePicId}
                                                />
                                            </Col>
                                            {
                                                state?.isApprove &&
                                                <Fragment>
                                                    <h3 className='customer-documents-header'>Approve customer manually</h3>
                                                    <Col span={6} />
                                                    <Col className="filter-field" xs={24} sm={24} lg={13}>
                                                        <InputField textArea type="text" name="adminComments" label="Comment" placeholder="Enter" />
                                                    </Col>
                                                    <Divider />
                                                    <Col className="filter-field" xs={24} sm={24} lg={13}>
                                                        <CheckboxComponent value={values?.riskyCustomer} title="Risky customer" label="Check this box to mark the customer as risky" onChecked={(value) => setFieldValue("riskyCustomer", value?.target?.checked)} />
                                                    </Col>
                                                    {values?.riskyCustomer &&
                                                        <Col className="filter-field" xs={24} sm={24} lg={13}>
                                                            <DropdownField
                                                                mode="multiple"
                                                                maxTagCount="responsive"
                                                                optionFilterProp="label"
                                                                allowClear
                                                                label="Reason for suspicion"
                                                                placeholder="Select"
                                                                name="suspicionReasonIds"
                                                                value={values?.suspicionReasonIds}
                                                                options={suspicionReasonList}
                                                                onChange={(value) => {
                                                                    setFieldValue("suspicionReasonIds", value);
                                                                }}
                                                            />
                                                        </Col>
                                                    }
                                                </Fragment>
                                            }

                                            <div className="approve-customers-control">
                                                <ButtonComponent
                                                    additionalTypes="secondary"
                                                    size="medium"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Discard
                                                </ButtonComponent>
                                                <ButtonComponent
                                                    size="medium"
                                                    type="primary"
                                                    htmlType="submit"
                                                    loading={loading || attachmentLoading}
                                                    onClick={() => {
                                                        validateForm(values).then((errors) => {
                                                            if (Object.entries(errors).length) {
                                                                handleSubmit()
                                                            } else {
                                                                const updatedValues = {
                                                                    ...values,
                                                                    acquisitionChannel: values?.acquisitionChannel ?? "organic",
                                                                    neighbourhood: {},
                                                                    neighbourhoodId: showAddNewNeighbourhood ? null : values?.outOfScope ? null : values?.neighbourhoodId,

                                                                };
                                                                const neighbourhoodData = {
                                                                    zoneScore: values?.zoneScore,
                                                                    label: values?.zoneKeyword,
                                                                    regionId: values?.regionId
                                                                  }
                                                                  if(showAddNewNeighbourhood){
                                                                    if(values.zoneKeyword && values.zoneScore != undefined)  {
                                                                        createNeighbourhood(neighbourhoodData).then((response) => {
                                                                            const newNeighbourhoodId = response?.id;
                                                                            const updatedWithNeighbourhood = {
                                                                              ...updatedValues,
                                                                              neighbourhoodId: newNeighbourhoodId
                                                                            };
                                                                            state.isSave ? saveCustomerDetails(updatedWithNeighbourhood) : verifyCustomerDetails(updatedWithNeighbourhood);
                                                                            setShowAddNewNeighbourhood(false);
                                                                            fetchCities();
                                                                        });
                                                                    }
                                                                    else {
                                                                        Notification({
                                                                            message: "Please fill Address details",
                                                                            description: "",
                                                                            type: NotificationTypes.ERROR,
                                                                        });
                                                                    }
                                                                  } else{
                                                                    if((!values?.outOfScope && values?.neighbourhoodId) || (values?.outOfScope)){
                                                                        state.isSave ? saveCustomerDetails(updatedValues) : verifyCustomerDetails(updatedValues);
                                                                    } else{
                                                                        Notification({
                                                                            message: "Please fill Neighbourhood details",
                                                                            description: "",
                                                                            type: NotificationTypes.ERROR,
                                                                        });
                                                                    }                                                                  }
                                                                // TODO needed for reference
                                                                // (isApprove ? verifyCustomerDetails(values, dirty, handleSubmit, errors) : onSubmit(values, errors, handleSubmit))
                                                            }
                                                        })
                                                    }}
                                                >
                                                    {state?.isSave ? "Save" : "Approve"}
                                                </ButtonComponent>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <ModalComponent
                type={isMobile ? 'large' : "alert-large"}
                title="Some of the customer details are empty. Are you sure you want to continue?"
                visible={dataIncomplete}
                closeHandler={() => setDataIncomplete(false)}
                successProps={{
                    title: "Approve",
                    loading: loading,
                    clickHandler: () => {
                        setDataIncomplete(false)
                        handleManualApproval(changedValues)
                    },
                }}
                className={`customer-details-confirmation-modal`}
                cancelProps={{
                    title: "Close",
                    clickHandler: () => setDataIncomplete(false),
                }}
            />
            <Approvaldrawer customer={state.customer} visible={approveDrawerVisible} openDrawer={openDrawer} closeDrawer={closeDrawer} />
        </PageView>
        </>
    )
}

export default CustomerEditView