import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import { axiosInstance } from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { PendingCollectionList, PastPaymentCollectionList, PaymentCollection } from '../../models/Collection/collection.model';
import { PaginationModel } from "../../models/pagination.model";
import { SortFilterModel } from '../../models/SortFilterModel/SortFilterModel.model';
import { generatePath } from 'react-router-dom';
import { MarkSettledPendingSettlement, MarkSettledPendingSettlementDetails } from '../../models/PartnerFees/partnerFees.model';
import { MarkAsSettledDetails } from '../../models/Settlement/settlement.model';

export const PaymentcollectionService = () => {
    const [pendingPaymentCollection, setPendingPaymentCollection] = useState<
    PendingCollectionList[]
    >([]);
    const [pastPaymentCollection, setPastPaymentCollection] = useState<
    PastPaymentCollectionList[]
    >([]);
    const [pendingPaymentCollectionIds, setPendingPaymentCollectionIds] = useState([]);
    const [markAsSettledLoading, setMarkAsSettledLoading] = useState(false);
    const [paymentCollection, setPaymentCollection] = useState<PaymentCollection>();
    const [markAsSettledDetails, setMarkAsSettledDetails] = useState<MarkAsSettledDetails>();
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
  
    const [paginations, setPaginations] = useState<PaginationModel>()
  
    const fetchPendingPaymentCollection = (params?: SortFilterModel) => {
      setLoading(true);
      const schema = Object.assign(new SortFilterModel(params), {...params})
      const _params = params && serialize(schema)
      axiosInstance
        .get(ApiRoutes.PENDING_COLLECTION_LIST, { params: _params })
        .then((response) => {
          const data = deserialize(
            PendingCollectionList,
            response?.data?.payment_collections
          ) as PendingCollectionList[];
          const meta = deserialize(PaginationModel,response?.data["meta"])
          setPaginations(meta);
          setPendingPaymentCollection(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    const fetchPastPaymentCollectionList = (params?:SortFilterModel) => {
      const schema = Object.assign(new SortFilterModel(params), {...params})
      const _params = params && serialize(schema)
      setLoading(true);
      axiosInstance
        .get(ApiRoutes.PAST_COLLECTION_LIST, { params: _params })
        .then((response) => {
          const data = deserialize(
            PastPaymentCollectionList,
            response?.data?.payment_collections
          ) as PastPaymentCollectionList[];
          const meta = deserialize(PaginationModel,response?.data["meta"])
          setPaginations(meta);
          setPastPaymentCollection(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const markPendingCollectionSettlements = (
      data: MarkSettledPendingSettlement,
      onSuccess: Function
    ) => {
      const requestPayload = serialize(MarkSettledPendingSettlement, data)
      setMarkAsSettledLoading(true);
      
      return axiosInstance
      .post(ApiRoutes.PENDING_COLLECTION_MARK_SETTLED, requestPayload)
      .then((response) => {
          onSuccess();
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setMarkAsSettledLoading(false);
        });
    };

    const fetchPastCollection = (settlementId: string) => {
      setLoading(true);
      const API_URL = generatePath(ApiRoutes.COLLECTION_DETAILS, {
        settlementId,
      });
      axiosInstance
        .get(API_URL)
        .then((response) => {
          const data = deserialize(
            PaymentCollection,
            response?.data?.payment_collection
          );
          setPaymentCollection(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const updatePastCollectionDetails = (
      settlement: PaymentCollection,
      onSuccess?: () => void,
      onError?: Function,
    ) => {
      setLoading(true);
      const API_URL = generatePath(ApiRoutes.COLLECTION_DETAILS, {
        settlementId: settlement?.id?.toString(),
      });
      const serializedPayload = serialize(PaymentCollection, settlement);
      delete serializedPayload["id"];
      const requestPayload = { payment_collection: serializedPayload };
      return axiosInstance
        .patch(API_URL, requestPayload)
        .then(onSuccess)
        .catch((error) => {
          setError(error);
          onError && onError()
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const fetchAllPendingPaymentCollectionForPartner = (params?: SortFilterModel) => {
      setDataLoading(true);
      const _params = params && serialize(SortFilterModel, params)
      axiosInstance
        .get(ApiRoutes.PENDING_COLLECTION_LIST_SPECIFIC_PARTNER, { params: _params })
        .then((response) => {
          const data = response?.data
          setPendingPaymentCollectionIds(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };

    const markPendingSettlementsDetails = (
      data: MarkSettledPendingSettlementDetails,
    ) => {
      const requestPayload = data && serialize(MarkSettledPendingSettlementDetails, data)
      setMarkAsSettledLoading(true);
      return axiosInstance
      .post(ApiRoutes.PENDING_COLLECTION_SETTLEMENT_MARK_SETTLED_DETAILS, requestPayload)
      .then((response) => {
        const data = deserialize(MarkAsSettledDetails, response.data)
        setMarkAsSettledDetails(data)
        })
        .catch((error) => {
          setError(error);
          return error;
        })
        .finally(() => {
          setMarkAsSettledLoading(false);
        });
    };
  
    return {
      loading,
      error,
      pendingPaymentCollection,
      pastPaymentCollection,
      fetchPendingPaymentCollection,
      fetchPastPaymentCollectionList,
      markPendingCollectionSettlements,
      markAsSettledLoading,
      fetchPastCollection,
      paymentCollection,
      updatePastCollectionDetails,
      paginations,
      fetchAllPendingPaymentCollectionForPartner,
      dataLoading,
      pendingPaymentCollectionIds,
      markAsSettledDetails,
      markPendingSettlementsDetails
    };
  };

export default PaymentcollectionService