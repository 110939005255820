import { MetaModel } from './../../models/Meta/meta.model';
import { axiosInstance } from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Axios from "axios";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { convertJsonToFormData } from "../../shared/utils/convertJsonToFormData";
import { convertXMLToJSON } from "../../shared/utils/convertXmlToJson";
import { S3AttachementModel } from "../../models/S3Attachment/s3Attachment.model";
import { DocumentTypes } from "../../enums/documentTypes";
import { types } from 'sass';

const S3AttachmentService = () => {
  const {PROFILE_PIC,NATIONAL_ID_BACK,NATIONAL_ID_FRONT,BANK_STATEMENT,EMPLOYMENT_LETTER, COMMERCIAL_REGISTRATION, TAX_CARD, REFUND_RECEIPT, SELFIE_PIC, VEHICLE_CARD, PAYSLIP, SPORTS_CLUB_MEMBERSHIP, CREDIT_CARD_STATEMENT, EMPLOYMENT_LETTER_MEDICAL_CARD, PAYSLIP_BANK_STATEMENT, MEDICAL_CARD, STUDENT_CARD} = DocumentTypes
  const [error, setError] = useState<Error>();

  const [loading, setLoading] = useState(false);
  const [uploadingProfile, setUploadingProfile] = useState(false);
  const [uploadingSelfie, setUploadingSelfie] = useState(false);
  const [uploadingNationalIdBack, setUploadingNationalIdBack] = useState(false);
  const [uploadingNationalIdFront, setUploadingNationalIdFront] = useState(false);
  const [uploadingBankStatement, setUploadingBankStatement] = useState(false);
  const [uploadingEmploymentLetter, setUploadingEmploymentLetter] = useState(false);
  const [uploadingCommercialRegistration, setUploadingCommercialRegistration] = useState(false);
  const [uploadingTaxCard, setUploadingTaxCard] = useState(false);
  const [uploadingPayslip, setUploadingPayslip] = useState(false);
  const [uploadingVehicleCard, setUploadingVehicleCard] = useState(false);
  const [uploadingSportsClubMembership, setUploadingSportsClubMembership] = useState(false);
  const [uploadingCreditCardStatement, setUploadingCreditCardStatement] = useState(false);
  const [uploadingPayslipBankstatement, setUploadingPayslipBankstatement] = useState(false);
  const [uploadingEmploymentLetterMedicalCard, setUploadingEmploymentLetterMedicalCard] = useState(false);
  const [uploadingMedicalCard, setUploadingMedicalCard] = useState(false);
  const [uploadingStudentCard, setUploadingStudentCard] = useState(false);

  const setLoaderState = (attachmentType: string, state: boolean) => {
    switch (attachmentType) {
      case PROFILE_PIC:
        setUploadingProfile(state)
        break;
      case SELFIE_PIC:
        setUploadingSelfie(state)
        break;
      case NATIONAL_ID_BACK:
        setUploadingNationalIdBack(state)
        break;
      case NATIONAL_ID_FRONT:
        setUploadingNationalIdFront(state)
        break;
      case EMPLOYMENT_LETTER:
        setUploadingEmploymentLetter(state)
        break;
      case BANK_STATEMENT:
        setUploadingBankStatement(state)
        break;
      case COMMERCIAL_REGISTRATION:
        setUploadingCommercialRegistration(state)
        break;
      case TAX_CARD:
        setUploadingTaxCard(state)
        break;
      case VEHICLE_CARD:
        setUploadingVehicleCard(state)
        break;
      case PAYSLIP:
        setUploadingPayslip(state)
        break;
      case SPORTS_CLUB_MEMBERSHIP:
        setUploadingSportsClubMembership(state)
        break;
      case CREDIT_CARD_STATEMENT:
        setUploadingCreditCardStatement(state)
        break;
      case PAYSLIP_BANK_STATEMENT:
        setUploadingPayslipBankstatement(state)
        break;
      case EMPLOYMENT_LETTER_MEDICAL_CARD:
        setUploadingEmploymentLetterMedicalCard(state)
        break;
      case MEDICAL_CARD:
        setUploadingMedicalCard(state)
        break;
      case STUDENT_CARD:
        setUploadingStudentCard(state)
        break;
      default:
        break;
    }
  }
  
  const uploadAttachment = async (file: File, attachmentType?: typeof PROFILE_PIC|typeof NATIONAL_ID_FRONT|typeof NATIONAL_ID_BACK|typeof EMPLOYMENT_LETTER|typeof BANK_STATEMENT|typeof COMMERCIAL_REGISTRATION|typeof TAX_CARD|typeof REFUND_RECEIPT|typeof SELFIE_PIC|typeof VEHICLE_CARD|typeof PAYSLIP|typeof SPORTS_CLUB_MEMBERSHIP|typeof CREDIT_CARD_STATEMENT|typeof PAYSLIP_BANK_STATEMENT|typeof EMPLOYMENT_LETTER_MEDICAL_CARD|typeof MEDICAL_CARD|typeof STUDENT_CARD) => {
    setLoading(true);
    attachmentType&&
    setLoaderState(attachmentType, true)
    const presignedResponse = await axiosInstance
      .post(ApiRoutes.S3_PRESIGNED_URL,{"filename":file?.name})
      .then((response) => {
        return {
          ...response?.data?.data?.url_fields,
          url: response?.data?.data?.url,
          file,
        };
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    const jsonData = { ...presignedResponse };
    delete jsonData["url"];
    const formData = convertJsonToFormData(jsonData);
    if (presignedResponse?.url) {
      const xmlResponse = await Axios.post(presignedResponse?.url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((xmlResponse:any) => {
          return xmlResponse;
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
      const key = convertXMLToJSON(xmlResponse?.data, true);
      const attachmentId = await uploadS3Attachment(file?.name, file?.type, key, attachmentType);
      return attachmentId;
    } else {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Failed while uploading to S3",
        description: "Please try again later.",
      });
    }
  };
  const uploadS3Attachment = (filename: string, fileType:string, s3Key: string, attachmentType?: string) => {
    const payload = serialize(S3AttachementModel, {
      filename,
      s3Key,
      fileType
    });
    return axiosInstance
      .post(ApiRoutes.S3_ATTACHMENT, payload)
      .then((response) => {
        return response?.data?.["attachment"]?.id;
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
        attachmentType &&
        setLoaderState(attachmentType, false)
      });
  };
  return {
    loading,
    uploadAttachment,
    uploadS3Attachment,
    uploadingBankStatement,
    uploadingEmploymentLetter,
    uploadingProfile,
    uploadingNationalIdFront,
    uploadingNationalIdBack,
    uploadingCommercialRegistration, 
    uploadingTaxCard,
    uploadingSelfie,
    uploadingPayslip,
    uploadingSportsClubMembership,
    uploadingVehicleCard,
    uploadingCreditCardStatement,
    uploadingEmploymentLetterMedicalCard,
    uploadingPayslipBankstatement,
    uploadingMedicalCard,
    uploadingStudentCard
  };
};

export default S3AttachmentService;
