import { Col, Divider, Row } from 'antd'
import { Formik } from 'formik'
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BadgeTypes } from '../../../../../../../enums/badgeTypes'
import { TerminalIdModel, VendorInstoreModel } from '../../../../../../../models/Vendor/Instore/instore.model'
import { AppRoutes } from '../../../../../../../routes/routeConstants/appRoutes'
import VendorService from '../../../../../../../services/VendorService/vendor.service'
import BadgeComponent from '../../../../../../../shared/components/BadgeComponent'
import ButtonComponent from '../../../../../../../shared/components/ButtonComponent'
import CardComponent from '../../../../../../../shared/components/CardComponent'
import CardSkeletonComponent from '../../../../../../../shared/components/CardSkeletonComponent'
import IconComponent from '../../../../../../../shared/components/IconComponent'
import MapComponent from '../../../../../../../shared/components/MapComponent'
import MenuComponent from '../../../../../../../shared/components/MenuComponent'
import ModalComponent from '../../../../../../../shared/components/ModalComponent'
import SwitchComponent from '../../../../../../../shared/components/SwitchComponent'
import "./instoreView.scss"

interface InstoreViewProps {
}

const InstoreView: FC<InstoreViewProps> = () => {

    const { deleteVendorInstore, fetchVendorInstore, loading, vendorInstore, updateVendorInstoreStatus, updateRisePointTerminal } = VendorService()
    const navigate = useNavigate()
    const location = useLocation()
    const { instoreId } = useParams()
    const { vendorId, vendorName } = useParams()
    const [statusLoading, setStatusLoading] = useState<BadgeTypes.APPROVED | BadgeTypes.REJECTED>();
    const [editRiseStore, setEditRiseStore] = useState<boolean>(false)
    const [initialValues, setInitialValues] = useState<{status: string}>({status: ""})
    const [terminalData, setTerminalData] = useState<TerminalIdModel>({})
    const [isVendorInstoreDeleteAlertShown, setIsVendorInstoreDeleteAlertShown] = useState(false);

    const handleUpdate = (status: BadgeTypes.APPROVED | BadgeTypes.REJECTED) => {
        if (vendorInstore?.id) {
            setStatusLoading(status);
            updateVendorInstoreStatus(
                vendorInstore?.id,
                {
                    status,
                },
                () => {
                    navigate(-1)
                    setStatusLoading(undefined);
                },
                () => {
                    setStatusLoading(undefined);
                }
            );
        }
    };

    const handleRefresh = () => instoreId && fetchVendorInstore(instoreId)

    useEffect(() => {
        handleRefresh()
    }, [instoreId])

    const handleEditClick = (terminal: TerminalIdModel) => {
        setInitialValues({ status: terminal.status ?? "" });
        setTerminalData(terminal);
        setEditRiseStore(true);
    };

    return (
        <div className="instore-view instore-form-view">
            <ModalComponent
                type="alert"
                visible={isVendorInstoreDeleteAlertShown}
                closeHandler={() => setIsVendorInstoreDeleteAlertShown(false)}
                successProps={{
                    title: "Delete",
                    loading: false,
                    clickHandler: () => {
                        if (vendorInstore?.id) {
                            deleteVendorInstore(vendorInstore?.id)
                                ?.then(() => {
                                    setIsVendorInstoreDeleteAlertShown(false);
                                    navigate(-1)
                                })
                        }
                        setIsVendorInstoreDeleteAlertShown(false);
                    },
                }}
                cancelProps={{
                    title: "Close",
                    clickHandler: () => {
                        setIsVendorInstoreDeleteAlertShown(false);
                    },
                }}
                alert={{
                    title: "Are you sure you want to delete the Instore ?",
                    buttonType: "negative"
                }}
            />
            <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={() => {}}
            >
                {({values, setFieldValue}) => (
                <ModalComponent
                    type="alert"
                    visible={editRiseStore}
                    closeHandler={() => setEditRiseStore(false)}
                    successProps={{
                        title: "Delete Rise Point POS",
                        loading: false,
                        clickHandler: () => {
                            updateRisePointTerminal({status: "inactive", instoreId: null, terminalId: terminalData?.terminalId}, handleRefresh)
                            setEditRiseStore(false);
                        },
                    }}
                    alert={{
                        title: '',
                        description: '',
                        buttonType: "negative"
                    }}
                >
                <div className='risepoint-card'>
                        <div className='header'>
                            <div className='label'>{terminalData.label}</div>
                            <SwitchComponent
                                label=""
                                value={values?.status === 'active'}
                                checkedTitle={"Activated"}
                                unCheckedTitle={"Deactivated"}
                                onSwitch={(value) => {
                                    setFieldValue('status', value ? "active" : "inactive"); 
                                    updateRisePointTerminal({status: value ? "active" : "inactive", terminalId: terminalData?.terminalId, instoreId: Number(instoreId)}, handleRefresh)
                                }}
                            />
                        </div>
                        <div className='terminal-id'>{terminalData?.terminalId}</div>
                    </div>
                </ModalComponent>)}
            </Formik>
            <div className="header">
                <Row align="middle">
                    <Col span={vendorInstore?.status === BadgeTypes.PENDING_APPROVAL ? 19 : 21}>
                        <h2 className='title'>
                            <span className="back-icon" onClick={() => navigate(generatePath(AppRoutes.VENDOR_INSTORES, { vendorId, vendorName }))}>
                                <IconComponent name="icon-back" />
                            </span>
                        </h2>
                    </Col>
                    {
                        vendorInstore ?
                        (vendorInstore?.status === BadgeTypes.PENDING_APPROVAL ?
                            <Fragment>
                                <Col span={3} className="action-btn">
                                    <ButtonComponent
                                        size="small"
                                        additionalTypes="affirmative"
                                        onClick={() => handleUpdate(BadgeTypes.APPROVED)}
                                        loading={statusLoading == BadgeTypes.APPROVED}
                                    >
                                        Approve
                                    </ButtonComponent>
                                </Col>
                                <Col span={2} className="action-btn">
                                    <ButtonComponent
                                        size="small"
                                        additionalTypes="negative"
                                        onClick={() => handleUpdate(BadgeTypes.REJECTED)}
                                        loading={statusLoading == BadgeTypes.REJECTED}
                                    >
                                        Reject
                                    </ButtonComponent>
                                </Col>
                            </Fragment> :
                            <Fragment>
                                <Col span={2} className="action-btn">
                                    <ButtonComponent
                                      type="primary"
                                      size="medium"
                                      icon={<IconComponent name="icon-edit-white" />}
                                      onClick={()=>navigate({ pathname: generatePath(AppRoutes.VENDOR_INSTORES_EDIT, { vendorId, vendorName, instoreId: `${vendorInstore?.id}` }) }, { state: vendorInstore })}
                                      className="vendor-form-edit"
                                    >
                                      <span className="text">
                                        Edit
                                      </span>
                                    </ButtonComponent>
                                </Col>
                                <Col span={1} className="action-btn">
                                    <MenuComponent
                                        dropdownClassName="document-reupload"
                                        menu={[
                                            {
                                                title: "Delete store",
                                                icon: <IconComponent name="icon-trash"/>,
                                                clickHandler: () => setIsVendorInstoreDeleteAlertShown(true)
                                            },
                                        ]}
                                    />
                                </Col>
                            </Fragment>):
                            <Fragment/>
                    } 
                </Row>
            </div>
            <div className="content vendor-instore-details-modal">
                {
                    loading ?
                        <Row gutter={[24, 24]}>
                            <Col span={10}>
                                <CardSkeletonComponent />
                            </Col>
                            <Col span={10}>
                                <CardSkeletonComponent />
                            </Col>
                        </Row> :
                        <div className="vendor-instore-details">
                            <Row gutter={[24, 24]}>
                                <Col span={10}>
                                    <CardComponent className='form-section'>
                                        <div className="vendor-instore-details">
                                            <Row align="top">
                                                <Col span={24}>
                                                    <div className="vendor-details__wrapper">
                                                        <div className="vendor-details">
                                                            <h2>{vendorInstore?.vendorName}</h2>
                                                            <BadgeComponent className='fill' type={vendorInstore?.status === BadgeTypes.APPROVED ? BadgeTypes.ACTIVE : vendorInstore?.status as BadgeTypes} />
                                                        </div>
                                                        <div className="label">{"Address"}</div>
                                                        <div className="instore-address__wrapper">
                                                            <IconComponent name="icon-map-blue" />
                                                            <p className="instore-address">{vendorInstore?.address}</p>
                                                        </div>
                                                    </div>

                                                    {vendorInstore?.lat && vendorInstore?.long ? (
                                                        <div className="instore-map__wrapper" onClick={() => window.open(`https://maps.google.com/?q=${vendorInstore?.lat},${vendorInstore?.long}`)}>
                                                            <MapComponent
                                                                position={{
                                                                    lat: vendorInstore?.lat,
                                                                    lng: vendorInstore?.long,
                                                                }}
                                                                editable={false}
                                                                className="vendor-instore-details-map"
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                            </Row>

                                            <Divider />
                                            <div className="instore-contact-details">
                                                <div className="label">{"Contact person"}</div>
                                                <div className="name">{vendorInstore?.fullName}</div>
                                                <div className="contact">
                                                    <p className="phone-number">
                                                        <IconComponent name="icon-phone-blue" />
                                                        {vendorInstore?.countryCode?.isdCode} {vendorInstore?.mobileNumber}
                                                    </p>
                                                </div>
                                                <div className="contact">
                                                    <p className="email">
                                                        <IconComponent name="icon-email-blue" />
                                                        {vendorInstore?.email}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardComponent>
                                </Col>
                                <Col span={10}>
                                    <CardComponent className='form-section'>
                                        <div className="vendor-instore-terminals">
                                            <h3>POS Terminal ID</h3>
                                            <Row className='label'>
                                                <Col span={4}>LABEL</Col>
                                                <Col offset={1} span={4}>POS TYPE</Col>
                                                <Col offset={1} span={8}>POS ID</Col>
                                                <Col offset={1} span={3}>POS STATUS</Col>
                                            </Row>
                                            <Row className='terminal-id-row'>
                                                {
                                                    vendorInstore?.fawryTerminalIds?.map((terminal) => (
                                                        <Fragment>
                                                            <Col span={4}>
                                                                <p className='terminal-name'>{terminal?.label}</p>
                                                            </Col>
                                                            <Col offset={1} span={4}>
                                                                <p className='value'>Fawry</p>
                                                            </Col>
                                                            <Col offset={1} span={8}>
                                                                <p className='terminal-name'>{terminal?.terminalId}</p>
                                                            </Col>
                                                            <Col offset={1} span={3}/>
                                                        </Fragment>
                                                    ))
                                                }
                                                {
                                                    vendorInstore?.riseTerminals?.map((terminal) => (
                                                        <Fragment>
                                                            <Col span={4}>
                                                                <p className='terminal-name'>{terminal?.label}</p>
                                                            </Col>
                                                            <Col offset={1} span={4}>
                                                                <p className='value'>Rise point</p>
                                                            </Col>
                                                            <Col offset={1} span={8}>
                                                                <p className='terminal-name'>{terminal?.terminalId}</p>
                                                            </Col>
                                                            <Col offset={1} span={3}>
                                                                <p className={`status ${terminal?.status}`}>{terminal?.status}</p>
                                                            </Col>
                                                            <Col offset={1} span={1}>
                                                                <IconComponent name="icon-edit-blue" onClick={() => handleEditClick(terminal)} />
                                                            </Col>
                                                        </Fragment>
                                                    ))
                                                }
                                            </Row>
                                        </div>
                                    </CardComponent>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
        </div>
    )
}

export default InstoreView