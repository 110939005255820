
import {serializable, alias, primitive} from 'serializr';

export class Payments { 

	@serializable(alias('failure', primitive()))
	failure?: number;

	@serializable(alias('initiated', primitive()))
	initiated?: number;

	@serializable(alias('success', primitive()))
	success?: number;

}