import React, { FC, useEffect, useState } from "react";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../shared/components/TableComponent";
import "./overduePayments.scss";
import moment from "moment";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { currencyString } from "../..";
import { Form, Formik } from "formik";
import InputField from "../../../../shared/components/InputField";
import { Col, Row } from "antd";
import PaymentService from "../../../../services/PaymentService/payment.service";
import {
  OutstandingCustomerPaymentModel,
} from "../../../../models/Payment/payment.model";
import { removeUndefined } from "../../../../shared/utils/removeUndefined";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { objectHasKeys } from "../../../../shared/utils/objectHasKeys";
import { StateProps } from "../../../../models/Meta/meta.model";
interface OverduePaymentsProps {}

const OverduePayments: FC<OverduePaymentsProps> = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const isOverdueTab = location.hash === "#overdue-payments";
  const pageNumber = isOverdueTab ? stateValue?.pageNumber as number : null;
  let filterOptions = isOverdueTab ? stateValue?.filterOptions as SortFilterModel : null
  const [page, setPage] = useState(pageNumber ?? 1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const showTest = LocalStorage.getItem("IS_TEST")
  
  const {
    loading,
    fetchOverdueCustomerPayments,
    overdueCustomerPayments,
    paginations
  } = PaymentService();

  useEffect(() => {
    objectHasKeys(filters) &&
    fetchOverdueCustomerPayments({page:1, ...filters, ...sortOptions, showTest});
  }, [filters]);
  
  useEffect(() => {
    location.hash === "#overdue-payments" &&
    fetchOverdueCustomerPayments({page, ...filters, ...sortOptions, showTest});
  }, [location.hash]);

  const filterInitialValues = filterOptions ?? new SortFilterModel();

  const handleFilterSubmit = (values: SortFilterModel) => {
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  const handleFilterClose = () => {
    setIsFilterShown(false);
  };
  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              location.state = null;
              filterOptions = null;
              setPage(1);
              handleFilterClose();
              setFilters(() => ({
                ...filterOptions,
                search: filters?.search,
              }));
              resetForm({values: {}});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputField
                    name="fullName"
                    placeholder="Enter"
                    label="Customer Name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="nationalId"
                    placeholder="Enter"
                    label="National ID"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="purchaseId"
                    placeholder="Enter"
                    label="Purchase ID"
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );

  const handlePageChange = (page:number) => {
    location.state = null;
    setPage(page)
    fetchOverdueCustomerPayments({page, ...sortOptions, ...filters, search, showTest})
  }
  return (
    <div className="outstanding-payments">
      <TableComponent
      onChange={() => fetchOverdueCustomerPayments({page, ...sortOptions, ...filters, search, showTest})}
      onSort={(data)=>setSortOptions(data)}
      filtersSorts={{page, ...filters, ...sortOptions, search, showTest}}
      paginationMeta={paginations}
      pageNumber={pageNumber ?? page}
      handlePageChange={handlePageChange}
      onRow={(record)=>
        navigate(
          {pathname: generatePath(AppRoutes.PURCHASES_VIEW, {
            purchaseId: record?.customerPurchase?.id,
          })}, {state: {page, from: 'overdue-payments', filters}}
        )
      }
        search={{
          placeholder: "Search purchase by id",
          onSearch: (value) => {
            setSearch(value)
            handleFilterSubmit({ search: value });
          },
        }}
        filter={filter}
        loading={loading}
        columns={[
          {
            title: "Purchase ID",
            dataIndex: "purchaseId",
            key: SortColumnTypes.PURCHASE_ID,
            sorter: true,
          },
          {
            title: "POS Purchase ID",
            dataIndex: "customerReferenceNumber",
            key: "customerReferenceNumber"
          },
          {
            title: "Customer",
            dataIndex: "fullName",
            key: "fullName",
          },
          {
            title: "Minimum Due",
            dataIndex: "minimumDue",
            key: SortColumnTypes.MINIMUM_DUE,
            render: (text, record) => (
              <span>
                {currencyString}
                {text}
              </span>
            ),
            sorter: true,
          },
          {
            title: "Due on",
            dataIndex: "dueDate",
            key: SortColumnTypes.DUE_DATE,
            render: (text, record: OutstandingCustomerPaymentModel) => (
              <div className="due-on__container">
                <span>{moment(text).format("DD MMM YYYY")}</span>
                {record?.isOverDue && <BadgeComponent type={BadgeTypes.OVERDUE} />}
              </div>
            ),
            sorter: true,
          },
        ]}
        data={overdueCustomerPayments}
      />
    </div>
  );
};

export default OverduePayments;
