
import {serializable, alias, object, list, primitive} from 'serializr';

export class UpcomingPlanActions { 

	@serializable(alias('action_name', primitive()))
	actionName?: string;

	@serializable(alias('status', primitive()))
	status?: string;

}