
import {serializable, alias, object, list, primitive} from 'serializr';

export class OptionsArray { 

	@serializable(alias('answer_id', primitive()))
	answerId?: number;

	@serializable(alias('answer', primitive()))
	answer?: string;

	@serializable(alias('score', primitive()))
	score?: number;

}