import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import { axiosInstance } from "../../interceptor/axiosInstance";
import {
  VendorStorePromotionCreationModel,
  VendorStorePromotionModel,
} from "../../models/Vendor/StorePromotion/storePromotion.model";
import { VendorPurchaseConfigModel } from "../../models/Vendor/PurchaseConfig/purchaseConfig.model";
import { VendorListModel, VendorModel } from "../../models/Vendor/vendor.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { DeallUsersModel } from "../../models/DeallUsers/deallusers.model";
import { PaginationModel } from "../../models/pagination.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";

const DeallUserService = () => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [deallUsers, setDeallUsers] = useState<DeallUsersModel[]>([]);
  const [vendor, setVendor] = useState<VendorModel>();

  const [purchaseConfig, setPurchaseConfig] = useState<
    VendorPurchaseConfigModel[]
  >([]);
  const [vendorStorePromotions, setVendorStorePromotions] = useState<
    VendorStorePromotionModel[]
  >([]);

  const [paginations, setPaginations] = useState<PaginationModel>()

  const fetchDeallUserList = (params?: SortFilterModel, onSuccess?:(data: DeallUsersModel[])=>void, ) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    return axiosInstance
      .get(ApiRoutes.DEALL_USERS, {params: _params})
      .then((response) => {
        const list = deserialize(
          DeallUsersModel,
          response?.data?.admins
        ) as DeallUsersModel[];
        const _paginations = deserialize(PaginationModel,response?.data["meta"])
        setDeallUsers([...list]);
        setPaginations(_paginations)
        onSuccess && onSuccess(list)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createDeallUser = (deallUser: any,onSucces:any) => {
    setLoading(true);
    return axiosInstance
      .post(ApiRoutes.DEALL_USERS, deallUser)
      .then((response) => {
        onSucces()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updateDeallUser = (userId: any, deallUser: any,onSucces:any) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.DEALL_USERS_UPDATE_DELETE, { userId });
    return axiosInstance
      .put(API_URL, deallUser)
      .then((response) => {
        onSucces()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteDeallUser = (userId: any,onSucces:any) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.DEALL_USERS_UPDATE_DELETE, { userId });
    return axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSucces()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };




  return {
    loading,
    error,
    deallUsers,
    purchaseConfig,
    vendor,
    vendorStorePromotions,
    fetchDeallUserList,
    createDeallUser,
    updateDeallUser,
    deleteDeallUser,
    paginations
  };
};

export default DeallUserService;
