import { Row, Col } from 'antd'
import React, { FC, Fragment, useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { BadgeTypes } from '../../../../../../enums/badgeTypes'
import { AppRoutes } from '../../../../../../routes/routeConstants/appRoutes'
import CustomerService from '../../../../../../services/CustomerService/customer.service'
import BadgeComponent from '../../../../../../shared/components/BadgeComponent'
import ButtonComponent from '../../../../../../shared/components/ButtonComponent'
import "./customerassets.scss"

interface CustomerAssetsProps { 
    customerId?: string;
}

const CustomerAssets: FC<CustomerAssetsProps> = ({ customerId }) => {

    const { fetchCustomerPurchases, mobileCustomerPurchases } = CustomerService();
    const navigate = useNavigate();

    useEffect(() => {
        customerId && fetchCustomerPurchases({}, customerId)
    }, [])

    return (
        !!mobileCustomerPurchases?.length ?
        <>
        <div className="customerassets-card">
            <Row>
                <Col span={5} className='data'>
                    <div className='label'>Purchase ID</div>
                </Col>
                <Col span={5} className='data'>
                    <div className='label'>IMEI number</div>
                </Col>
                <Col span={5} className='data'>
                    <div className='label'>Device purchase status</div>
                </Col>
                <Col span={5} className='data'>
                    <div className='label'>Payment status</div>
                </Col>
            </Row>
            {mobileCustomerPurchases?.map(mobilePurchases => 
                <>
                <Row>
                    <Col span={5} className='data'>
                        <div className='value'>{mobilePurchases?.purchaseId}</div>
                    </Col>
                    <Col span={5} className='data'>
                        <div className='value'>{mobilePurchases?.imeiNumber}</div>
                    </Col>
                    <Col span={5} className='data'>
                        <div className='value'><BadgeComponent type={mobilePurchases?.devicePurchaseStatus as BadgeTypes} /></div>
                    </Col>
                    <Col span={5} className='data'>
                        <div className='value'><BadgeComponent type={mobilePurchases?.customerPaymentStatus as BadgeTypes} /></div>
                    </Col>
                    <Col span={4} className='data'>
                        <ButtonComponent type="primary" onClick={() => navigate(generatePath(AppRoutes.PURCHASES_VIEW,{purchaseId: mobilePurchases?.id?.toString()}))}>View purchase details</ButtonComponent>
                    </Col>
                </Row>
                </>
            )}
        </div> </>
        :
        <div className='no-data'>No data found</div>
    )
}

export default CustomerAssets