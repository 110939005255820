import React, { FC, Fragment, useState } from "react";
import ModalComponent from "../../../../../../../shared/components/ModalComponent";
import "./vendorInstoreDetails.scss";
import IconComponent from "../../../../../../../shared/components/IconComponent";
import { Col, Divider, Row } from "antd";
import ButtonComponent from "../../../../../../../shared/components/ButtonComponent";
import { VendorInstoreModel } from "../../../../../../../models/Vendor/Instore/instore.model";
import { BadgeTypes } from "../../../../../../../enums/badgeTypes";
import VendorService from "../../../../../../../services/VendorService/vendor.service";
import MapComponent from "../../../../../../../shared/components/MapComponent";
import {UserModel} from "../../../../../../../models/User/user.model";
import {LocalStorage} from "../../../../../../../shared/utils/localStorageHelpers";
interface VendorInstoreDetailsProps {
  visible: boolean;
  instore: VendorInstoreModel | undefined;
  closeHandler: () => void;
  refreshList?: () => void;
}

const VendorInstoreDetails: FC<VendorInstoreDetailsProps> = (props) => {
  const { visible, closeHandler, instore, refreshList } = props;

  const { updateVendorInstoreStatus } = VendorService();
  const [statusLoading, setStatusLoading] = useState<
    BadgeTypes.APPROVED | BadgeTypes.REJECTED
  >();
  const user = LocalStorage.getItem('USER') as UserModel;

  const handleUpdate = (status: BadgeTypes.APPROVED | BadgeTypes.REJECTED) => {
    if (instore?.id) {
      setStatusLoading(status);
      updateVendorInstoreStatus(
        instore?.id,
        {
          status,
        },
        () => {
          refreshList && refreshList();
          closeHandler();
          setStatusLoading(undefined);
        },
        () => {
          setStatusLoading(undefined);
        }
      );
    }
  };

  return (
    <ModalComponent
      visible={visible}
      closeHandler={closeHandler}
      type="details"
      className="vendor-instore-details-modal"
    >
      <div className="vendor-instore-details">
        <Row align="top">
          <Col span={12}>
            <div className="vendor-details__wrapper">
              <div className="vendor-details">
                <img src={instore?.logo?.s3Url} />
                <h4>{instore?.vendorName}</h4>
              </div>
              <h2 className="instore-name">{instore?.name}</h2>
              <div className="instore-address__wrapper">
                <IconComponent name="icon-map" />
                <p className="instore-address">{instore?.address}</p>
              </div>
            </div>
          </Col>

          <Col span={12}>
            {instore?.lat && instore?.long ? (
              <div className="instore-map__wrapper" onClick={()=>window.open(`https://maps.google.com/?q=${instore?.lat},${instore?.long}`)}>
                <MapComponent
                  position={{
                    lat: instore?.lat,
                    lng: instore?.long,
                  }}
                  editable={false}
                  className="vendor-instore-details-map"
                />
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Divider />
        <div className="instore-contact-details">
          <div className="label">CONTACT PERSON</div>
          <div className="name">{instore?.contactFullName}</div>
          <div className="contact">
            <p className="phone-number">
              <IconComponent name="icon-phone" />
              {instore?.countryCode?.isdCode} {instore?.mobileNumber}
            </p>
            <p className="email">
              <IconComponent name="icon-email" />
              {instore?.email}
            </p>
          </div>
        </div>
        {instore?.status == BadgeTypes.PENDING_APPROVAL && user?.role !== BadgeTypes.VIEWER && (
          <Fragment>
            <Divider />
            <div className="vendor-instore-details-controllers">
              <ButtonComponent
                size="fixed-v2"
                additionalTypes="negative"
                onClick={() => handleUpdate(BadgeTypes.REJECTED)}
                loading={statusLoading == BadgeTypes.REJECTED}
              >
                Reject
              </ButtonComponent>
              <ButtonComponent
                size="fixed-v2"
                additionalTypes="affirmative"
                onClick={() => handleUpdate(BadgeTypes.APPROVED)}
                loading={statusLoading == BadgeTypes.APPROVED}
              >
                Approve
              </ButtonComponent>
            </div>
          </Fragment>
        )}
      </div>
    </ModalComponent>
  );
};

export default VendorInstoreDetails;
