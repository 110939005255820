import {Spin, Upload} from "antd";
import {RcFile, UploadChangeParam} from "antd/lib/upload";
import {Field, Formik, FormikErrors} from "formik";
import React, {FC, Fragment, ReactElement, useEffect, useRef, useState} from "react";
import ButtonComponent from "../ButtonComponent";
import {DocumentItemCard} from "../DocumentsCard";
import Error from "../Error";
import IconComponent from "../IconComponent";
import MenuComponent from "../MenuComponent";
import SpinnerComponent from "../SpinnerComponent";
import {handleBeforeImageUpload, handleValidationError} from "../../utils/imageValidation";
import "./uploadComponent.scss";
import CardComponent from "../CardComponent";
import UploadIcon from "./../../../assets/images/export.svg"
import FileViewModal from "../FileViewModal";
import CropImageComponent from "../CropImageComponent";
import { getBase64 } from "../../utils/getBase64";

interface UploadComponentProps {
    fileUrl: string;
    fileId: string;
    fileType: string;
    fileName: string;
    onUpload: (file: File, fileUrl: string) => void;
    type: "cover-picture" | "logo" | "document" | "poster" | "document-preview" | "logo-preview" | "inline-card";
    title: string | ReactElement;
    label?: string;
    subLabel?: string;
    onRemove?: () => void;
    accept: string;
    isUploading?: boolean;
    disabled?: boolean;
    error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
    canRotate?: boolean;
    canReupload?: boolean;
}

const UploadComponent: FC<UploadComponentProps> = (props) => {
    const {
        canRotate,
        fileUrl,
        fileType,
        fileId,
        fileName,
        onUpload,
        type,
        title,
        label,
        onRemove,
        accept,
        isUploading,
        disabled,
        error,
        subLabel,
        canReupload = true,
        children
    } = props;
    const [fileViewModalVisible, setFileViewModalVisible] = useState(false);
    const [src, setSrc] = useState<string | null>(null);
    const [fileInfo, setFileInfo] = useState<UploadChangeParam>()
    const [visible, setVisible] = useState<boolean>(false);
    const uploadedImage = useRef<HTMLImageElement>(null);
    const rotationDegree = useRef(0);

    const handleChange = (info: UploadChangeParam) => {
        if (info.file.status !== "uploading" && info.file.originFileObj) {
            getBase64(info.file.originFileObj, (fileUrl: string) => {
                if(type === "logo") { setSrc(fileUrl); setFileInfo(info) }
                else if (info.file.originFileObj) onUpload(info.file.originFileObj, fileUrl);
            });
        }
    };

    const handleBeforeUpload = (file: File) => {
        const acceptedTypes = accept?.split(", ");
        const validationData = handleBeforeImageUpload(file, acceptedTypes, type)
        validationData?.then((result) => {
            handleValidationError(result, type)
        })
        return validationData
    };

    function rotateLeft() {
        rotationDegree.current = rotationDegree.current - 90;
        if (uploadedImage?.current) {
            uploadedImage.current.style.transform = `rotate(${rotationDegree.current}deg)`;
        }
    }

    function rotateRight() {
        rotationDegree.current = rotationDegree.current + 90;
        if (uploadedImage?.current) {
            uploadedImage.current.style.transform = `rotate(${rotationDegree.current}deg)`;
        }
    }

    function maintainImageView() {
        if (canRotate && uploadedImage?.current && uploadedImage.current.naturalHeight > uploadedImage.current.naturalWidth) {
            rotateRight();
        }
    }

    useEffect(() => {
    src && setVisible(true)
    }, [src])

    const closeHandler = () => { setVisible(false); setSrc(null); }

    return (
        <Fragment>
            <CropImageComponent visible={visible} closeHandler={closeHandler} onUpload={onUpload} fileInfo={fileInfo!} src={src!} />

            <Formik initialValues={{}} onSubmit={() => {
            }}>
                <Field as={Upload} name={Field?.name}>
                    {({field, form: {touched, errors}, meta}: any) => (
                        <div className="upload-component__wrapper">
                            {label ? <label>{label} <span className="sub-label">{subLabel}</span></label> : ""}
                            {fileUrl && type !== "poster" ? (
                                type === "cover-picture" ? (
                                    <div className="cover-picture-uploaded-component">
                                        <img className="uploaded-image" src={fileUrl}/>
                                        <MenuComponent
                                            dropdownClassName="uploaded-image__action"
                                            menu={[
                                                {
                                                    title: "Remove",
                                                    clickHandler: () => onRemove && onRemove(),
                                                    icon: <IconComponent name="icon-trash"/>,
                                                },
                                                {
                                                    title: "Re-upload",
                                                    icon: <IconComponent name="icon-upload"/>,
                                                    upload: {handleChange: handleChange, accept: accept},
                                                },
                                            ]}
                                            type={type}
                                        >
                                            <ButtonComponent
                                                size="medium"
                                                type="primary"
                                                icon={<IconComponent name="icon-camera"/>}
                                            >
                                                Upload Cover Picture
                                            </ButtonComponent>
                                        </MenuComponent>
                                    </div>
                                ) : type === "document" ? (
                                    <div className="document-uploaded-component">
                                        {
                                            isUploading ?
                                                <Spin/> :
                                                <DocumentItemCard
                                                    accept={accept}
                                                    fileType={fileType}
                                                    customerAttachmentId={fileId}
                                                    s3Url=""
                                                    filename={fileName}
                                                    uploadHandler={onUpload}
                                                    isUpload
                                                    handleChange={handleChange}
                                                    uploading={isUploading}
                                                />
                                        }
                                    </div>
                                ) : type === "logo" ? (
                                    <div className="logo-uploaded-component">
                                        <img className="logo-image" src={fileUrl}/>
                                        <MenuComponent
                                            dropdownClassName="logo-reupload"
                                            menu={[
                                                {
                                                    title: "Remove",
                                                    clickHandler: () => onRemove && onRemove(),
                                                    icon: <IconComponent name="icon-trash"/>,
                                                },
                                                {
                                                    title: "Re-upload",
                                                    icon: <IconComponent name="icon-upload"/>,
                                                    upload: {handleChange, accept},
                                                },
                                            ]}
                                            type={type}
                                        >
                                            <div className="logo-upload-camera-icon">
                                                <IconComponent name="icon-camera"/>
                                            </div>
                                        </MenuComponent>
                                    </div>
                                ) : type === "inline-card" ? (
                                    <div className="inline-card-uploaded-component">
                                        {
                                            isUploading ?
                                                <Spin/> :
                                                <div onClick={() => setFileViewModalVisible(true)}
                                                     className="inline-card-filename">
                                                    {fileName}
                                                </div>
                                        }
                                    </div>
                                ) : type === "logo-preview" ? (
                                    <div className="logo-uploaded-component">
                                        <img className="logo-image" src={fileUrl}/>
                                        <Upload
                                            beforeUpload={handleBeforeUpload}
                                            accept={accept}
                                            showUploadList={false}
                                            onChange={(info) => handleChange(info)}
                                        >
                                            <div className="logo-reupload">
                                                <div className="logo-upload-camera-icon">
                                                    <IconComponent name="icon-upload"/>
                                                </div>
                                            </div>
                                        </Upload>
                                    </div>
                                ) : type === "document-preview" ? (
                                        <>
                                            <div className='upload-button-container'>
                                                {canRotate && (
                                                    <>
                                                        <img className="rotate-icon" onClick={rotateLeft}
                                                             src="/icons/rotate-left-solid.svg"
                                                             alt="Rotate Left"/>
                                                        <img className="rotate-icon" onClick={rotateRight}
                                                             src="/icons/rotate-right-solid.svg" alt="Rotate Right"/>
                                                    </>
                                                )}
                                                {canReupload && <Upload
                                                    beforeUpload={handleBeforeUpload}
                                                    accept={accept}
                                                    showUploadList={false}
                                                    onChange={(info) => handleChange(info)}
                                                >

                                                    <ButtonComponent
                                                        className="uploaded-image__action"
                                                        size="medium"
                                                        type="default"
                                                        icon={<img className='upload-icon' src={UploadIcon}
                                                                   alt="upload-icon"/>}
                                                    >
                                                        Re-Upload
                                                    </ButtonComponent>
                                                </Upload>}
                                            </div>

                                            <CardComponent className="document-preview-card">
                                                <img className="uploaded-image" onLoad={maintainImageView}
                                                     ref={uploadedImage}
                                                     src={fileUrl}/>
                                            </CardComponent></>
                                    ) :
                                    (
                                        ""
                                    )
                            ) : (
                                <Upload
                                    beforeUpload={handleBeforeUpload}
                                    accept={accept}
                                    className={`upload-component ${type}`}
                                    name={field?.name}
                                    showUploadList={false}
                                    onChange={handleChange}
                                    disabled={disabled}
                                >
                                    {
                                        children ?
                                            children :
                                            (isUploading ? (
                                                <SpinnerComponent className="upload-component-spin" tip="Uploading"/>
                                            ) : (
                                                <div className="upload-component-button">
                                                    <IconComponent name="icon-upload-blue"/>
                                                    <div className="upload-component-button-title">{title}</div>
                                                </div>
                                            ))}
                                </Upload>
                            )}
                            {
                                error && !fileUrl &&
                                <Error message={error as string} name={field?.name}/>
                            }
                        </div>
                    )}
                </Field>
            </Formik>
            <FileViewModal data={{fileType, fileUrl}} visible={fileViewModalVisible}
                           closeHandler={() => setFileViewModalVisible(false)}/>
        </Fragment>
    );
};

export default UploadComponent;
