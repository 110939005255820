import { Drawer, Row, Col } from 'antd'
import { Formik, Form } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BadgeTypes } from '../../../enums/badgeTypes'
import { CustomerModel } from '../../../models/Customer/customer.model'
import { AppRoutes } from '../../../routes/routeConstants/appRoutes'
import CustomerService from '../../../services/CustomerService/customer.service'
import MetaService from '../../../services/MetaService/meta.service'
import { approvalFormValidationSchema } from '../../../views/Home/Customers/CustomerDetailsForm/customerDetailsFormValidation'
import ButtonComponent from '../ButtonComponent'
import DropdownField from '../DropdownField'
import InputField from '../InputField'
import "./approvaldrawer.scss"

interface ApprovaldrawerProps {
    customer: CustomerModel;
    openDrawer: () => void;
    closeDrawer: () => void;
    visible: boolean;
 }

const Approvaldrawer: FC<ApprovaldrawerProps> = ({ customer, openDrawer, closeDrawer, visible }) => {
    const { fetchApprovalReasonList, approvalReasonList } = MetaService();
    const { manualApproveCustomer, loading } = CustomerService();
    const navigate = useNavigate();

    useEffect(() => {
        fetchApprovalReasonList();
    }, [])

    const handleApprove = (comment: string, commentId: number[]) => {
        manualApproveCustomer(
            {
                status: BadgeTypes.APPROVED,
                id: customer?.id,
                approvalReason: comment,
                approvalReasonIds: commentId
            },
            customer?.id?.toString() ?? "",
            () => {
                closeDrawer();
                navigate(AppRoutes.APPROVE_CUSTOMERS);
            }
        );
    };

    return (
        <div>
            <Formik 
                initialValues={{ approvalComment: "", approvalReasonId: [] }} 
                onSubmit={() => {}}
                validationSchema={approvalFormValidationSchema}>
                {({values, setFieldValue, handleSubmit, errors}) => (
                <Form>
                    <Drawer
                    placement="bottom"
                    onClose={() => closeDrawer()}
                    visible={visible}
                    key="left"
                    className="reject-customer-drawer"
                    >
                        <h3 className="reject-drawer-header">Are you sure you want to approve the customer  ?</h3>
                        <DropdownField
                            mode="multiple"
                            maxTagCount="responsive"
                            optionFilterProp="label"
                            allowClear
                            label="Reason for approval"
                            placeholder="Select"
                            name="approvalReasonId"
                            value={values?.approvalReasonId}
                            options={approvalReasonList}
                            onChange={(value) => {
                                setFieldValue("approvalReasonId", value);
                            }}
                        />
                        <InputField className="reject-drawer-input" textArea type="text" name="approvalComment" label="Comment" placeholder="Enter" />
                        <Row gutter={12} className="reject-drawer-controllers">
                            <Col span={12}>
                                <ButtonComponent
                                    type="default"
                                    additionalTypes="secondary"
                                    size="fixed"
                                    onClick={() => closeDrawer()}
                                >
                                    Close
                                </ButtonComponent>
                            </Col>
                            <Col span={12}>
                                <ButtonComponent
                                    type={"primary"}
                                    htmlType={"submit"}
                                    size="fixed"
                                    additionalTypes="affirmative"
                                    onClick={() => {
                                        handleSubmit();
                                        if (!Object.keys(errors).length) { handleApprove(values.approvalComment, values.approvalReasonId); }
                                    }}
                                    loading={loading}
                                >
                                    Approve
                                </ButtonComponent>
                            </Col>
                        </Row>
                    </Drawer>
                </Form>
            )}
            </Formik>
        </div>
    )
}

export default Approvaldrawer