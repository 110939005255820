import React, { FC, Fragment, useEffect, useState } from 'react'
import "./creditscoreconfiguration.scss"
import CardSkeletonComponent from '../../../../shared/components/CardSkeletonComponent'
import IconComponent from '../../../../shared/components/IconComponent'
import TableComponent from '../../../../shared/components/TableComponent'
import { CreditScoreCriterions } from '../../../../models/CreditScoreCriterions/creditScoreCriterions.model'
import CreditScoreConfigurationForm from './CreditScoreConfigForm'
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import { ConfigurationFormTypes } from '../../../../enums/configurationFormTypes'
import ButtonComponent from '../../../../shared/components/ButtonComponent'
import {DeleteOutlined} from '@ant-design/icons';
import Notification from '../../../../shared/components/Notification'
import { NotificationTypes } from '../../../../enums/notificationTypes'
import { DatasourceTypesDefinition } from '../../../../definitions/datasourceTypesDefinition'
import { DatasourceTypes } from '../../../../enums/datasourceTypes'
import refresh from "../../../../assets/images/recalculate.svg"
import add from "../../../../assets/images/add.svg"
import ApprovalThresholdForm from './ApprovalThresholdForm'
import CardComponent from '../../../../shared/components/CardComponent'
import { Col, Row } from 'antd'
import MetaService from '../../../../services/MetaService/meta.service'
import ModalComponent from "../../../../shared/components/ModalComponent";
import alertIcon from "../../../../assets/images/alert-for-refund-creation.svg";

const CreditScoreConfiguration: FC = () => {
  const loading = false
  const [isCreditScoreConfigFormShown,setIsCreditScoreConfigFormShown] = useState(false)
  const [selectedVal, setSelectedVal] = useState<CreditScoreCriterions>()
  const { fetchCreditScoreCriteria, creditScore, updateCreditScore, recalculateAllCreditScore } = ConfigurationService()
  const { getRiseConfig, riseConfigMeta } = MetaService()
  const [showCreditScoreAlert, setShowCreditScoreAlert] = useState(false)
  const [creditScoreData, setCreditScoreData] = useState<CreditScoreCriterions[]>()
  const [formType, setFormType] = useState<string>('')
  const [openApprovalThresholdForm, setOpenApprovalThresholdForm] = useState(false)
  const emptyData = new CreditScoreCriterions()
  const [isMobile, setIsMobile] = useState(false);

  const updateData = (data: CreditScoreCriterions[]) => { data && setCreditScoreData(data) }

  const handleSendData = (allData: CreditScoreCriterions[]) => {

    const activeCriteria = allData?.filter(criteria => criteria?.active)     // Factor weight must be added only for active criteria
    const totalWeightage = Number(activeCriteria?.reduce((total, data) => total + (Number(data?.criteria?.factorWeight) ?? 0), 0))
    
    if(totalWeightage !== 100){
      Notification({
        message: "Sum of factor weight must be 100",
        description: "Please update the factor weight for the criteria!",
        type: NotificationTypes.ERROR,
      });
    } else {
      allData?.map(data => {
        delete data?.criteria?.optionsArrays
        delete data?.criteria?.questions
        delete data?.createdAt
        delete data?.updatedAt
      })
      updateCreditScore(allData!)
    }
  }
  
  useEffect(() => {
    fetchCreditScoreCriteria()
    getRiseConfig()
    if (window.innerWidth <= 420) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    setCreditScoreData(creditScore)
  }, [creditScore])

  return (
      <Fragment>
        <ModalComponent
            type={isMobile ? 'large' : "alert-large"}
            visible={showCreditScoreAlert}
            closeHandler={() => setShowCreditScoreAlert(false)}
            successProps={{
              title: "Yes",
              loading: loading,
              clickHandler: () => {
                recalculateAllCreditScore();
                setShowCreditScoreAlert(false)
              },
            }}
            cancelProps={{
              title: "No",
              clickHandler: () => setShowCreditScoreAlert(false),
            }}
        >
          <div className="alert-info">
            <img src={alertIcon} alt="alert-refund"/>
            <b>Are you sure you want to recalculate all users credit score?</b>
          </div>
        </ModalComponent>
        {
          loading ?
              <CardSkeletonComponent rows={4}/> :
              <>
                <div className='creditscoreconfiguration'>
                  <CreditScoreConfigurationForm
                      visible={isCreditScoreConfigFormShown}
                      closeHandler={() => setIsCreditScoreConfigFormShown(false)}
                    data={selectedVal!}
                    allData={creditScoreData}
                    updateData={updateData}
                    type={formType}
                />
                <ApprovalThresholdForm visible={openApprovalThresholdForm} closeHandler={() => setOpenApprovalThresholdForm(false)} data={riseConfigMeta} handleRefresh={getRiseConfig} />
                <>
                  <Row>
                  <Col className="label">Credit Score Rating Configuration</Col>
                  <Col offset={9}>
                    <span className='add-new-score' onClick={() => { setIsCreditScoreConfigFormShown(true); setSelectedVal(emptyData); setFormType(ConfigurationFormTypes.ADD) }}>
                      <img className="icon-style-referred" src={add}></img>
                      Add new credit score rating        
                    </span>
                  </Col>
                  <Col>
                    <span className='send-data' onClick={() => { handleSendData(creditScoreData!) }}>
                      <IconComponent
                          name="icon-edit-blue"
                      />
                      Send data
                    </span>
                  </Col>
                  <Col>
                    <span className='send-data' onClick={() => setShowCreditScoreAlert(true)}>
                     <img className="icon-style-referred" src={refresh}></img>
                      Recalculate credit score                  
                    </span>
                  </Col>
                  </Row>
                  <CardComponent className='threshold-card'>
                    <div className='approval-threshold'>
                        <div className='label'>approval threshold</div>
                        <div className='value'>{riseConfigMeta?.configJson?.thresholdCreditScore} <IconComponent name="icon-edit-blue" onClick={() => setOpenApprovalThresholdForm(true)} />
                        </div>
                    </div> 
                </CardComponent>
                <CardComponent>
                  <TableComponent
                  loading={loading}
                  data={creditScoreData}
                  columns={[
                    {
                      dataIndex: ["criteria", "factor"],
                      key: "factor",
                      title: "Factor name",
                      width: "14%",
                      render: (text) => {
                        return text.replace(/_/g, ' ').replace(/^\w/, (char: string) => char.toUpperCase());
                      }
                    },
                      {
                      dataIndex: ["criteria", "factorWeight"],
                      key: "factorWeight",
                      title: "Factor Weight",
                      width: "12%",
                      },
                      {
                      dataIndex: ["criteria", "datasource"],
                      key: "dataSource",
                      title: "Data Source",
                      width: "20%",
                      render: (text: DatasourceTypes) => <div>{DatasourceTypesDefinition[text]}</div>
                      },
                      {
                      dataIndex: ["criteria", "questions"],
                      key: "questions",
                      title: "Questions",
                      width: "30%",
                      render: (text: string) => <div>{text ?? "n/a"}</div>
          
                      },
                      {
                      dataIndex: "active",
                      key: "active",
                      title: "Active",
                      width: "12%",
                      render: (text: boolean) => <div>{text ? 'Yes' : 'No'}</div>
                      },
                      {
                      title: "",
                      key: "action",
                      width: "10%",
                      render: (val, record) => (
                          <div className="purchase-config-action">
                          <IconComponent
                              name="icon-edit"
                              onClick={() => {
                              setSelectedVal(val)
                              setFormType(ConfigurationFormTypes.EDIT)
                              setIsCreditScoreConfigFormShown(true);
                              }}
                          />
                          </div>
                      ),
                      },
                      {
                        title: "",
                        key: "action",
                        width: "10%",
                        render: (val, record) => (
                            <div className="purchase-config-action">
                            <ButtonComponent
                                type="text"
                                size="icon-only"
                                icon={<DeleteOutlined/>}
                                onClick={() => {
                                  const filteredRecords = creditScoreData?.filter(criteria => 
                                    ((criteria?.criteria?.id !== val.criteria.id) ||
                                    (criteria?.criteria?.factor !== val.criteria.factor)
                                    ))
                                  setCreditScoreData(filteredRecords)
                                }}
                            />
                            </div>
                        ),
                        },
                  ]}
                  />
                </CardComponent>
                </>
                
            </div>
          </>
        }
      </Fragment>
  )
}

export default CreditScoreConfiguration