import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useState } from "react";
import { PastPartnerSettlements } from "../../../../../models/PartnerFees/partnerFees.model";
import { SettlementModel } from "../../../../../models/Settlement/settlement.model";
import { SettlementService } from "../../../../../services/SettlementService/settlement.service";
import PartnerfeeService from "../../../../../services/PartnerfeeService/partnerfee.service";
import InputField from "../../../../../shared/components/InputField";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import "./editVendorSettlement.scss";
import { PaymentcollectionService } from "../../../../../services/PaymentcollectionService/paymentcollection.service";
import { settlementIdValidation } from "./settlementIdValidation";

interface EditVendorSettlementProps {
  settlement?: SettlementModel | PastPartnerSettlements,
  visible: boolean;
  closeHandler: () => void;
  handleRefresh: () => void;
  type?: "Vendor" | "Partner Fee" | "Collection";
}

const EditVendorSettlement: FC<EditVendorSettlementProps> = (props) => {
  const { visible, closeHandler, handleRefresh, settlement, type } = props;
  const { updatePastSettlementDetails, loading } = SettlementService();
  const { updatePastPartnerSettlementDetails, loading: partnerLoading } = PartnerfeeService();
  const { updatePastCollectionDetails, loading: collectionLoading } = PaymentcollectionService();

  const [initialValues, setInitialValues] = useState({
    transactionId: settlement?.transactionId
  });
  const onSubmit = (values: FormikValues) => {
    switch (type) {
      case "Vendor":
        updatePastSettlementDetails({...values, id: settlement?.id}, ()=>{
          closeHandler()
        })
        break;
      case "Partner Fee":
        updatePastPartnerSettlementDetails({...values, id: settlement?.id}, ()=>{
          closeHandler()
        })
        break;
      case "Collection":
        updatePastCollectionDetails({...values, id: settlement?.id}, ()=>{
          closeHandler()
        })
        break;
      default:
        break;
    }
    handleRefresh()
  };

  useEffect(() => {
    setInitialValues({
      transactionId: settlement?.transactionId
    })
  }, [settlement])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={settlementIdValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({handleSubmit}) => (
        <ModalComponent
          type="small"
          visible={visible}
          closeHandler={closeHandler}
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading || partnerLoading || collectionLoading
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: closeHandler,
          }}
          title={`Edit ${type} Settlement ID`}
        >
          <div className="edit-vendor-settlement">
            <Form>
              <InputField
                name="transactionId"
                label={`${type} Settlement ID`}
                placeholder="Enter"
              />
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default EditVendorSettlement;
