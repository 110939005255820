import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button } from "antd";
import UserService from "../../../services/AuthService/auth.service";
import { useNavigate } from "react-router-dom";
import "./loginForm.scss";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { LoginRequestModel } from "../../../models/Auth/auth.model";
import { loginValidationSchema } from "./LoginValidation";

interface User {
  email: string;
  password: string;
}

const initialValue = {
  email: "",
  password: "",
};
interface LoginFormProps {
  submitHandler?: Function;
}

const LoginForm = ({ submitHandler }: LoginFormProps) => {
  const { error, loading, loginUser } = UserService();
  const navigate = useNavigate();
  const onSubmit = (user: LoginFormProps) => {
    const payload: LoginRequestModel = { ...user, grantType: "password" };
    loginUser(payload);
    submitHandler && submitHandler(user);
  };

  return (
    <div className="login-form">
      <h2>Admin login</h2>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={loginValidationSchema}
      >
        {({ isValid, values }) => (
          <Form>
            <InputField
              type="email"
              name="email"
              placeholder="Enter email"
              label="Email"
            />
            <InputField
              type="password"
              name="password"
              placeholder="Enter password"
              label="Password"
            />
            <ButtonComponent
              type="primary"
              htmlType="submit"
              className="auth-form__btn"
              size="wide"
              loading={loading}
            >
              Login
            </ButtonComponent>
          </Form>
        )}
      </Formik>
      <p className="login-form__forgot-password">
        Forgot Password?{" "}
        <span
          onClick={() => {
            navigate(AppRoutes.FORGOT_PASSWORD);
          }}
        >
          Click Here
        </span>
      </p>
    </div>
  );
};

export default LoginForm;
