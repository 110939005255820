import React, { FC, Fragment, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  ContactPersonDetails,
  Vendor,
} from "../../../../../../models/Vendor/vendor.model";
import VendorService from "../../../../../../services/VendorService/vendor.service";
import DocumentsCard from "../../../../../../shared/components/DocumentsCard";
import "./vendorDetails.scss";
import VendorDetailsCard from "./VendorDetailsCard";
import VendorInfoCard from "./VendorInfoCard";
import VendorPersonalDetailsCard from "./VendorPersonalDetailsCard";

interface VendorDetailsProps {
  refresh?: boolean;
  vendor?: Vendor | undefined;
  loading?: boolean;
  refreshData?: () => void;
  openVendorForm?: () => void;
}

const VendorDetails: FC<VendorDetailsProps> = (props) => {
  const { vendor, loading, refreshData, openVendorForm } = props;
  const [isBlacklistAlertShown, setIsBlacklistAlertShown] = useState(false);

  return (
    <Fragment>
      <Row className="vendor-details" gutter={[24, 24]}>
        <Col span={6}>
          <VendorDetailsCard
            loading={loading}
            vendor={vendor?.vendorDetails}
            vendorType="approved"
            editHandler={openVendorForm}
            blacklistHandler={() => setIsBlacklistAlertShown(true)}
            refreshList={refreshData}
          />
        </Col>
        <Col span={12}>
          <VendorInfoCard
            vendorType="approved"
            refreshList={refreshData}
            loading={loading}
            vendor={vendor?.vendorDetails}
            contactPerson={vendor?.contactPersonDetails as ContactPersonDetails}
          />
        </Col>
        <Col span={6}>
          <DocumentsCard
            loading={loading}
            data={[
              {
                label: "COMMERCIAL REGISTRATION",
                document: [vendor?.supportDocuments?.commercialRegistration]
              },
              {
                label: "TAX CARD",
                document: [vendor?.supportDocuments?.taxCard]
              },
            ]}
            className="vendor-details-documents"
            vendor={vendor}
          />
        </Col>
        <Col span={24}>
          <VendorPersonalDetailsCard
            loading={loading!}
            vendor={vendor}
            editHandler={openVendorForm}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default VendorDetails;
