import React, { FC, SetStateAction, useEffect, useState } from "react";
import TableComponent from "../../../../shared/components/TableComponent";
import "./customerList.scss";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import CustomerService from "../../../../services/CustomerService/customer.service";
import NoCustomersImg from "../../../../assets/empty/customers.svg";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import moment from "moment";
import { StateProps } from "../../../../models/Meta/meta.model";

interface CustomerListProps {
  setCount: React.Dispatch<React.SetStateAction<number>>
}

const CustomerList: FC<CustomerListProps> = (props) => {
  const { setCount } = props
  const { loading, customers, fetchCustomersList, paginations, } = CustomerService();
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = location.hash === "#list" ? stateValue?.pageNumber as number : null
  const [page, setPage] = useState(pageNumber ?? 1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()

  const handlePageChange = (page:number) => {
    location.state = null
    setPage(page)
    fetchCustomersList({page,...sortOptions, search})
  }

  useEffect(() => {
    location.hash === "#list" &&
    fetchCustomersList({page,...sortOptions, search});
  }, [location.hash]);

  useEffect(() => {
    setCount(paginations?.totalCount as number);
  }, [paginations]);


  return (
    <div className="customer-list">
      <TableComponent
      pageNumber={pageNumber ?? page}
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      onChange={fetchCustomersList}
      onSort={(data)=>{
        setSortOptions(data)
      }}
      filtersSorts={{page, search}}
      onRow={(record)=>
        navigate({
          pathname: generatePath(AppRoutes.CUSTOMERS_LIST_VIEW, {
            customerId: record?.id,
          }),
        }, 
        {state: page}
        )
      }
        search={{
          placeholder: "Search customer by name or national id or phone number",
          onSearch: (value) => {
            setSearch(value)
            fetchCustomersList({
              search: value
            });
          },
        }}
        loading={loading}
        columns={[
          {
            title: "Customer Name",
            dataIndex: "fullName",
            key: SortColumnTypes.CUSTOMER_NAME,
            sorter: true,
            render: (text) => text
          },
          {
            title: "Name from Id",
            dataIndex: "nameFromId",
            width: 130,
            render: (text) => text
          },
          {
            title: "National ID",
            dataIndex: "nationalId",
            key: "nationalId",
            width: 80,
          },
          {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            render: (text, record) => <span>{record?.countryCode?.isdCode ?? ""} {text}</span>
          },
          {
            title: "Level",
            dataIndex: "plan",
            key: SortColumnTypes.PLAN,
            sorter: true,
          },
          {
            title: "Purchasing Power",
            dataIndex: "maxPurchasePower",
            key: SortColumnTypes.MAX_PURCHASE_POWER,
            sorter: true,
            render: (text, record) => (
              <span className="vendor__name">
                LE &nbsp;
                {text}
              </span>
            ),
          },
          {
            title: "Processing Fee",
            dataIndex: "processingFee",
            key: SortColumnTypes.PROCESSING_FEE,
            sorter: true,
            render: (text, record) => <span>{text}%</span>
          },
          {
            title: "Status",
            dataIndex: "status",
            key: SortColumnTypes.CUSTOMER_STATUS,
            render: (text) => <BadgeComponent type={text===BadgeTypes.APPROVED?BadgeTypes.ACTIVE:BadgeTypes.INACTIVE} />,
            sorter: true,
          },
          {
            title: "Approval date",
            dataIndex: "onboardedDate",
            key: SortColumnTypes.ONBOARDED_DATE,
            render: (text, record) => <span>{moment(record?.onboardedDate).format("DD MMM YYYY hh:mm a")}</span>,
            sorter: true,
            width: 80,
          },
          {
            title: "Kyc Status",
            dataIndex: "kycStatus",
            key: "kycStatus",
            width: 130,
            render: (text) => <BadgeComponent type={(text === BadgeTypes.APPROVED ? BadgeTypes.KYC_APPROVED : text === BadgeTypes.FAILED ? BadgeTypes.KYC_FAILED : text === BadgeTypes.KYC_PENDING && BadgeTypes.KYC_PENDING) as BadgeTypes} />
          }
        ]}
        data={customers}
        empty={{
          img: NoCustomersImg,
          text: "No customer data",
        }}
      />
    </div>
  );
};

export default CustomerList;
