export const AppRoutes = {
  AUTH: "/auth",
  LOGIN: "/auth/login",
  OTP: "/auth/otp",
  FORGOT_PASSWORD: "/auth/forgot-password",
  NEW_PASSWORD: "/auth/new-password",
  HOME: "/",
  FILE_VIEW: "/file/:fileId",
  APP_COMPONENTS: "/app-components",
  CUSTOMERS: "/customers",
  CUSTOMER_LIST: "/customers/list",
  CUSTOMERS_LIST_VIEW: "/customers/list/:customerId",
  APPROVE_CUSTOMERS_VIEW: "/customers/approve-customers/:customerId",
  REJECTED_CUSTOMERS_VIEW: "/customers/rejected-customers/:customerId",
  APPROVE_CUSTOMERS_EDIT_VIEW: "/customers/approve-customers/:customerId/edit",
  WAITLISTED_CUSTOMERS_VIEW: "/customers/waitlisted-customers/:customerId",
  INCOMPLETE_ONBOARDING_CUSTOMERS_VIEW: "/customers/incomplete-onboarding-customers/:customerId",
  APPROVE_DOCUMENTS_VIEW: "/customers/approve-documents/:documentId",
  APPROVE_CUSTOMERS: "/customers/approve-customers",
  APPROVE_DOCUMENTS: "/customers/approve-documents",
  VENDORS: "/vendors",
  APPROVE_VENDORS: "/vendors/approve-vendors",
  APPROVE_VENDORS_VIEW: "/vendors/approve-vendors/:vendorId",
  APPROVE_INSTORES: "/vendors/approve-stores",
  VENDOR_LIST: "/vendors/list",
  VENDOR_REJECTED_DETAILS: "/vendors/rejected/:vendorName/:vendorId/details",
  VENDOR_LIST_VIEW: "/vendors/list/:vendorName/:vendorId",
  VENDOR_DETAILS: "/vendors/list/:vendorName/:vendorId/details",
  VENDOR_STORE_PROMOTIONS: "/vendors/list/:vendorName/:vendorId/store-promotions",
  VENDOR_INSTORES: "/vendors/list/:vendorName/:vendorId/stores",
  VENDOR_INSTORES_CREATE: "/vendors/list/:vendorName/:vendorId/stores/create",
  VENDOR_INSTORES_EDIT: "/vendors/list/:vendorName/:vendorId/stores/:instoreId/edit",
  VENDOR_INSTORES_VIEW: "/vendors/list/:vendorName/:vendorId/stores/:instoreId",
  VENDOR_INSTORES_ADD_POS: "/vendors/list/:vendorName/:vendorId/stores/:instoreId/create-pos",
  VENDOR_SETTLEMENTS: "/vendors/list/:vendorName/:vendorId/settlements",
  VENDOR_PURCHASE_CONFIG: "/vendors/list/:vendorName/:vendorId/purchase-config",
  DASHBOARD: "/dashboard",
  PURCHASES: "/purchases",
  PURCHASES_VIEW: "/purchases/:purchaseId",
  SETTLEMENTS: "/settlements",
  SETTLEMENTS_VENDOR: "/settlements/vendor",
  SETTLEMENTS_VENDOR_PENDING: "/settlements/vendor/pending",
  SETTLEMENTS_VENDOR_PAST: "/settlements/vendor/past",
  SETTLEMENTS_PAYMENT_COLLECTION: "/settlements/payment-collection",
  SETTLEMENTS_PAYMENT_COLLECTION_PENDING: "/settlements/payment-collection/pending",
  PARTNER_FEE_SETTLEMENTS: "/settlements/partner-fee",
  PENDING_SETTLEMENTS: "/settlements/pending",
  PAST_SETTLEMENTS: "/settlements/past-settlements",
  PAST_SETTLEMENTS_VIEW: "/settlements/past-settlements/:settlementId",
  PARTNER_PAST_SETTLEMENTS_VIEW: "/settlements/past-partner-settlements/:settlementId",
  COLLECTION_PAST_SETTLEMENTS_VIEW: "/settlements/past-payment-collection/:settlementId",
  PAYMENTS: "/payments",
  PAYMENT_LOGS: "/payments/payment-logs",
  OUTSTANDING_PAYMENTS: "/payments/outstanding-payments",
  PAYMENT_VIEW: "/payments/:transactionId",
  DEALL_USERS: "/rise-users",
  CONFIGURATION: "/configuration",
  MONITORING: "/monitoring"
};
