import React, {FC, Fragment, ReactElement, useEffect, useState} from "react";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import "./approveCustomersControllers.scss";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import {CustomerModel} from "../../../../../../models/Customer/customer.model";
import {BadgeTypes} from "../../../../../../enums/badgeTypes";
import {generatePath, useNavigate} from "react-router-dom";
import InputField from "../../../../../../shared/components/InputField";
import {Form, Formik, FormikValues} from "formik";
import {approveCustomerFormValidation, neighbourhoodFormValidation} from "./approveCustomerFormValidation";
import CustomerDetailsForm from "../../../CustomerDetailsForm";
import {LocalStorage} from "../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../models/User/user.model";
import {Col, Drawer, Row, Tooltip} from "antd";
import {AppRoutes} from "../../../../../../routes/routeConstants/appRoutes";
import MenuComponent from "../../../../../../shared/components/MenuComponent";
import IconComponent from "../../../../../../shared/components/IconComponent";
import deleteAlert from "../../../../../../assets/images/delete-alert.png"
import DropdownField from "../../../../../../shared/components/DropdownField";
import MetaService from "../../../../../../services/MetaService/meta.service";
import {AcquisitionDefinitionTypes} from "../../../../../../definitions/acquisitionTypes";
import {AcquisitionTypes} from "../../../../../../enums/acquisitionTypes";
import alertIcon from "../../../../../../assets/images/alert-for-refund-creation.svg"
import TooltipComponent from "../../../../../../shared/components/TooltipComponent";
import UploadComponent from "../../../../../../shared/components/UploadComponent";
import CardSkeletonComponent from "../../../../../../shared/components/CardSkeletonComponent";
import {getInfoIcon} from "../../../../../../shared/utils/getInfoIcon";
import infoIcon from "../../../../../../assets/images/icon-grey.svg"
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import Notification from "../../../../../../shared/components/Notification";
import { approvalFormValidationSchema } from "../../../CustomerDetailsForm/customerDetailsFormValidation";
import Approvaldrawer from "../../../../../../shared/components/ApprovalDrawer";

interface ApproveCustomersControllersProps {
  customer: CustomerModel | undefined;
  handleRefresh: () => void
}

interface DocumentDetails {
  customerAttachmentId?: string | number;
  s3Url?: string;
  filename?: string;
  fileType?: string;
  attachmentType?: string;
}

const ApproveCustomersControllers: FC<ApproveCustomersControllersProps> = (
  props
) => {
  const { customer, handleRefresh } = props;
  const [showRejectAlert, setRejectAlertVisibility] = useState(false);
  const [showApproveAlert, setApproveAlertVisibility] = useState(false);
  const [showVerifyModal, setVerifyModalVisibility] = useState(false);
  const [showManualApproveModal, setShowManualApproveModal] = useState(false);
  const [dataIncomplete, setDataIncomplete] = useState(false)
  const [docVerificationIncomplete, setDocVerificationIncomplete] = useState(false)
  const [editCustomerDetails, setEditCustomerDetails] = useState(false);
  const [saveCustomerDetails, setSaveCustomerDetails] = useState(false);
  const [isApproveFlow, setIsApproveFlow] = useState(false);
  const { updateCustomerDetails, manualApproveCustomer, loading, deleteCustomerData, fetchNeighbourhoodList, neighbourhood, updateNeighbourhood } = CustomerService();
  const {fetchApprovalReasonList, approvalReasonList , fetchRejectionReasonList, rejectionReasonList, getRiseConfig, riseConfigMeta } = MetaService()
  const [verificationStatus, setVerificationStatus] = useState<"validating" | "verifying" | "calculating" | "verified">();
  const [isBlacklistAlertShown, setIsBlacklistAlertShown] = useState(false);
  const [isPermanentlyDeleteAlertShown, setIsPermanentlyDeleteAlertShown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [systemApprovalDecision, setSystemApprovalDecision] = useState<boolean>()
  const [verifyNeighbourhood, setVerifyNeighbourhood] = useState(false)
  const [documentPendingCount, setDocumentPendingCount] = useState(0)
  const [neighborhoodDetailsMissing, setNeighborhoodDetailsMissing] = useState(false)
  const [showRecommendationWarning, setShowRecommendationWarning] = useState(false)
  const [engineDecisionDrawerVisible, setEngineDecisionDrawerVisible] = useState(false)
  const [pendingDocumentDetails, setPendingDocumentDetails] = useState<DocumentDetails | null>()
  const [isDocVerificationIncomplete, setIsDocVerificationIncomplete] = useState(false)
  const [verifyNeighbourhoodDrawerVisible, setverifyNeighbourhoodDrawerVisible] = useState(false)
  const [decision, setDecision] = useState<string>('')
  const [redirection, setRedirection] = useState<'approval' | 'rejection' | undefined>()

  const navigate = useNavigate();
  const user = LocalStorage.getItem('USER') as UserModel
  const configMeta = riseConfigMeta?.configJson?.thresholdCreditScore

  const [rejectDrawerVisible, setRejectDrawerVisible] = useState(false)
  const [approveDrawerVisible, setApproveDrawerVisible] = useState(false)

  const isApproved = customer?.status === BadgeTypes.APPROVED || customer?.status === BadgeTypes.INACTIVE
  // Changed for au10tix earlier user to be failed_verification
  const isApprovedOrWaitlistOrRejected = customer?.status === BadgeTypes.APPROVED || customer?.status === BadgeTypes.WAITLISTED || customer?.status === BadgeTypes.REJECTED
  const isWaitlisted = customer?.status === BadgeTypes.WAITLISTED
  const isPendingApproval = customer?.status === BadgeTypes.PENDING_APPROVAL
  const isRejected = customer?.status === BadgeTypes.REJECTED

  const openDrawer = () => setApproveDrawerVisible(true);
  const closeDrawer = () => setApproveDrawerVisible(false);

  const docApprovalTitle = "Please verify additional document uploaded"
  const docApprovalDescription = "To reject the customer please approve or reject the additional document uploaded by the customer"
  const handleBlacklist = (rejectedReasonIds: number[]) => {
    setIsBlacklistAlertShown(true);
    updateCustomerDetails(
      {
        status: customer?.status === BadgeTypes.APPROVED ? BadgeTypes.INACTIVE : BadgeTypes.APPROVED,
        id: customer?.id,
        rejectedReasonIds
      },
      () => {
        setIsBlacklistAlertShown(false);
        handleRefresh()
      }
    );
  };

  // TODO: Might need in future sprints
  // const handleVerify = () => {
  //   setVerificationStatus("validating");
  //   setVerifyModalVisibility(true);
  //   if (customer?.id)
  //     verifyCustomerNationalId(`${customer?.id}`).then(() => {
  //       setVerificationStatus("verifying")
  //       verifyCustomerProfile(`${customer?.id}`).then(() => {
  //         setVerificationStatus("calculating")
  //         calculateCustomerPurchasingPower(`${customer?.id}`).then(() => {
  //           setVerificationStatus("verified")
  //           navigate(-1)
  //           setVerifyModalVisibility(false)
  //         })
  //       })
  //     })
  //     .catch(() => {
  //       setVerifyModalVisibility(false)
  //       handleRefresh()
  //     });
  // };

  const handleApprove = (comment: string, commentId: number[]) => {
    manualApproveCustomer(
        {
            status: BadgeTypes.APPROVED,
            id: customer?.id,
            approvalReason: comment,
            approvalReasonIds: commentId
        },
        customer?.id?.toString() ?? "",
        () => {
            setApproveAlertVisibility(false);
            setApproveDrawerVisible(false);
            navigate(-1);
        }
    );
  };

    const handleReject = (comment: string, commentId: number[], acquisitionChannel: string | undefined) => {
        if(commentId.length > 0 && comment){
            updateCustomerDetails(
                {
                    status: BadgeTypes.REJECTED,
                    id: customer?.id,
                    rejectedReason: comment,
                    rejectedReasonIds: commentId,
                    acquisitionChannel: acquisitionChannel ?? "organic"
                },
                () => {
                    setRejectAlertVisibility(false);
                    setRejectDrawerVisible(false);
                    navigate(-1);
                }
            );
        }
    };

  const verifyDocumentApproval = () => {
    setRedirection('rejection')
    systemApprovalDecision ? setShowRecommendationWarning(true) : isMobile ? setRejectDrawerVisible(true) : setRejectAlertVisibility(true) 
  }

  const checkSystemRecommendation = () => {
    setRedirection('approval');
    (isPendingApproval || isRejected) && setIsApproveFlow(true);
    !systemApprovalDecision && customer?.status === BadgeTypes.PENDING_APPROVAL ? setShowRecommendationWarning(true) : isMobile ? navigate(generatePath(AppRoutes.APPROVE_CUSTOMERS_EDIT_VIEW, { customerId: customer?.id?.toString() }), { state: { isApprove: true, customer, isSave: false, redirection: 'approval' } }) : setEditCustomerDetails(true)
  }

  const documentTypes = [
    'bankStatement',
    'vehicleCard',
    'sportsClubMembership',
    'paySlip',
    'creditCardStatement',
    'propertyContract',
    'employmentLetter',
    'employmentLetterMedicalCard',
    'paySlipBankStatement',
    'medicalCard',
    'studentCard'
] as const;

type DocumentType = (typeof documentTypes)[number];

const getPendingDocumentDetails = (documentType: DocumentType): DocumentDetails | null => {
    const document = customer?.[documentType]?.[0];
    if (document?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION) {
        return {
            customerAttachmentId: document.customerAttachmentId,
            s3Url: document.s3Url,
            filename: document.filename,
            fileType: document.fileType,
            attachmentType: document.attachmentType,
        };
    }
    return null;
};

const checkPendingDocs = (): DocumentDetails | null => {
    for (const documentType of documentTypes) {
        const details = getPendingDocumentDetails(documentType);
        if (details) {
            return details;
        }
    }
    return null;
};

const isDocumentPending = (documentType: DocumentType): boolean => {
  const document = customer?.[documentType]?.[0];
  return document?.attachmentStatus === BadgeTypes.PENDING_VERIFICATION;
};

const countPendingDocuments = (): number => {
  let pendingCount = 0;
  for (const documentType of documentTypes) {
      if (isDocumentPending(documentType)) {
          pendingCount++;
      }
  }
  return pendingCount;
};

const checkIfMandatoryFieldsPresent = (customer: CustomerModel) => {
  const isfirstNameEmpty = !customer.firstName
  const islastNameEmpty = !customer.lastName
  const isnationalIdNumberEmpty = !customer.nationalIdNumber
  const isdateOfBirthEmpty = !customer.dateOfBirth
  const isaddressEmpty = !customer.address

  return (isfirstNameEmpty || islastNameEmpty || isnationalIdNumberEmpty || isdateOfBirthEmpty || isaddressEmpty)
}

useEffect(() => {
  setNeighborhoodDetailsMissing(customer?.neighbourhood === null ||  customer?.neighbourhood === undefined)
}, [customer])

useEffect(() => {
  setDocumentPendingCount(countPendingDocuments())
  fetchNeighbourhoodList()
}, [])

useEffect(() => {
  setIsDocVerificationIncomplete(pendingDocumentDetails !== null)
}, [pendingDocumentDetails])

const redirectToFileView = () => {
    if (pendingDocumentDetails) {
        const { customerAttachmentId, s3Url, filename, fileType, attachmentType } = pendingDocumentDetails;
        navigate(
            generatePath(AppRoutes.FILE_VIEW, { fileId: `${customerAttachmentId}` }),
            { state: { s3Url, filename, fileType, attachmentType, customerStatus: customer?.status, from: 'customer' } }
        );
    }
};

  const controllerButton = (isEdit: boolean) => (
    (isEdit) ?
      <>
      <ButtonComponent
        size="medium"
        type="primary"
        additionalTypes={isApprovedOrWaitlistOrRejected ? "primary" : "affirmative"}
        onClick={checkSystemRecommendation}
      >
        {(isApproved || isWaitlisted) ? "Edit Details" : isRejected ? "Edit & Approve" :  "Approve"}

      </ButtonComponent></> :
      <div className="right-button">
      {isPendingApproval ? 
      <ButtonComponent
        size="medium"
        additionalTypes="negative"
        onClick={verifyDocumentApproval}
      >
        Reject
      </ButtonComponent> : <></> }
        {isApproved && (isMobile ?
          <ButtonComponent
            size="medium"
            additionalTypes="negative"
            onClick={() => setIsBlacklistAlertShown(true)}
          >
            {customer?.status === BadgeTypes.APPROVED ? "Deactivate" : "Activate"} Customer
          </ButtonComponent> :
          <MenuComponent
            dropDownPlacement="topCenter"
            dropdownClassName="customer-reject-menu"
            menu={[
              {
                title: `${customer?.status === BadgeTypes.APPROVED ? "Deactivate" : "Activate"} Customer`,
                clickHandler: () => setIsBlacklistAlertShown(true),
                icon: <IconComponent name="icon-blacklist" />,
              },
              {
                title: "Permanently delete customer",
                clickHandler: () => setIsPermanentlyDeleteAlertShown(true),
                icon: <img src={deleteAlert} alt="delete" />,
              },
            ]}
          />)}
      </div>
  )

  useEffect(() => {
    fetchRejectionReasonList()
    fetchApprovalReasonList()
    getRiseConfig()
    setPendingDocumentDetails(checkPendingDocs())
    
    if (window.innerWidth <= 420) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    //Engine decision should be Reject if Credit score < Threshold score and if KYC status is failed
    setSystemApprovalDecision((customer?.creditScore ?? 0) >= (riseConfigMeta?.configJson?.thresholdCreditScore ?? 0) && customer?.kycStatus === BadgeTypes.APPROVED)
  }, [riseConfigMeta])

  useEffect(() => {
   setDecision(systemApprovalDecision ? "approve" : "reject")
  }, [systemApprovalDecision])

  const addressDocumentsVerified = !isDocVerificationIncomplete && !neighborhoodDetailsMissing
  const detailsNotverified = isDocVerificationIncomplete && neighborhoodDetailsMissing
  const pendingApprovalLargeScreen = !isMobile && isPendingApproval
  const applyGutter = isDocVerificationIncomplete && neighborhoodDetailsMissing && isMobile && isPendingApproval
  const pendingApprovalSmallScreen = isMobile && isPendingApproval
  const isKycPending = customer?.kycStatus === BadgeTypes.KYC_PENDING

  const neighbourhoodElement = (values: FormikValues, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) =>  
  {
  return (<>
    <UploadComponent
      isUploading={false}
      canReupload={false}
      canRotate={false}
      fileId={customer?.nationalIdFrontId!}
      fileType={customer?.nationalIdFront?.[0]?.fileType!}
      fileName={customer?.nationalIdFront?.[0]?.filename!}
      accept={"image/png, image/jpg, image/jpeg"}
      fileUrl={customer?.nationalIdFront?.[0]?.s3Url!}
      onUpload={() => { }}
      title="Upload Document"
      type="document-preview"
      label="National ID - Front"
  />
  <DropdownField
      label="Neighbourhood"
      placeholder="Select"
      name="neighbourhoodId"
      value={values?.neighbourhoodId}
      options={neighbourhood}
      onChange={(value) => {
        setFieldValue("neighbourhoodId", value);
        setFieldValue("neighbourhood", value);
      }}
  />
  </>)}

  return (
    <Formik
      initialValues={{ comment: "", rejectionComment: "", approvalComment: "", approvalReasonId: [], rejectionReasonId: [], acquisitionChannel: "", neighbourhood: null }}
      onSubmit={() => { }}
      validationSchema={approveCustomerFormValidation}
    >
      {({ values, setFieldValue }) => (
        <Fragment>
          <ModalComponent
            type={isMobile ? 'large' : "alert-large"}
            title="Some of the customer details are empty. Are you sure you want to continue?"
            visible={dataIncomplete}
            closeHandler={() => setDataIncomplete(false)}
            successProps={{
              title: "Continue",
              loading: loading,
              clickHandler: () => {
                setDataIncomplete(false)
              },
            }}
            cancelProps={{
              title: "Close",
              clickHandler: () => setDataIncomplete(false),
            }}
          />
          <Formik onSubmit={() => {}} validationSchema={approvalFormValidationSchema} initialValues={{ approvalComment: "", approvalReasonId: [] }} enableReinitialize>
          {({values, setFieldValue, handleSubmit, errors}) => (
            <Form>
              <ModalComponent
                type="alert"
                alert={{
                    title: "Are you sure you want to approve the customer ?",
                    buttonType: "affirmative"
                }}
                visible={showApproveAlert}
                closeHandler={() => setApproveAlertVisibility(false)}
                successProps={{
                    title: "Approve",
                    loading: loading,
                    clickHandler: () => {
                      handleSubmit()
                      if (!Object.keys(errors).length) { handleApprove(values.approvalComment, values.approvalReasonId); }
                    },
                }}
                cancelProps={{
                    title: "Close",
                    clickHandler: () => setApproveAlertVisibility(false),
                }}
              >
                <DropdownField
                    mode="multiple"
                    maxTagCount="responsive"
                    optionFilterProp="label"
                    allowClear
                    label="Reason for approval"
                    placeholder="Select"
                    name="approvalReasonId"
                    value={values?.approvalReasonId}
                    options={approvalReasonList}
                    onChange={(value) => {
                        setFieldValue("approvalReasonId", value);
                    }}
                />
                <InputField textArea type="text" name="approvalComment" label="Comment" placeholder="Enter" />
              </ModalComponent>
            </Form>
          )}
          </Formik>

          <ModalComponent
            type="alert"
            alert={{
              title: "Are you sure you want to reject the customer ?",
              buttonType: "negative"
            }}
            visible={showRejectAlert}
            closeHandler={() => setRejectAlertVisibility(false)}
            successProps={{
              title: "Reject",
              loading: loading,
              clickHandler: () => {
                handleReject(values.rejectionComment, values.rejectionReasonId, values.acquisitionChannel);
              },
            }}
            cancelProps={{
              title: "Close",
              clickHandler: () => setRejectAlertVisibility(false),
            }}
          >
            <DropdownField
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="label"
              allowClear
              label="Reason for rejection"
              placeholder="Select"
              name="rejectionReasonId"
              value={values?.rejectionReasonId}
              options={rejectionReasonList}
              onChange={(value) => {
                setFieldValue("rejectionReasonId", value);
              }}
            />
            <InputField textArea type="text" name="rejectionComment" label="Comment" placeholder="Enter" />
            <DropdownField
              label="Acquisition channel"
              placeholder="Select"
              name="acquisitionChannel"
              defaultValue="Organic"
              value={values?.acquisitionChannel}
              options={[
                  {
                      value: AcquisitionTypes.ORGANIC,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.ORGANIC],
                  },
                  {
                      value: AcquisitionTypes.RISE_REFERRAL_PROGRAM,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.RISE_REFERRAL_PROGRAM],
                  },
                  {
                      value: AcquisitionTypes.USHER,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.USHER],
                  },
                  {
                      value: AcquisitionTypes.AMBASSADOR,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.AMBASSADOR],
                  },
                  {
                      value: AcquisitionTypes.CORPORATE_DEAL,
                      label: AcquisitionDefinitionTypes[AcquisitionTypes.CORPORATE_DEAL],
                  },
              ]}
              onChange={(value) => setFieldValue("acquisitionChannel", value)}
            />
          </ModalComponent>

          <Formik initialValues={{ neighbourhood: undefined }} onSubmit={() => {}} validationSchema={neighbourhoodFormValidation} enableReinitialize>
          {({values, setFieldValue, handleSubmit, errors}) => (
            <Form>
              <ModalComponent
                type="medium"
                visible={verifyNeighbourhood}
                closeHandler={() => setVerifyNeighbourhood(false)}
                successProps={{
                  title: "Update",
                  loading: loading,
                  clickHandler: () => { 
                    handleSubmit();
                    if (!Object.keys(errors).length && values?.neighbourhood) { 
                     updateNeighbourhood(customer?.id?.toString() || "", values?.neighbourhood!, handleRefresh).then(() => setVerifyNeighbourhood(false))
                    }
                  }
                }}
                cancelProps={{
                  title: "Close",
                  clickHandler: () => setVerifyNeighbourhood(false),
                }}
              >
                <div className="verify-neighbourhood-modal">
                  <div className="neighbourhood-header">Update customer address</div>
                  <div className="neighbourhood-description">Customer neighbourhood wasn’t captured automatically. Please update manually by looking at the ID below</div>
                  {loading ? <CardSkeletonComponent rows={5} /> : <>
                    {neighbourhoodElement(values, setFieldValue)}
                  </>
                  }
                </div>
              </ModalComponent>
            </Form>
          )}
          </Formik>

          <Drawer
            placement="bottom"
            onClose={() => setverifyNeighbourhoodDrawerVisible(false)}
            visible={verifyNeighbourhoodDrawerVisible}
            key="left"
            className="verify-address-drawer"
          >
            <Formik initialValues={{ neighbourhood: undefined }} onSubmit={() => {}} validationSchema={neighbourhoodFormValidation} enableReinitialize>
              {({values, setFieldValue, handleSubmit, errors}) => (
                <Form>
                  <h3 className="verify-address-drawer-header">Update customer address</h3>
                  <div>Customer neighbourhood wasn’t captured automatically. Please update manually by looking at the ID below</div>
                  <div className="content">
                    {loading ? <CardSkeletonComponent rows={5} /> : <>
                      {neighbourhoodElement(values, setFieldValue)}
                    </>
                    }
                  </div>
                  <div className="controllers">
                    <div className="action-1">
                      <ButtonComponent
                        type={"primary"}
                        htmlType={"submit"}
                        size="fixed"
                        onClick={() => {
                          handleSubmit();
                          if(!Object.keys(errors).length && values?.neighbourhood){
                            updateNeighbourhood(customer?.id?.toString() || "", values?.neighbourhood!, handleRefresh).then(() => setverifyNeighbourhoodDrawerVisible(false))
                          }
                        }}
                        loading={loading}
                      >
                        Update
                      </ButtonComponent>
                    </div>
                    <div className="action-1">
                      <ButtonComponent
                        type="default"
                        additionalTypes="secondary"
                        size="fixed"
                        onClick={() => setverifyNeighbourhoodDrawerVisible(false)}
                      >
                        Close
                      </ButtonComponent>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Drawer>
          {customer && <Approvaldrawer customer={customer} visible={approveDrawerVisible} openDrawer={openDrawer} closeDrawer={closeDrawer} />}
          <Drawer
            placement="bottom"
            onClose={() => setRejectDrawerVisible(false)}
            visible={rejectDrawerVisible}
            key="left"
            className="reject-customer-drawer"
          >
            <h3 className="reject-drawer-header">Are you sure you want to reject the customer ?</h3>
            <DropdownField
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="label"
              allowClear
              label="Reason for rejection"
              placeholder="Select"
              name="rejectionReasonId"
              value={values?.rejectionReasonId}
              options={rejectionReasonList}
              onChange={(value) => {
                setFieldValue("rejectionReasonId", value);
              }}
            />
            <InputField className="reject-drawer-input" textArea type="text" name="rejectionComment" label="Comment" placeholder="Enter" />
            <DropdownField
              label="Acquisition channel"
              defaultValue="Organic"
              placeholder="Select"
              name="acquisitionChannel"
              value={values?.acquisitionChannel}
              options={[
                {
                  value: AcquisitionTypes.ORGANIC,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.ORGANIC],
              },
              {
                  value: AcquisitionTypes.RISE_REFERRAL_PROGRAM,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.RISE_REFERRAL_PROGRAM],
              },
              {
                  value: AcquisitionTypes.USHER,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.USHER],
              },
              {
                  value: AcquisitionTypes.AMBASSADOR,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.AMBASSADOR],
              },
              {
                  value: AcquisitionTypes.CORPORATE_DEAL,
                  label: AcquisitionDefinitionTypes[AcquisitionTypes.CORPORATE_DEAL],
              },
              ]}
              onChange={(value) => setFieldValue("acquisitionChannel", value)}
            />
            <Row gutter={12} className="reject-drawer-controllers">
              <Col span={12}>
                <ButtonComponent
                  type="default"
                  additionalTypes="secondary"
                  size="fixed"
                  onClick={() => setRejectDrawerVisible(false)}
                >
                  Close
                </ButtonComponent>
              </Col>
              <Col span={12}>
                <ButtonComponent
                  type={"primary"}
                  htmlType={"submit"}
                  size="fixed"
                  additionalTypes="negative"
                  onClick={() => handleReject(values.rejectionComment, values?.rejectionReasonId, values.acquisitionChannel)}
                  loading={loading}
                >
                  Reject
                </ButtonComponent>
              </Col>
            </Row>
          </Drawer>
          <Drawer
            placement="bottom"
            onClose={() => setEngineDecisionDrawerVisible(false)}
            visible={engineDecisionDrawerVisible}
            key="left"
            className="engine-decision-drawer"
          >
            <div className="engine-decision-drawer-header">Engine Decision:</div>
            <div className="engine-decision-drawer-content">This setting recommends approval or reject based on the credit score of the user.</div>
          </Drawer>
          <ModalComponent
            type="alert"
            visible={isBlacklistAlertShown}
            closeHandler={() => setIsBlacklistAlertShown(false)}
            successProps={{
              title: customer?.status === BadgeTypes.APPROVED ? "Deactivate" : "Activate",
              loading: loading,
              clickHandler: () => {
                handleBlacklist(values?.rejectionReasonId);
              },
            }}
            cancelProps={{
              title: "Close",
              clickHandler: () => {
                setIsBlacklistAlertShown(false);
              },
            }}
            alert={{
              title: `Are you sure you want to ${customer?.status === BadgeTypes.APPROVED ? "deactivate" : "activate"
                } the customer ?`,
              description:
                customer?.status === BadgeTypes.APPROVED
                  ? "Customer will not be able to make purchases"
                  : "",
              ...(customer?.status === "inactive"
                ? { buttonType: "affirmative" }
                : {}),
            }}
          >
            {customer?.status === BadgeTypes.APPROVED &&
            <DropdownField
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="label"
              allowClear
              label="Reason for deactivation"
              placeholder="Select"
              name="rejectionReasonId"
              value={values?.rejectionReasonId}
              options={rejectionReasonList}
              onChange={(value) => {
                setFieldValue("rejectionReasonId", value);
              }}
            />}
          </ModalComponent>

          <ModalComponent
            type="alert"
            visible={isPermanentlyDeleteAlertShown}
            closeHandler={() => setIsPermanentlyDeleteAlertShown(false)}
            successProps={{
              title: "Yes, Delete",
              loading: loading,
              clickHandler: () => {
                deleteCustomerData(customer?.id?.toString() ?? "", () => navigate(-1))
              },
            }}
            cancelProps={{
              title: "No",
              clickHandler: () => {
                setIsPermanentlyDeleteAlertShown(false);
              },
            }}
            alert={{
              title: "Permanently delete customer",
              description: "User will be permanently deleted from the database. Are you sure you want to continue?",
            }}
          />

          <ModalComponent
            type={isMobile ? 'small' : "alert-large"}
            title={isMobile ? docApprovalTitle : "" }
            description={isMobile ? docApprovalDescription : ""}
            alert={{
              title: docApprovalTitle,
              description: docApprovalDescription,
              buttonType: "primary"
            }
            }
            visible={docVerificationIncomplete}
            closeHandler={() => setDocVerificationIncomplete(false)}
            successProps={{
              title: "View document",
              loading: loading,
              clickHandler: () => {
                setDocVerificationIncomplete(false)
              },
            }}
          />

            <ModalComponent
                type={isMobile ? 'small' : "alert-large"}
                visible={showRecommendationWarning}
                closeHandler={() => setShowRecommendationWarning(false)}
                successProps={{
                    title: "Yes, Proceed",
                    loading: loading,
                    clickHandler: () => {
                        setShowRecommendationWarning(false);
                        const dataNotPresent: boolean = checkIfMandatoryFieldsPresent(customer!)
                        if (redirection === 'approval') {
                          if (!dataNotPresent){
                            if (isMobile) {
                              setApproveDrawerVisible(true);
                            } else {
                                setApproveAlertVisibility(true)
                            }
                          }
                          else{
                            Notification({
                              message: 'Mandatory fields empty!',
                              description: "Please fill all the mandatory fields",
                              type: NotificationTypes.ERROR,
                            });

                            isMobile 
                            ? 
                            navigate(
                                generatePath(AppRoutes.APPROVE_CUSTOMERS_EDIT_VIEW, {customerId: customer?.id?.toString()}),
                                {state: {isApprove: true, customer, isSave: false, redirection: 'approval' }}
                            )
                            : setEditCustomerDetails(true);
                          }
                        } else if (redirection === 'rejection') {
                            if (isMobile) {
                                setRejectDrawerVisible(true);
                            } else {
                                setRejectAlertVisibility(true);
                            }
                        }

                    },
                }}
            >
                <div className="alert-info">
                    <img src={alertIcon} alt="alert-refund"/>
                    <h3>{`${systemApprovalDecision ? "Reject" : "Approve"} against recommendation`}</h3>
                    <p>{`you are about to ${systemApprovalDecision ? "reject" : "approve"} a user against the system recommendations`}</p>
                </div>
            </ModalComponent>

          {/* TODO: Might need in future sprints */}
          {/* <ModalComponent
        title={
          verificationStatus == "validating"
            ? "Verifying Customer National ID"
            : verificationStatus == "verifying"
              ? "Verifying Customer Profile"
              : verificationStatus == "calculating"
                ? "Calculating Customer Purchasing Power"
                : "Customer Details Verified"
        }
        type="no-controllers"
        visible={showVerifyModal}
        closeHandler={() => {
          setVerifyModalVisibility(false);
          if (verificationStatus == "verified") {
            navigate(-1);
          }
        }}
      >
        <div className="approve-customers-verify-modal__container">
          <LottiePlayerComponent
            className="approve-customers-verify-animation__container"
            width={160}
            height={160}
            data={
              verificationStatus == "verified"
                // ? ValidatingNationalIdAnimation
                // : verificationStatus == "verifying"
                //   ? ProfileVerifyingAnimation
                //   : verificationStatus == "calculating"
                    ? CustomerVerifiedAnimation
                    : ValidatingNationalIdAnimation
            }
          />
          <div className="info">
            {verificationStatus == "verified"
              ? "Customer Account Created"
              : "Please Hold On …"}
          </div>
        </div>
      </ModalComponent> */}

          <CustomerDetailsForm
            visible={editCustomerDetails}
            closeHandler={() => { setEditCustomerDetails(false); setSaveCustomerDetails(false); setIsApproveFlow(false)}}
            openHandler={() => setEditCustomerDetails(true)}
            data={customer}
            successHandler={() => {
              handleRefresh()
              setEditCustomerDetails(false)
            }
            }
            isApprove={isApproveFlow}
            isSave={saveCustomerDetails}
            redirection={redirection}
            openApproveAlertVisibility={() => setApproveAlertVisibility(true)}
            neighbourhoodList={neighbourhood}
          />
          {user?.role !== BadgeTypes.VIEWER && (
            <>
            {!isKycPending && 
              <>
                {pendingApprovalSmallScreen && (neighborhoodDetailsMissing || isDocVerificationIncomplete) ? 
                  <div className="verification-label">
                    Verify above details to approve or reject customer
                  </div>
                : (pendingApprovalSmallScreen && configMeta && !neighborhoodDetailsMissing && !isDocVerificationIncomplete) ?
                  <div className={`system-decision ${decision}`}>
                    <div className={`label ${decision}`}>
                      <img
                        className="info-icon"
                        src={getInfoIcon(decision)}
                        alt=""
                        onClick={() => setEngineDecisionDrawerVisible(true)}
                      />
                      Engine Decision 
                      <span className={`value ${decision}`}>
                        {systemApprovalDecision ? " Approve" : " Reject"}
                      </span>
                    </div>
                  </div> : <></>
                }
              </>}
            <div className={`approve-customers-controllers ${(isKycPending && isMobile && isPendingApproval) && 'hide-controller'}`}>
              <Row align="middle" className="approve-customers-controllers-row" gutter={applyGutter ? [16, 16] : 10}>
                <Col sm={0} xs={0} lg={(pendingApprovalLargeScreen) ? 2 : 0}> {(pendingApprovalLargeScreen && !isKycPending) ? <IconComponent name="icon-edit-blue" onClick={() => {setSaveCustomerDetails(true); setEditCustomerDetails(true);}} /> : <></>}</Col>
                {(!isPendingApproval || (addressDocumentsVerified && isPendingApproval && !isKycPending)) &&
                <>
                <Col sm={11} xs={11} md={12} lg={(pendingApprovalLargeScreen) ? 8 : 20}>
                  {controllerButton(!isMobile)}
                </Col>
                <Col sm={11} xs={11} md={12} lg={(pendingApprovalLargeScreen) ? 7 : 4}>
                  {controllerButton(isMobile)}
                </Col>
                <Col sm={0} xs={0} lg={(pendingApprovalLargeScreen) ? 7 : 0}>
                {(pendingApprovalLargeScreen && !isKycPending) ? 
                <>
                  <div className="label">Engine decision </div>
                  <div className={`value ${decision}`}>{configMeta && (systemApprovalDecision ? " Approve" : " Reject")} {configMeta && (<TooltipComponent content="Engine Decision: This setting recommends approval or reject based on the credit score of the user." iconType={systemApprovalDecision ? "accept" : "reject"} />)}</div>
                </> 
                :  <></>}
                </Col>
                </>
                }
                {!isKycPending && 
                <>
                  {
                    (neighborhoodDetailsMissing && isPendingApproval) &&
                    <>
                      {(!isDocVerificationIncomplete && !isMobile) &&
                        <Col sm={11} xs={11} md={12} lg={10}>
                          <div>Verify neighbourhood to approve or reject customer</div>
                        </Col>
                      }
                      <Col sm={24} xs={24} md={12} lg={10}>
                      <ButtonComponent
                        size="medium"
                        type="primary"
                        additionalTypes="primary"
                        onClick={() => isMobile ? setverifyNeighbourhoodDrawerVisible(true) : setVerifyNeighbourhood(true)}
                      >
                        Verify Address
                      </ButtonComponent>
                      </Col>
                    </>
                  }
                  {(isDocVerificationIncomplete && isPendingApproval) &&
                    <>
                      {(!neighborhoodDetailsMissing && !isMobile) &&
                      <Col sm={11} xs={11} md={10} lg={9}>
                        <div>Verify documents to approve or reject customer</div>
                      </Col>}

                      <Col sm={24} xs={24} md={10} lg={10}>
                      <ButtonComponent
                        size="medium"
                        type="primary"
                        additionalTypes="primary"
                        onClick={() => redirectToFileView()}
                      >
                        {documentPendingCount > 1 ? `Verify Documents (${documentPendingCount})` : 'Verify Document'}
                      </ButtonComponent>
                      </Col>
                    </>
                  }
                  {detailsNotverified && pendingApprovalLargeScreen && <>
                    <Col offset={1} sm={1} xs={1} md={1} lg={1}>
                      <Tooltip title="Verify documents uploaded by customer and update neighbourhood details approve or reject customer ">
                        <span className={`refresh-icon`}>
                          <img src={infoIcon}></img>                    
                        </span>   
                      </Tooltip>
                    </Col>
                  </>}
                </> }
              </Row>
            </div>
            </>
          )
          }
        </Fragment>
      )}
    </Formik>
  );
};

export default ApproveCustomersControllers;
