import React, { useContext, createContext, useMemo, useState, Dispatch, SetStateAction } from "react";
import { UserModel } from "../../models/User/user.model";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";

export interface AuthState {
  authenticated?: boolean;
  user?: UserModel;
}

type SetAuthState = Dispatch<SetStateAction<AuthState>>;

type AuthContentProps = [AuthState, SetAuthState];

const user = LocalStorage.getItem("USER")

// Define the default context state
const initialValues: AuthState = {
  authenticated: user ? true : false,
  user: user ?? new UserModel(),
};

// Create the context
const AuthContent: any = createContext({});

// Create method to use context
const AuthContext = () => {
  const context = useContext<AuthContentProps>(AuthContent);
  if (!context) {
    throw new Error(`useMeContext must be used within a MeContextProvider`);
  }
  const [auth, setAuth] = context;
  
  const setAuthenticated = (user?: UserModel) => {
    setAuth((auth) => ({
      ...auth,
      authenticated: true,
      user,
    }));
  };
  const setUnauthenticated = () => {
    setAuth((auth) => ({
      ...auth,
      authenticated: false,
      user: undefined,
    }));
  };
  return {
    ...auth,
    setAuthenticated,
    setUnauthenticated
  };
};

// Create the context provider
const AuthProvider = (ownProps: any) => {
  const [auth, setAuth] = useState<AuthState>(initialValues);
  const value = useMemo(() => [auth, setAuth], [auth]);
  return <AuthContent.Provider value={value} {...ownProps} />;
}

export { AuthProvider, AuthContext };
