import { Tabs } from "antd";
import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import IconComponent from "../../../../shared/components/IconComponent";
import PageView from "../../../../shared/components/PageView";
import PastPartnerFeeSettlements from "./PastPartnerFeeSettlements";
import PendingPartnerFeeSettlements from "./PendingPartnerFeeSettlements";
import SettlementInfo from "../VendorSettlements/SettlementInfo";
const { TabPane } = Tabs;

interface PartnerFeesSettlementsProps {}

const PartnerFeesSettlements: FC<PartnerFeesSettlementsProps> = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.hash?.replace(/#/gi, "")

  useEffect(() => {
    if (!location?.hash)
      navigate(
        { pathname: AppRoutes.PARTNER_FEE_SETTLEMENTS, hash: "pending" },
        { replace: true }
      );
  }, [location]);

  return (
    <PageView
      title={{
        name: "Partner Fees Settlements",
        icon: <IconComponent name="icon-settlements" />,
        info: path === "pending" && <SettlementInfo/>
      }}
    >
      <Tabs
        activeKey={path}
        onTabClick={(value) => {
          navigate({ pathname: location.pathname, hash: value });
        }}
        className="settlements"
      >
        <TabPane key="pending" tab="Pending">
          <PendingPartnerFeeSettlements />
        </TabPane>
        <TabPane key="past-settlements" tab="Past Settlements">
          <PastPartnerFeeSettlements/>
        </TabPane>
      </Tabs>
    </PageView>
  );
};

export default PartnerFeesSettlements;
