import { MetaModel } from "../../models/Meta/meta.model"

export function capitalizeFirstLetter(str: string): string {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

export const sortData = (arr: MetaModel[]) => arr.sort((a, b) => (a?.label as string).localeCompare(b?.label as string));

export const sortAndCapitalize = (arr: MetaModel[]) => {
    let data = sortData(arr)
    let newArr = data.map((item) => {
        const newObj = Object.assign({}, item)
        newObj.label = capitalizeFirstLetter(item?.label as string)
        return newObj
    });

    return newArr
}

