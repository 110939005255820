import React, { FC, Fragment, useEffect, useState } from "react";
import TableComponent from "../../../../../../shared/components/TableComponent";
import "./vendorInstores.scss";
import faker from "faker";
import { BadgeTypes } from "../../../../../../enums/badgeTypes";
import BadgeComponent from "../../../../../../shared/components/BadgeComponent";
import { Space } from "antd";
import IconComponent from "../../../../../../shared/components/IconComponent";
import VendorInstoreForm from "./VendorInstoreForm";
import DeleteAlert from "../../../../../../shared/components/DeleteAlert";
import VendorInstoreDetails from "./VendorInstoreDetails";
import VendorService from "../../../../../../services/VendorService/vendor.service";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { tableSorter } from "../../../../../../shared/utils/tableSorter";
import { VendorInstoreModel } from "../../../../../../models/Vendor/Instore/instore.model";
import { AppRoutes } from "../../../../../../routes/routeConstants/appRoutes";
import { SortFilterModel } from "../../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../../shared/types/SortColumn.type";
import {LocalStorage} from "../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../models/User/user.model";
interface VendorInstoresProps { }

const VendorInstores: FC<VendorInstoresProps> = (props) => {
  const user = LocalStorage.getItem('USER') as UserModel;
  const isViewer = user?.role === BadgeTypes.VIEWER;
  const { } = props;
  const [isVendorInstoreFormShown, setIsVendorInstoreFormShown] =
    useState(false);
  const [isVendorInstoreDetailsShown, setIsVendorInstoreDetailsShown] =
    useState(false);
  const [isVendorInstoreDeleteAlertShown, setIsVendorInstoreDeleteAlertShown] =
    useState(false);
  const [currentInstore, setCurrentInstore] = useState<VendorInstoreModel>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const {
    fetchVendorInstoreList,
    vendorInstores,
    deleteVendorInstore,
    loading,
    paginations
  } = VendorService();

  const { vendorId, vendorName } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    if (vendorId) fetchVendorInstoreList({}, vendorId);
  }, [vendorId]);

  const handleRefresh = () => {
    if (vendorId) fetchVendorInstoreList({}, vendorId);
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    vendorId &&
      fetchVendorInstoreList({ page, ...sortOptions, search }, vendorId)
  }

  let newBtn = {};
  if(user?.role !== BadgeTypes.VIEWER){
    newBtn = {
      clickHandler: () => navigate({ pathname: generatePath(AppRoutes.VENDOR_INSTORES_CREATE, { vendorName, vendorId }) }, { state: { currentInstore } }),
      text: "New Store"
    };
  }

  return (
    <div className="vendor-instores">
      <DeleteAlert
        title="Are you sure you want to delete the Store?"
        isDeleting={isDeleting}
        sucessHandler={() => {
          if (currentInstore?.id) {
            setIsDeleting(true);
            deleteVendorInstore(currentInstore?.id)
              ?.then(() => {
                setIsVendorInstoreDeleteAlertShown(false);
                setCurrentInstore(undefined);
              })
              .finally(() => {
                setIsDeleting(false);
                vendorId && fetchVendorInstoreList({}, vendorId)
              });
          }
        }}
        visible={isVendorInstoreDeleteAlertShown}
        cancelHandler={() => {
          setIsVendorInstoreDeleteAlertShown(false);
          setCurrentInstore(undefined);
        }}
      />
      {/* TODO needed for reference */}
      {/* <VendorInstoreForm
        data={currentInstore}
        visible={isVendorInstoreFormShown}
        closeHandler={() => {
          setIsVendorInstoreFormShown(false);
          setCurrentInstore(undefined);
        }}
        refreshList={handleRefresh}
      /> */}
      <VendorInstoreDetails
        visible={isVendorInstoreDetailsShown}
        instore={currentInstore}
        closeHandler={() => {
          setIsVendorInstoreDetailsShown(false);
          setCurrentInstore(undefined);
        }}
      />
      <TableComponent
        paginationMeta={paginations}
        handlePageChange={handlePageChange}
        newBtn={newBtn}
        loading={loading}
        data={vendorInstores}
        onChange={fetchVendorInstoreList}
        onChangeParam={vendorId}
        onSort={(data) => setSortOptions(data)}
        filtersSorts={{ page, search }}
        search={{
          placeholder: "Search stores by name",
          onSearch: (value) => {
            if (vendorId) {
              setSearch(value)
              fetchVendorInstoreList({ search: value }, vendorId);
            }
          },
        }}
        columns={[
          {
            dataIndex: "name",
            key: SortColumnTypes.INSTORE_NAME,
            title: "Store Name",
            sorter: true,
            render: (text, record) => (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setCurrentInstore(record);
                  navigate({ pathname: generatePath(AppRoutes.VENDOR_INSTORES_VIEW, { vendorId, vendorName, instoreId: `${record?.id}` }) }, { state: record })
                }}
              >
                {text}
              </div>
            ),
          },
          {
            dataIndex: "contactFullName",
            key: "contactFullName",
            title: "Contact Person",
          },
          {
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            title: "Phone Number",
          },
          {
            dataIndex: "email",
            key: "email",
            title: "Email",
          },
          {
            dataIndex: "noOfOrders",
            key: SortColumnTypes.INSTORE_NO_OF_ORDERS,
            title: "# of Orders",
            sorter: true,
          },
          {
            dataIndex: "status",
            key: SortColumnTypes.VENDOR_STATUS,
            title: "Status",
            render: (text) => <BadgeComponent type={text === BadgeTypes.APPROVED ? BadgeTypes.ACTIVE : text} />,
            sorter: true,
          },
          {
            title: "",
            key: "action",
            render: (_, record) => {
                if(!isViewer) {
                    return (
                        <div className="vendor-instores-actions">
                            {record?.status !== BadgeTypes.PENDING_APPROVAL &&
                                <IconComponent
                                    name="icon-edit"
                                    onClick={() => {
                                        setCurrentInstore(record);
                                        navigate({
                                            pathname: generatePath(AppRoutes.VENDOR_INSTORES_EDIT, {
                                                vendorId,
                                                vendorName,
                                                instoreId: `${record?.id}`
                                            })
                                        }, {state: record})
                                    }}
                                />}
                            <IconComponent
                                name="icon-trash"
                                onClick={() => {
                                    setCurrentInstore(record);
                                    setIsVendorInstoreDeleteAlertShown(true);
                                }}
                            />
                        </div>
                    )
                }
          },
          },
        ]}
      />
    </div>
  );
};

export default VendorInstores;
