import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import { axiosInstance } from "../../interceptor/axiosInstance";
import { CreditScoreConfig, RiseConfig } from "../../models/CreditScoreConfig/creditScoreConfig.model";
import { CategoryModel, MetaModel, PartnersMetaModel } from "../../models/Meta/meta.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";
import { VendorConfigurationModel } from "../../models/Vendor/vendor.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { sortAndCapitalize } from "../../shared/utils/sortData";

const MetaService = () => {
  const [error, setError] = useState<Error>();
  const [banks, setBanks] = useState<MetaModel[]>([]);
  const [vendorConfiguration, setVendorConfiguration] = useState<VendorConfigurationModel>({});
  const [branches, setBranches] = useState<MetaModel[]>([]);
  const [categories, setCategories] = useState<MetaModel[]>([]);
  const [category, setCategory] = useState<MetaModel>();
  const [subCategories, setSubCategories] = useState<MetaModel[]>([]);
  const [walletTypesMeta, setWalletTypesMeta] = useState<MetaModel[]>([])
  const [countryCodesMeta, setCountryCodesMeta] = useState<MetaModel[]>([])
  const [vendorsMeta, setVendorsMeta] = useState<MetaModel[]>([])
  const [partnersMeta, setPartnersMeta] = useState<PartnersMetaModel[]>([])
  const [rejectionReasonList, setRejectionReasonsList] = useState<MetaModel[]>([]);
  const [approvalReasonList, setApprovalReasonsList] = useState<MetaModel[]>([]);
  const [riseConfigMeta, setRiseConfigMeta] = useState<CreditScoreConfig>()
  const [suspicionReasonList, setSuspicionReasonsList] = useState<MetaModel[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchVendorConfiguration = (vendorId: string) => {
    setLoading(true);
    axiosInstance
      .get(generatePath(ApiRoutes.META_VENDOR_CONFIGURATION, {vendorId}))
      .then((response) => {
        const data = deserialize(
          VendorConfigurationModel,
          response?.data["vendor_processing_fee_config"]
        );
        setVendorConfiguration(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchBankList = () => {
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.META_BANK_LIST)
      .then((response) => {
        const meta = deserialize(
          MetaModel,
          response?.data?.banks
        ) as MetaModel[];
        setBanks(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchBranchList = (bankId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.META_BRANCH_LIST, {
      bankId: bankId?.toString(),
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const meta = deserialize(
            MetaModel,
            response?.data?.branches
          ) as MetaModel[];
        setBranches(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCategoryList = () => {
    setLoading(true);

    axiosInstance
      .get(ApiRoutes.META_CATEGORY_LIST)
      .then((response) => {
        const meta = deserialize(
            MetaModel,
            response?.data?.categories
          ) as MetaModel[];
        setCategories(sortAndCapitalize(meta));
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCategory = (data: MetaModel[], onSuccess: Function) => {
    setLoading(true);
    const categories = serialize(CategoryModel, data as CategoryModel)
    axiosInstance
      .put(generatePath(ApiRoutes.META_CATEGORY_LIST), {categories})
      .then((response) => {
        const meta = deserialize(MetaModel,response?.data?.categories);
        setCategory(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        onSuccess()
        setLoading(false);
      });
  };
  const fetchSubCategoryList = (categoryId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.META_SUB_CATEGORY_LIST, {
      categoryId: categoryId?.toString(),
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const meta = deserialize(
            MetaModel,
            response?.data?.categories
          ) as MetaModel[];
        setSubCategories(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getWalletTypesMeta = () => {
		setLoading(true);
		return axiosInstance
			.get(ApiRoutes.META_WALLET_TYPES)
			.then((response) => {
				const walletTypes = deserialize(MetaModel, response.data["wallet_types"]);
				setWalletTypesMeta(sortAndCapitalize(walletTypes as MetaModel[]))
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

  const getCountryCodesMeta = () => {
		setLoading(true);
		return axiosInstance
			.get(ApiRoutes.META_COUNTRY_CODES)
			.then((response) => {
				const countryCodes = deserialize(MetaModel, response.data["country_codes"]);
        // TODO needed for next phase
        // const data = setCountryCodeFlag(countryCodes as MetaModel[])
				setCountryCodesMeta(countryCodes as MetaModel[])
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

  const fetchVendorsMeta = (params?: SortFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    return axiosInstance
      .get(ApiRoutes.VENDOR_LIST, { params: _params })
      .then((response) => {
        const vendorsMeta = deserialize(MetaModel, response.data["vendors"]);
				setVendorsMeta(sortAndCapitalize(vendorsMeta as MetaModel[]))
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPartnersMeta = (params?: SortFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    return axiosInstance
      .get(ApiRoutes.META_PARTNERS, { params: _params })
      .then((response) => {
        const partnersMeta = deserialize(PartnersMetaModel, response.data["partners"]);
				setPartnersMeta(sortAndCapitalize(partnersMeta as PartnersMetaModel[]))
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchRejectionReasonList = () => {
    setLoading(true);

    axiosInstance
      .get(ApiRoutes.META_REJECTION_REASON_LIST)
      .then((response) => {
        const meta = deserialize(
          MetaModel,
            response?.data?.rejection_reasons
          ) as MetaModel[];
        setRejectionReasonsList(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchApprovalReasonList = () => {
    setLoading(true);

    axiosInstance
        .get(ApiRoutes.META_APPROVAL_REASON_LIST)
        .then((response) => {
          const meta = deserialize(
              MetaModel,
              response?.data?.approval_reasons
          ) as MetaModel[];
          setApprovalReasonsList(meta);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const fetchSuspicionReasonList = () => {
    setLoading(true);

    axiosInstance
      .get(ApiRoutes.META_SUSPICION_REASON_LIST)
      .then((response) => {
        const meta = deserialize(
          MetaModel,
            response?.data?.suspicion_reasons
          ) as MetaModel[];
          setSuspicionReasonsList(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRiseConfig = () => {
    setLoading(true);
    const queryParams = {
      config_class: 'CreditScoreConfig',
    };

    axiosInstance
      .get(ApiRoutes.RISE_CONFIG, { params: queryParams })
      .then((response) => {
        const meta = deserialize(CreditScoreConfig,response?.data)
        setRiseConfigMeta(meta)
      })
      .catch(error => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateRiseConfig = (config: RiseConfig) => {
    setLoading(true);
    const API_URL = ApiRoutes.RISE_CONFIG;
    const queryParams = {
      config_class: 'CreditScoreConfig',
    };
    const serializePayload = serialize(RiseConfig, config)
    const requestPayload = {rise_config: serializePayload}

    axiosInstance
      .put(API_URL, requestPayload, { params: queryParams })
      .then((response) => {
        const meta = deserialize(CreditScoreConfig,response?.data)
        setRiseConfigMeta(meta)
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    loading,
    error,
    banks,
    branches,
    categories,
    subCategories,
    fetchBankList,
    fetchBranchList,
    fetchCategoryList,
    fetchSubCategoryList,
    getWalletTypesMeta,
    walletTypesMeta,
    fetchVendorConfiguration,
    vendorConfiguration,
    getCountryCodesMeta,
    countryCodesMeta,
    fetchVendorsMeta,
    vendorsMeta,
    fetchPartnersMeta,
    partnersMeta,
    updateCategory, 
    category,
    fetchRejectionReasonList,
    rejectionReasonList,
    fetchSuspicionReasonList,
    suspicionReasonList,
    getRiseConfig,
    updateRiseConfig,
    riseConfigMeta,
    approvalReasonList,
    fetchApprovalReasonList
  };
};

export default MetaService;
