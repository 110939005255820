import { PendingSettlementModel } from './../../models/Settlement/settlement.model';
import { PendingCollectionList } from '../../models/Collection/collection.model';
import { PendingPartnerFees } from '../../models/PartnerFees/partnerFees.model';
import { SetStateAction, Dispatch } from 'react';

export interface HandleSelectionProps<T> {
  record: T;
  selected: boolean;
  selectedRows: T[];
  selectedSettlements: React.Key[];
  selectedSettlementsData: T[];
  setSelectedSettlements: Dispatch<SetStateAction<(React.Key | undefined | number)[]>>;
  setSelectedSettlementsData: Dispatch<SetStateAction<T[]>>;
}

export const handleSelection = <T extends PendingSettlementModel | PendingCollectionList | PendingPartnerFees>(
  props: HandleSelectionProps<T>
) => {
  const {
    record,
    selected,
    selectedRows,
    selectedSettlements,
    selectedSettlementsData,
    setSelectedSettlements,
    setSelectedSettlementsData,
  } = props;

  const key = record.key;

  if (selected) {
    const selectedSettlementsSet = new Set([...selectedSettlements, key]);
    const selectedSettlementsDataSet = new Set([...selectedSettlementsData, ...selectedRows]);
    setSelectedSettlements(Array.from(selectedSettlementsSet));
    setSelectedSettlementsData(Array.from(selectedSettlementsDataSet).filter(data => data !== undefined));
  } else {
    setSelectedSettlements(selectedSettlements.filter(select => select !== key));
    setSelectedSettlementsData(selectedSettlementsData.filter(data => data.key !== key));
  }
};
