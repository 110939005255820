import React, { FC, Fragment, useEffect, useState } from "react";
import "./customerDetailsCard.scss";
import { CustomerEditModel, CustomerModel } from "../../../../../../models/Customer/customer.model";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import CardSkeletonComponent from "../../../../../../shared/components/CardSkeletonComponent";
import { currencyString } from "../../../..";
import { dashboardFraction } from "../../../../../../shared/utils/dashboardFraction";
import IconComponent from "../../../../../../shared/components/IconComponent";
import { PurchasingPowerForm, ProcessingFeeForm } from "../CustomerInfoCard";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { BadgeTypes } from "../../../../../../enums/badgeTypes";
import { Col, Divider, Row, Tooltip } from "antd";
import completedIcon from "./../../../../../../assets/images/completed.png"
import crossGreyIcon from "./../../../../../../assets/images/cross-grey.png"
import pendingIcon from "./../../../../../../assets/images/pending.png"
import PurchasingStatusService from "../../../../../../services/PurchasingStatusService/purchasingStatus.service";
import { PurchasingStatusTypes } from "../../../../../../shared/types/purchasingStatusTypes";
import SliderComponent from "../../../../../../shared/components/Slidercomponent";
import ConfigurationService from "../../../../../../services/ConfigurationService/configuration.service";
import refreshIcon from "../../../../../../assets/images/refresh-white-icon.png"
import infoIcon from "../../../../../../assets/images/icon-grey.svg"

interface CustomerDetailsCardProps {
  customer: CustomerModel | undefined;
  isFetching: boolean;
  refreshList: () => void;
  detailsExpanded?: boolean;
  view?: boolean;
  docVerificationIncomplete?: boolean;
  isNeighborhoodDetailsMissing?: boolean;
}

const CustomerDetailsCard: FC<CustomerDetailsCardProps> = (props) => {
  const { customer, refreshList, detailsExpanded, isFetching, view, docVerificationIncomplete, isNeighborhoodDetailsMissing } = props;

  const creditScoreBreakdown = customer?.creditScoreBreakdown
  const isBreakdownAvailable = Object.keys(creditScoreBreakdown ?? {}).length > 0

  const user = LocalStorage.getItem('USER')
  const isAdminManager = user?.role !== BadgeTypes.VIEWER

  const [isPurchasingPowerFormShown, setIsPurchasingPowerFormShown] =
    useState(false);
  const [isProcessingFeeFormShown, setIsProcessingFeeFormShown] =
    useState(false);
  const [toggleProgress, setToggleProgress] = useState(false)
  const [toggleCreditBreakdown, setToggleCreditBreakdown] = useState(false)

  const { updateCustomerDetails, loading } = CustomerService();
  const { fetchActiveAndUpcomingPlan, fetchAllPlanDetails, allPlanDetails, activeAndUpcomingPlan, purchasingStatusloading } = PurchasingStatusService();
  const { recalculateCreditScoreForAUser } = ConfigurationService()

  const handleUpdate = (data: CustomerEditModel) => {
    updateCustomerDetails({ ...data, id: customer?.id }, () => {
      setIsPurchasingPowerFormShown(false);
      setIsProcessingFeeFormShown(false);
      refreshList();
    });
  };

  const getStatus = (value: number) => {
    if (value >= 0 && value <= 200) {
      return 'Very Poor';
    } else if (value >= 201 && value <= 400) {
      return 'Poor';
    } else if (value >= 401 && value <= 600) {
      return 'Fair';
    } else if (value >= 601 && value <= 800) {
      return 'Good';
    } else if (value >= 801 && value <= 1000) {
      return 'Excellent';
    }
  };

  const pendingCustomer = customer?.status === BadgeTypes.PENDING_APPROVAL
  const verificationInComplete = docVerificationIncomplete || isNeighborhoodDetailsMissing || customer?.kycStatus === BadgeTypes.KYC_PENDING
  const maskData = (docVerificationIncomplete || isNeighborhoodDetailsMissing || customer?.kycStatus === BadgeTypes.KYC_PENDING) && pendingCustomer

  useEffect(() => {
    if (customer?.id) {
      // TODO needed for next release
      fetchActiveAndUpcomingPlan(customer?.id)
      fetchAllPlanDetails(customer?.id)
    }
  }, [customer])

  const scoreLabels = [
    { label: 'System score', value: creditScoreBreakdown?.parameters?.systemScore },
    { label: 'Address score', value: creditScoreBreakdown?.parameters?.addressScore },
    { label: 'Doc score', value: creditScoreBreakdown?.parameters?.docScore },
  ];
  
  const creditBreakdownElement = <>
  <Divider />
  {
    <>
      {scoreLabels.map(({ label, value }) => (
        <>
          <div className="breakdown-container" key={label}>
            <div className="label">{label}</div>
            <div className={`${maskData ? 'masked-value' : ''}`}>{value}</div>
          </div>
          {label === 'Address score' &&
            <div className="address-breakdown">
              <div className="label">(ID address, Questionnaire address)</div>
              <div className={`address-value ${maskData ? 'masked-value' : ''}`}>{`(${creditScoreBreakdown?.parameters?.idNeighborhoodScoreCalculated ?? 0}, ${creditScoreBreakdown?.parameters?.questionnaireNeighborhoodScoreCalculated ?? 0})`}</div>
            </div>
          }
        </>
      ))}
    </>
  }
  <Divider />
  <div className="breakdown-container">
    <div>Final score</div>
    <div className={`${maskData ? 'masked-value' : ''}`}>
      {creditScoreBreakdown?.parameters?.finalScore}
    </div>
  </div>
</>

  return loading ? (<CardSkeletonComponent />
  ) : (
    <Fragment>
      <PurchasingPowerForm
        visible={isPurchasingPowerFormShown}
        data={customer}
        closeHandler={() => setIsPurchasingPowerFormShown(false)}
        loading={loading}
        submitHandler={(values) => handleUpdate(values)}
      />
      <ProcessingFeeForm
        data={customer}
        visible={isProcessingFeeFormShown}
        closeHandler={() => setIsProcessingFeeFormShown(false)}
        loading={loading}
        submitHandler={(values) => handleUpdate(values)}
      />
      {
        (isFetching || purchasingStatusloading) ?
          <CardSkeletonComponent /> :
          <div className={`customer-details-card-content ${detailsExpanded && "max-height"} ${docVerificationIncomplete && "not-verified"}`}>
            {
              toggleProgress ?
                <div className="purchasing-status-progress">
                  <Row className="status-header" justify="space-between" align="middle">
                    <Col className="status">{activeAndUpcomingPlan?.upcomingPlan?.name} Status progress</Col>
                    <Col className="close"><IconComponent name="icon-close-white" onClick={() => setToggleProgress(false)} /></Col>
                  </Row>
                  <Divider />
                  <div className="progress">
                    {
                      allPlanDetails?.actions?.upcomingPlanActions?.map((action) => (
                        <Row align="middle" gutter={10} className="point">
                          <Col span={2} className="icon"><img src={action?.status === PurchasingStatusTypes.COMPLETED ? completedIcon : action?.status === PurchasingStatusTypes.IN_PROGRESS ? pendingIcon : crossGreyIcon} alt="" /></Col>
                          <Col span={21} className="text">{action?.actionName}</Col>
                        </Row>
                      ))
                    }
                  </div>
                </div> : toggleCreditBreakdown ? 
                <div className="credit-score-breakdown">
                  <Row className="status-header" justify="space-between" align="middle">
                    <Col span={22} className="status">Rise credit score breakdown</Col>
                    <Col span={2} className="close"><IconComponent name="icon-close-white" onClick={() => setToggleCreditBreakdown(false)} /></Col>
                  </Row>
                   {isBreakdownAvailable && creditBreakdownElement}
                </div> :
                <div className="">
                  {/* TODO needed for next release */}
                  {!view && 
                  <>
                    <div className="item">
                      <div className="title">Purchasing Status</div>
                      <div className="value mb-3">{activeAndUpcomingPlan?.activePlan?.name}</div>
                      <div className="sub-title">
                        <span className="label">Next Status &nbsp;</span>
                        <span className="sub-title-value">{activeAndUpcomingPlan?.upcomingPlan?.name} - {allPlanDetails?.actions?.percentageOfCompletion}{"%"}</span>
                        <span><IconComponent name="icon-info-white" onClick={() => setToggleProgress(true)} /></span>
                      </div>
                    </div>
                    <Divider />
                  </>
                  }
                  <div className="item">  
                    <div className="title">Rise credit score</div>
                        <Row>
                          <Col span={18}>
                            <span className={`value ${maskData && 'masked-value'}`}>{Math.trunc(customer?.creditScore || 0)}</span>
                            {!maskData && <span className='credit-status'>{getStatus(customer?.creditScore ?? 0)}</span>}
                          </Col>
                          <Col offset={pendingCustomer ? 3 : 0} span={2}>
                            {!maskData ? 
                            <Tooltip title="Recalculate credit score">
                                <span className={`refresh-icon`} onClick={() => recalculateCreditScoreForAUser(customer?.id?.toString() ?? "", refreshList)}>
                                  <img src={refreshIcon}></img>                    
                                </span>   
                            </Tooltip> :
                              <Tooltip title="Verify documents uploaded by customer and update neighbourhood details to view credit score">
                                <span className={`refresh-icon`} onClick={() => recalculateCreditScoreForAUser(customer?.id?.toString() ?? "", refreshList)}>
                                  <img src={infoIcon}></img>                    
                                </span>   
                              </Tooltip>}                          
                          </Col>
                          <Col span={1}>
                          {(customer?.status === BadgeTypes.APPROVED || customer?.status === BadgeTypes.INACTIVE || customer?.status === BadgeTypes.REJECTED || (!verificationInComplete && pendingCustomer)) && <span className="info"><IconComponent name="icon-info-white" onClick={() => setToggleCreditBreakdown(true)} /></span>}
                          </Col>
                        </Row>
                      <SliderComponent score={!maskData ? (customer?.creditScore ?? 0) : 0} />
                      {customer?.status === BadgeTypes.PENDING_APPROVAL &&
                        <div className="credit-score-breakdown">
                          {isBreakdownAvailable && creditBreakdownElement}
                        </div>
                      }
                    </div>
                  {!view && 
                  <>
                    <Divider />
                    <div className="item">
                      <div className="title">Purchasing Power</div>
                      <div className="value">{currencyString}{dashboardFraction(customer?.maxPurchasePower?.toString() ?? "0")}
                        {isAdminManager && customer?.status !== BadgeTypes.WAITLISTED &&
                          <IconComponent
                            onClick={() => setIsPurchasingPowerFormShown(true)}
                            name="icon-edit-white"
                          />
                        }
                      </div>
                    </div>
                    <Divider />
                    <div className="item">
                      <div className="title">Processing Fee %</div>
                      <div className="value">{customer?.processingFee} {"%"}
                        {isAdminManager && customer?.status !== BadgeTypes.WAITLISTED &&
                          <IconComponent
                            onClick={() => setIsProcessingFeeFormShown(true)}
                            name="icon-edit-white"
                          />
                        }
                      </div>
                    </div>
                  </>
                  }
                </div>
            }
          </div>
      }
    </Fragment>
  )
};

export default CustomerDetailsCard;
