import { Badge } from "antd";
import React, { FC } from "react";
import "./tabCountComponent.scss";

interface TabCountComponentProps {
  title: string;
  count: number;
}

const TabCountComponent: FC<TabCountComponentProps> = (props) => {
  const { title, count } = props;

  return (
    <span className="tab-count-component">
      {title}
      <Badge overflowCount={100000} count={count} className="tab-count-badge"/>
    </span>
  );
};

export default TabCountComponent;
