
import {serializable, alias, object, list, primitive} from 'serializr';
import { Actions } from '../Actions/actions.model';

export class UpcomingPlan { 

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('purchasing_power', primitive()))
	purchasingPower?: number;

	@serializable(alias('completed', primitive()))
	completed?: boolean;

	@serializable(alias('active', primitive()))
	active?: boolean;

	@serializable(alias('no_of_installments', primitive()))
	noOfInstallments?: number;

}

export class AllPlanDetails {
	@serializable(alias('plans', list(object(UpcomingPlan))))
	plans?: UpcomingPlan;
	
	@serializable(alias('actions', object(Actions)))
	actions?: Actions;
}