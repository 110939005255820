import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { axiosInstance } from "../../interceptor/axiosInstance";
import { PaginationModel } from "../../models/pagination.model";
import { CustomerPaymentModel, OutstandingCustomerPaymentModel } from "../../models/Payment/payment.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { generatePath } from "react-router-dom";
import { PaymentDetails } from "../../models/PaymentDetails/paymentDetails.model";

export const PaymentService = () => {
    const [customerPayments, setCustomerPayments] = useState<CustomerPaymentModel[]>([]);
    const [outstandingCustomerPayments, setOutstandingCustomerPayments] = useState<OutstandingCustomerPaymentModel[]>([])
    const [overdueCustomerPayments, setOverdueCustomerPayments] = useState<OutstandingCustomerPaymentModel[]>([])
    const [payments, setPayments] = useState<PaymentDetails>();
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(false);
    const [paginations, setPaginations] = useState<PaginationModel>()

    const fetchCustomerPayments = (params?: SortFilterModel) => {
        setLoading(true)
        const schema = Object.assign(new SortFilterModel(params), {...params})
        const _params = params && serialize(schema)
        return axiosInstance.get(ApiRoutes.PAYMENT_LIST, {params: _params})
        .then((response) => {
            const payments = deserialize(CustomerPaymentModel, response?.data?.customer_payments) as CustomerPaymentModel[]
            const _paginations = deserialize(PaginationModel,response?.data["meta"])
            setCustomerPayments(payments)
            setPaginations(_paginations)
        }).catch((error) => {
            setError(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    const fetchOutstandingCustomerPayments = (params?: SortFilterModel) => {
        setLoading(true)
        const _params = params && serialize(SortFilterModel, params)
        return axiosInstance.get(ApiRoutes.OUTSTANDING_PAYMENT_LIST, {params: _params})
        .then((response) => {
            const payments = deserialize(OutstandingCustomerPaymentModel, response?.data?.customer_payments) as OutstandingCustomerPaymentModel[]
            const _paginations = deserialize(PaginationModel,response?.data["meta"])
            setOutstandingCustomerPayments(payments)
            setPaginations(_paginations)
        }).catch((error) => {
            setError(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    const fetchOverdueCustomerPayments = (params?: SortFilterModel) => {
        setLoading(true)
        const _params = params && serialize(SortFilterModel, params)
        return axiosInstance.get(ApiRoutes.OVERDUE_PAYMENT_LIST, {params: _params})
        .then((response) => {
            const payments = deserialize(OutstandingCustomerPaymentModel, response?.data?.customer_payments) as OutstandingCustomerPaymentModel[]
            const _paginations = deserialize(PaginationModel,response?.data["meta"])
            setOverdueCustomerPayments(payments)
            setPaginations(_paginations)
        }).catch((error) => {
            setError(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    const fetchPaymentDetails = (transactionId: string) => {
        setLoading(true)
        return axiosInstance.get(ApiRoutes.PAYMENT_VIEW, {
            params: {
                transaction_id: transactionId
            }
        })
        .then((response) => {
            const payments = deserialize(PaymentDetails, response?.data)
            setPayments(payments)
        }).catch((error) => {
            setError(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    return {
        loading,
        customerPayments,
        outstandingCustomerPayments,
        overdueCustomerPayments,
        error,
        fetchCustomerPayments,
        fetchOutstandingCustomerPayments,
        fetchOverdueCustomerPayments,
        paginations,
        fetchPaymentDetails,
        payments
    }

}

export default PaymentService;