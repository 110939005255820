import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import UserService from "../../../services/AuthService/auth.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
import Notification from "../../../shared/components/Notification";
import "./newPasswordForm.scss";
import { newPasswordValidationSchema } from "./NewPasswordValidation";

interface NewPasswordFormProps {}

const NewPasswordForm: FC<NewPasswordFormProps> = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const { loading, validateUserResetPasswordToken, updateNewUserPassword } =
    UserService();
  const initialValue = {
    password: "",
    confirmPassword: "",
  };
  const [searchParams] = useSearchParams();
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const resetPasswordToken: any = searchParams.get("reset_password_token");
  const email: any = searchParams.get("email");
  useEffect(() => {
    if (resetPasswordToken && email)
      validateUserResetPasswordToken({
        resetPasswordToken,
        email,
      })
        .then(() => {
          setIsResetEnabled(true);
        })
        .catch(() => {
          // Notification({
          //   description: "Invalid user!",
          //   type: NotificationTypes.ERROR,
          // });
          setIsResetEnabled(true);

        });
  }, []);
  const onSubmit = (values: FormikValues) => {
    if (resetPasswordToken && email) {
      updateNewUserPassword(
        {
          email: email,
          newPassword: values?.password,
          resetPasswordToken: resetPasswordToken,
        },
        () => {
          navigate(AppRoutes.LOGIN);
        }
      );
    } else {
      Notification({
        description: "Invalid password reset link!",
        type: NotificationTypes.ERROR,
      });
    }
  };
  return (
    <div className="new-password-form">
      <h2>Reset password</h2>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={newPasswordValidationSchema}
      >
        {({ handleSubmit }) => (
          <Form>
            <InputField
              type="password"
              name="password"
              placeholder="Enter"
              label="New password"
            />
            <InputField
              type="password"
              name="confirmPassword"
              placeholder="Enter"
              label="Confirm password"
            />
            <ButtonComponent
              type="primary"
              htmlType="submit"
              className="auth-form__btn"
              size="wide"
              loading={loading}
              disabled={!isResetEnabled}
            >
              Update password
            </ButtonComponent>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewPasswordForm;
