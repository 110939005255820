import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import {axiosInstance} from "../../interceptor/axiosInstance";
import {
  CustomerDocumentModel,
  CustomerEditModel,
  CustomerListModel,
  CustomerModel,
  CustomerPurchaseModel,
} from "../../models/Customer/customer.model";
import { CustomerAttachment } from "../../models/CustomerAttachment/customerAttachment.model";
import { NeighbourhoodModel } from "../../models/Meta/meta.model";
import { PaginationModel } from "../../models/pagination.model";
import { CustomerPaymentModel } from "../../models/Payment/payment.model";
import { MobilePurchaseDetails } from "../../models/Purchase/purchase.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

const CustomerService = () => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<CustomerListModel[]>([]);
  const [customer, setCustomer] = useState<CustomerModel>();
  const [customerPurchases, setCustomerPurchases] = useState<
    CustomerPurchaseModel[]
  >([]);
  const [mobileCustomerPurchases, setMobileCustomerPurchases] = useState<MobilePurchaseDetails[]>()
  const [customerPayments, setCustomerPayments] = useState<
    CustomerPaymentModel[]
  >([]);
  const [unapprovedCustomerDocuments, setUnapprovedCustomerDocuments] =
    useState<CustomerDocumentModel[]>([]);
  const [customerDocument, setCustomerDocument] =
    useState<CustomerAttachment>();

  const [customerContactStat, setCustomerContactStat] = useState<Record<string, number>>();
  const [customerSmsAnalysis, setCustomerSmsAnalysis] = useState<Record<string, number>[]>();

  const [paginations, setPaginations] = useState<PaginationModel>()

  const [neighbourhood, setNeighbourhood] = useState<NeighbourhoodModel[]>([])

  const fetchCustomersList = (params?: SortFilterModel) => {
    const _params = params && serialize(SortFilterModel, params)
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.CUSTOMER_LIST, { params: _params })
      .then((response) => {
        const customers = deserialize(
          CustomerListModel,
          response?.data?.customers
        ) as CustomerListModel[];
        const _paginations = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(_paginations);
        setCustomers(customers);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchUnVerifiedCustomersList = (params?: SortFilterModel) => {
    const _params = params && serialize(SortFilterModel, params)
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.UN_VERIFIED_CUSTOMERS, { params: _params })
      .then((response) => {
        const customers = deserialize(
          CustomerListModel,
          response?.data?.customers
        ) as CustomerListModel[];
        const _paginations = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(_paginations);
        setCustomers(customers);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchWaitlistedCustomersList = (params?: SortFilterModel) => {
    const _params = params && serialize(SortFilterModel, params)
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.WAITLISTED_CUSTOMERS, { params: _params })
      .then((response) => {
        const customers = deserialize(
          CustomerListModel,
          response?.data?.customers
        ) as CustomerListModel[];
        const _paginations = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(_paginations);
        setCustomers(customers);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchRejectedCustomersList = (params?: SortFilterModel) => {
    const _params = params && serialize(SortFilterModel, params)
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.REJECTED_CUSTOMERS, { params: _params })
      .then((response) => {
        const customers = deserialize(
          CustomerListModel,
          response?.data?.customers
        ) as CustomerListModel[];
        const _paginations = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(_paginations);
        setCustomers(customers);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchKycPendingCustomersList = (params?: SortFilterModel) => {
    const _params = params && serialize(SortFilterModel, params)
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.PENDING_KYC_CUSTOMERS, { params: _params })
      .then((response) => {
        const customers = deserialize(
          CustomerListModel,
          response?.data?.customers
        ) as CustomerListModel[];
        const _paginations = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(_paginations);
        setCustomers(customers);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCustomerDetails = (customerId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CUSTOMER_DETAILS, { customerId });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const customer = deserialize(CustomerModel, response?.data?.customer);
        setCustomer(customer);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCustomerContactsStatistics = (customerId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CUSTOMER_CONTACTS_STATISTICS, { customerId });
    axiosInstance
        .get(API_URL)
        .then((response) => {
          const result: Record<string, number> = Object.fromEntries(Object.entries(response.data).map(([key, value]) => [
            key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
            value
          ])) as Record<string, number>
          setCustomerContactStat(result)
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const fetchCustomerSmsAnalysis = (customerId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CUSTOMER_CONTACTS_SMS_ANALYSIS, { customerId });
    axiosInstance
        .get(API_URL)
        .then((response) => {
          setCustomerSmsAnalysis(response?.data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const updateCustomerDetails = (
    customer: CustomerEditModel,
    onSuccess?: () => void,
    onError?: Function,
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CUSTOMER_DETAILS, {
      customerId: customer?.id?.toString(),
    });
    const serializedPayload = serialize(CustomerEditModel, customer);
    delete serializedPayload["id"];
    const requestPayload = { customer: serializedPayload };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then(onSuccess)
      .catch((error) => {
        setError(error);
        onError && onError()
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCustomerPurchases = (params?: SortFilterModel, customerId?: string) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    const API_URL = generatePath(ApiRoutes.CUSTOMER_PURCHASE_LIST, { customerId });
    axiosInstance
      .get(API_URL,{params: _params})
      .then((response) => {
        const data = deserialize(
          CustomerPurchaseModel,
          response?.data?.customer_purchases
        ) as CustomerPurchaseModel[];
        const mobileData = deserialize(MobilePurchaseDetails, response?.data?.mobile_purchase_details) as MobilePurchaseDetails[];
        const meta = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(meta);
        setCustomerPurchases(data);
        setMobileCustomerPurchases(mobileData)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCustomerPayments = (
    params?: SortFilterModel,
    customerId?: string,
  ) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    const API_URL = generatePath(ApiRoutes.CUSTOMER_PAYMENT_LIST, { customerId });
    axiosInstance
      .get(API_URL, { params: _params })
      .then((response) => {
        const data = deserialize(
          CustomerPaymentModel,
          response?.data?.customer_payments
        ) as CustomerPaymentModel[];
        const meta = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(meta);
        setCustomerPayments(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const verifyCustomer = (customerId: string) => {
    const API_URL = generatePath(ApiRoutes.CUSTOMER_APPROVAL, { customerId });
    return axiosInstance.post(API_URL);
  };
  const fetchCustomerDocument = (documentId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VIEW_DOCUMENT, {
      customerAttachmentId: documentId,
    });
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const document = deserialize(
          CustomerAttachment,
          response?.data?.customer_attachment
        );
        setCustomerDocument(document);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updateCustomerDocument = (
    document: CustomerDocumentModel,
    onSuccess?: () => void
  ) => {
    setLoading(true);

    const API_URL = generatePath(ApiRoutes.UPDATE_UNAPPROVED_DOCUMENT, {
      customerAttachmentId: document?.id?.toString(),
    });
    const serializedPayload = serialize(CustomerDocumentModel, document);
    delete serializedPayload["id"];
    const requestPayload = { customer_attachment: serializedPayload };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then(onSuccess)
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchUnapprovedCustomerDocuments = (params?: SortFilterModel) => {
    const schema = Object.assign(new SortFilterModel(params), {...params})
    const _params = params && serialize(schema)
    setLoading(true);
    axiosInstance
      .get(ApiRoutes.UNAPPROVED_CUSTOMER_DOCUMENT_LIST, {params: _params})
      .then((response) => {
        const documents = deserialize(
          CustomerDocumentModel,
          response?.data?.customer_attachments
        ) as CustomerDocumentModel[];
        setUnapprovedCustomerDocuments(documents);
        const meta = deserialize(PaginationModel,response?.data["meta"])
        setPaginations(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVerificationDocument = (
    customerId: string,
    documentId: string,
    document: CustomerDocumentModel,
    onSuccess?: () => void
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.UPDATE_VERIFICATION_DOC, {
      attachmentId: documentId,
      customerId
    });
    const serializedPayload = serialize(CustomerDocumentModel, document);
    delete serializedPayload["id"];
    const requestPayload = { customer_attachment: serializedPayload };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then(onSuccess)
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyCustomerNationalId = (customerId:string) => {
    const API_URL = generatePath(ApiRoutes.CUSTOMER_NATIONAL_ID_VERIFY,{customerId});
    setLoading(true);
    return axiosInstance.put(API_URL);
  };

  const verifyCustomerProfile = (customerId:string) => {
    const API_URL = generatePath(ApiRoutes.CUSTOMER_PROFILE_VERIFY,{customerId});
    setLoading(true);
    return axiosInstance.put(API_URL);
  };

  const calculateCustomerPurchasingPower = (customerId:string) => {
    const API_URL = generatePath(ApiRoutes.CUSTOMER_PURCHASE_POWER_CALCULATE,{customerId});
    setLoading(true);
    return axiosInstance.put(API_URL);
  };

  const resetRetryCount = (
    customerId:string,
    onSuccess?: () => void
    ) => {
    setLoading(true)
    const API_URL = generatePath(ApiRoutes.CUSTOMER_RESET_RETRY_COUNT,{customerId});
    return axiosInstance
    .put(API_URL)
    .then(onSuccess)
    .catch((error) => {
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const manualApproveCustomer = (
    customer: CustomerEditModel,
    customerId:string,
    onSuccess?: () => void
    ) => {
    setLoading(true)
    const serializedPayload = serialize(CustomerEditModel, customer);
    delete serializedPayload["id"];
    const requestPayload = { customer: serializedPayload };
    const API_URL = generatePath(ApiRoutes.MANUAL_VERIFY_CUSTOMER,{customerId});
    return axiosInstance
    .put(API_URL, requestPayload)
    .then(onSuccess)
    .catch((error) => {
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const updatePendingCustomerDetails = (
    customer: CustomerEditModel,
    onSuccess?: () => void,
    onError?: Function,
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.UPDATE_CUSTOMER_DETAILS, {
      customerId: customer?.id?.toString(),
    });
    const serializedPayload = serialize(CustomerEditModel, customer);
    delete serializedPayload["id"];
    const requestPayload = { customer: serializedPayload };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then(onSuccess)
      .catch((error) => {
        setError(error);
        onError && onError()
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const resetKycRetry = (
    customerId: string,
    status: boolean,
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.KYC_RETRY, {customerId})
    return axiosInstance
      .put(API_URL, {status})
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCustomerData = (customerId: string, onSuccess: () => void) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CUSTOMER_DETAILS, { customerId });
    axiosInstance
      .delete(API_URL)
      .then(() => {
        onSuccess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchNeighbourhoodList = () => {
    setLoading(true);

    axiosInstance
      .get(ApiRoutes.GET_NEIGHBOURHOOD)
      .then((response) => {
        const meta = deserialize(
          NeighbourhoodModel,
            response?.data?.neighbourhoods
          ) as NeighbourhoodModel[];
        const index = meta.findIndex(item => item.nameInEn === 'Other');
        if (index >= 0) {
          const [item] = meta.splice(index, 1);
          item.label = `${item.label} (${item.nameInEn})`;
          meta.unshift(item);
        }
        setNeighbourhood(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateNeighbourhood = (
    customerId: string,
    neighbourhoodId: string,
    onSuccess?: () => void
    ) => {
    setLoading(true)
    const serializedPayload = {neighbourhood_id: neighbourhoodId}
    const requestPayload = { customer: serializedPayload };
    const API_URL = generatePath(ApiRoutes.UPDATE_NEIGHBOURHOOD,{customerId});
    return axiosInstance
    .put(API_URL, requestPayload)
    .then(onSuccess)
    .catch((error) => {
      setError(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return {
    loading,
    error,
    customers,
    customer,
    customerPayments,
    customerPurchases,
    unapprovedCustomerDocuments,
    customerDocument,
    mobileCustomerPurchases,
    fetchCustomerDetails,
    fetchCustomersList,
    fetchCustomerPurchases,
    verifyCustomer,
    updateCustomerDocument,
    fetchCustomerDocument,
    fetchUnapprovedCustomerDocuments,
    fetchCustomerPayments,
    updateCustomerDetails,
    fetchUnVerifiedCustomersList,
    fetchWaitlistedCustomersList,
    fetchKycPendingCustomersList,
    updateVerificationDocument,
    paginations,
    verifyCustomerNationalId,
    verifyCustomerProfile,
    calculateCustomerPurchasingPower,
    resetRetryCount,
    manualApproveCustomer,
    updatePendingCustomerDetails,
    fetchRejectedCustomersList,
    resetKycRetry,
    deleteCustomerData,
    fetchCustomerContactsStatistics,
    customerContactStat,
    fetchCustomerSmsAnalysis,
    customerSmsAnalysis,
    fetchNeighbourhoodList,
    neighbourhood,
    updateNeighbourhood
  };
};

export default CustomerService;
