import { DocumentTypes } from "../enums/documentTypes";

const {
    BANK_STATEMENT,PROFILE_PIC,NATIONAL_ID_BACK,NATIONAL_ID_FRONT, TAX_CARD,EMPLOYMENT_LETTER,COMMERCIAL_REGISTRATION, 
    UTILITY_BILL, PROOF_OF_INCOME, PROOF_OF_ASSET, REFUND_RECEIPT, SELFIE_PIC, VEHICLE_CARD, SPORTS_CLUB_MEMBERSHIP, PAYSLIP, PROPERTY_CONTRACT, CREDIT_CARD_STATEMENT, PAYSLIP_BANK_STATEMENT, EMPLOYMENT_LETTER_MEDICAL_CARD, STUDENT_CARD, MEDICAL_CARD} = DocumentTypes

export const DocumentTypesDefinition = {
    [BANK_STATEMENT] : "Bank Statement",
    [EMPLOYMENT_LETTER] : "Employment Letter",
    [NATIONAL_ID_FRONT] : "National ID Front",
    [NATIONAL_ID_BACK] : "National ID Back",
    [PROFILE_PIC] : "Profile Pic",
    [TAX_CARD]: "Tax Card",
    [COMMERCIAL_REGISTRATION]: "Commercial Registration",
    [UTILITY_BILL]: "Utility Bill",
    [PROOF_OF_INCOME]: "Proof Of Income",
    [PROOF_OF_ASSET]: "Proof Of Asset",
    [REFUND_RECEIPT]: "Refund Receipt",
    [SELFIE_PIC]: "Selfie Pic",
    [VEHICLE_CARD]: "Vehicle Card",
    [SPORTS_CLUB_MEMBERSHIP]: "Sports Club Membership",
    [PAYSLIP]: "Payslip",
    [PROPERTY_CONTRACT]: "Property Contract",
    [CREDIT_CARD_STATEMENT]: "Credit Card Statement",
    [PAYSLIP_BANK_STATEMENT]: "Pay slip / Bank statement",
    [EMPLOYMENT_LETTER_MEDICAL_CARD]: "Employment letter / Medical card",
    [STUDENT_CARD]: "Student card",
    [MEDICAL_CARD]: "Medical card"
}