
import {serializable, alias, object, list, primitive} from 'serializr';
import { RefundModeTypes, RefundTypes } from '../../enums/refundTypes';
import { AttachmentModel } from '../Attachment/attachment.model';

export class InstallmentDetails { 

	@serializable(alias('due_date', primitive()))
	dueDate?: string;

	@serializable(alias('updated_amount', primitive()))
	updatedAmount?: string;

	@serializable(alias('old_amount', primitive()))
	oldAmount?: string;
	
	@serializable(alias('status', primitive()))
	status?: string;

}

export class ProcessingFee { 

	@serializable(alias('total', primitive()))
	total?: string;

	@serializable(alias('vat', primitive()))
	vat?: string;

	@serializable(alias('remaining', primitive()))
	remaining?: string;

}

export class PurchaseDetail { 

	@serializable(alias('purchase_amount', primitive()))
	purchaseAmount?: string;

	@serializable(alias('processing_fee', object(ProcessingFee)))
	processingFee?: ProcessingFee;

}

export class RefundDetails { 

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('admin_id', primitive()))
	adminId?: string;

	@serializable(alias('receipt_attachment_id', primitive()))
	receiptAttachmentId?: string;

	@serializable(alias('receipt_id', primitive()))
	receiptId?: string;
	
	@serializable(alias('receipt_not_to_be_present', primitive()))
	receiptNotToBePresent?: boolean = false;

	@serializable(alias('refund_amount', primitive()))
	refundAmount?: number;

	@serializable(alias('returned_date', primitive()))
	returnedDate?: string;

	@serializable(alias('refund_status', primitive()))
	refundStatus?: string;

	@serializable(alias('refund_type', primitive()))
	refundType?: RefundTypes.FULL_PAYMENT_ADJUSTED | RefundTypes.PARTIAL_PAYMENT_ADJUSTED | RefundTypes.CASH_REFUND;

	@serializable(alias('old_purchase_amount', primitive()))
	oldPurchaseAmount?: number;

	@serializable(alias('old_processing_fee', primitive()))
	oldProcessingFee?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('created_by', primitive()))
	createdBy?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('cash_refund_amount', primitive()))
	cashRefundAmount?: number;
	
	@serializable(alias('receipt_attachment', object(AttachmentModel)))
	receiptAttachment?: AttachmentModel;

	@serializable(alias('installment_details', list(object(InstallmentDetails))))
	installmentDetails?: InstallmentDetails[] = [];

	@serializable(alias('old_purchase_detail', object(PurchaseDetail)))
	oldPurchaseDetail?: PurchaseDetail;

	@serializable(alias('new_purchase_detail', object(PurchaseDetail)))
	newPurchaseDetail?: PurchaseDetail;

	@serializable(alias('refund_mode', primitive()))
	refundMode?: RefundModeTypes.FULL_REFUND | RefundModeTypes.PARTIAL_REFUND;

}