import moment from "moment";
import * as Yup from "yup";

const requiredSchema = Yup.string().required("Comment is required");
const notRequiredSchema = Yup.string().notRequired();

export const customerDetailsFormValidation = (isApproval?: boolean) => Yup.object().shape({
	firstName: Yup.string().required('First Name is required').nullable(),
	lastName: Yup.string().required('Last Name is required').nullable(),
	nationalIdNumber: Yup.string().required('National ID is required').nullable(),
  dateOfBirth: Yup.date()
  .nullable()
  .required('Date of Birth is required')
  .test('is-eighteen', 'Must be at least 18 years old', function (value) {
    if (value) {
      const age = moment().diff(moment(value), 'years');
      return age >= 18;
    }
    return true;
  }),
	address: Yup.string().required('Address is required').nullable(),
	nationalIdFrontId: Yup.string().required('National id front is required'),
	nationalIdBackId: Yup.string().required('National id back is required'),
});

export const approvalFormValidationSchema = Yup.object().shape({
  approvalComment: Yup.string()
    .required('Approval Comment is required'),
  
  approvalReasonId: Yup.array()
    .min(1, 'At least one reason is required')
    .required('Approval Reason is required'),
});
