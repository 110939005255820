import axios from "axios";
import { deserialize } from "serializr";
import { NotificationTypes } from "../enums/notificationTypes";
import { TokenModel, UserModel } from "../models/User/user.model";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import { AppRoutes } from "../routes/routeConstants/appRoutes";
import Notification from "../shared/components/Notification";
import { LocalStorage } from "../shared/utils/localStorageHelpers";

export const getHeaders = (): any => {
  let headers;
  const accessToken = LocalStorage.getItem("ACCESS_TOKEN");
  headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken ? accessToken : ""}`,
  };
  return headers;
};

const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    timeout: 30000,
});

instance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

instance.interceptors.response.use(
  (response): any => {
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
    };
  },
  (error) => {
    const { response } = error;
        if (response?.status === 401) {
            const urlParams = new URLSearchParams(window.location.search);
            const redirectUrl = urlParams.get('redirectUrl');
            localStorage.clear();
            redirectUrl ? window.location.replace(`${AppRoutes.LOGIN}?redirectUrl=${encodeURIComponent(redirectUrl)}`)  : window.location.reload();
            const originalRequest = error?.config;
            originalRequest._retry = true;
            const refreshToken = LocalStorage.getItem("REFRESH_TOKEN");
            const admin = {
                grant_type: "refresh_token",
                refresh_token: refreshToken,
            };
            return axios
                .post(`${ApiRoutes.BASE_URL}${ApiRoutes.USER_LOGIN}`, {admin})
                .then((response) => {
                    if (response.status >= 200 && response.status <= 299) {
                        const userDetails = deserialize(UserModel, response.data["admin"]);
                        const token = deserialize(TokenModel, response.data["token"]);
                        LocalStorage.setItem("USER",userDetails)
                        LocalStorage.setItem("ACCESS_TOKEN", token?.accessToken);
                        LocalStorage.setItem("REFRESH_TOKEN", token?.refreshToken);
                        axios.defaults.headers.common["Authorization"] =
                            "bearer " + token?.accessToken;
                        originalRequest.headers["Authorization"] =
                            "bearer " + token?.accessToken;
                        return axios(originalRequest);
                    }
                })
                .catch((error) => {
                    localStorage.clear();
                    redirectUrl ? window.location.replace(`${AppRoutes.LOGIN}?redirectUrl=${encodeURIComponent(redirectUrl)}`)  : window.location.replace(AppRoutes.LOGIN);
                    // setUnauthenticated()
                });
        }
        else if (response?.status === 422) {
            const errorMessage = response?.data?.error
            Notification({
                message: typeof(errorMessage) === 'string' ? errorMessage : errorMessage?.error,
                description: "Please try again!",
                type: NotificationTypes.ERROR,
            });
        } else if (response?.status === 500) {
            Notification({
                message: "Error 500",
                description: "Oops, something went wrong.",
                type: NotificationTypes.ERROR,
            });
        }
        return Promise.reject(error);
  }
)
return instance
};

const axiosInstance = createAxiosInstance(`${ApiRoutes.BASE_URL}/v1`);
const axiosInstanceV2 = createAxiosInstance(`${ApiRoutes.BASE_URL}/v2`);

export { axiosInstance, axiosInstanceV2 };
