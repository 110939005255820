import { Pagination } from "antd";
import React, { FC, useEffect } from "react";
import './pagination.scss';

interface PaginationComponentProps {
    handlePageChange: (page: number, pageSize?: number) => void;
    paginationMeta: any;
    pageNumber?: number;
}

const PaginationComponent: FC<PaginationComponentProps> = (props) => {

    const { handlePageChange, paginationMeta, pageNumber } = props;

    return (
        <div className="pagination-component">
            <Pagination 
                onChange={handlePageChange}
                current={pageNumber ?? paginationMeta?.currentPage}
                total={paginationMeta?.totalCount || 0}
                size={"small"}
                responsive
                hideOnSinglePage={true}
                pageSize={paginationMeta?.itemPerPage || 0}
                showSizeChanger={false}
                showTotal={(total: any, range: any) => `Results ${range[0]}-${range[1]} of ${total}`}
            />
        </div>
    )
}

export default PaginationComponent;
