
import {Installment} from '../Installment/installment.model';

import {PurchasePayment} from '../PurchasePayment/purchasePayment.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class PurchasesPayment { 

	@serializable(alias('purchase', object(PurchasePayment)))
	purchasePayment?: PurchasePayment;

	@serializable(alias('installment', object(Installment)))
	installment?: Installment;

}