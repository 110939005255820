import { serializable, alias, object, list, primitive } from "serializr";
import { DocumentTypes } from "../../enums/documentTypes";
import { PaymentModeTypes } from "../../enums/paymentModeTypes";
import { PurchaseModeTypes } from "../../enums/purchaseModeTypes";
import { VendorModel } from "../Vendor/vendor.model";

export class ConfigurationListModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("customer_processing_fee", primitive()))
  customerProcessingFee?: number;

  @serializable(alias("deall_score_range", list(primitive())))
  deallScoreRange?: number;

  @serializable(alias("installments", primitive()))
  installments?: number;

  @serializable(alias("purchase_power", primitive()))
  purchasePower?: number;

  

}
export class ConfigurationProcessingFeeModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("processing_fee", primitive()))
  processingFee?: number;

  @serializable(alias("late_fee", primitive()))
  lateFee?: number;

}

