import { Divider, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardFilterModel } from "../../../models/Dashboard/dashboard.model";
import { UserModel } from "../../../models/User/user.model";
import MonitoringService from "../../../services/MonitoringService/monitoring.service";
import BarChartLegendComponent from "../../../shared/components/BarChartLegendComponent";
import DashboardFilterMenu from "../../../shared/components/DashboardFilterMenu";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import Viewondesktop from "../../../shared/components/Viewondesktop";
import { DashboardDateFilterTypes } from "../../../shared/types/DashboardDateFilterTypes";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import useWindowWidth from "../../../shared/utils/useWindowWidth";
import PaymentMetrics from "./PaymentMetrics";
import { paymentTransactionsNumberConfig } from "./paymentTransactionsNumberConfig";

const Monitoring = () => {
    const showTest = LocalStorage.getItem('IS_TEST')
    const [params, setParams] = useState<DashboardFilterModel | undefined>({ dateFilterKey: DashboardDateFilterTypes["CURRENT_YEAR"], showTest })
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= 480;
    const { fetchCardPaymentKeyMetrics, fetchWalletPaymentKeyMetrics, walletErrorsByType, cardErrorsByType, cardPaymentKeyMetrics, walletPaymentKeyMetrics, loading } = MonitoringService()

    useEffect(() => {
        fetchCardPaymentKeyMetrics();
        fetchWalletPaymentKeyMetrics();
    }, [])

    return (
        <PageView type='dashboard' title={{
            name: "Monitoring",
            icon: <IconComponent name="icon-dashboard" />,
          }}>
            {isMobile ? <Viewondesktop /> :
              <div className="dashboard-view">
                <div className="header">
                  <Divider />
                  <div className='date-range-container'>
                    <div className='date'><IconComponent name="icon-calender" />
                      <h3> Date Range: </h3> &nbsp;
                      <DashboardFilterMenu setParams={setParams} onChange={(values) => {
                        fetchCardPaymentKeyMetrics(values)
                        fetchWalletPaymentKeyMetrics(values)
                      }} />
                    </div>
                  </div>
                  <Divider />
                  <Row gutter={[16,40]}>
                    <Col span={24}>
                        <PaymentMetrics cardData={cardPaymentKeyMetrics} walletData={walletPaymentKeyMetrics} />
                    </Col>
                    <Col span={24}>
                        <BarChartLegendComponent onChange={fetchCardPaymentKeyMetrics} data={cardErrorsByType} loading={loading} options={paymentTransactionsNumberConfig} title='Card errors by type' />
                    </Col>
                    <Col span={24}>
                        <BarChartLegendComponent onChange={fetchWalletPaymentKeyMetrics} data={walletErrorsByType} loading={loading} options={paymentTransactionsNumberConfig} title='Wallet errors by type' />
                    </Col>
                  </Row>
                </div>
              </div>
            }
          </PageView>
    )
} 

export default Monitoring;
