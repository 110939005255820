import {serializable, alias, primitive, object} from 'serializr';
import { MetaModel } from '../Meta/meta.model';

export class DeallUsersModel {

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('mobile_number', primitive()))
    mobileNumber?: string;

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('first_name', primitive()))
    firstName?: string;

    @serializable(alias('last_name', primitive()))
    lastName?: string;

    @serializable(alias("full_name",primitive()))
    fullName?: string;

    @serializable(alias('role',primitive()))
    role?: string;

    @serializable(alias('status',primitive()))
    status?: string;

    @serializable(alias('password',primitive()))
    password?: string;

    @serializable(alias('country_code_id', primitive()))
	countryCodeId?: string;

	@serializable(alias('isd_code', primitive()))
	isdCode?: string;

    @serializable(alias('country_code', object(MetaModel)))
    countryCode?: MetaModel;

    @serializable(alias('mfa_type', primitive()))
    mfaType?: string;

}