import React, { FC, SetStateAction, useEffect, useState } from "react";
import TableComponent from "../../../../shared/components/TableComponent";
import "./approveVendors.scss";
import faker from "faker";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import VendorService from "../../../../services/VendorService/vendor.service";
import { VendorTypes } from "../../../../enums/vendorTypes";
import { VendorTypesDefinition } from "../../../../definitions/vendorTypesDefinition";
import { tableSorter } from "../../../../shared/utils/tableSorter";
import NoVendorsImg from "../../../../assets/empty/vendors.svg";
import moment from "moment";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";

interface ApproveVendorsProps {
  setCount: SetStateAction<any>;
}

const ApproveVendors: FC<ApproveVendorsProps> = (props) => {
  const { setCount } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const pageNumber = location.hash === "#approve-vendors" ? location.state as number : null;
  const [page, setPage] = useState(pageNumber ?? 1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const showTest = LocalStorage.getItem("IS_TEST")

  const { fetchUnverifiedVendorList, unverifiedVendors, loading, paginations } =
    VendorService();

    const handlePageChange = (page:number) => {
      location.state = null;
      setPage(page)
      fetchUnverifiedVendorList({page, search, ...sortOptions, showTest})
    }

  useEffect(() => {
    location.hash === "#approve-vendors" &&
    fetchUnverifiedVendorList({page, search, ...sortOptions, showTest});
  }, [location.hash]);

  useEffect(() => {
    fetchUnverifiedVendorList({page, search, ...sortOptions, showTest});
  }, []);

  useEffect(() => {
    setCount(unverifiedVendors?.length);
  }, [unverifiedVendors]);

  return (
    <div className="approve-vendors">
      <TableComponent
      paginationMeta={paginations}
      pageNumber={pageNumber ?? page}
      handlePageChange={handlePageChange}
      onChange={fetchUnverifiedVendorList}
      onSort={(data)=>{
        setSortOptions(data)
      }}
      filtersSorts={{page, search, showTest}}
      onRow={(record)=>
        navigate(
          {pathname: generatePath(AppRoutes.APPROVE_VENDORS_VIEW, {
            vendorId: record?.id,
          })}, {state: page}
        )
      }
        search={{
          placeholder: "Search vendor by name or id",
          onSearch: (value) => {
            setSearch(value)
            fetchUnverifiedVendorList({ search: value, showTest });
          },
        }}
        empty={{
          text: "No vendor data",
          img: NoVendorsImg,
        }}
        columns={[
          {
            title: "Vendor",
            dataIndex: "name",
            key: SortColumnTypes.VENDOR_NAME,
            render: (text, record) => (
              <span className="list-vendors-business__name">
                {record?.logo && <img src={record?.logo?.s3Url} />}
                {text}
              </span>
            ),
            sorter: true,
          },

          {
            title: "Type",
            dataIndex: "type",
            key: SortColumnTypes.VENDOR_TYPE,
            render: (text: VendorTypes) => VendorTypesDefinition[text],
            sorter: true,
          },
          {
            title: "Contact Person",
            dataIndex: "contactFullName",
            key: "contactFullName",
          },
          {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Onboard Date",
            dataIndex: "createdAt",
            key: SortColumnTypes.CREATED_AT,
            render: (text) => moment(text)?.format("DD MMM YYYY"),
            sorter: true,
          },
        ]}
        data={unverifiedVendors}
        loading={loading}
      />
    </div>
  );
};

export default ApproveVendors;
