import React, { FC } from 'react'
import "./viewondesktop.scss"
import desktopIcon from "../../../assets/images/desktop2.png"

const Viewondesktop: FC = () => {

    return (
        <div className="viewondesktop">
            <img className="desktop-icon" src={desktopIcon} />
            <div className="content">View the site in laptop or desktop to view this section</div>
        </div>
    )
}

export default Viewondesktop