import infoIconGreen from "../../assets/images/info-icon-green.svg"
import infoIconRed from "../../assets/images/icon-info-red.svg"
import infoIconDefault  from "../../assets/images/icon-grey.svg"

export const getInfoIcon = (iconType: string | undefined) => {
    switch (iconType) {
        case "accept":
        return infoIconGreen;
        case "reject":
        return infoIconRed;
        default:
        return infoIconDefault;
    }
}