
import {serializable, alias, object, list, primitive} from 'serializr';
import { BadgeTypes } from '../../enums/badgeTypes';
import { PaymentModeTypes } from '../../enums/paymentModeTypes';
import { CustomerPurchase } from '../notification.model';

export class CustomerPaymentModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('customer_purchase_id', primitive()))
	customerPurchaseId?: string;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('customer_name', primitive()))
	customerName?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('payment_mode', primitive()))
	paymentMode?: PaymentModeTypes;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('is_amount_negetive', primitive()))
	isAmountNegative?: boolean;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('transaction_type', primitive()))
	transactionType?: string;

	@serializable(alias('external_payment_id', primitive()))
	externalPaymentId?: number;
	
	@serializable(alias('payment_status', primitive()))
	paymentStatus?: BadgeTypes;
}

export class OutstandingCustomerPaymentModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias("customer_reference_number", primitive()))
  	customerReferenceNumber?: string;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('min_amount', primitive()))
	minimumDue?: number;

	@serializable(alias('outstanding_payments', primitive()))
	outstandingPayments?: number;

	@serializable(alias('due_date', primitive()))
	dueDate?: string;

	@serializable(alias('is_over_due', primitive()))
	isOverDue?: string;

	@serializable(alias('customer_purchase', object(CustomerPurchase)))
	customerPurchase?: CustomerPurchase;

}

export class CustomerPaymentFilterModel {

    @serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

    @serializable(alias('national_id', primitive()))
	nationalId?: string;

    @serializable(alias("search", primitive()))
	search?: string;

	@serializable(alias("page", primitive()))
	page?: number;

}