
import {OptionsArray} from '../OptionsArray/optionsArray.model';

import {serializable, alias, object, list, primitive, map} from 'serializr';

export class Criteria { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('factor', primitive()))
	factor?: string;

	@serializable(map(primitive()))
	options?: { [key: string]: number } = {};

	@serializable(alias('datasource', primitive()))
	datasource?: string;

	@serializable(alias('question_text', primitive()))
	questions?: string;

	@serializable(alias('factor_weight', primitive()))
	factorWeight?: number;

	@serializable(alias('options_array', list(object(OptionsArray))))
	optionsArrays?: OptionsArray[] = [];

}