
import {serializable, alias, object, list, primitive} from 'serializr';

export class LoginRequestModel { 

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('password', primitive()))
	password?: string;

	@serializable(alias('grant_type', primitive()))
	grantType?: string;

}

export class VerifyTokenRequestModel {

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('code', primitive()))
	code?: string;
}

export class ForgotPasswordRequestModel { 

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('redirect_url', primitive()))
	redirectUrl?: string;

}

export class NewPasswordRequestModel { 

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('reset_password_token', primitive()))
	resetPasswordToken?: string;

	@serializable(alias('new_password', primitive()))
	newPassword?: string;

}

export class ResetPasswordTokenRequestModel { 

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('reset_password_token', primitive()))
	resetPasswordToken?: string;

	@serializable(alias('new_password', primitive()))
	newPassword?: string;

}