type KEY = "ACCESS_TOKEN" | "REFRESH_TOKEN" | "USER" | "IS_TEST" | "MOBILE_DEVICE";

export class LocalStorage {
    static setItem(key: KEY, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static getItem(key: KEY)  {
        const data = localStorage.getItem(key);
        if(data && data !== "undefined") {
            return JSON.parse(data);
        }
        return null
    }
}