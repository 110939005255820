import {serializable, alias, object, list, primitive} from 'serializr';
import { BadgeTypes } from '../../enums/badgeTypes';
import { DocumentTypes } from '../../enums/documentTypes';
import { AttachmentModel } from '../Attachment/attachment.model';

export class CustomerAttachment { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('customer_name', primitive()))
	customerName?: string;

	@serializable(alias('customer_id', primitive()))
	customerId?: number;
	
	@serializable(alias('customer_status', primitive()))
	customerStatus?: BadgeTypes;
	
	@serializable(alias("attachment_type", primitive()))
    attachmentType?: DocumentTypes;

	@serializable(alias('attachment_details', object(AttachmentModel)))
	attachmentDetails?: AttachmentModel;

	@serializable(alias('attachment_status', primitive()))
	attachmentStatus?: string;

	@serializable(alias('submitted_on', primitive()))
	submittedOn?: string;

	@serializable(alias('approved_on', primitive()))
	approvedOn?: string;
	
	@serializable(alias('rejected_on', primitive()))
	rejectedOn?: string;

	@serializable(alias('rejected_by', primitive()))
	rejectedBy?: object;

	@serializable(alias('admin', primitive()))
	admin?: object;

	@serializable(alias('admin_comments', primitive()))
	adminComments?: string;

	@serializable(alias('profile_pic', object(AttachmentModel)))
	profilePic?: AttachmentModel;

}