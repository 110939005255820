import React, { FC } from "react";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import "./approveVendorAlert.scss";

interface ApproveVendorAlertProps {
  visible: boolean;
  closeHandler: () => void;
  successHandler: () => void;
  loading?: boolean;
}

const ApproveVendorAlert: FC<ApproveVendorAlertProps> = (props) => {
  const { visible, closeHandler, successHandler, loading } = props;

  return (
    <ModalComponent
      type="alert"
      visible={visible}
      successProps={{
        title: "Approve",
        clickHandler: successHandler,
        loading: loading
      }}
      cancelProps={{
        title: "Close",
        clickHandler: closeHandler,
      }}
      alert={{
        title: "Are you sure you want to approve the vendor ?",
        buttonType: "affirmative"
      }}
      closeHandler={closeHandler}
      className="approve-vendor-alert"
    />
  );
};

export default ApproveVendorAlert;
