import { Col, Divider, Row, Select } from 'antd'
import { ErrorMessage, FieldArray, Form, Formik } from 'formik'
import React, { FC, useEffect, useRef, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BadgeTypes } from '../../../../../../../enums/badgeTypes'
import { VendorInstoreCreationModel, VendorInstoreModel } from '../../../../../../../models/Vendor/Instore/instore.model'
import MetaService from '../../../../../../../services/MetaService/meta.service'
import VendorService from '../../../../../../../services/VendorService/vendor.service'
import ButtonComponent from '../../../../../../../shared/components/ButtonComponent'
import CardComponent from '../../../../../../../shared/components/CardComponent'
import CardSkeletonComponent from '../../../../../../../shared/components/CardSkeletonComponent'
import Error from '../../../../../../../shared/components/Error'
import InputField from '../../../../../../../shared/components/InputField'
import MapComponent from '../../../../../../../shared/components/MapComponent'
import SwitchComponent from '../../../../../../../shared/components/SwitchComponent'
import { countryCodesInitialValue, generateCodeOptions, getCountryIsdCode } from '../../../../../../../shared/utils/generateCountryCodeOptions'
import { vendorInstoreFormValidation } from '../VendorInstoreForm/vendorInstoreFormValidation'
import "./instoreFormView.scss"
import {LocalStorage} from "../../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../../models/User/user.model";
import {AppRoutes} from "../../../../../../../routes/routeConstants/appRoutes";
import backIcon from "./../../../../../../../assets/images/back-icon.png"
import { hideBrowserSuggestions } from '../../../../../../../shared/utils/hideBrowserSuggestions'


const InstoreFormView: FC = () => {
    const user = LocalStorage.getItem('USER') as UserModel
    const navigate = useNavigate()
    const location = useLocation()
    const data = location?.state as VendorInstoreModel
    const [initialValues, setInitialValues] =
        useState<VendorInstoreCreationModel>({} as VendorInstoreCreationModel);
    const { updateVendorInstore, createVendorInstore, vendorInstore, loading: buttonLoading } = VendorService();
    const { getCountryCodesMeta, countryCodesMeta, loading } = MetaService();
    const { vendorId, vendorName } = useParams();
    const formRef = useRef<any>(null);
    const isAdmin = user?.role === BadgeTypes.ADMIN;
    const isEdit = Object.entries(data)?.length > 1
    useEffect(() => {
        if(user?.role === BadgeTypes.VIEWER){
            navigate(AppRoutes.CUSTOMERS);
        }
        setInitialValues(
            isEdit ? { ...data,  countryCodeId: data?.countryCodeId ?? countryCodesInitialValue(countryCodesMeta), switchStatus: data?.status==="approved" } : { ...new VendorInstoreCreationModel(), status: BadgeTypes.APPROVED, countryCodeId: countryCodesInitialValue(countryCodesMeta), switchStatus: true }
        );
    }, [countryCodesMeta]);

    useEffect(() => {
        if(vendorInstore?.id)  navigate({pathname:generatePath(AppRoutes.VENDOR_INSTORES_ADD_POS,{vendorName, vendorId, instoreId: vendorInstore.id?.toString()})},{state: isEdit})
    }, [vendorInstore])

    const onSubmit = (values: VendorInstoreCreationModel) => {
        values.status = values.switchStatus ? BadgeTypes.APPROVED : BadgeTypes.INACTIVE
        if (data?.id) {
            updateVendorInstore(data?.id, values);
        } else {
            createVendorInstore(vendorId??"",values);
        }
    };

    const goBack = () => navigate(-1)

    useEffect(() => {
        getCountryCodesMeta()
    }, [])

    return (
        <div className="instore-form-view">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={onSubmit}
                innerRef={formRef}
                validationSchema={vendorInstoreFormValidation}
            >
                {({ values, setFieldValue, handleSubmit, errors }) => (
                    <Form onScroll={hideBrowserSuggestions}>
                        <div className="header">
                            <Row>
                                <Col span={19}>
                                    <h2 className='title'>
                                        <span className="back-icon" onClick={goBack}>
                                        <img src={backIcon} alt="back" className="back-icon-image" />
                                        </span>
                                        <span className='text'>
                                        {isEdit ? "Edit store" : "Add new store"}
                                        </span>
                                    </h2>
                                </Col>
                                <Col span={5}>
                                    <div className="controllers">
                                        {isAdmin && (<ButtonComponent
                                            type={"primary"}
                                            htmlType={"submit"}
                                            size="medium"
                                            loading={buttonLoading}
                                        >
                                            {isEdit ? "Save & Proceed" : "Create Store & Proceed"}
                                        </ButtonComponent>)}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="content">

                            {loading ? <CardSkeletonComponent /> : (
                            <div className='form-section' title=''>
                                <div className="vendor-instore-form">
                                    <Row gutter={16}>
                                        <Col className="filter-field" span={12}>
                                            <InputField
                                                label="Store Name"
                                                name="name"
                                                placeholder="Enter"
                                            />
                                        </Col>
                                        <Col span={12}/>
                                        <Col className="filter-field" span={12}>
                                            <InputField
                                                label="Contact Person First Name"
                                                name="contactFirstName"
                                                placeholder="Enter"
                                            />
                                        </Col>
                                        <Col className="filter-field" span={12}>
                                            <InputField
                                                label="Contact Person Last Name"
                                                name="contactLastName"
                                                placeholder="Enter"
                                            />
                                        </Col>
                                        <Col className="filter-field" span={12}>
                                            <InputField label="Email" name="email" placeholder="Enter" />
                                        </Col>
                                        <Col className="filter-field" span={12}>
                                        <InputField
                                            addonBefore={<Select
                                                value={values?.countryCodeId ?? ''}
                                                onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                                                if(typeof value !== "string"){
                                                    setFieldValue("countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                                                    setFieldValue("countryCodeId", value)
                                                } 
                                                setFieldValue("mobileNumber", undefined)
                                                }}
                                                options={generateCodeOptions(countryCodesMeta)}
                                                />
                                            }
                                            name="mobileNumber"
                                            label="Phone Number"
                                            placeholder="Enter" />
                                            {/* TODO: needed for next phase */}
                                            {/* <InputField
                                                name="mobileNumber"
                                                label="Phone Number"
                                                placeholder="Enter" /> */}
                                        </Col>
                                        <Col className="filter-field" span={24}>
                                            <MapComponent
                                                label='Address'
                                                position={
                                                    values?.lat && values?.long
                                                        ? {
                                                            lat: values?.lat ?? 0,
                                                            lng: values?.long ?? 0,
                                                            address: values?.address ?? "",
                                                        }
                                                        : undefined
                                                }
                                                onChange={(position) => {
                                                    setFieldValue("lat", position?.lat);
                                                    setFieldValue("long", position?.lng);
                                                    setFieldValue("address", position?.address);
                                                }}
                                                error={errors?.address}
                                            />
                                        </Col>
                                        <Col className="filter-field" span={24}>
                                            <SwitchComponent
                                                label="Status"
                                                value={values?.switchStatus ?? (values?.status === "approved" || values?.status === "inactive")}
                                                checkedTitle="Active"
                                                unCheckedTitle="Inactive"
                                                onSwitch={(value) => setFieldValue("switchStatus", value)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            )}
                            <Divider/>

                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default InstoreFormView