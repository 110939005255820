
import {serializable, alias, object, list, primitive} from 'serializr';

export class Installment { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('customer_purchase_id', primitive()))
	customerPurchaseId?: number;

	@serializable(alias('customer_payment_id', primitive()))
	customerPaymentId?: number;

	@serializable(alias('due_date', primitive()))
	dueDate?: string;

	@serializable(alias('payment_date', primitive()))
	paymentDate?: string;

	@serializable(alias('min_amount', primitive()))
	minAmount?: number;

	@serializable(alias('paid_amount', primitive()))
	paidAmount?: number;

	@serializable(alias('late_fee', primitive()))
	lateFee?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('installment_no', primitive()))
	installmentNo?: number;

	@serializable(alias('installment_amount', primitive()))
	installmentAmount?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('old_amount', primitive()))
	oldAmount?: number;

}