import React, { FC } from "react";
import ModalComponent from "../ModalComponent";
import "./deleteAlert.scss";

interface DeleteAlertProps {
  sucessHandler: () => void;
  cancelHandler: () => void;
  title: string;
  visible: boolean;
  description?: string;
  successBtnText?: string;
  cancelBtnText?: string;
  isDeleting?: boolean
}

const DeleteAlert: FC<DeleteAlertProps> = (props) => {
  const { title, sucessHandler, cancelHandler, visible, description, successBtnText, cancelBtnText, isDeleting } = props;

  return (
    <ModalComponent
      type="alert"
      className="delete-alert"
      alert={{
        title,
        description
      }}
      visible={visible}
      closeHandler={cancelHandler}
      successProps={{
        title: successBtnText ?? "Delete",
        clickHandler: sucessHandler,
        loading: isDeleting
      }}
      cancelProps={{
        title: cancelBtnText ?? "Close",
        clickHandler: cancelHandler,
      }}
    />
  );
};

export default DeleteAlert;
