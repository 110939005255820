import React, { FC, useEffect, useState } from "react";
import "./pastSettlements.scss";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../../shared/components/TableComponent";
import { Form, Formik } from "formik";
import { Col, DatePicker, Row } from "antd";
import InputField from "../../../../../shared/components/InputField";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import { SettlementService } from "../../../../../services/SettlementService/settlement.service";
import {
  PastSettlementListModel,
} from "../../../../../models/Settlement/settlement.model";
import NoTransactionsImg from "../../../../../assets/empty/transactions.svg";
import moment, { Moment } from "moment";
import { removeUndefined } from "../../../../../shared/utils/removeUndefined";
import { SortFilterModel } from "../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../shared/types/SortColumn.type";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { generateDateString } from "../../../../../shared/utils/generateDateString";
import { RangePickerValueType } from "../../../../../shared/types/rangePicker.type";
import { convertToTwoDecimals } from "../../../../../shared/utils/convertToTwoDecimals";
import MetaService from "../../../../../services/MetaService/meta.service";
import DropdownField from "../../../../../shared/components/DropdownField";
import { objectHasKeys } from "../../../../../shared/utils/objectHasKeys";
import { getTimezoneISOString } from "../../../../../shared/utils/getTimezoneISOString";
import { StateProps } from "../../../../../models/Meta/meta.model";
import { parseDate } from "../../../../../shared/utils/formatDate";
interface PastSettlementsProps {}

const { RangePicker } = DatePicker;

const PastSettlements: FC<PastSettlementsProps> = (props) => {

  const [isFilterShown, setIsFilterShown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location.state as StateProps;
  const isPastSettlement = location.hash === "#past-settlements";
  const pageNumber = isPastSettlement ? stateValue?.pageNumber as number : null;
  let filterOptions = isPastSettlement ? stateValue?.filterOptions as SortFilterModel : null;
  const [page, setPage] = useState(pageNumber ?? 1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const { pastSettlements, fetchPastSettlementList, loading, paginations } =
    SettlementService();
  const {fetchVendorsMeta, vendorsMeta} = MetaService()
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const startDate = parseDate(filterOptions?.startDate)
  const endDate = parseDate(filterOptions?.endDate)
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([startDate ?? null, endDate ?? null]);
  const filterInitialValues = filterOptions ?? new SortFilterModel();
  const showTest = LocalStorage.getItem("IS_TEST")

  const handleFilterSubmit = (values: SortFilterModel) => {
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  useEffect(() => {
    objectHasKeys(filters) &&
    fetchPastSettlementList({page:1, ...filters, ...sortOptions, showTest});
  }, [filters]);
  
  useEffect(() => {
    location.hash === "#past-settlements" &&
    fetchPastSettlementList({page, ...filters, ...sortOptions, showTest});
  }, [location.hash])

  useEffect(() => {
    fetchVendorsMeta({showTest, pagination: false})
  }, [])
  
  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              location.state = null;
              filterOptions = null;
              setPage(1);
              handleFilterClose();
              setDateRange([null, null])
              setFilters(() => ({
                ...filterOptions,
                search: filters?.search,
              }));
              resetForm({values: {}});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <DropdownField
                    label="Vendor Name"
                    placeholder="Select"
                    name="vendorId"
                    value={values?.vendorId}
                    options={vendorsMeta}
                    onChange={(value) => setFieldValue("vendorId", value)}
                  />
                </Col>
                <Col span={12}/>
                <Col span={12}>
                  <InputField
                    name="transactionId"
                    placeholder="Enter"
                    label="Vendor Settlement ID"
                  />
                </Col>
                <Col span={12}>
                  <div className="input-field">
                    <label>{"Settled Date Range"}</label>
                    <RangePicker
                      disabledDate={(date) => date.isAfter(moment())}
                      value={dateRange}
                      onChange={(values) => {
                        if(values?.length){
                        setDateRange(values)
                        const dateString = generateDateString(values as RangePickerValueType)
                        if(values?.length){
                          setFieldValue("startDate", dateString.includes('/') ? dateString.split(' - ')[0] : "")
                          setFieldValue("endDate", dateString.includes('/') ? dateString.split(' - ')[1] : "")
                        }
                      }
                      }}
                      format={'DD/MM/YYYY'} />
                  </div>
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  const handlePageChange = (page:number) => {
    location.state = null;
    setPage(page)
    fetchPastSettlementList({...filters, page, ...sortOptions, search, showTest})
  } 
  return (
    <div className="past-settlements">
      <TableComponent
      paginationMeta={paginations}
      pageNumber={pageNumber ?? page}
      handlePageChange = {handlePageChange}
      onChange={() => fetchPastSettlementList({...filters, page, ...sortOptions, search, showTest})}
        onSort={(data)=>{
         setSortOptions(data)
        }}
        filtersSorts={{page, ...filters, ...sortOptions, showTest}}
        search={{
          placeholder: "Search by vendor settlement ID",
          onSearch: (value) => {
            setSearch(value)
            handleFilterSubmit({ search: value });
          },
        }}
        empty={{
          text: "No past settlements",
          img: NoTransactionsImg,
        }}
        filter={filter}
        rowClassName={() => "cursor-pointer"}
        onRow={(data) =>
          navigate(
            {pathname: generatePath(AppRoutes.PAST_SETTLEMENTS_VIEW, {
              settlementId: data?.id,
            })}, {state: {pageNumber: page, filterOptions: filters}}
          )
        }
        columns={[
          {
            title: "Vendor ID",
            dataIndex: "vendor",
            key: SortColumnTypes.PAST_VENDOR_SETTLEMENTS_VENDOR_ID,
            render: (text: PastSettlementListModel["vendor"]) => text?.id,
            sorter: true,
          },
          {
            title: "Vendor",
            dataIndex: "vendor",
            key: SortColumnTypes.PAST_VENDOR_SETTLEMENTS_VENDOR_NAME,
            render: (text: PastSettlementListModel["vendor"]) => (
              <span className="vendor__name">
                <img src={text?.logo?.s3Url} />
                {text?.name}
              </span>
            ),
            sorter: true,
            width: "10%"
          },

          {
            title: "Settled Date",
            dataIndex: "settledDate",
            key: SortColumnTypes.PAST_VENDOR_SETTLEMENTS_SETTLED_DATE,
            render: (settledDate, obj) => moment(settledDate ?? obj.createdAt)?.format("DD MMM YYYY"),
            sorter: true,
          },

          {
            title: "Vendor Settlement ID",
            dataIndex: "transactionId",
            key: "transactionId",
          },
          {
            title: "Purchase Time Period",
            dataIndex: "purchaseTimePeriod",
            render: (text, record: PastSettlementListModel) => (
            <span>
              {moment(record?.minimumPurchaseDate)?.format("DD MMM YYYY")} - {moment(record?.maximumPurchaseDate)?.format("DD MMM YYYY")}
            </span>
            ),
            width: "20%"
          },
          {
            title: "# Of Purchases",
            dataIndex: "noOfOrders",
            key: SortColumnTypes.PAST_VENDOR_SETTLEMENTS_NO_OF_ORDERS,
            sorter: true,
          },
          {
            title: "Total Vendor Settlement",
            dataIndex: "vendorSettlementAmount",
            key: SortColumnTypes.PAST_VENDOR_SETTLEMENTS_VENDOR_SETTLEMENT_AMOUNT,
            render: (text, record: PastSettlementListModel) => (
              <span>
                {text ? convertToTwoDecimals(text) : 0.00}
              </span>
            ),
            sorter: true,
          },
        ]}
        data={pastSettlements}
        loading={loading}
      />
    </div>
  );
};

export default PastSettlements;
