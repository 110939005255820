import { axiosInstance }  from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import {TokenModel, TotpModel, UserModel} from "../../models/User/user.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import { AppRoutes } from "../../routes/routeConstants/appRoutes";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";
import {
  ForgotPasswordRequestModel,
  LoginRequestModel,
  NewPasswordRequestModel,
  ResetPasswordTokenRequestModel, VerifyTokenRequestModel,
} from "../../models/Auth/auth.model";

const UserService = () => {
  const navigate = useNavigate();
  const location = useLocation();


  const [error, setError] = useState<Error>();

  const [loading, setLoading] = useState(false);

  const { setAuthenticated, setUnauthenticated } = AuthContext();

  const [profile, setProfile] = useState<UserModel>();

  const isMobile = window.innerWidth < 480
    type ResponseData<T = { [key: string]: unknown }> = {
        data: T;
    };
    function loginWithToken(response: ResponseData){
        const user = deserialize(UserModel, response.data["admin"]);
        const token = deserialize(TokenModel, response.data["token"]);
        LocalStorage.setItem("USER", user);
        LocalStorage.setItem("ACCESS_TOKEN", token?.accessToken);
        LocalStorage.setItem("REFRESH_TOKEN", token?.refreshToken);
        if(window.innerWidth <= 480) LocalStorage.setItem("MOBILE_DEVICE", true);
        Notification({
            description: "Logged in successfully",
            type: NotificationTypes.SUCCESS,
        });
        setAuthenticated(user);
        isMobile ? navigate({pathname: AppRoutes.CUSTOMERS, hash: "#approve-customers"}) : navigate(AppRoutes.HOME);
    }
  const loginUser = (data: LoginRequestModel) => {
    const requestPayload = { admin: serialize(LoginRequestModel, data) };
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirectUrl');
    setLoading(true);
    return axiosInstance
      .post(ApiRoutes.USER_LOGIN, requestPayload)
      .then((response) => {
        if(response?.data?.admin){
          loginWithToken(response);
        }
        const user = LocalStorage.getItem("USER")
        !user && LocalStorage.setItem("USER", {email: requestPayload.admin.email});
        const accessToken = LocalStorage.getItem("ACCESS_TOKEN");
        (accessToken && user?.id) && setAuthenticated(user);
        (redirectUrl && accessToken) ? window.location.replace(decodeURIComponent(redirectUrl)) : accessToken ? navigate(AppRoutes.HOME) : navigate(AppRoutes.OTP, {state: response?.data?.message});
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyToken = (data: VerifyTokenRequestModel) => {
    const requestPayload = { admin: serialize(VerifyTokenRequestModel, data) };
    setLoading(true);
    return axiosInstance
        .post(ApiRoutes.USER_VERIFY_TOKEN, requestPayload)
        .then((response) => {
            loginWithToken(response);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const sendPasswordResetLinkToUser = (
    data: ForgotPasswordRequestModel,
    onSuccess: Function
  ) => {
    const requestPayload = {
      admin: serialize(ForgotPasswordRequestModel, data),
    };
    setLoading(true);

    return axiosInstance
      .post(ApiRoutes.USER_FORGOT_PASSWORD, requestPayload)
      .then((response) => {
        Notification({
          description: response?.data?.success,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateNewUserPassword = (
    data: NewPasswordRequestModel,
    onSuccess: Function
  ) => {
    const requestPayload = {admin: serialize(NewPasswordRequestModel, data)};
    setLoading(true);
    return axiosInstance
      .put(ApiRoutes.USER_NEW_PASSWORD, requestPayload)
      .then((response) => {
        Notification({
          description: "Your password has been changed successfully.",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateUserResetPasswordToken = (
    data: ResetPasswordTokenRequestModel
  ) => {
    const requestPayload = {
      admin: serialize(ResetPasswordTokenRequestModel, data),
    };
    return axiosInstance.put(
      ApiRoutes.USER_RESET_PASSWORD_TOKEN_VALIDATE,
      requestPayload
    );
  };
  const logoutUser = () => {
    setLoading(true)
    return axiosInstance
      .delete(ApiRoutes.USER_LOGOUT)
      .then(() => {
        setUnauthenticated();
        localStorage.clear(); 
        window.location.replace(AppRoutes.LOGIN);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePassword = (
    data: UserModel,
    onSuccess: Function
  ) => {
    const requestPayload = {admin: serialize(UserModel, data)};
    setLoading(true);
    return axiosInstance
      .put(ApiRoutes.ME, requestPayload)
      .then((response) => {
        const admin = deserialize(UserModel, response?.data?.admin);
        setAuthenticated(admin);
        LocalStorage.setItem("USER", admin);
        Notification({
          description: "Your password has been changed successfully.",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editProfile = (
    data: UserModel,
    onSuccess: Function
  ) => {
    const requestPayload = {admin: serialize(UserModel, data)};
    setLoading(true);
    return axiosInstance
      .put(ApiRoutes.ME, requestPayload)
      .then((response) => {
        const admin = deserialize(UserModel, response?.data?.admin);
        setProfile(admin)
        setAuthenticated(admin);
        LocalStorage.setItem("USER", admin);
        Notification({
          description: "Your profile has been updated successfully.",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateTotpQR = (data: UserModel) => {
      const requestPayload = {admin: serialize(UserModel, data)};
      setLoading(true);
      return axiosInstance
          .post(ApiRoutes.GENERATE_TOTP, requestPayload)
          .then((response) => {
            return response.data as TotpModel;
          })
          .catch((error) => {
              setError(error);
          })
          .finally(() => {
              setLoading(false);
          });
  };

    const verifyOtpCode = (data: UserModel) => {
        const requestPayload = {admin: serialize(UserModel, data)};
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.VERIFY_TOTP, requestPayload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

  return {
    error,
    loading,
    loginUser,
    verifyToken,
    sendPasswordResetLinkToUser,
    updateNewUserPassword,
    validateUserResetPasswordToken,
    logoutUser,
    changePassword,
    editProfile,
    profile,
    generateTotpQR,
    verifyOtpCode
  };
};

export default UserService;
