import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import LoginForm from "../../views/Auth/LoginForm";
import { RouterProps } from "../../shared/types/route.type";
import { Col, Row } from "antd";
import riseLogo from '../../assets/images/rise-logo.png';
import hummingBird from '../../assets/images/humminbird.png';
import "./auth.scss";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { AppRoutes } from "../../routes/routeConstants/appRoutes";
import NewPasswordForm from "./NewPasswordForm";

const authRouter = () => {
  const routes: RouterProps[] = [
    { path: AppRoutes.LOGIN, component: <LoginForm /> },
    { path: AppRoutes.FORGOT_PASSWORD, component: <ForgotPasswordForm /> },
    { path: AppRoutes.NEW_PASSWORD, component: <NewPasswordForm /> },
  ];

  return (
    <Row className="auth">
      <Col xs={0} xl={13} sm={13}>
        <div className="landing-image__container">
            <img src={riseLogo} alt="logo" className="logo"/>
            <div className="content">
              <img src={hummingBird} alt="bird" className="bird"/>
            </div>
          </div>
      </Col>
      <Col xs={24} xl={11}>
        <div className="auth-form__container">
          <Outlet />
        </div>
      </Col>
    </Row>
  );
};

export default authRouter;
