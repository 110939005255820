import React, { FC, useState, useEffect, useRef } from "react";
import IconComponent from "../../../../shared/components/IconComponent";
import TableComponent from "../../../../shared/components/TableComponent";
import "./customerPurchaseConfiguration.scss";
import faker from "faker";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import ModalComponent from "../../../../shared/components/ModalComponent";
import { Col, Row } from "antd";
import InputField from "../../../../shared/components/InputField";
import ConfigurationService from '../../../../services/ConfigurationService/configuration.service'
import { configurationSchema } from "./configurationSchema";
import { ConfigurationListModel } from "../../../../models/Configuration/configuration.model";
import { serialize } from "serializr";

interface CustomerPurchaseConfigurationProps { }
interface configObjProps {
  customerProcessingFee: number;
  deallScoreRange: string;
  id: number;
  installments: number;
  purchasePower: number;
}
interface CustomerPurchaseConfigurationFormProps {
  visible: boolean;
  closeHandler: () => void;
  data?: configObjProps;
  callback: Function;
}

const CustomerPurchaseConfigurationForm: FC<
  CustomerPurchaseConfigurationFormProps
> = (props) => {
  const { visible, closeHandler, data, callback } = props;
  const [initialValues, setInitialValues] = useState<any>({});
  const { loading, updateConfigurationList } = ConfigurationService()
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const onSubmit = (values: FormikValues) => {
    delete values?.deallScoreRange
    let body = {
      customer_purchase_configs: serialize(ConfigurationListModel,values)
    }
    updateConfigurationList(values.id, body).then(()=>{
      closeHandler()
      callback()
    })
  };

  const resetFormAndClose = () =>{
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }

  useEffect(() => {
    if (data) {
      setInitialValues({
        id: data.id,
        customerProcessingFee: data.customerProcessingFee,
        purchasePower: data.purchasePower,
        installments: data.installments,
        deallScoreRange: data.deallScoreRange
      })
    }
  }, [data, visible])
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={configurationSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <ModalComponent
          visible={visible}
          closeHandler={resetFormAndClose}
          type="small"
          title="Customer Purchase Config"
          successProps={{
            title: "Update",
            clickHandler: handleSubmit,
            loading: loading
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          className="purchase-config-form-modal"
        >
          <Form>
            <div className="purchase-config-form">
              <Row gutter={16}>
                <Col span={24}>
                  <InputField
                    label="Internal Score"
                    name="deallScoreRange"
                    placeholder="Enter"
                    disabled

                  />
                </Col>
                <Col span={24}>
                  <InputField
                    label="Processing Fee %"
                    name="customerProcessingFee"
                    placeholder="Enter"
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    label="Purchasing Power"
                    name="purchasePower"
                    placeholder="Enter"
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    label="Installments"
                    name="installments"
                    placeholder="Enter"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

const CustomerPurchaseConfiguration: FC<CustomerPurchaseConfigurationProps> = (
  props
) => {
  const [list, setList] = useState([])
  const [selectedVal, setSelectedVal] = useState()
  const { loading,fetchConfigurationList, configrationList } = ConfigurationService()
  useEffect(() => {
    fetchConfigurationList()
  },[])

  useEffect(() => {
    let newList: any = configrationList?.map((val: any) => {
      let first = val?.deallScoreRange[0];
      let last = val?.deallScoreRange[9];
      val.deallScoreRange = (first+" - "+last)
      return val
    })
    setList(newList)
  }, [configrationList])


  const { } = props;

  const [isPurchaseConfigFormShown, setIsPurchaseConfigFormShown] = useState(false);

  /* const [data, setData] = useState([
    {
      internalScore: "1 - 10",
      purchasingPower: faker?.datatype.number(),
      installments: faker.datatype.number(10),
      customerProcessingFee: "10",
    },
    {
      internalScore: "10 - 20",
      purchasingPower: faker?.datatype.number(),
      installments: faker.datatype.number(10),
      customerProcessingFee: "10",
    },
    {
      internalScore: "20 - 30",
      purchasingPower: faker?.datatype.number(),
      installments: faker.datatype.number(10),
      customerProcessingFee: "10",
    },
  ]); */
  return (
    <div className="customer-purchase-configuration">
      <CustomerPurchaseConfigurationForm
        visible={isPurchaseConfigFormShown}
        closeHandler={() => setIsPurchaseConfigFormShown(false)}
        data={selectedVal}
        callback={()=>fetchConfigurationList()}
      />
      <div className="label">Customer Purchase Configuration</div>
      <TableComponent
       loading={loading}
        data={list}
        columns={[
          {
            dataIndex: "deallScoreRange",
            key: "deallScoreRange",
            title: "Internal Score",
            width: "14%"
          },
          {
            dataIndex: "purchasePower",
            key: "purchasePower",
            title: "Purchasing Power",
            width: "14%",
            className: "text-right"
          },
          {
            dataIndex: "customerProcessingFee",
            key: "customerProcessingFee",
            title: "Customer Processing Fee %",
            render: (text) => <div className="text-center">{text + "%"}</div>,
            width: "30%"

          },
          {
            dataIndex: "installments",
            key: "installments",
            title: "Installments",
            width: "12%",
            className: "text-center"
          },
          {
            title: "",
            key: "action",
            width: "25%",
            render: (val) => (
              <div className="purchase-config-action">
                <IconComponent
                  name="icon-edit"
                  onClick={() => {
                    setSelectedVal(val)
                    setIsPurchaseConfigFormShown(true);
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default CustomerPurchaseConfiguration;
