import { Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import TabCountComponent from "../../../shared/components/TabCountComponent";
import ApproveInstores from "./ApproveInstores";
import ApproveVendors from "./ApproveVendors";
import VendorList from "./VendorList";
import "./vendors.scss";
const { TabPane } = Tabs;
interface VendorsProps {}

const Vendors: FC<VendorsProps> = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [approvedVendorsCount, setApprovedVendorsCount] = useState(0);
  const [unapprovedVendorsCount, setUnapprovedVendorsCount] = useState();
  const [unapprovedInstoresCount, setUnapprovedInstoresCount] = useState();

  useEffect(() => {
    if (!location?.hash)
      navigate(
        { pathname: AppRoutes.VENDORS, hash: "list" },
        { replace: true }
      );
  }, [location]);
  return (
    <PageView
      title={{
        name: "Vendors",
        icon: <IconComponent name="icon-vendors" />,
      }}
    >
      <Tabs
        activeKey={location.hash?.replace(/#/gi, "")}
        className="vendors"
        onTabClick={(value) => {
          navigate({ pathname: location.pathname, hash: value });
        }}
      >
        <TabPane key="list" 
          tab={
            <TabCountComponent
              title="Vendors"
              count={approvedVendorsCount ?? 0}
            />
          }>
          <VendorList setCount={setApprovedVendorsCount}/>
        </TabPane>
        <TabPane
          key="approve-vendors"
          tab={
            <TabCountComponent
              title="Approve Vendors"
              count={unapprovedVendorsCount ?? 0}
            />
          }
          forceRender
        >
          <ApproveVendors setCount={setUnapprovedVendorsCount} />
        </TabPane>
        <TabPane
          key="approve-instores"
          tab={
            <TabCountComponent
              title="Approve Instores"
              count={unapprovedInstoresCount ?? 0}
            />
          }
          forceRender
        >
          <ApproveInstores setCount={setUnapprovedInstoresCount} />
        </TabPane>
      </Tabs>
    </PageView>
  );
};

export default Vendors;
