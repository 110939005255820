import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'antd'
import InfoIcon from "./../../../../../assets/images/info-fill.svg"
import "./refundDetailsCard.scss"
import PaymentAdjustmentModal from './PaymentAdjustmentModal'
import CalculationModal from './CalculationModal'
import UploadIcon from "./../../../../../assets/images/export.svg"
import UploadComponent from '../../../../../shared/components/UploadComponent'
import { Formik, FormikValues } from 'formik'
import { DocumentTypes } from '../../../../../enums/documentTypes'
import S3AttachmentService from '../../../../../services/S3UploadService/s3upload.service'
import PurchaseService from '../../../../../services/PurchaseService/purchase.service'
import { PurchaseModel } from '../../../../../models/Purchase/purchase.model'
import moment from 'moment'
import { RefundTypeDefinition } from '../../../../../definitions/refundTypeDefinition'
import { RefundTypes } from '../../../../../enums/refundTypes'
import CardSkeletonComponent from '../../../../../shared/components/CardSkeletonComponent'
import { RefundDetails } from '../../../../../models/RefundDetails/refundDetails.model'
import refundIcon from "./../../../../../assets/images/refund-title.png"
interface RefundDetailsCardProps { 
    purchase?: PurchaseModel
}

const RefundDetailsCard: FC<RefundDetailsCardProps> = (props) => {
    const { purchase } = props
    const {uploadAttachment} = S3AttachmentService()
    const {viewPurchaseRefund, loading, purchaseRefund, editPurchaseRefund} = PurchaseService()
    const [paymentAdjustmentModalVisible, setPaymentAdjustmentModalVisible] = useState(false)
    const [calculationModalVisible, setCalculationModalVisible] = useState(false)
    const [initialValues, setInitialValues] = useState<RefundDetails>(new RefundDetails())

    const openPaymentAdjustmentModal = () => setPaymentAdjustmentModalVisible(true)
    const closePaymentAdjustmentModal = () => setPaymentAdjustmentModalVisible(false)

    const openCalculationModal = () => setCalculationModalVisible(true)
    const closeCalculationModal = () => setCalculationModalVisible(false)

    const onSubmit = (values: FormikValues) => editPurchaseRefund(purchase?.purchaseId as string, values, ()=>{})

    useEffect(() => {
        purchase?.purchaseId && viewPurchaseRefund(purchase?.purchaseId, ()=>{})
    }, [purchase])

    useEffect(() => {
        setInitialValues({...purchaseRefund, receiptAttachmentId: purchaseRefund?.receiptAttachment?.id})
    }, [purchaseRefund])
    
    return (
        <div className="refund-details-card">
                {loading ?
                <CardSkeletonComponent rows={4} /> :
                <div className="refund-details-card__container">
                <div className="metrics">
                    <Row className='metrics-banner'>
                        <Col span={24}>
                            <div className="title">
                                <img src={refundIcon} alt="" />
                                <span className='text'>Refund Details</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="metrics-column">
                                <div className="label">
                                    <h4>REFUND AMOUNT ON THE RECEIPT</h4>
                                </div>
                                <div className="value">
                                    <p>LE {purchaseRefund?.refundAmount}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="metrics-column">
                                <div className="label">
                                    <h4>REFUND AMOUNT PROCESSED BY RISE</h4>
                                </div>
                                <div className="value">
                                    <p>LE {(purchaseRefund?.cashRefundAmount || purchaseRefund?.refundType === RefundTypes.CASH_REFUND) ? purchaseRefund?.cashRefundAmount : purchaseRefund?.refundAmount} <span><img onClick={openCalculationModal} className='info-icon tooltip-component' src={InfoIcon} alt="" /> </span> </p>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="metrics-column">
                                <div className="label">
                                    <h4>REFUND METHOD</h4>
                                </div>
                                <div className="value">
                                    <p>
                                        {RefundTypeDefinition[purchaseRefund?.refundType as RefundTypes]} 
                                        {
                                            purchaseRefund?.refundType !== RefundTypes.CASH_REFUND && 
                                            <span>
                                                <img onClick={openPaymentAdjustmentModal} className='info-icon tooltip-component' src={InfoIcon} alt="" />
                                            </span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="info">
                    <Row gutter={[56, 24]}>
                        <Col className='left' span={12}>
                            <div className="info-column">
                                <h3>Refund Receipt ID</h3>
                                <p>{purchaseRefund?.receiptId}</p>
                            </div>
                            <div className="info-column">
                                <h3>Returned Date</h3>
                                <p>{moment(purchaseRefund?.returnedDate).format("DD MMM YYYY") }</p>
                            </div>
                            <div className="info-column">
                                <h3>Refund Receipt</h3>
                                <p className='upload-section'>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={onSubmit}
                                        enableReinitialize
                                    >
                                        {({ handleSubmit, setFieldValue, errors, values }) => (
                                            <Form>
                                                <UploadComponent
                                                    isUploading={false}
                                                    accept={"image/png, image/jpg, image/jpeg"}
                                                    fileUrl={values?.receiptAttachment?.s3Url as string}
                                                    fileId={values?.receiptAttachment?.id as string}
                                                    fileType={values?.receiptAttachment?.fileType as string}
                                                    fileName={values?.receiptAttachment?.filename as string}
                                                    onUpload={async (file, fileUrl) => {
                                                        setFieldValue("receiptAttachment.s3Url", fileUrl);
                                                        setFieldValue("receiptAttachment.filename", file?.name);
                                                        const _receipt = await uploadAttachment(file, DocumentTypes.REFUND_RECEIPT);
                                                        setFieldValue("receiptAttachment.receiptAttachmentId", _receipt);
                                                        _receipt && onSubmit({receiptAttachmentId: _receipt})
                                                    }}
                                                    title="Upload Document"
                                                    type="inline-card"
                                                    error={errors?.receiptAttachmentId}
                                                >
                                                    {/* TODO change icon to blue */}
                                                    {/* <img className='upload-icon' src={UploadIcon} alt="upload-icon" /> */}
                                                     Upload
                                                </UploadComponent>
                                            </Form>)}
                                    </Formik>
                                </p>
                            </div>
                        </Col>
                        <Col className='right' span={12}>
                            <div className="info-column">
                                <h3>Refund Created by</h3>
                                <p>{purchaseRefund?.createdBy}</p>
                            </div>
                            <div className="info-column">
                                <h3>Refund Created on</h3>
                                <p>{ moment(purchaseRefund?.createdAt).format("DD MMM YYYY")}, {moment(purchaseRefund?.createdAt).format("HH:mm")}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                </div> }
            <PaymentAdjustmentModal purchaseRefund={purchaseRefund} visible={paymentAdjustmentModalVisible} closeHandler={closePaymentAdjustmentModal} />
            <CalculationModal purchaseRefund={purchaseRefund} purchase={purchase!} visible={calculationModalVisible} closeHandler={closeCalculationModal} />
        </div>
    )
}

export default RefundDetailsCard