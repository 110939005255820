import React, { FC, useEffect, useRef, useState } from 'react'
import IconComponent from '../../../shared/components/IconComponent';
import PageView from '../../../shared/components/PageView'
import "./dashboard.scss"
import { useNavigate } from 'react-router-dom';
import NorthStartKpiComponent from './NorthStartKpiComponent';
import LineChartComponent from '../../../shared/components/LineChartComponent';
import { Col, Divider, Row } from 'antd';
import DoughnutComponent from '../../../shared/components/DoughnutComponent';
import { paymentLogsConfig } from './paymentLogsConfig';
import { numberOfTransactionsConfig } from './numberOfTransactionsConfig';
import PaymentKpiComponent from './PaymentKpiComponent';
import { allPaymentConfig } from './allPaymentConfig';
import BarChartComponent from '../../../shared/components/BarChartComponent';
import { paymentMethodConfig } from './paymentMethodConfig';
import { vendorTransactionsNumberConfig } from './vendorTransactionsNumberConfig';
import { vendorTransactionsValueConfig } from './vendorTransactionsValueConfig';
import DashboardService, { graphDataType } from '../../../services/DasboardService/dashboard.service';
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import { UserModel } from "../../../models/User/user.model";
import { BadgeTypes } from "../../../enums/badgeTypes";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import SettlementComponent from './SettlementComponent/Settlementcomponent';
import Viewondesktop from '../../../shared/components/Viewondesktop';
import useWindowWidth from '../../../shared/utils/useWindowWidth';
import DashboardFilterMenu from '../../../shared/components/DashboardFilterMenu';
import { Dashboard as DashboardModel, DashboardFilterModel } from '../../../models/Dashboard/dashboard.model';
import { DashboardDateFilterTypes } from '../../../shared/types/DashboardDateFilterTypes';
import ButtonComponent from "../../../shared/components/ButtonComponent";
import ReportForm from "../Reports/ReportForm";
import folderIcon from "../../../assets/images/folder.png"

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const showTest = LocalStorage.getItem('IS_TEST')
  const [params, setParams] = useState<DashboardFilterModel | undefined>({ dateFilterKey: DashboardDateFilterTypes["CURRENT_YEAR"], showTest })
  const user = LocalStorage.getItem('USER') as UserModel
  const windowWidth = useWindowWidth();
  const [isDasboardNorthStartLoading, setIsDasboardNorthStartLoading] = useState(true)
  const [isDasboardPurchasesLoading, setIsDasboardPurchasesLoading] = useState(true)
  const [isDasboardPurchasesVisible, setIsDasboardPurchasesVisible] = useState(false)
  const [isDasboardPaymentsLoading, setIsDasboardPaymentsLoading] = useState(true)
  const [isDasboardPaymentsVisible, setIsDasboardPaymentsVisible] = useState(false)
  const [isDasboardSettlementsLoading, setIsDasboardSettlementsLoading] = useState(true)
  const [isDasboardSettlementsVisible, setIsDasboardSettlementsVisible] = useState(false)
  const [isReportFormShown, setIsReportFormShown] = useState(false);

  const dashboardPurchasesRef = useRef<HTMLDivElement>(null);
  const dashboardPaymentsRef = useRef<HTMLDivElement>(null);
  const dashboardSettlementsRef = useRef<HTMLDivElement>(null);

  const isMobile = windowWidth <= 480;
  if (user?.role === BadgeTypes.VIEWER) {
    navigate(AppRoutes.CUSTOMERS);
  }
  const {
    loading,
    fetchDashboardKeyMetrics,
    dashboardKeyMetrics,
    fetchDashboardTransactionValues,
    dashboardTransactionValues,
    fetchDashboardTransactionCounts,
    dashboardTransactionCounts,
    fetchDashboardTransactionPerVendor,
    dashboardTransactionPerVendor,
    fetchDashboardTransactionValuePerVendor,
    dashboardTransactionValuePerVendor,
    fetchDashboardPaymentMethods,
    dashboardPaymentMethods,
    fetchDashboardPayments,
    dashboardPayments,
    fetchDashboardPaymentKeyMetrics,
    dashboardPaymentsKeyMetrics } = DashboardService()

  const fetchDashboardNorthStarData = async (values?: DashboardFilterModel) => {
    setIsDasboardNorthStartLoading(true)
    await fetchDashboardKeyMetrics(values)
    setIsDasboardNorthStartLoading(false)
  }

  const fetchDashboardPurchasesData = async (values?: DashboardFilterModel) => {
    setIsDasboardPurchasesLoading(true)
    await Promise.all(
      [fetchDashboardTransactionValues(values),
      fetchDashboardTransactionPerVendor(values),
      fetchDashboardTransactionValuePerVendor(values),
      fetchDashboardTransactionCounts(values)]
    )
    setIsDasboardPurchasesLoading(false)
  }

  const fetchDashboardPaymentsData = async (values?: DashboardFilterModel) => {
    setIsDasboardPaymentsLoading(true)
    await Promise.all(
      [fetchDashboardPaymentMethods(values),
      fetchDashboardPayments(values),
      fetchDashboardPaymentKeyMetrics(values)]
    )
    setIsDasboardPaymentsLoading(false)
  }

  useEffect(() => {
    isDasboardPurchasesVisible &&
      fetchDashboardPurchasesData(params)
  }, [isDasboardPurchasesVisible])

  useEffect(() => {
    isDasboardPaymentsVisible &&
      fetchDashboardPaymentsData(params)
  }, [isDasboardPaymentsVisible])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry?.target?.id === "purchase-ref" && setIsDasboardPurchasesVisible(true);
          entry?.target?.id === "payment-ref" && setIsDasboardPaymentsVisible(true);
          entry?.target?.id === "settlement-ref" && setIsDasboardSettlementsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (dashboardPurchasesRef.current) {
      observer.observe(dashboardPurchasesRef.current);
    }

    if (dashboardPaymentsRef.current) {
      observer.observe(dashboardPaymentsRef.current);
    }

    if (dashboardSettlementsRef.current) {
      observer.observe(dashboardSettlementsRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [params]);


  return (
    <PageView type='dashboard' title={{
      name: "Dashboard",
      icon: <IconComponent name="icon-dashboard" />,
    }}>
      {isMobile ? <Viewondesktop /> :
        <div className="dashboard-view">
          <ReportForm
            successHandler={() => {
              setIsReportFormShown(false);
            }}
            visible={isReportFormShown}
            closeHandler={() => setIsReportFormShown(false)} />
          <div className="header">
            <Divider />
            <div className='date-range-container'>
              <div className='date'><IconComponent name="icon-calender" />
                <h3> Date Range: </h3> &nbsp;
                <DashboardFilterMenu setParams={setParams} onChange={(values) => {
                  setIsDasboardPurchasesVisible(false)
                  setIsDasboardPaymentsVisible(false)
                  setIsDasboardSettlementsVisible(false)
                  fetchDashboardNorthStarData(values)
                }} />
              </div>
              <div className='report' onClick={() => setIsReportFormShown(true)}><img src={folderIcon} className='icon-folder' /> <span className='label'>Create Report</span></div>
            </div>
            <Divider />
          </div>
          <Row gutter={[24, 40]}>
            <Col span={24}>
              <NorthStartKpiComponent keyMetricsLoading={isDasboardNorthStartLoading} dashboardKeyMetrics={dashboardKeyMetrics as DashboardModel} />
            </Col>
            <Col span={24}>
              <div className="dashboard-header purchases">
                <h2 className='dashboard-title'>Purchases</h2>
              </div>
            </Col>
            <Col span={12} id="purchase-ref" ref={dashboardPurchasesRef}>
              <LineChartComponent data={dashboardTransactionValues} loading={isDasboardPurchasesLoading} classname="small" options={paymentLogsConfig} title="LE value of purchases" />
            </Col>
            <Col span={12}>
              <LineChartComponent onChange={fetchDashboardTransactionCounts} data={dashboardTransactionCounts} loading={isDasboardPurchasesLoading} classname="small" options={numberOfTransactionsConfig} title="Number of purchases" />
            </Col>
            <Col span={12}>
              <BarChartComponent onChange={fetchDashboardTransactionPerVendor} data={dashboardTransactionPerVendor} loading={isDasboardPurchasesLoading} options={vendorTransactionsNumberConfig} title='# of purchases per vendor' />
            </Col>
            <Col span={12}>
              <BarChartComponent onChange={fetchDashboardTransactionValuePerVendor} data={dashboardTransactionValuePerVendor} loading={isDasboardPurchasesLoading} options={vendorTransactionsValueConfig} title='Value of purchases per vendor' />
            </Col>
            {/* Commenting as change might be required in the future */}
            {/* <Col span={12}>
              <DoughnutComponent transactionPerCustomerLoading={isDasboardPurchasesLoading} dashboardTransactionPerCustomer={dashboardTransactionPerCustomer as graphDataType} />
            </Col> */}
            <Col span={24} id="payment-ref" ref={dashboardPaymentsRef}>
              <PaymentKpiComponent paymentKeyMetricsLoading={isDasboardPaymentsLoading} dashboardPaymentsKeyMetrics={dashboardPaymentsKeyMetrics as DashboardModel} />
            </Col>
            <Col span={12}>
              <BarChartComponent onChange={fetchDashboardPaymentMethods} data={dashboardPaymentMethods} loading={isDasboardPaymentsLoading} options={paymentMethodConfig} title='Payment Methods' />
            </Col>
            <Col span={12}>
              <LineChartComponent onChange={fetchDashboardPayments} data={dashboardPayments} loading={isDasboardPaymentsLoading} classname="small" backgroundColor='rgba(255, 114, 158, .2)' borderColor='#FF729E' title='LE value of payments' options={allPaymentConfig} />
            </Col>
            <Divider />
            <Col span={24} id="settlement-ref" ref={dashboardSettlementsRef}>
              <SettlementComponent setIsDasboardSettlementsLoading={setIsDasboardSettlementsLoading} isDasboardSettlementsLoading={isDasboardSettlementsLoading} isDasboardSettlementsVisible={isDasboardSettlementsVisible} params={params as DashboardFilterModel} />
            </Col>
          </Row>
        </div>
      }
    </PageView>
  )
}

export default Dashboard