import { Tabs } from "antd";
import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import OutstandingPayments from "./OutstandingPayments";
import OverduePayments from "./OverduePayments";
import PaymentLogs from "./PaymentLogs";
import "./payments.scss";
const { TabPane } = Tabs;

interface PaymentsProps {}

const Payments: FC<PaymentsProps> = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.hash)
      navigate(
        { pathname: AppRoutes.PAYMENTS, hash: "payment-logs" },
        { replace: true }
      );
  }, [location]);
  return (
    <PageView
      title={{
        name: "Payments",
        icon: <IconComponent name="icon-payments" />,
      }}
    >
      <Tabs
        activeKey={location.hash?.replace(/#/gi, "")}
        onTabClick={(value) => {
          navigate({ pathname: location.pathname, hash: value });
        }}
      >
        <TabPane key="payment-logs" tab="Payment Logs">
          <PaymentLogs />
        </TabPane>
        <TabPane key="outstanding-payments" tab="Outstanding Payments">
          <OutstandingPayments />
        </TabPane>
        <TabPane key="overdue-payments" tab="Overdue Payments">
          <OverduePayments />
        </TabPane>
      </Tabs>
    </PageView>
  );
};

export default Payments;
