import { Badge, Col, Divider, Dropdown, Row } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import "./notifications.scss";
import NotificationsReadIcon from "../../../../assets/images/notifications.svg";
import NotificationsUnreadIcon from "../../../../assets/images/notifications.svg";
import NotificationService from "../../../../services/NotificationService/notification.service";
import IconComponent from "../../IconComponent";
import { NotificationModel } from "../../../../models/notification.model";
import { InAppNotificationTypes } from "../../../../enums/inAppNotificationTypes";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
interface NotificationsProps { }

const Notifications: FC<NotificationsProps> = (props) => {
  const { } = props;
  const navigate = useNavigate()
  const { loading, error, getNotifications, notifications , updateNotificationIsSeen} = NotificationService()

  const _updateNotification = (notification:NotificationModel) => {
    !notification?.isSeen &&
    updateNotificationIsSeen(`${notification?.id}`).then(()=>{
      getNotifications()
    })
    switch (notification?.notificationType) {
      case InAppNotificationTypes.RETURN_STATUS:
        const purchaseId=notification?.data?.customerPurchase?.id?.toString()
        navigate(generatePath(AppRoutes.PURCHASES_VIEW,{purchaseId}))
        break;

      case InAppNotificationTypes.PURCHASE_STATUS:
        navigate(generatePath(AppRoutes.CONFIGURATION))
        break;

      case InAppNotificationTypes.INSTORE_STATUS:
          const vendorId = notification?.data?.vendor?.id?.toString()
          const vendorName = notification?.data?.vendor?.name?.toString()
          navigate(generatePath(AppRoutes.VENDOR_INSTORES,{vendorId, vendorName}))        
        break;

      default:
        break;
    } 
  }

  const notificationsList = (
    <div className="notifications-list">
      {notifications?.length ?
        notifications?.map((value, index) => {
          return (
            <Fragment>
              <Row
                className={`notification-item ${value?.isSeen ? "read" : "unread"}`}
                onClick={()=>_updateNotification(value)}
              >
                <Col className="notification-icon" span={3}>
                  <img
                    alt=""
                    src={
                      value?.isSeen
                        ? NotificationsReadIcon
                        : NotificationsUnreadIcon
                    }
                  />
                </Col>
                <Col className="notification-details" span={19}>
                  <div className="title">{value?.body}</div>
                  <div className="date">{value?.createdAt && new Date(value?.createdAt)?.toDateString()}</div>
                </Col>
              </Row>
              <Divider />
            </Fragment>
          );
        }) :
        <div className="empty-view">
          <img src={NotificationsReadIcon} alt="" className="empty-img" />
          <p className="empty-text">{"No notifications"}</p>
        </div>
      }
    </div>
  );
  return (
    <Dropdown
      className="notifications"
      trigger={["click"]}
      overlay={notificationsList}
      onVisibleChange={isVisible => isVisible && getNotifications()}
    >
      <Badge className="notification-badge" count={notifications?.[0]?.noOfUnreadNotifications ?? 0}>
        <IconComponent name="icon-bell" />
      </Badge>
    </Dropdown>
  );
};

export default Notifications;
