
import {serializable, alias, object, list, primitive} from 'serializr';
import { UpcomingPlan } from '../UpcomingPlan/upcomingPlan.model';

export class CustomerDetails {
	@serializable(alias("processing_fee", primitive()))
	processingFee?: number;
}
export class ActivePlan { 

	@serializable(alias('due_date', primitive()))
	dueDate?: string;

	@serializable(alias('min_amount', primitive()))
	minAmount?: number;

	@serializable(alias('installment_no', primitive()))
	installmentNo?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('total_installments', primitive()))
	totalInstallments?: number;

	@serializable(alias('is_overdue', primitive()))
	isOverdue?: boolean;
	
	@serializable(alias('active_plan', primitive()))
	activePlan?: string;
	
	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('purchasing_power', primitive()))
	purchasingPower?: number;

	@serializable(alias('customer_details', object(CustomerDetails)))
	customerDetails?: CustomerDetails;

}

export class ActiveAndUpcomingPlan {
	@serializable(alias('active_plan', object(ActivePlan)))
	activePlan?: ActivePlan;
	
	@serializable(alias('upcoming_plan', object(UpcomingPlan)))
	upcomingPlan?: UpcomingPlan;
}

