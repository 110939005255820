import { Col, Row, Select } from "antd";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import React, { FC, useEffect, useState, useRef } from "react";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import ModalComponent from "../../../../shared/components/ModalComponent";
import SwitchComponent from "../../../../shared/components/SwitchComponent";
import "./deallUserForm.scss";

import DeallUserService from "../../../../services/DeallUsersService/dealluser.service";
import { BadgeTypesDefinition } from "../../../../definitions/badgeTypesDefinition";
import { deallUserFormValidation } from "./deallUserFormValidation";
import { serialize } from "serializr";
import { DeallUsersModel } from "../../../../models/DeallUsers/deallusers.model";
import { countryCodesInitialValue, generateCodeOptions, getCountryIsdCode } from "../../../../shared/utils/generateCountryCodeOptions";
import MetaService from "../../../../services/MetaService/meta.service";
import RadioComponent from "../../../../shared/components/RadioComponent";

interface DeallUserFormProps {
  visible: boolean;
  deallUser?: any;
  closeHandler: () => void;
  onUpdate: () => void;
}

const DeallUserForm: FC<DeallUserFormProps> = (props) => {
  const { visible, closeHandler, deallUser, onUpdate } = props;
  const [initialValues, setInitialValues] = useState<any>({});
  const { createDeallUser, updateDeallUser, fetchDeallUserList, loading, error } = DeallUserService();
  const { getCountryCodesMeta, countryCodesMeta } = MetaService();
  const [modalLabel, setModalLabel] = useState("Add New ");
  const [modalSuccessButton, setModalSuccessButton] = useState("Create");
  const formRef = useRef<FormikProps<FormikValues>>(null);
  useEffect(()=>{
    getCountryCodesMeta()
  }, [])
  useEffect(() => {
    if (Object.entries(deallUser).length) {
      const deallUserObj = {
        firstName: deallUser.firstName,
        lastName: deallUser.lastName,
        mobileNumber: deallUser.mobileNumber,
        email: deallUser.email,
        fullName: deallUser.fullName,
        id: deallUser.id,
        role: deallUser.role,
        status: deallUser.status === "approved",
        countryCodeId: deallUser.countryCodeId ?? '',
        countryCode: deallUser.countryCode,
        mfaType: deallUser.mfaType
      }
      setModalLabel("Edit ");
      setModalSuccessButton("Update")
      deallUser && setInitialValues(deallUserObj)
    }
    else {
      setModalLabel("Add New ");
      setModalSuccessButton("Create");
      setInitialValues({
        "firstName": "",
        "lastName": "",
        "email": "",
        "password": "",
        "role": null,
        "mobileNumber": "",
        "status": "",
        "isdCode": "+20",
        "countryCodeId": countryCodesInitialValue(countryCodesMeta),
        "countryCode": {
          "isdCode": "+20"
        }
      })
    }
  }, [deallUser, visible])
  const handleClose = () => {
    setInitialValues({
      "firstName": "",
      "lastName": "",
      "email": "",
      "password": "",
      "role": null,
      "mobileNumber": "",
      "status": "",
      "isdCode": "+20",
      "countryCodeId": countryCodesInitialValue(countryCodesMeta),
      "countryCode": {
        "isdCode": "+20"
      }

    })
    closeHandler()


  }
  const onSubmit = (values: FormikValues) => {
    const payload = {
      ...values,
      status: values.status ? "approved" : "inactive"
    }
    const obj = {
      admin: serialize(DeallUsersModel,payload)
    }
    if (modalSuccessButton === "Create") 
    createDeallUser(obj, () => {
      onUpdate()
      resetFormAndClose()
    })
    else {
      updateDeallUser(values.id, obj, () => {
        onUpdate()
        resetFormAndClose()
      })
    }
  };
  const resetFormAndClose = () =>{
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
      validationSchema={deallUserFormValidation}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <ModalComponent
          title={modalLabel + "Rise User"}
          visible={visible}
          closeHandler={resetFormAndClose}
          successProps={{
            title: modalSuccessButton,
            clickHandler: handleSubmit,
            loading
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose
          }}
          type="large"
        >
          <div className="deall-user-form">
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <InputField
                    placeholder="Enter"
                    name="firstName"
                    label="First Name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    placeholder="Enter"
                    name="lastName"
                    label="Last Name"
                  />
                </Col>
                <Col span={12}>
                  <InputField placeholder="Enter" name="email" label="Email" />
                </Col>
                <Col span={12}>
                  <InputField
                    addonBefore={<Select
                      value={values?.countryCodeId ?? ''}
                      onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                        if(typeof value !== "string"){
                          setFieldValue("countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                          setFieldValue("countryCodeId", value)
                        } 
                        setFieldValue("mobileNumber", undefined)
                      }}
                      options={generateCodeOptions(countryCodesMeta)}
                      />
                    }
                    name="mobileNumber"
                    label="Phone Number"
                    placeholder="Enter" />
                </Col>
                <Col span={12}>
                  <DropdownField
                    placeholder="Select"
                    name="role"
                    label="Role"
                    value={values?.role}
                    onChange={(value) => setFieldValue("role", value)}
                    options={[
                      {
                        value: BadgeTypes.ADMIN,
                        label: BadgeTypesDefinition[BadgeTypes.ADMIN],
                      },
                      {
                        value: BadgeTypes.MANAGER,
                        label: BadgeTypesDefinition[BadgeTypes.MANAGER],
                      },
                      {
                        value: BadgeTypes.VIEWER,
                        label: BadgeTypesDefinition[BadgeTypes.VIEWER],
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    placeholder="Enter"
                    name="password"
                    label="Password"
                    type="text"
                    className="password-field"
                  />
                </Col>
                <Col span={12}>
                  <SwitchComponent
                    label="Status"
                    value={values?.status}
                    checkedTitle={"Active"}
                    unCheckedTitle={"Inactive"}
                    onSwitch={(value) => setFieldValue("status", value)}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default DeallUserForm;
