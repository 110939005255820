import React, { FC, useEffect, useState } from "react";
import TableComponent from "../../../../../../shared/components/TableComponent";
import { currencyString } from "../../../..";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import { useParams } from "react-router-dom";
import { tableSorter } from "../../../../../../shared/utils/tableSorter";
import { PaymentModeTypesDefinition } from "../../../../../../definitions/paymentModeTypesDefinition";
import { PaymentModeTypes } from "../../../../../../enums/paymentModeTypes";
import moment from "moment";
import { CustomerPaymentModel } from "../../../../../../models/Payment/payment.model";
import { SortFilterModel } from "../../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../../shared/types/SortColumn.type";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import BadgeComponent from "../../../../../../shared/components/BadgeComponent";
import { BadgeTypes } from "../../../../../../enums/badgeTypes";

export const CustomerPayments: FC = () => {
  const { fetchCustomerPayments, loading, customerPayments, paginations } = CustomerService();
  const { customerId } = useParams();
  const [page, setPage] = useState(1)
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const showTest = LocalStorage.getItem("IS_TEST")
  const handlePageChange = (page: number) => {
    setPage(page)
    customerId &&
      fetchCustomerPayments({ page, ...sortOptions, showTest }, customerId)
  }
  useEffect(() => {
    if (customerId) fetchCustomerPayments({showTest}, customerId);
  }, []);
  return (
    <TableComponent
      onChange={fetchCustomerPayments}
      onChangeParam={customerId}
      onSort={(data) => {
        setSortOptions(data)
      }}
      filtersSorts={{ page, showTest }}
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      search={{
        placeholder: "Search by external payment id",
        onSearch: (value) => {
          if (customerId) fetchCustomerPayments({ search: value, ...sortOptions, showTest }, customerId)
        },
      }}
      loading={loading}
      columns={[
        {
          title: "External Payment ID",
          dataIndex: "externalPaymentId",
          key: SortColumnTypes.EXTERNAL_PAYMENT_ID,
          sorter: true,
          render: (text, record) => record?.transactionType === "return" ? "-" : text
        },
        {
          title: "Payment Date",
          dataIndex: "createdAt",
          key: SortColumnTypes.PAYMENT_DATE,
          sorter: true,
          render: (text) => moment(text)?.format("DD MMM YYYY"),
        },
        {
          title: "Payment Time",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (text) => moment(text)?.local().format("HH:mm")
        },
        {
          title: "Payment Mode",
          dataIndex: "paymentMode",
          key: "paymentMode",
          render: (text: PaymentModeTypes) => PaymentModeTypesDefinition[text]
        },
        {
          title: "Payment ID",
          dataIndex: "transactionId",
          key: "transactionId",
          render: (text, record) => record?.transactionType === "return" ? "-" : text
        },
        {
          title: "Purchase ID",
          dataIndex: "customerPurchaseId",
          key: SortColumnTypes.PURCHASE_ID,
          sorter: true,
        },
        {
          title: "Payment Amount",
          dataIndex: "totalAmount",
          key: SortColumnTypes.PAYMENT_AMOUNT,
          render: (text, record: CustomerPaymentModel) => (
            <span className={(record?.isAmountNegative||record?.paymentStatus==="failure") ? "payment-loss" : "payment-profit"}>
              <span>
                {record?.paymentStatus==="failure" ? "" : record?.isAmountNegative ? "-" : "+"}&nbsp; {currencyString}
                {record?.amount}
              </span>
              {
                record?.paymentStatus==="failure" &&
                <BadgeComponent type={record?.paymentStatus as BadgeTypes} />
              }
            </span>
          ),
          sorter: true,
        },
      ]}
      data={customerPayments}
    />
  );
};
