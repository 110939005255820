import { CustomerPayments, PendingCollectionList } from './../../models/Collection/collection.model';
import moment from "moment";
import { PendingSettlementModel, SettlementCustomerPurchaseModel } from "../../models/Settlement/settlement.model";
import { PendingPartnerFees, SettledPartnerFees } from "../../models/PartnerFees/partnerFees.model";

const getRange = (dates: number[]) => {
    let minDate = new Date(Math.min.apply(null, dates))
    let maxDate = new Date(Math.max.apply(null, dates))

    return `${moment(minDate).format("DD MMM YYYY")} - ${moment(maxDate).format("DD MMM YYYY")}`
}

export const getDateRange = (settlementData: PendingSettlementModel[]): string => {
    const dates = settlementData?.map(data => data?.createdAt && new Date(data.createdAt).getTime()) as number[]
    return getRange(dates)
}

export const getPartnerDateRange = (settlementData: PendingPartnerFees[]): string => {
    const dates = settlementData?.map(data => data?.transactionDate && new Date(data.transactionDate).getTime()) as number[]
    return getRange(dates)
}

export const getCollectionrDateRange = (settlementData: PendingCollectionList[]): string => {
    const dates = settlementData?.map(data => data?.createdAt && new Date(data.createdAt).getTime()) as number[]
    return getRange(dates)
}

export const getPastVendorDateRange = (settlementData: SettlementCustomerPurchaseModel[]): string => {
    const dates = settlementData?.map(data => data?.createdAt && new Date(data.createdAt).getTime()) as number[]
    return getRange(dates)
}

export const getPastPartnerDateRange = (settlementData: SettledPartnerFees[]): string => {
    const dates = settlementData?.map(data => data?.createdAt && new Date(data.createdAt).getTime()) as number[]
    return getRange(dates)
}

export const getPastPaymentDateRange = (settlementData: CustomerPayments[]): string => {
    const dates = settlementData?.map(data => data?.createdAt && new Date(data.createdAt).getTime()) as number[]
    return getRange(dates)
}