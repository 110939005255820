import { GraphColors } from "../../../enums/graphColors";

export const paymentTransactionsNumberConfig = {
  responsive: true,
  aspectRatio: 3 / 2,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: '# OF ERRORS',
        color: GraphColors.YAXESCOLOR,
      },
      grid: {
        color: GraphColors.PAYMENTGRIDCOLOR,
      },
      min: 0,
      ticks: {
        precision: 0,
      },
    },
    x: {
      title: {
        display: false,
        text: 'ERROR TYPE',
        color: GraphColors.YAXESCOLOR,
      },
      grid: {
        color: GraphColors.PAYMENTGRIDCOLOR,
      },
      ticks: {
        display: false
      },
    },
  },
  borderRadius: 10,
  barThickness: 20,
  colours: [{ fillColor: GraphColors.PRIMARYBLACK }],
};
