
import {serializable, alias, primitive, list, object} from 'serializr';
import { UserModel } from '../User/user.model';
import { TransactionRange } from '../TransactionRange/transactionRange.model';

export class SettledPartnerFees { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('customer_id', primitive()))
	customerId?: number;

	@serializable(alias('external_payment_id', primitive()))
	externalPaymentId?: number;

	@serializable(alias('purchase_type', primitive()))
	purchaseType?: string;

	@serializable(alias('instore_id', primitive()))
	instoreId?: number;

	@serializable(alias('vendor_id', primitive()))
	vendorId?: number;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: string;

	@serializable(alias('customer_payment_status', primitive()))
	customerPaymentStatus?: string;

	@serializable(alias('vendor_settlement_status', primitive()))
	vendorSettlementStatus?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('customer_processing_fee', primitive()))
	customerProcessingFee?: number;

	@serializable(alias('return_payment_mode', primitive()))
	returnPaymentMode?: string;

	@serializable(alias('payment_mode', primitive()))
	paymentMode?: string;

	@serializable(alias('return_receipt', primitive()))
	returnReceipt?: object;

	@serializable(alias('customer_return_payment_status', primitive()))
	customerReturnPaymentStatus?: object;

	@serializable(alias('return_purchase_status', primitive()))
	returnPurchaseStatus?: string;

	@serializable(alias('reason_for_failure', primitive()))
	reasonForFailure?: object;

	@serializable(alias('customer_reference_number', primitive()))
	customerReferenceNumber?: object;

	@serializable(alias('pos_type', primitive()))
	posType?: object;

	@serializable(alias('partner_id', primitive()))
	partnerId?: number;

	@serializable(alias('partner_settlement_status', primitive()))
	partnerSettlementStatus?: string;

	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: string;

	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: string;

	@serializable(alias('vat', primitive()))
	vat?: string;

	@serializable(alias('partner_settlement_id', primitive()))
	partnerSettlementId?: number;

	@serializable(alias('partner_processing_fee', primitive()))
	partnerProcessingFee?: number;

	@serializable(alias('partner_processing_fee_vat', primitive()))
	partnerProcessingFeeVat?: number;

	@serializable(alias('settleable_type', primitive()))
	settleableType?: string;
	
	@serializable(alias('transaction_type', primitive()))
	transactionType?: string;

}

export class PendingPartnerFees { 

	@serializable(alias('id', primitive()))
	key?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('partner_id', primitive()))
	partnerId?: string;

	@serializable(alias('customer_purchase_id', primitive()))
	customerPurchaseId?: string;

	@serializable(alias('settleable_type', primitive()))
	settleableType?: string;

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('external_purchase_id', primitive()))
	externalPurchaseId?: object;

	@serializable(alias('transaction_type', primitive()))
	transactionType?: string;

	@serializable(alias('transaction_date', primitive()))
	transactionDate?: string;

	@serializable(alias('payment_id', primitive()))
	paymentId?: object;

	@serializable(alias('payment_mode', primitive()))
	paymentMode?: object;

	@serializable(alias('transaction_amount', primitive()))
	transactionAmount?: number;

	@serializable(alias('settleable_id', primitive()))
	settleableId?: number;

	@serializable(alias('partner_processing_fee', primitive()))
	partnerProcessingFee?: number;

	@serializable(alias('partner_processing_fee_vat', primitive()))
	partnerProcessingFeeVat?: number;

	@serializable(alias('partner_settlement_status', primitive()))
	partnerSettlementStatus?: string;

	@serializable(alias('settlement_amount', primitive()))
	settlementAmount?: number;

}

export class PastPartnerSettlements { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('settled_date', primitive()))
	settledDate?: string;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('transaction_type', primitive()))
	transactionType?: string;

	@serializable(alias('total_partner_fees', primitive()))
	totalPartnerFees?: number;

	@serializable(alias('no_of_orders', primitive()))
	noOfOrders?: number;

	@serializable(alias('total_vat', primitive()))
	totalVat?: number;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('transaction_range', object(TransactionRange)))
	transactionRange?: TransactionRange;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('settleables', list(object(SettledPartnerFees))))
	settleables?: SettledPartnerFees[] = [];

	@serializable(alias('admin', object(UserModel)))
	admin?: UserModel;

}

export class MarkSettledPendingSettlement {
	@serializable(alias('ids', list(primitive())))
	id?: React.Key[] = [];

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('admin_id', primitive()))
	adminId?: number;

	@serializable(alias('transaction_id', primitive()))
	partnerFeeSettlementId?: string;

	@serializable(alias('settled_date', primitive()))
	settledDate?: string;
}

export class Partner { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('purchase_fee', primitive()))
	purchaseFee?: number;

	@serializable(alias('purchase_fee_surcharge', primitive()))
	purchaseFeeSurcharge?: number;

	@serializable(alias('cash_payment_percent_fee', primitive()))
	cashPaymentPercentFee?: number;

	@serializable(alias('cash_payment_surcharge', primitive()))
	cashPaymentSurcharge?: number;

	@serializable(alias('card_payment_percent_fee', primitive()))
	cardPaymentPercentFee?: number;

	@serializable(alias('card_payment_surcharge', primitive()))
	cardPaymentSurcharge?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('vat', primitive()))
	vat?: number;

	@serializable(alias('ewallet_payment_percent_fee', primitive()))
	ewalletPaymentPercentFee?: number;

	@serializable(alias('ewallet_payment_surcharge', primitive()))
	ewalletPaymentSurcharge?: number;

	@serializable(alias('collect_payments_separately', primitive()))
	collectPaymentsSeparately?: boolean;

	@serializable(alias('refund_percent_fee', primitive()))
	refundPercentFee?: number;

	@serializable(alias('track_cashout_offline', primitive()))
	trackCashoutOffline?: boolean;

	@serializable(alias('cashout_percent_fee', primitive()))
	cashoutPercentFee?: number;

}

export class MarkSettledPendingSettlementDetails extends MarkSettledPendingSettlement {
	@serializable(alias('ids', list(primitive())))
	pendingSettlementIds?: number[] = [];

	@serializable(alias('partner_id', primitive()))
	partnerId?: string;

}