
import {serializable, alias, object, list, primitive, custom, serialize, deserialize} from 'serializr';

export class AddressData {
    @serializable(alias('city', primitive()))
    city?: string;

    @serializable(alias('street_address', primitive()))
    streetAddress?: string;

    @serializable(alias('neighbourhood', primitive()))
    neighbourhood?: string;

	@serializable(alias('region', primitive()))
    region?: string;

    @serializable(alias('building_number', primitive()))
    buildingNumber?: string | number;
}

export class Questionnaire {
    @serializable(alias('question_id', primitive()))
    question_id?: number;

    @serializable(alias('question', primitive()))
    question?: string;

    @serializable(alias('answer', custom(
        (data) => {
            if (Array.isArray(data) && data.length > 0 && typeof data[0] === 'string') {
                return data;
            } else if (Array.isArray(data) && data.length > 0 && typeof data[0] === 'object') {
                return data.map(item => serialize(AddressData, item));
            }
            return data;
        },
        (data) => {
            if (Array.isArray(data) && data.length > 0 && typeof data[0] === 'string') {
                return data;
            } else if (Array.isArray(data) && data.length > 0 && typeof data[0] === 'object') {
                return data.map(item => deserialize(AddressData, item));
            }
            return data;
        }
    )))
    answer?: string[] | AddressData[];
}
