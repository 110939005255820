import React, { FC } from "react";
import { currencyString } from "../../..";
import "./purchaseOutstandingPaymentsCard.scss";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";
import moment from "moment";
import { PurchaseOutstandingPaymentModel } from "../../../../../models/Purchase/purchase.model";
import TableComponent from "../../../../../shared/components/TableComponent";
import outstandingPaymentsImage from "./../../../../../assets/images/fi_3175010.png"
import { Col, Row } from "antd";

interface PurchaseOutstandingPaymentsCardProps {
  purchase: PurchaseModel;
}

const PurchaseOutstandingPaymentsCard: FC<
  PurchaseOutstandingPaymentsCardProps
> = (props) => {
  const { purchase } = props;
  const { outstandingPayments } = purchase;

  return (
    <div className="purchase-outstanding-payments-card purchase-card-table">
      <Row className="purchase-card-title" align="middle" gutter={12}>
        <Col>
          <img src={outstandingPaymentsImage} alt="" />
        </Col>
        <Col>
          <span className="text">Customer Outstanding Payments</span>
        </Col>
      </Row>
      <TableComponent 
        columns={[
          {
            title: "Due On",
            dataIndex: "dueDate",
            key: "dueDate",
            render: (text, record: PurchaseOutstandingPaymentModel) => (
              <div className="due-on__container">
                <span>{moment(text).format("DD MMM YYYY")}</span>
              </div>
            ),
          },
          {
            title: "Minimum Due",
            dataIndex: "minimumDue",
            key: "minimumDue",
            render: (text, record: PurchaseOutstandingPaymentModel) => (
              <span>
                {currencyString}
                {Number(text) + Number(record?.lateFee)}
              </span>
            ),
          },
        ]}
        data={outstandingPayments?.filter(
          (payment) =>
            !payment.isOverDue &&
            payment.minimumDue &&
            !(payment.minimumDue === 0)
        )}
      />
    </div>
  );
};

export default PurchaseOutstandingPaymentsCard;
