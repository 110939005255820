import { Col, Row, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import faker from "faker";
import UploadComponent from "../../../../../../shared/components/UploadComponent";
import "./vendorStorePromotions.scss";
import IconComponent from "../../../../../../shared/components/IconComponent";
import VendorService from "../../../../../../services/VendorService/vendor.service";
import {useNavigate, useParams} from "react-router-dom";
import {
  VendorStorePromotionCreationModel,
  VendorStorePromotionModel,
} from "../../../../../../models/Vendor/StorePromotion/storePromotion.model";
import SpinnerComponent from "../../../../../../shared/components/SpinnerComponent";
import S3AttachmentService from "../../../../../../services/S3UploadService/s3upload.service";
import {LocalStorage} from "../../../../../../shared/utils/localStorageHelpers";
import {UserModel} from "../../../../../../models/User/user.model";
import {BadgeTypes} from "../../../../../../enums/badgeTypes";
import {AppRoutes} from "../../../../../../routes/routeConstants/appRoutes";
const VendorStorePromotions: FC = () => {
  const navigate = useNavigate();
  const user = LocalStorage.getItem('USER') as UserModel
  const {
    vendorStorePromotions,
    loading,
    createVendorStorePromotion,
    fetchStorePromotionList,
    deleteVendorStorePromotion,
  } = VendorService();
  const [isUploading, setIsUploading] = useState(false);
  const [currentPromotion, setCurrentPromotion] =
    useState<VendorStorePromotionModel>();
  const { vendorId } = useParams();
  const { uploadAttachment } = S3AttachmentService()

  useEffect(() => {
    if(user?.role === BadgeTypes.VIEWER){
      navigate(AppRoutes.CUSTOMERS);
    }
    if (vendorId) fetchStorePromotionList(vendorId);
  }, [vendorId]);

  const uploadHandler = (payload: VendorStorePromotionCreationModel) => {
    if (vendorId) {
      setIsUploading(true);
      createVendorStorePromotion(vendorId, payload, () => {
        setIsUploading(false);
      });
    }
  };

  const deleteHandler = (vendorStorePromotionId: number) => {
    if (vendorStorePromotionId)
      deleteVendorStorePromotion(vendorStorePromotionId, () =>
        setCurrentPromotion(undefined)
      );
  };

  return loading ? (
    <SpinnerComponent tip="Loading..." />
  ) : (
    <Row className="vendor-store-promotions" gutter={[24, 24]}>
      {
        vendorStorePromotions?.length < 8 &&
        <Col span={8}>
          <UploadComponent
            fileName=""
            fileId=""
            fileType=""
            fileUrl=""
            accept={"image/png, image/jpg, image/jpeg"}
            type="poster"
            isUploading={isUploading}
            disabled={isUploading}
            onUpload={async (file, fileUrl) => {
              const attachment = await uploadAttachment(file);
              uploadHandler({
                attachment,
              });
            }}
            title="Upload Poster (16:9 aspect ratio)"
          />
        </Col>
      }
      {vendorStorePromotions?.map((value, index) => {
        return (
          <Col span={8}>
            <div
              className={`vendor-store-promotion__container ${value?.id == currentPromotion?.id && "is-deleting"
                }`}
            >
              {currentPromotion?.id == value?.id && (
                <SpinnerComponent
                  tip="Deleting..."
                  className="vendor-store-promotion-delete-spin"
                />
              )}
              <img
                className="vendor-store-promotion-img"
                src={value?.attachment?.s3Url}
                alt=""
              />
              <div
                className="vendor-store-promotion-close"
                onClick={() => {
                  setCurrentPromotion(value);
                  if (value?.id) deleteHandler(value?.id);
                }}
              >
                <IconComponent name="icon-close" />
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};
export default VendorStorePromotions;
