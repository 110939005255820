import { PaymentModeTypesDefinition } from "../../definitions/paymentModeTypesDefinition";
import { PaymentModeTypes } from "../../enums/paymentModeTypes";
import { getRandomColors } from "./generateRandomColors";

export const generateGraphData = (rawData: object, reverse?: boolean, backgroundColor?: string | string[], borderColor = "", isLineChart?: boolean) => {
    const paymentArray = Object.values(PaymentModeTypes) as string[]
    let labels = Object.keys(rawData).map((label: string) => {
        if(paymentArray.includes(label)){
            return PaymentModeTypesDefinition[label as PaymentModeTypes]
        }
        return label;
    })
    let data = Object.values(rawData)
    labels = reverse?labels.reverse():labels
    data = reverse?data.reverse():data
    if(isLineChart && data?.length === 1) {
        data.push(null)
        data.unshift(null)
        labels.push("")
        labels.unshift("")
    }
    const bgColor = labels.map(label => getRandomColors(label))
    return {
        labels,
        datasets:[{
            data,
            backgroundColor: backgroundColor ?? bgColor,
            borderColor
        }]
    }
}