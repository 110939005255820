import React, { FC, useEffect, useState } from "react";
import TableComponent from "../../../../../../shared/components/TableComponent";
import "./vendorSettlements.scss";
import { currencyString } from "../../../..";
import VendorService from "../../../../../../services/VendorService/vendor.service";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { SortFilterModel } from "../../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../../shared/types/SortColumn.type";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { AppRoutes } from "../../../../../../routes/routeConstants/appRoutes";

interface VendorSettlementsProps {}

const VendorSettlements: FC<VendorSettlementsProps> = (props) => {
  const {} = props;
  const navigate = useNavigate()
  const { fetchVendorSettlementList, loading, vendorSettlements, paginations } = VendorService();
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const [page, setPage] = useState(1)
  const showTest = LocalStorage.getItem("IS_TEST")
  
  const { vendorId } = useParams();

  const handlePageChange = (page:number) => {
    setPage(page)
    vendorId &&
    fetchVendorSettlementList({page, ...sortOptions, showTest}, vendorId)
  }

  useEffect(() => {
    if (vendorId) fetchVendorSettlementList({showTest}, vendorId);
  }, []);

  return (
    <div className="vendor-settlements">
      <TableComponent
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      onChange={fetchVendorSettlementList}
      onChangeParam={vendorId}
        onSort={(data)=>{
          setSortOptions(data)
        }}
        onRow={(record) =>
          navigate(
            generatePath(AppRoutes.PAST_SETTLEMENTS_VIEW, {
              settlementId: record?.id,
            })
          )
        }
        filtersSorts={{page, showTest}}
        data={vendorSettlements}
        loading={loading}
        columns={[
          {
            dataIndex: "settledDate",
            key: SortColumnTypes.VENDOR_SETTLEMENT_SETTLED_AT,
            render: (text) => moment(text)?.format("DD MMM YYYY"),
            title: "Settled Date",
            sorter: true,
          },
          {
            dataIndex: "noOfOrders",
            key: SortColumnTypes.VENDOR_SETTLEMENT_NO_OF_ORDERS,
            title: "# of Orders",
            sorter: true, 

          },
          {
            dataIndex: "transactionId",
            key: "transactionId",
            title: "Transaction ID",
          },
          {
            dataIndex: "settlementAmount",
            key: "settlementAmount",
            title: "Settlement Amount",
            render: (text, record) => (
              <span>
                {currencyString}
                {text}
              </span>
            ),
          },
        ]}
      />
    </div>
  );
};

export default VendorSettlements;
