import * as Yup from 'yup';
import { DatasourceTypes } from '../../../../../enums/datasourceTypes';

const validationSchema = Yup.object().shape({
  datasource: Yup.string().required("Data source is required"),
  factor: Yup.string().required("Factor name is required"),
  factorWeight: Yup.number()
  .when(['active', 'datasource'], {
    is: (active) => active,
    then: Yup.number().min(1, "Factor weight must be at least 1").max(100, "Factor weight must be at most 100").required("Factor weight is required"),
    else: Yup.number().min(0, "Factor weight must be at least 0").max(0, "Factor weight must be at most 0").required("Factor weight is required"),
  }),  questions: Yup.string().when('datasource', {
    is: DatasourceTypes.QUESTIONNAIRE_RESPONSES,
    then: Yup.string().required('Questions is required'),
  }),
  answers: Yup.array().of(
    Yup.object().shape({
      score: Yup.number().min(0, "Score must be at least 0").max(100, "Score must be at most 100").required("Score is required"),
    })
  ),
});

export default validationSchema;
