import { UserModel } from "./../User/user.model";
import { serializable, alias, object, primitive, list, map } from "serializr";
import { BadgeTypes } from "../../enums/badgeTypes";
import { DocumentTypes } from "../../enums/documentTypes";
import { PurchaseModeTypes } from "../../enums/purchaseModeTypes";
import { AttachmentModel } from "../Attachment/attachment.model";
import { VendorModel } from "../Vendor/vendor.model";
import { OnboardingDetail } from "../OnboardingDetail/onboardingDetail.model";
import { MetaModel, NeighbourhoodModel } from "../Meta/meta.model";
import { Questionnaire } from "../Questionnaire/questionnaire.model";
import { RejectedBy } from "../RejectedBy/rejectedBy.model";

const {
  BANK_STATEMENT,
  NATIONAL_ID_BACK,
  NATIONAL_ID_FRONT,
  PROFILE_PIC,
  EMPLOYMENT_LETTER,
  SELFIE_PIC,
  VEHICLE_CARD,
  PAYSLIP,
  SPORTS_CLUB_MEMBERSHIP,
  CREDIT_CARD_STATEMENT,
  PAYSLIP_BANK_STATEMENT,
  EMPLOYMENT_LETTER_MEDICAL_CARD,
  MEDICAL_CARD,
  STUDENT_CARD
} = DocumentTypes;

export class Parameters { 

	@serializable(alias('IOS', primitive()))
	IOS?: number;

	@serializable(alias('Male', primitive()))
	Male?: number;

	@serializable(alias('Android', primitive()))
	Android?: number;

	@serializable(alias('final_score', primitive()))
	finalScore?: number;

	@serializable(alias('system_score', primitive()))
	systemScore?: number;

	@serializable(alias('address_score', primitive()))
	addressScore?: number;

    @serializable(alias('doc_score', primitive()))
	docScore?: number;

	@serializable(alias('Submitted_email', primitive()))
	SubmittedEmail?: number;

	@serializable(alias('id_neighborhood_score', primitive()))
	idNeighborhoodScore?: number;

	@serializable(alias('has_approved_attachment', primitive()))
	hasApprovedAttachment?: number;

	@serializable(alias('questionnaire_neighborhood_score', primitive()))
	questionnaireNeighborhoodScore?: number;

  @serializable(alias('id_neighborhood_score_calculated', primitive()))
	idNeighborhoodScoreCalculated?: number;

  @serializable(alias('questionnaire_neighborhood_score_calculated', primitive()))
	questionnaireNeighborhoodScoreCalculated?: number;

}
export class CreditScoreBreakdown { 

	@serializable(alias('calculator', primitive()))
	calculator?: string;

	@serializable(alias('parameters', object(Parameters)))
	parameters?: Parameters;

}
export class CustomerListModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("name_from_id", primitive()))
  nameFromId?: string;

  @serializable(alias("nation_id_number", primitive()))
  nationalId?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("deall_score", primitive()))
  deallScore?: number;

  @serializable(alias("max_purchase_power", primitive()))
  maxPurchasePower?: number;

  @serializable(alias("processing_fee", primitive()))
  processingFee?: number;

  @serializable(alias("status", primitive()))
  status?: BadgeTypes.ACTIVE | BadgeTypes.INACTIVE | BadgeTypes.WAITLISTED;
 
  @serializable(alias("plan", primitive()))
  plan?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("onboarded_date", primitive()))
  onboardedDate?: string;

  @serializable(alias("onboarding_detail", object(OnboardingDetail)))
  onboardingDetail?: OnboardingDetail;

  @serializable(alias("onboarding_start_date", primitive()))
  onboardingStartDate?: string;

  @serializable(alias("country_code", object(MetaModel)))
  countryCode?: MetaModel;

  @serializable(alias("kyc_status", primitive()))
  kycStatus?: BadgeTypes.APPROVED | BadgeTypes.FAILED | BadgeTypes.KYC_PENDING;

  @serializable(alias("rejected_at", primitive()))
  rejectedAt?: string;

  @serializable(alias("rejected_by", object(RejectedBy)))
  rejectedBy?: RejectedBy;

  @serializable(alias("rejected_reason", primitive()))
  rejectedReason?: string;
}

export class CustomerModel {
  @serializable(alias("id", primitive()))
  id?: number;
  
  @serializable(alias("customer_attachment_id", primitive()))
  customerAttachmentId?: number;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("name_from_id", primitive()))
  nameFromId?: string;

  @serializable(alias("nation_id_number", primitive()))
  nationalIdNumber?: string;

  @serializable(alias("job_title", primitive()))
  jobTitle?: string;

  @serializable(alias("job_place", primitive()))
  jobPlace?: string;

  @serializable(alias("marital_status", primitive()))
  maritalStatus?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("os", primitive()))
  os?: string;

  @serializable(alias("religion", primitive()))
  religion?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("country_code", object(MetaModel)))
  countryCode?: MetaModel;

  @serializable(alias("status", primitive()))
  status?:
    | BadgeTypes.APPROVED
    | BadgeTypes.INACTIVE
    | BadgeTypes.PENDING_APPROVAL
    | BadgeTypes.REJECTED
    | BadgeTypes.WAITLISTED
    | BadgeTypes.FAILED_VERIFICATION;

  @serializable(alias("kyc_status", primitive()))
  kycStatus?: BadgeTypes.APPROVED | BadgeTypes.FAILED | BadgeTypes.KYC_PENDING;
  
  @serializable(alias("kyc_last_verified_at", primitive()))
  kycLastVerifiedAt?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("deall_score", primitive()))
  deallScore?: number;

  @serializable(alias("max_purchase_power", primitive()))
  maxPurchasePower?: number;

  @serializable(alias("processing_fee", primitive()))
  processingFee?: number;

  @serializable(alias("late_fee", primitive()))
  lateFee?: number;

  @serializable(alias("date_of_birth", primitive()))
  dateOfBirth?: string;

  @serializable(alias("display_picture_url", primitive()))
  displayPictureUrl?: string;

  @serializable(alias("profile_pic", list(object(AttachmentModel))))
  profilePic?: AttachmentModel[];
  
  @serializable(alias("proof_of_asset", list(object(AttachmentModel))))
  proofOfAsset?: AttachmentModel[];
  
  @serializable(alias("proof_of_income", list(object(AttachmentModel))))
  proofOfIncome?: AttachmentModel[];

  @serializable(alias("selfie_pic", list(object(AttachmentModel))))
  selfiePic?: AttachmentModel[];

  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: string;

  @serializable(alias("selfie_pic_id", primitive()))
  selfiePicId?: string;

  @serializable(alias("address", primitive()))
  address?: string;

  @serializable(alias("national_id_front", list(object(AttachmentModel))))
  nationalIdFront?: AttachmentModel[];

  @serializable(alias("national_id_back", list(object(AttachmentModel))))
  nationalIdBack?: AttachmentModel[];

  @serializable(alias("employment_letter", list(object(AttachmentModel))))
  employmentLetter?: AttachmentModel[];

  @serializable(alias("bank_statement", list(object(AttachmentModel))))
  bankStatement?: AttachmentModel[];

  @serializable(alias("employment_letter_medical_card", list(object(AttachmentModel))))
  employmentLetterMedicalCard?: AttachmentModel[];

  @serializable(alias("pay_slip_bank_statement", list(object(AttachmentModel))))
  paySlipBankStatement?: AttachmentModel[];

  @serializable(alias("vehicle_card", list(object(AttachmentModel))))
  vehicleCard?: AttachmentModel[];

  @serializable(alias("sports_club_membership", list(object(AttachmentModel))))
  sportsClubMembership?: AttachmentModel[];

  @serializable(alias("pay_slip", list(object(AttachmentModel))))
  paySlip?: AttachmentModel[];

  @serializable(alias("medical_card", list(object(AttachmentModel))))
  medicalCard?: AttachmentModel[];

  @serializable(alias("student_card", list(object(AttachmentModel))))
  studentCard?: AttachmentModel[];

  @serializable(alias("credit_card_statement", list(object(AttachmentModel))))
  creditCardStatement?: AttachmentModel[];

  @serializable(alias("property_contract", list(object(AttachmentModel))))
  propertyContract?: AttachmentModel[];

  @serializable(alias("national_id_front_id", primitive()))
  nationalIdFrontId?: string;

  @serializable(alias("national_id_back_id", primitive()))
  nationalIdBackId?: string;

  @serializable(alias("employment_letter_id", primitive()))
  employmentLetterId?: string;

  @serializable(alias("bank_statement_id", primitive()))
  bankStatementId?: string;

  @serializable(alias("student_card_id", primitive()))
  studentCardId?: string;

  @serializable(alias("medical_card_id", primitive()))
  medicalCardId?: string;

  @serializable(alias("vehicle_card_id", primitive()))
  vehicleCardId?: string;

  @serializable(alias("sports_club_membership_id", primitive()))
  sportsClubMembershipId?: string;

  @serializable(alias("pay_slip_id", primitive()))
  paySlipId?: string;

  @serializable(alias("credit_card_statement_id", primitive()))
  creditCardStatementid?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("onboarding_detail", object(OnboardingDetail)))
  onboardingDetail?: OnboardingDetail;

  @serializable(alias("admin_comments", primitive()))
  adminComments?: string;

  @serializable(alias("rejected_by", object(UserModel)))
  rejectedBy?: UserModel;

  @serializable(alias("rejected_at", primitive()))
  rejectedAt?: string;

  @serializable(alias("rejected_reason", primitive()))
  rejectedReason?: string;

  @serializable(alias("rejection_reasons", list(object(MetaModel))))
  rejectionReasons?: MetaModel[];

  @serializable(alias("onboarding_start_date", primitive()))
  onboardingStartDate?: string;

  @serializable(alias("questionnaire", list(object(Questionnaire))))
  questionnaire?: Questionnaire[] = [];

  @serializable(alias("credit_score", primitive()))
  creditScore?: number;

  @serializable(alias("acquisition_channel", primitive()))
  acquisitionChannel?: string;

  @serializable(alias("risky_customer", primitive()))
  riskyCustomer?: boolean;

  @serializable(alias("suspicion_reasons", list(object(MetaModel))))
  suspicionReasons?: MetaModel[];

  @serializable(alias("neighbourhood", object(NeighbourhoodModel)))
  neighbourhood?: NeighbourhoodModel;

  @serializable(alias("neighbourhood_id", primitive()))
  neighbourhoodId?: string;

  @serializable(alias("credit_score_breakdown", object(CreditScoreBreakdown)))
  creditScoreBreakdown?: CreditScoreBreakdown;

  @serializable(alias("neighbourhood_from_questionnaire", object(NeighbourhoodModel)))
  neighbourhoodFromQuestionnaire?: NeighbourhoodModel;

  @serializable(alias("is_address_matching", primitive()))
  isAddressMatching?: boolean;
}

export class CustomerEditModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("name_from_id", primitive()))
  nameFromId?: string;

  @serializable(alias("neighbourhood", object(NeighbourhoodModel)))
  neighbourhood?: NeighbourhoodModel;

  @serializable(alias("neighbourhood_id", primitive()))
  neighbourhoodId?: string;

  @serializable(alias("nation_id_number", primitive()))
  nationalIdNumber?: string;

  @serializable(alias("job_title", primitive()))
  jobTitle?: string;

  @serializable(alias("job_place", primitive()))
  jobPlace?: string;

  @serializable(alias("marital_status", primitive()))
  maritalStatus?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("religion", primitive()))
  religion?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("date_of_birth", primitive()))
  dateOfBirth?: string;

  @serializable(alias("profile_pic", object(AttachmentModel)))
  profilePic?: AttachmentModel;

  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: string;
  
  @serializable(alias("selfie_pic", object(AttachmentModel)))
  selfiePic?: AttachmentModel;

  @serializable(alias("selfie_pic_id", primitive()))
  selfiePicId?: string;

  @serializable(alias("address", primitive()))
  address?: string;

  @serializable(alias("national_id_front", object(AttachmentModel)))
  nationalIdFront?: AttachmentModel;

  @serializable(alias("national_id_back", object(AttachmentModel)))
  nationalIdBack?: AttachmentModel;

  @serializable(alias("national_id_front_id", primitive()))
  nationalIdFrontId?: string;

  @serializable(alias("national_id_back_id", primitive()))
  nationalIdBackId?: string;

  @serializable(alias("admin_comments", primitive()))
  adminComments?: string;

  @serializable(alias("status", primitive()))
  status?:
    | BadgeTypes.APPROVED
    | BadgeTypes.INACTIVE
    | BadgeTypes.PENDING_APPROVAL
    | BadgeTypes.REJECTED
    | BadgeTypes.WAITLISTED
    | BadgeTypes.FAILED_VERIFICATION;

  @serializable(alias("rejected_reason", primitive()))
  rejectedReason?: string;

  @serializable(alias("rejection_reasons", list(object(MetaModel))))
  rejectionReasons?: MetaModel[];

  @serializable(alias("rejection_reason_ids", list(primitive())))
  rejectedReasonIds?: number[];

  @serializable(alias("approval_reason", primitive()))
  approvalReason?: string;

  @serializable(alias("approval_reason_ids", list(primitive())))
  approvalReasonIds?: number[];

  @serializable(alias("suspicion_reason_ids", list(primitive())))
  suspicionReasonIds?: number[];

  @serializable(alias("suspicion_reasons", list(object(MetaModel))))
  suspicionReasons?: MetaModel[];

  @serializable(alias("max_purchase_power", primitive()))
  maxPurchasePower?: number;

  @serializable(alias("processing_fee", primitive()))
  processingFee?: number;

  @serializable(alias("risky_customer", primitive()))
  riskyCustomer?: boolean;

  @serializable(alias("acquisition_channel", primitive()))
  acquisitionChannel?: string;
}

export class CustomerDocumentModel extends CustomerModel {
  @serializable(alias("attachment_type", primitive()))
  attachmentType?:
    | typeof BANK_STATEMENT
    | typeof PROFILE_PIC
    | typeof NATIONAL_ID_BACK
    | typeof NATIONAL_ID_FRONT
    | typeof SELFIE_PIC
    | typeof EMPLOYMENT_LETTER
    | typeof VEHICLE_CARD
    | typeof PAYSLIP
    | typeof SPORTS_CLUB_MEMBERSHIP
    | typeof CREDIT_CARD_STATEMENT
    | typeof PAYSLIP_BANK_STATEMENT
    | typeof EMPLOYMENT_LETTER_MEDICAL_CARD
    | typeof MEDICAL_CARD
    | typeof STUDENT_CARD;

  @serializable(alias("attachment_status", primitive()))
  attachmentStatus?: string;

  @serializable(alias("attachment", object(AttachmentModel)))
  attachment?: AttachmentModel;

  @serializable(alias("file_name", primitive()))
  fileName?: string;

  @serializable(alias("attachment_id", primitive()))
  attachmentId?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("submitted_on", primitive()))
  submittedOn?: string;

  @serializable(alias("risk_model_comment", primitive()))
  riskModelComment?: string;
}

export class CustomerPurchaseModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("purchase_id", primitive()))
  purchaseId?: string;

  @serializable(alias("customer_reference_number", primitive()))
  customerReferenceNumber?: string;

  @serializable(alias("pos_type", primitive()))
  posType?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("vendor", object(VendorModel)))
  vendor?: VendorModel;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;
 
  @serializable(alias("customer_processing_fee", primitive()))
  customerProcessingFee?: number;
  
  @serializable(alias("cash_discount", primitive()))
  cashDiscount?: number = 0;
  
  @serializable(alias("promotion_discount", primitive()))
  promotionDiscount?: number = 0;

  @serializable(alias("is_amount_negetive", primitive()))
  isAmountNegative?: boolean;

  @serializable(alias("customer_payment_status", primitive()))
  customerPaymentStatus?: string;

  @serializable(alias("purchase_status", primitive()))
  purchaseStatus?: string;

  @serializable(alias("purchase_mode", primitive()))
  purchaseMode?: PurchaseModeTypes;

  @serializable(alias("total_late_fee", primitive()))
  totalLateFee?: number;
}
