
import {RewardsHistory} from '../RewardsHistory/rewardsHistory.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class ReferralRewardsMetrics { 

	@serializable(alias('eligible_referral_cash', primitive()))
	eligibleReferralCash?: number;

	@serializable(alias('referral_cash_earned', primitive()))
	referralCashEarned?: number;

	@serializable(alias('total_friends_referred', primitive()))
	totalFriendsReferred?: number;

	@serializable(alias('accepted_invite', primitive()))
	acceptedInvite?: number;

	@serializable(alias('no_of_friends_made_purchase', primitive()))
	noOfFriendsMadePurchase?: number;

	@serializable(alias('no_of_friends_made_payment', primitive()))
	noOfFriendsMadePayment?: number;

	@serializable(alias('rewards_history', list(object(RewardsHistory))))
	rewardsHistories?: RewardsHistory[] = [];

}