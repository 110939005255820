import { Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import TabCountComponent from "../../../shared/components/TabCountComponent";
import ApproveCustomers from "./ApproveCustomers";
import ApproveDocuments from "./ApproveDocuments";
import CustomerList from "./CustomerList";
import WaitlistedCustomers from "./WaitlistedCustomers";
import RejectedCustomers from "./RejectedCustomers";
import ApproveCustomersMobile from "./ApproveCustomerMobile";
import "./customers.scss";
import useWindowWidth from "../../../shared/utils/useWindowWidth";
import PendingKycCustomers from "./PendingKYCCustomers";
const { TabPane } = Tabs;
interface CustomersProps {}

const Customers: FC<CustomersProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [approvedCustomersCount, setApprovedCustomersCount] = useState<number>(0);
  const [unapprovedCustomersCount, setUnapprovedCustomersCount] = useState<number>(0);
  const [waitlistedCustomersCount, setWaitlistedCustomersCount] = useState<number>(0);
  const [unapprovedDocumentsCount, setUnapprovedDocumentsCount] = useState<number>(0);
  const [rejectedCustomersCount, setRejectedCustomersCount] = useState<number>(0);
  const [kycPendingCustomersCount, setKycPendingCustomersCount] = useState<number>(0);

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 480;

  useEffect(() => {
    if (!location?.hash)
      navigate({ pathname: AppRoutes.CUSTOMERS, hash: "list" }, { replace: true });
  }, [location]);

  return (
    <PageView
      title={{
        name: "Customers",
        icon: <IconComponent name="icon-customers" />,
      }}
    >
      <Tabs
        activeKey={location.hash?.replace(/#/gi, "")}
        className="customers"
        onTabClick={(value) => {
          navigate({ pathname: location.pathname, hash: value });
        }}
      >
        <TabPane key="list" 
          tab={
            <TabCountComponent
              title="Approved"
              count={approvedCustomersCount ?? 0}
            />
          }>
          <CustomerList setCount={setApprovedCustomersCount}/>
        </TabPane>
        <TabPane
          key="approve-customers"
          tab={
            <TabCountComponent
              title="Pending Approval"
              count={unapprovedCustomersCount ?? 0}
            />
          }
          forceRender
        >
          {isMobile ? <ApproveCustomersMobile setCount={setUnapprovedCustomersCount} /> : <ApproveCustomers setCount={setUnapprovedCustomersCount} />}
        </TabPane>
        <TabPane
          key="incomplete-onboarding-customers"
          tab={
            <TabCountComponent
              title="Incomplete onboarding"
              count={kycPendingCustomersCount ?? 0}
            />
          }
          forceRender
        >
          <PendingKycCustomers setCount={setKycPendingCustomersCount} />
        </TabPane>
        <TabPane
          key="waitlisted"
          tab={
            <TabCountComponent
              title="Waitlist"
              count={waitlistedCustomersCount ?? 0}
            />
          }
          forceRender
        >
          <WaitlistedCustomers setCount={setWaitlistedCustomersCount} />
        </TabPane>
        <TabPane
          key="rejected-customers"
          tab={
            <TabCountComponent
              title="Rejected"
              count={rejectedCustomersCount ?? 0}
            />
          }
          forceRender
        >
          <RejectedCustomers setCount={setRejectedCustomersCount} />
        </TabPane>
        <TabPane
          key="approve-documents"
          tab={
            <TabCountComponent
              title="Documents"
              count={unapprovedDocumentsCount ?? 0}
            />
          }
          forceRender
        >
          <ApproveDocuments setCount={setUnapprovedDocumentsCount}/>
        </TabPane>
      </Tabs>
    </PageView>
  );
};

export default Customers;
