import { DocumentTypes } from "../enums/documentTypes";

export const RiskModelCommentDefinition = {
    [DocumentTypes.SPORTS_CLUB_MEMBERSHIP] : "Please type the name of the club",
    [DocumentTypes.VEHICLE_CARD] : "Please type car model",
    [DocumentTypes.BANK_STATEMENT] : "What is the income of the user?",
    [DocumentTypes.PAYSLIP] : "What is the income of the user?",
    [DocumentTypes.EMPLOYMENT_LETTER] : "What is the name of the employer?",
    [DocumentTypes.UTILITY_BILL] : "How much is the utility bill?",
    [DocumentTypes.PROPERTY_CONTRACT] : "How much does this user pay?",
    [DocumentTypes.NATIONAL_ID_BACK]: "",
    [DocumentTypes.NATIONAL_ID_FRONT]: "",
    [DocumentTypes.SELFIE_PIC]: "",
    [DocumentTypes.PROOF_OF_ASSET]: "",
    [DocumentTypes.PROOF_OF_INCOME]: "",
    [DocumentTypes.COMMERCIAL_REGISTRATION]: "",
    [DocumentTypes.CREDIT_CARD_STATEMENT]: "",
    [DocumentTypes.TAX_CARD]: "",
    [DocumentTypes.REFUND_RECEIPT]: "",
    [DocumentTypes.PROFILE_PIC]: "",
    [DocumentTypes.PAYSLIP_BANK_STATEMENT] : "",
    [DocumentTypes.EMPLOYMENT_LETTER_MEDICAL_CARD] : "",
    [DocumentTypes.MEDICAL_CARD] : "",
    [DocumentTypes.STUDENT_CARD] : "",
}



