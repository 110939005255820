import { Col, Divider, Row } from "antd";
import React from "faker";
import { Form, Formik, FormikProps } from "formik";
import { FC, useRef, useState } from "react";
import { currencyString } from "../../../..";
import DatePickerField from "../../../../../../shared/components/DatePickerField";
import InputField from "../../../../../../shared/components/InputField";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import "./settlementForm.scss"
import { MarkSettledPendingSettlement, PendingPartnerFees } from "../../../../../../models/PartnerFees/partnerFees.model";
import PartnerfeeService from "../../../../../../services/PartnerfeeService/partnerfee.service";
import { settlementFormValidation } from "./settlementFormValidation";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { getPartnerDateRange } from "../../../../../../shared/utils/geDateRange";
import { UserModel } from "../../../../../../models/User/user.model";
import IconComponent from "../../../../../../shared/components/IconComponent";
import TooltipComponent from "../../../../../../shared/components/TooltipComponent";
import { formatDateOnly, getDate } from "../../../../../../shared/utils/formatDate";
import { MarkAsSettledDetails } from "../../../../../../models/Settlement/settlement.model";

interface PartnerFeeSettlementFormProps {
    visible: boolean;
    closeHandler: () => void;
    refreshList: () => void;
    settlementData: PendingPartnerFees[];
    selectedSettlements: React.Key[];
    callback: (showTest:boolean) => void;
    markAsSettledDetails?: MarkAsSettledDetails;
}
const PartnerFeeSettlementForm: FC<PartnerFeeSettlementFormProps> = (props) => {
    const { visible, closeHandler, settlementData, selectedSettlements, callback, refreshList, markAsSettledDetails } = props;
    const { markPendingPartnerSettlements, markAsSettledLoading } = PartnerfeeService()
    const user = LocalStorage.getItem("USER") as UserModel
    const [initialValues, setInitialValues] =
        useState<MarkSettledPendingSettlement>({settledDate: "", partnerFeeSettlementId: "",id:[], adminId: user.id});
    const formRef = useRef<FormikProps<MarkSettledPendingSettlement>>(null);
    const showTest = LocalStorage.getItem("IS_TEST")
    const resetFormAndClose = () => {
        formRef?.current?.resetForm({ values: {id:[]} });
        closeHandler()
    }

    const dateRange = getDate(`${formatDateOnly(markAsSettledDetails?.transactionRange?.start!)} - ${formatDateOnly(markAsSettledDetails?.transactionRange?.end!)}`)

    const onSubmit = (values: MarkSettledPendingSettlement) => {
        values.id = selectedSettlements;
        values.partnerName = markAsSettledDetails?.partner?.name;
        values.adminId = user.id;
        markPendingPartnerSettlements(values, ()=>{
            callback(showTest)
            resetFormAndClose()
        })
        refreshList()
    };
    
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            innerRef={formRef}
            validationSchema={settlementFormValidation}
        >
            {({ values, setFieldValue, handleSubmit }) => (
                <ModalComponent
                    visible={visible}
                    closeHandler={resetFormAndClose}
                    type="large"
                    successProps={{
                        title: "Confirm",
                        clickHandler: handleSubmit,
                        loading: markAsSettledLoading,
                    }}
                    cancelProps={{
                        title: "Discard",
                        clickHandler: resetFormAndClose,
                    }}
                    className="partner-settlement-form-modal"
                >
                    <Form>
                        <div className="partner-settlement-form">
                            <Row gutter={16}>
                                <div className="partner-name-display-picture">
                                <IconComponent name="icon-customers" />
                                    <div>
                                        <h4>{markAsSettledDetails?.partner?.name}</h4>
                                    </div>
                                </div>
                                <div className="settlements-total__container">
                                    <div className="details">
                                        <div className="label">Transaction Time Period <span>({markAsSettledDetails?.noOfOrders} Transaction{markAsSettledDetails?.noOfOrders === 1 ? '' : 's'})</span> </div>
                                        <div className="value">{`${formatDateOnly(markAsSettledDetails?.transactionRange?.start!)} - ${formatDateOnly(markAsSettledDetails?.transactionRange?.end!)}`}</div>
                                    </div>
                                    <div className="details">
                                        <div className="label">Total Partner Fees <TooltipComponent content={`0.35% for purchases\n1% for cash payment\n1.5% + LE 2 for card payment`} /></div>
                                        <div className="value">{markAsSettledDetails?.partnerProcessingFee?.toFixed(2)}</div>
                                    </div>
                                    <div className="details">
                                        <div className="label">Total VAT on Rise Vendor Fee <span>(14%)</span></div>
                                        <div className="value">
                                            <span className="">
                                                {markAsSettledDetails?.partnerProcessingFeeVat?.toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="settlements-amount__container">
                                    <div className="details">
                                        <div className="label">Settlement Amount</div>
                                        <div className="value">
                                            &nbsp;
                                            {currencyString}
                                            {markAsSettledDetails?.settlementAmount?.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <Col span={12}>
                                    <InputField
                                        label="Partner Fee Settlement ID"
                                        name="partnerFeeSettlementId"
                                        placeholder="Enter"
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePickerField
                                        format="DD MMM YYYY"
                                        value={values?.settledDate}
                                        onChange={(date,dateString) => setFieldValue("settledDate", dateString)}
                                        label="Settled Date"
                                        name="settledDate"
                                        placeholder="Select"
                                        dateRange={dateRange}
                                    />
                                </Col>
                                <Divider />
                            </Row>
                        </div>
                    </Form>
                </ModalComponent>
            )}
        </Formik>
    );
};

export default PartnerFeeSettlementForm;
