
import {serializable, alias, object, list, primitive} from 'serializr';

export class DeallPurchaseConfig { 

	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: boolean;

	@serializable(alias('monthly_limit', primitive()))
	monthlyLimit?: number;

	@serializable(alias('total_utilised_this_month', primitive()))
	totalUtilisedThisMonth?: number;

}