import React, { FC, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import "./approveCustomerMobile.scss";
import CustomerService from "../../../../services/CustomerService/customer.service";
import NoCustomersImg from "../../../../assets/empty/customers.svg";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import SearchInput from "../../../../shared/components/SearchInput";
import { Col, Row } from "antd";
import IconComponent from "../../../../shared/components/IconComponent";
import moment from "moment";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import { BadgeTypes } from "../../../../enums/badgeTypes";

interface ApproveCustomersProps {
  setCount: React.Dispatch<React.SetStateAction<number>>
}

const ApproveCustomersMobile: FC<ApproveCustomersProps> = (props) => {
  const { setCount } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const { loading, fetchUnVerifiedCustomersList, customers, paginations } = CustomerService();

  const handlePageChange = (page:number) => {
    setPage(page)
    fetchUnVerifiedCustomersList({page, ...sortOptions, search})
  }

  useEffect(() => {
    location.hash === "#approve-customers" &&
    fetchUnVerifiedCustomersList();
  }, [location?.hash]);
  
  useEffect(() => {
    fetchUnVerifiedCustomersList();
  }, []);

  useEffect(() => {
    setCount(paginations?.totalCount as number);
  }, [paginations]);

  return (
    <div className="approve-customers-mobile">
        <SearchInput
          placeholder="Search customer by name or phone number"
          onChange={(value) => {
            setSearch(value)
            fetchUnVerifiedCustomersList({search: value})
          }}
        />
        {customers.map(customer => 
        <div className="pending-approval-list-card" onClick={() => {       
          navigate(
          generatePath(AppRoutes.APPROVE_CUSTOMERS_VIEW, {
            customerId: customer?.id?.toString(),
          })
        )}}>
          <>
            <span className="reapply-badge">{customer?.rejectedAt ? <BadgeComponent type={BadgeTypes.REAPPLY} className="reapply" /> : ""}</span>
            <div className="customer-name">{customer.fullName}</div>
            <div className="customer-details-1">
              <span className="phone-number">
              <IconComponent name="icon-phone-blue" />
              {customer.countryCode?.isdCode}{customer.mobileNumber}
              </span>

              <span className="email">
              <IconComponent name="icon-email-blue" />
              {customer.email}
              </span>
            </div>
            <div className="customer-details-2">
              <span className="label">ONBOARDING START DATE</span>
              <span className="onboard-date">{moment(customer?.onboardingDetail?.onboardingStartedAt).format("DD MMM YY")}</span>
            </div>
            <div className="customer-details-2">
              <span className="label">KYC STATUS</span>
              <span className="kyc-status">{<BadgeComponent type={(customer?.kycStatus === BadgeTypes.APPROVED ? BadgeTypes.KYC_APPROVED : customer?.kycStatus === BadgeTypes.FAILED ? BadgeTypes.KYC_FAILED : customer?.kycStatus === BadgeTypes.KYC_PENDING && BadgeTypes.KYC_PENDING) as BadgeTypes} />}</span>
            </div>
          </>
        </div>
        )}
    </div>
  );
};

export default ApproveCustomersMobile;
