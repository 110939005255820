import React, { FC, useEffect, useState } from "react";
import {Col, Divider, Row, Tabs} from "antd";
import { useLocation, useParams } from "react-router-dom";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import { DocumentTypes } from "../../../../../enums/documentTypes";
import CustomerService from "../../../../../services/CustomerService/customer.service";
import S3AttachmentService from "../../../../../services/S3UploadService/s3upload.service";
import DocumentsCard from "../../../../../shared/components/DocumentsCard";
import PageView from "../../../../../shared/components/PageView";
import ApproveCustomerThirdPartyDetailsCard from "./ApproveCustomerThirdPartyDetailsCard";
import "./approveCustomersView.scss";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import { CustomerModel } from "../../../../../models/Customer/customer.model";
import CustomerQuestionnaire from "../../CustomerList/CustomerListView/CustomerQuestionnaire";
import { UploadChangeParam } from "antd/lib/upload";
import CustomerDetailsCard from "../../CustomerList/CustomerListView/CustomerDetailsCard";
import { checkDocApprovalStatus } from "../../../../../shared/utils/checkDocApprovalStatus";
import CustomerAdditionalInfo from "../../CustomerList/CustomerListView/CustomerAdditionalInfo";

const { TabPane } = Tabs;


interface ApproveCustomersViewProps { }

const ApproveCustomersView: FC<ApproveCustomersViewProps> = () => {
  const location = useLocation()
  const pageNumber = location?.state as number;
  const {PROFILE_PIC,NATIONAL_ID_BACK,NATIONAL_ID_FRONT,BANK_STATEMENT,EMPLOYMENT_LETTER, SELFIE_PIC, PAYSLIP, SPORTS_CLUB_MEMBERSHIP, VEHICLE_CARD, CREDIT_CARD_STATEMENT, PAYSLIP_BANK_STATEMENT, EMPLOYMENT_LETTER_MEDICAL_CARD, STUDENT_CARD, MEDICAL_CARD} = DocumentTypes
  const { fetchCustomerDetails, loading, customer, updateVerificationDocument, resetRetryCount, fetchCustomerContactsStatistics, customerContactStat } = CustomerService();

  const { uploadAttachment, uploadingBankStatement, uploadingEmploymentLetter, uploadingNationalIdBack, uploadingNationalIdFront, uploadingProfile, uploadingSelfie, uploadingPayslip, uploadingSportsClubMembership, uploadingVehicleCard, uploadingCreditCardStatement, uploadingEmploymentLetterMedicalCard, uploadingPayslipBankstatement, uploadingStudentCard, uploadingMedicalCard } = S3AttachmentService()
  const { customerId } = useParams();

  const [detailsExpanded, setDetailsExpanded] = useState(false)
  const [docVerificationIncomplete, setDocVerificationIncomplete] = useState(false)

  const refreshCustomerInfo = ()=> {
    if (customerId) {
      fetchCustomerDetails(customerId);
      fetchCustomerContactsStatistics(customerId);
    }
  }
  const handleRefresh = () => {
    if (customerId) {
      fetchCustomerDetails(customerId);
      fetchCustomerContactsStatistics(customerId);
    }
  };

  const _updateVerificationDocument = async (file:any,updateAttachmentId:string, attachmentType: typeof PROFILE_PIC|typeof NATIONAL_ID_FRONT|typeof NATIONAL_ID_BACK|typeof EMPLOYMENT_LETTER|typeof BANK_STATEMENT|typeof SELFIE_PIC|typeof VEHICLE_CARD|typeof PAYSLIP|typeof SPORTS_CLUB_MEMBERSHIP|typeof CREDIT_CARD_STATEMENT|typeof PAYSLIP_BANK_STATEMENT|typeof EMPLOYMENT_LETTER_MEDICAL_CARD|typeof MEDICAL_CARD|typeof STUDENT_CARD) => {
    const attachmentId = await uploadAttachment(file?.file?.originFileObj, attachmentType)
    updateVerificationDocument(customerId ?? "", updateAttachmentId, { attachmentType, attachmentId }, () => {
      handleRefresh()
    })
  }

  useEffect(() => {
    handleRefresh();
  }, []);

  // Changed for au10tix earlier user to be failed_verification
  const isPendingCustomer = customer?.status === BadgeTypes.PENDING_APPROVAL 
  const isRejected = customer?.status === BadgeTypes.REJECTED
  const pathName = `${location?.pathname?.split('/')[2]}`
  const backRouteURL = `${AppRoutes.CUSTOMERS}${pathName === "rejected-customers" ? "#rejected-customers" : (pathName === 'incomplete-onboarding-customers') ? "#incomplete-onboarding-customers" : "#approve-customers"}`
  // TODO: Might need in future sprints
  // const handleRetry = () => {
  //   customerId && resetRetryCount(customerId, () => {
  //     handleRefresh()
  //   })
  // }

  const isDocVerificationIncomplete = customer && checkDocApprovalStatus(customer)
  const isNeighborhoodDetailsMissing = !customer?.neighbourhood;

  return (
    <PageView className="pending-approval-view" type="back-only" backRoute={backRouteURL} title={{ name: customer?.nameFromId ?? "" }} customer={customer as CustomerModel} handleRefresh={handleRefresh} pageNumber={pageNumber}>
      <div className="approve-customers-view">
        <Row gutter={24}>
          <Col xs={24} sm={24} lg={6}>
            <CustomerDetailsCard customer={customer} detailsExpanded={detailsExpanded} isFetching={loading} refreshList={handleRefresh} view={true} docVerificationIncomplete={isDocVerificationIncomplete} isNeighborhoodDetailsMissing={isNeighborhoodDetailsMissing} />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            {/* Need to be tested and removed */}
            {/* {customer?.status === BadgeTypes.WAITLISTED ? 
            <CustomerInfoCard customer={customer} isFetching={loading} refreshList={handleRefresh}/> : */}
            <ApproveCustomerThirdPartyDetailsCard
              loading={loading}
              customer={customer}
              handleRefresh={handleRefresh}
              detailsExpanded={detailsExpanded}
              setDetailsExpanded={setDetailsExpanded}
            />
            {/* } */}
          </Col>
          <Divider className="customer-divider" />
          <Col  xs={24} sm={24} lg={6}>
            <DocumentsCard
              detailsExpanded={detailsExpanded}
              isUpload={isPendingCustomer}
              customer={customer}
              data={[
                {
                  label: "CUSTOMER PHOTO",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.profilePic?.[0]?.id??"",DocumentTypes.PROFILE_PIC),
                  isUpload: true,
                  accept: "image/png, image/jpg, image/jpeg",
                  uploading: uploadingProfile,
                  document: customer?.profilePic
                },
                {
                  label: "SELFIE PIC",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.selfiePic?.[0]?.id??"",DocumentTypes.SELFIE_PIC),
                  isUpload: true,
                  accept: "image/png, image/jpg, image/jpeg",
                  uploading: uploadingSelfie,
                  document: customer?.selfiePic
                },
                {
                  label: "NATIONAL ID - FRONT",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.nationalIdFront?.[0]?.id??"",DocumentTypes.NATIONAL_ID_FRONT),
                  isUpload: true,
                  accept:"image/png, image/jpg, image/jpeg, application/pdf",
                  uploading: uploadingNationalIdFront,
                  document: customer?.nationalIdFront
                },
                {
                  label: "NATIONAL ID - BACK",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.nationalIdBack?.[0]?.id??"",DocumentTypes.NATIONAL_ID_BACK),
                  isUpload: true,
                  accept:"image/png, image/jpg, image/jpeg, application/pdf",
                  uploading: uploadingNationalIdBack,
                  document: customer?.nationalIdBack
                },
                {
                  label: "BANK STATEMENT",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.bankStatement?.[0]?.id??"",DocumentTypes.BANK_STATEMENT),
                  isUpload: true,
                  accept:"image/png, image/jpg, image/jpeg, application/pdf",
                  uploading: uploadingBankStatement,
                  document: customer?.bankStatement
                },
                {
                  label: "EMPLOYMENT LETTER",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.employmentLetter?.[0]?.id??"",DocumentTypes.EMPLOYMENT_LETTER),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingEmploymentLetter,
                  document: customer?.employmentLetter
                },
                {
                  label: "VEHICLE CARD",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.vehicleCard?.[0]?.id??"",DocumentTypes.VEHICLE_CARD),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingVehicleCard,
                  document: customer?.vehicleCard
                },
                {
                  label: "SPORTS CLUB MEMBERSHIP",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.sportsClubMembership?.[0]?.id??"",DocumentTypes.SPORTS_CLUB_MEMBERSHIP),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingSportsClubMembership,
                  document: customer?.sportsClubMembership
                },
                {
                  label: "PAY SLIP",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.paySlip?.[0]?.id??"",DocumentTypes.PAYSLIP),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingPayslip,
                  document: customer?.paySlip
                },
                {
                  label: "CREDIT CARD STATEMENT",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.creditCardStatement?.[0]?.id??"",DocumentTypes.CREDIT_CARD_STATEMENT),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingCreditCardStatement,
                  document: customer?.creditCardStatement
                },
                {
                  label: "PAY SLIP / BANK STATEMENT",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.paySlipBankStatement?.[0]?.id??"",DocumentTypes.PAYSLIP_BANK_STATEMENT),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingPayslipBankstatement,
                  document: customer?.paySlipBankStatement
                },
                {
                  label: "EMPLOYMENT LETTER / MEDICAL CARD",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.employmentLetterMedicalCard?.[0]?.id??"",DocumentTypes.EMPLOYMENT_LETTER_MEDICAL_CARD),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingEmploymentLetterMedicalCard,
                  document: customer?.employmentLetterMedicalCard
                },
                {
                  label: "MEDICAL CARD",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.medicalCard?.[0]?.id??"",DocumentTypes.MEDICAL_CARD),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingMedicalCard,
                  document: customer?.medicalCard
                },
                {
                  label: "STUDENT CARD",
                  handleChange: async (file: UploadChangeParam) => _updateVerificationDocument(file,customer?.studentCard?.[0]?.id??"",DocumentTypes.STUDENT_CARD),
                  isUpload: true,
                  accept: "application/pdf",
                  uploading: uploadingStudentCard,
                  document: customer?.studentCard
                },
              ]}
              loading={loading}
            />
          </Col>
        </Row>
        <Tabs className="customer-list-view-tabs">
          <TabPane tab="Questionnaire" key={"questionnaire"}>
            {!!customer?.questionnaire?.length && <CustomerQuestionnaire data={customer?.questionnaire} />}
          </TabPane>
          <TabPane tab="Additional Information" key={"additional-information"}>
            <CustomerAdditionalInfo/>
          </TabPane>
        </Tabs>
        {/* TODO: Might need in future sprints */}
        {/* {customer && isVerificationFailed && customer.onboardingDetail && <ApproveCustomersVerificationDetailsCard data={customer.onboardingDetail} handleRetry={handleRetry} loading={loading} />} */}
      </div>
    </PageView>
  );
};

export default ApproveCustomersView;
