import React, { FC, useState } from "react";
import { currencyString } from "../../..";
import "./purchaseOverduePaymentsCard.scss";
import BadgeComponent from "../../../../../shared/components/BadgeComponent";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";
import moment from "moment";
import { PurchaseOutstandingPaymentModel } from "../../../../../models/Purchase/purchase.model";
import TableComponent from "../../../../../shared/components/TableComponent";
import deleteAlert from "../../../../../assets/images/delete-alert.png"
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../../routes/routeConstants/appRoutes";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import alertIcon from "../../../../../assets/images/alert-for-refund-creation.svg"
import PurchaseService from "../../../../../services/PurchaseService/purchase.service";

interface PurchaseOverduePaymentsCardProps {
  purchase: PurchaseModel;
  handleRefresh: () => void;
}

const PurchaseOverduePaymentsCard: FC<
  PurchaseOverduePaymentsCardProps
> = (props) => {
  const { purchase, handleRefresh } = props;
  const { outstandingPayments } = purchase
  const navigate = useNavigate()
  const { waiveLateFee } = PurchaseService()
  const [showRemoveLateFeeWarning, setShowRemoveLateFeeWarning] = useState(false);
  const [installmentId, setInstallmentId] = useState<number>()

  return (
    <>
      <ModalComponent
        type="alert-large"
        visible={showRemoveLateFeeWarning}
        alert={{
          buttonType: "negative"
        }}
        closeHandler={() => setShowRemoveLateFeeWarning(false)}
        successProps={{
          title: "Confirm",
          clickHandler: () => {
            installmentId && waiveLateFee(installmentId.toString(), handleRefresh)
            setShowRemoveLateFeeWarning(false);

          },
        }}
        cancelProps={{
            title: "Cancel",
            clickHandler: () => setShowRemoveLateFeeWarning(false),
        }}
        className="remove-late-fee-confirmation-modal"
        >
          <div className="alert-info">
              <img src={alertIcon} alt="alert-refund"/>
              <div className="description">Are you sure you want to remove the late fee for this installment?</div>
              <div className="subtitle">Late fee from the overdue installment will be removed</div>
          </div>
      </ModalComponent>
    <div className="purchase-overdue-payments-card purchase-card-table">
      <TableComponent
        columns={[
          {
            title: "INSTALLMENT AMOUNT",
            dataIndex: "minimumDue",
            key: "minimumDue",
            render: (text, record: PurchaseOutstandingPaymentModel) => (
              <span>
                {currencyString}
                {Number(text)}
              </span>
            ),
          },
          {
            title: "LATE FEE",
            dataIndex: "lateFee",
            key: "lateFee",
            render: (text, record: PurchaseOutstandingPaymentModel) => (
              (record?.lateFee ?? 0) > 0 ? 
              <span>
                {currencyString}
                {Number(text)}
              </span> : "-"
            ),
          },
          {
            title: "CUSTOMER OVERDUE PAYMENT",
            dataIndex: "minimumDue",
            key: "minimumDue",
            render: (text, record: PurchaseOutstandingPaymentModel) => (
              <span className="value">
                {currencyString}
                {Number(record?.minimumDue) + Number(record?.lateFee)}
              </span>
            ),
          },
          {
            title: "Due On",
            dataIndex: "dueDate",
            key: "dueDate",
            render: (text, record: PurchaseOutstandingPaymentModel) => (
              <div className="due-on__container">
                <div className="late-date">{moment(text).format("DD MMM YYYY")}</div>
                {record?.isOverDue && <div className="badge"><BadgeComponent type={BadgeTypes.OVERDUE} className="fill" /></div>}
              </div>
            ),
          },
          {
            title: "",
            dataIndex: "",
            key: "",
            render: (_, record: PurchaseOutstandingPaymentModel) => (
              record?.lateFee && record?.lateFee > 0 ? (
              <div className="container" onClick={() => {setInstallmentId(record?.id); setShowRemoveLateFeeWarning(true)}}>      
                <img src={deleteAlert} /> <span className="remove-late-fee">Remove late fee</span>
              </div>) : <></>
            ),
          },
        ]}
        data={outstandingPayments?.filter(payment => payment.isOverDue)}
      />
    </div>
    </>
  );
};

export default PurchaseOverduePaymentsCard;
