export enum DocumentTypes {
    BANK_STATEMENT = "bank_statement",
    EMPLOYMENT_LETTER = "employment_letter",
    NATIONAL_ID_FRONT = "national_id_front",
    NATIONAL_ID_BACK = "national_id_back",
    PROFILE_PIC = "profile_pic",
    TAX_CARD = "tax_card",
    COMMERCIAL_REGISTRATION = "commercial_registration",
    REFUND_RECEIPT = "refund_receipt",
    UTILITY_BILL = "utility_bill",
    PROOF_OF_INCOME = "proof_of_income",
    PROOF_OF_ASSET = "proof_of_asset",
    SELFIE_PIC = "selfie_pic",
    VEHICLE_CARD = "vehicle_card",
    SPORTS_CLUB_MEMBERSHIP = "sports_club_membership",
    PAYSLIP = "pay_slip",
    PROPERTY_CONTRACT= "property_contract",
    CREDIT_CARD_STATEMENT = "credit_card_statement",
    PAYSLIP_BANK_STATEMENT = "pay_slip_bank_statement",
    EMPLOYMENT_LETTER_MEDICAL_CARD = "employment_letter_medical_card",
    STUDENT_CARD = "student_card",
    MEDICAL_CARD = "medical_card"
}