import React, { FC } from "react";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import "./passwordResetLinkSent.scss";
import MailImage from "../../../assets/images/email.png";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
interface PasswordResetLinkSentProps {}

const PasswordResetLinkSent: FC<PasswordResetLinkSentProps> = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate(AppRoutes.LOGIN)
  };
  return (
    <div className="password-reset-link-sent">
      <h2>Password reset link sent</h2>
      <h4>Check your email for the password reset link</h4>
      <img src={MailImage} className="mail__img"/>
      
      <ButtonComponent
        type="primary"
        htmlType="submit"
        className="password-reset-link-sent-proceed__btn"
        onClick={onSubmit}
        size="wide"
      >
        Proceed to login
      </ButtonComponent>
    </div>
  );
};

export default PasswordResetLinkSent;
