
import {Criteria} from '../Criteria/criteria.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class CreditScoreCriterions { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('active', primitive()))
	active?: boolean;

	@serializable(alias('criteria', object(Criteria)))
	criteria?: Criteria;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

}