import { BadgeTypes } from "../enums/badgeTypes";

export const BadgeTypesDefinition = {
    [BadgeTypes.ACTIVE]: "Active",
    [BadgeTypes.APPROVED]: "Approved",
    [BadgeTypes.INACTIVE]: "Inactive",
    [BadgeTypes.COMPLETED]: "Completed",
    [BadgeTypes.SUCCESS]: "Success",
    [BadgeTypes.FAILED]: "Failed",
    [BadgeTypes.RETURN]: "Returned",
    [BadgeTypes.REFUND_COMPLETED]: "Refund Completed",
    [BadgeTypes.PURCHASED]: "Purchased",
    [BadgeTypes.PARTIAL_RETURN]: "Partial Return",
    [BadgeTypes.PENDING_APPROVAL]:"Pending Approval",
    [BadgeTypes.PENDING_SETTLEMENT]: "Pending Settlement",
    [BadgeTypes.OUTSTANDING_PAYMENT]: "Outstanding Payment",
    [BadgeTypes.RETURNED]: "Returned",
    [BadgeTypes.RETURN_CANCELLED]: "Return Cancelled",
    [BadgeTypes.OVERDUE]: "Overdue",
    [BadgeTypes.OUTSTANDING]: "Outstanding",
    [BadgeTypes.SETTLED]: "Settled",
    [BadgeTypes.REJECTED]: "Rejected",
    [BadgeTypes.PENDING]: "Pending",
    [BadgeTypes.INITIATED]: "Initiated",
    [BadgeTypes.ADMIN]:"Admin",
    [BadgeTypes.INTERNAL_USER]:"Internal Users",
    [BadgeTypes.VIEWER]:"Viewer",
    [BadgeTypes.MANAGER]:"Manager",
    [BadgeTypes.WAITLISTED]:"Waitlisted",
    [BadgeTypes.FAILED_VERIFICATION]:"Failed Verification",
    [BadgeTypes.PENDING_SETTLEMENTS]: "Pending Settlements",
    [BadgeTypes.FAWRY]: "Fawry",
    [BadgeTypes.PAID]: "Paid",
    [BadgeTypes.FAILURE]: "Failure",
    [BadgeTypes.PENDING_VERIFICATION]: "Pending",
    [BadgeTypes.KYC_PENDING]: "KYC Pending",
    [BadgeTypes.KYC_APPROVED]: "KYC Verified",
    [BadgeTypes.KYC_FAILED]: "KYC Rejected",
    [BadgeTypes.REAPPLY]: "Reapply",
    [BadgeTypes.USER_CANCELLED]: "Cancelled"
}