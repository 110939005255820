import React, { FC } from 'react'
import { useParams } from 'react-router-dom';
import PurchaseService from '../../../../../services/PurchaseService/purchase.service';
import ModalComponent from '../../../../../shared/components/ModalComponent';
import alertIcon from "../../../../../assets/images/alert-for-refund-creation.svg"
import "./removeimeimodal.scss"

interface RemoveImeiModalProps { 
    visible: boolean;
    closeHandler: () => void;
    refreshHandler: () => void;
}

const RemoveImeiModal: FC<RemoveImeiModalProps> = (props) => {

    const { visible, closeHandler, refreshHandler } = props
    const { addImeiNumber, loading } = PurchaseService();
    const {purchaseId} = useParams();

    return (
        <ModalComponent
        type="alert-large"
        alert={{
            title: "",
            description: "",
            buttonType: "negative"
        }}
        visible={visible}
        closeHandler={closeHandler}
        successProps={{
            title: "Confirm",
            loading: loading,
            clickHandler: () => {
                addImeiNumber(purchaseId!, "", () => {
                    refreshHandler();
                    closeHandler();
                })
            },
        }}
        cancelProps={{
            title: "Close",
            clickHandler: closeHandler
        }}
    >
        <div className="alert-info">
            <img src={alertIcon} alt="alert-refund"/>
            <h3>Are you sure you want to remove IMEI number from the purchase?</h3>
            <p>Removing IMEI number from the purchase will remove the purchase from customer asset</p>
        </div>
    </ModalComponent>
    )
}

export default RemoveImeiModal