import * as Yup from 'yup'

interface LoanLimit {
  monthlyLoanLimit: string, 
  totalUtilisedThisMonth: number
}

interface TestContextExtended {
    from: Array<{
      schema: Yup.ObjectSchema
      value: LoanLimit
    }>
  }

export const monthlyLoanLimitSchema = Yup.object().shape({
    monthlyLoanLimit: Yup
    .number()
    .required('Monthly Loan Limit is required')
    .test('isTotalUtilisedThisMonth', 'Monthly Loan Limit must be greater than Loan amount utilised this month',
    function (): boolean { 
      const { from } = this as Yup.TestContext & TestContextExtended
      const { monthlyLoanLimit, totalUtilisedThisMonth } = from[0]?.value
      return Number(monthlyLoanLimit ?? 0) > totalUtilisedThisMonth
    })
})