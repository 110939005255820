import {Form, Formik, FormikProps, FormikValues} from "formik";
import React, {FC, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../../../../context/AuthContext";
import UserService from "../../../../../services/AuthService/auth.service";
import {LocalStorage} from "../../../../utils/localStorageHelpers";
import InputField from "../../../InputField";
import ModalComponent from "../../../ModalComponent";
import "./twoFactorForm.scss";
import MetaService from "../../../../../services/MetaService/meta.service";
import RadioComponent from "../../../RadioComponent";
import QRCode from "react-qr-code";
import {Divider} from "antd";

interface TwoFactorFormProps {
    visible: boolean;
    closeHandler: () => void;
}

interface Admin {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    full_name: string;
    role: string;
    mobile_number: string;
    country_code: string;
    country_code_id: number;
    status: string;
    mfa_type: string;
}

interface SerializedAdmin {
    admin?: Admin;
    token?: string;
}

const TwoFactorAuthenticationForm: FC<TwoFactorFormProps> = (props) => {
    const {editProfile, generateTotpQR, verifyOtpCode, loading, error} = UserService()
    const {getCountryCodesMeta, countryCodesMeta} = MetaService();
    const {visible, closeHandler} = props;
    const admin = AuthContext()
    const [initialValues, setInitialValues] = useState<SerializedAdmin>({});
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const [qrCodeValue, setQrCodeValue] = useState<string>();
    const onSubmit = async (values: FormikValues) => {
        const isVerified = await verifyOtp(values);
        if (isVerified) {
            editProfile(values, () => {
                closeHandler()
            })
        }
    };
    const resetFormAndClose = () => {
        formRef?.current?.resetForm({values: {}});
        closeHandler()
    }
    const user = admin?.user ?? LocalStorage.getItem('USER')
    useEffect(() => {
        visible && getCountryCodesMeta()
    }, [visible])
    useEffect(() => {
        setInitialValues({...user})
    }, [user, visible])
    const generateTotp = async (values: FormikValues) => {
        const response = await generateTotpQR(values);
        if (response) {
            setQrCodeValue(response.uri);
        }
    }
    const verifyOtp = async (values: FormikValues) => {
        if (!qrCodeValue) {
            return true;
        }
        const response = await verifyOtpCode(values);
        return response?.valid;
    }
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            innerRef={formRef}
            onSubmit={onSubmit}
        >
            {({handleSubmit, setFieldValue, values}) => (
                <ModalComponent
                    type="medium"
                    visible={visible}
                    closeHandler={resetFormAndClose}
                    successProps={{
                        title: "Confirm",
                        clickHandler: handleSubmit,
                        loading: loading
                    }}
                    cancelProps={{
                        title: "Discard",
                        clickHandler: resetFormAndClose,
                    }}
                    title={""}
                >
                    <div className="two-factor-form">
                        <div className="two-factor-form-title-container">
                            <img className="shield-icon" src="/icons/shield-icon.png"/>
                            <p className="title">Two-Factor Authentication</p>
                            <p className="sub-title">
                                Securely sign in to your account by using time-based OTPs generated by an authenticator
                                app or received via your email ID
                            </p>
                        </div>
                        <Divider/>
                        <Form>
                            <RadioComponent
                                name="mfaType"
                                label=""
                                className="radio-row"
                                value={values?.mfaType}
                                data={[
                                    {
                                        value: "email",
                                        label: "Email OTP",
                                        subText: "Sends a temporary code to your email address"
                                    },
                                    {
                                        value: "totp",
                                        label: "Authenticator app",
                                        subText: "Generates time-sensitive codes on any authenticator app like google auth or Microsoft auth in your device"
                                    },
                                ]}
                                onChange={(event) => {
                                    setFieldValue("mfaType", event?.target?.value);
                                    if (event?.target?.value === 'totp') {
                                        generateTotp(values);
                                    } else {
                                        setQrCodeValue('');
                                    }
                                }}
                            />
                            {values?.mfaType === 'totp' && qrCodeValue &&
                                <>
                                    <div className="totp-instuctions">
                                        <ol>
                                            <li>Download any authenticator app on your device</li>
                                            <li>Tap on add new &gt; Scan QR code</li>
                                            <li>Once you scan the QR code, enter the OTP shown in the authenticator app
                                                in the below field
                                            </li>
                                        </ol>
                                    </div>


                                    <>
                                        <div>
                                            <div className="qr-container">
                                                <div className="qr-section">
                                                    <QRCode value={qrCodeValue}/>
                                                </div>
                                                <div className="rotate-section">
                                                    <img className="rotate-icon"
                                                         onClick={() => generateTotp(values)}
                                                         src="/icons/rotate-right-solid.svg" alt="Rotate Right"/>
                                                </div>
                                            </div>
                                            <InputField name='otpCode' label='OTP'
                                                        placeholder='Enter OTP shown in the authenticator app'></InputField>
                                        </div>
                                    </>

                                </>
                            }
                        </Form>
                    </div>
                </ModalComponent>
            )}
        </Formik>
    );
};

export default TwoFactorAuthenticationForm;
