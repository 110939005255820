export enum PurchasingStatusTypes {
    IN_PROGRESS= "in_progress",
    COMPLETED= "completed",
    PENDING= "pending",
}

export enum PurchasingPowerActionTypes {
    COMPLETE_PAYMENT= "complete_payment",
    REFERRAL= "referral",
    UPLOAD_PROPERTY_DOC= "upload_property_doc",
    APP_RATING= "app_rating",
    UPLOAD_POI_DOC= "upload_POI_doc",
}