import { Col, Divider, Row, Spin } from "antd";
import React from "faker";
import { Form, Formik, FormikProps } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { currencyString } from "../../../..";
import DatePickerField from "../../../../../../shared/components/DatePickerField";
import InputField from "../../../../../../shared/components/InputField";
import ModalComponent from "../../../../../../shared/components/ModalComponent";
import "./settlementForm.scss"
import { MarkAsSettledDetails, MarkSettledPendingSettlement, PendingSettlementModel } from "../../../../../../models/Settlement/settlement.model";
import { settlementFormValidation } from "./settlementFormValidation";
import MetaService from "../../../../../../services/MetaService/meta.service";
import { SettlementService } from "../../../../../../services/SettlementService/settlement.service";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import Notification from "../../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import { formatDateOnly, getDate } from "../../../../../../shared/utils/formatDate";

interface SettlementFormProps {
    visible: boolean;
    closeHandler: () => void;
    refreshList: () => void;
    settlementData: PendingSettlementModel[];
    selectedSettlements: React.Key[];
    selectedSettlementsPaymentIds: number[];
    callback: (showTest:boolean) => void
    markAsSettledDetails?: MarkAsSettledDetails
}
const SettlementForm: FC<SettlementFormProps> = (props) => {
    const { visible, closeHandler, refreshList, settlementData, selectedSettlements, callback, selectedSettlementsPaymentIds, markAsSettledDetails } = props;
    const { fetchVendorConfiguration, vendorConfiguration, loading, error } = MetaService()
    const { markPendingSettlements, markAsSettledLoading } = SettlementService()
    const [initialValues, setInitialValues] =
        useState<MarkSettledPendingSettlement>({settledDate: "", transactionId: "",id:[]});
    const formRef = useRef<FormikProps<MarkSettledPendingSettlement>>(null);
    const showTest = LocalStorage.getItem("IS_TEST")
    const resetFormAndClose = () => {
        formRef?.current?.resetForm({ values: {id:[]} });
        closeHandler()
    }

    const onSubmit = async (values: MarkSettledPendingSettlement) => {
        if(Math.sign(markAsSettledDetails?.totalSettlementAmount ?? 0) < 0){
            Notification({
                message:"Can't perform a negative transaction",
                description: "",
                type: NotificationTypes.INFO
            })
        } else {
            values.id = selectedSettlements;
            values.vendorId = markAsSettledDetails?.vendor?.id;
            values.paymentId = selectedSettlementsPaymentIds
            let response = await markPendingSettlements({showTest}, values, ()=>{
                callback(showTest)
                resetFormAndClose()
            })
            if(!response){
                refreshList()
            }
        }
    };

    const dateRange = getDate(`${formatDateOnly(markAsSettledDetails?.transactionRange?.start!)} - ${formatDateOnly(markAsSettledDetails?.transactionRange?.end!)}`)

    useEffect(() => {
        visible &&
        settlementData?.length &&
        fetchVendorConfiguration(`${markAsSettledDetails?.vendor?.id}`)
    }, [settlementData, visible])
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            innerRef={formRef}
            validationSchema={settlementFormValidation}
        >
            {({ values, setFieldValue, handleSubmit }) => (
                <ModalComponent
                    visible={visible}
                    closeHandler={resetFormAndClose}
                    type="large"
                    successProps={{
                        title: "Confirm",
                        clickHandler: handleSubmit,
                        loading: markAsSettledLoading,
                    }}
                    cancelProps={{
                        title: "Discard",
                        clickHandler: resetFormAndClose,
                    }}
                    className="vendor-settlement-form-modal"
                >
                    <Form>
                        <div className="vendor-settlement-form">
                            <Row gutter={16}>
                                <div className="vendor-name-display-picture">
                                    <img src={markAsSettledDetails?.vendor?.logo?.s3Url} />
                                    <div>
                                        <h4>{markAsSettledDetails?.vendor?.name}</h4>
                                        <p>{markAsSettledDetails?.vendor?.id}</p>
                                    </div>
                                </div>
                                <div className="settlements-total__container">
                                    <div className="details">
                                        <div className="label">Transactions Time Period</div>
                                        <div className="value">{`${formatDateOnly(markAsSettledDetails?.transactionRange?.start!)} - ${formatDateOnly(markAsSettledDetails?.transactionRange?.end!)}`}</div>
                                    </div>
                                    <div className="details">
                                        <div className="label">Total Purchase Amount  
                                            <span>({markAsSettledDetails?.purchaseCount} Purchase{markAsSettledDetails?.purchaseCount === 1 ? '' : 's'})</span>
                                        </div>
                                        <div className="value">{markAsSettledDetails?.totalAmount}</div>
                                    </div>
                                    <div className="details">
                                        <div className="label">Total Rise Vendor Fee <span>({markAsSettledDetails?.vendor?.processingFee}% of purchase amount)</span></div>
                                        <div className="value">
                                            {loading ? <Spin/> :
                                            <span className={"payment-loss"}>
                                                {"-"} {markAsSettledDetails?.vendorProcessingFee}
                                            </span>}
                                        </div>
                                    </div>
                                    <div className="details">
                                        <div className="label">Total VAT on Rise Vendor Fee <span>(14%)</span></div>
                                        <div className="value">
                                            {loading ? <Spin/> :
                                            <span className={"payment-loss"}>
                                                {"-"} {markAsSettledDetails?.totalVat}
                                            </span>}
                                        </div>
                                    </div>
                                    {
                                        (markAsSettledDetails?.refundedCount && markAsSettledDetails?.refundedCount > 0 ) ?
                                        <div className="details">
                                            <div className="label">Total Return Amount <span>({markAsSettledDetails?.refundedCount} return)</span></div>
                                            <div className="value">
                                                {loading ? <Spin/> :
                                               <span className={"payment-loss"}>
                                                   {"-"} {markAsSettledDetails?.refundedAmount}
                                                </span>}
                                            </div>
                                        </div>
                                        : <></>
                                    }
                                </div>
                                <Divider />
                                <div className="settlements-amount__container">
                                    <div className="details">
                                        <div className="label">Total Vendor Settlement</div>
                                        {loading ? <Spin/> :
                                        <div className={`value ${Math.sign(markAsSettledDetails?.totalSettlementAmount ?? 0) < 0 && "payment-loss"}`}>
                                            &nbsp;
                                            {currencyString}
                                            {markAsSettledDetails?.totalSettlementAmount}
                                        </div>}
                                    </div>
                                </div>
                                <Divider />
                                <Col span={12}>
                                    <InputField
                                        label="Vendor Settlement ID"
                                        name="transactionId"
                                        placeholder="Enter"
                                    />
                                </Col>
                                <Col span={12}>
                                    <DatePickerField
                                        format="DD MMM YYYY"
                                        value={values?.settledDate}
                                        onChange={(date,dateString) => setFieldValue("settledDate", dateString)}
                                        label="Settled Date"
                                        name="settledDate"
                                        placeholder="Select"
                                        dateRange={dateRange}
                                    />
                                </Col>
                                <Divider />
                            </Row>
                        </div>
                    </Form>
                </ModalComponent>
            )}
        </Formik>
    );
};

export default SettlementForm;
