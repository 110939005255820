import React, { FC, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import TableComponent, { TableFilterComponent, TableFilterDropdownComponent } from "../../../../shared/components/TableComponent";
import CustomerService from "../../../../services/CustomerService/customer.service";
import NoCustomersImg from "../../../../assets/empty/customers.svg";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { CustomerModel } from "../../../../models/Customer/customer.model";
import moment from "moment";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { StateProps } from "../../../../models/Meta/meta.model";
import { Row, Col } from "antd";
import { Formik, Form } from "formik";
import { BadgeTypesDefinition } from "../../../../definitions/badgeTypesDefinition";
import { DocumentTypesDefinition } from "../../../../definitions/documentTypesDefinition";
import { DocumentTypes } from "../../../../enums/documentTypes";
import DropdownField from "../../../../shared/components/DropdownField";
import { objectHasKeys } from "../../../../shared/utils/objectHasKeys";

interface PendingKycCustomersProps {
  setCount: React.Dispatch<React.SetStateAction<number>>
}

const PendingKycCustomers: FC<PendingKycCustomersProps> = (props) => {
  const { setCount } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const stateValue = location?.state as StateProps;
  const pageNumber = location.hash === "#incomplete-onboarding-customers" ? stateValue?.pageNumber as number : null;
  let filterOptions = location.hash === "#incomplete-onboarding-customers" ? stateValue?.filterOptions as SortFilterModel : null;
  const [sortOptions, setSortOptions] = useState<SortFilterModel>();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [page, setPage] = useState(pageNumber ?? 1)  
  const [filters, setFilters] = useState<SortFilterModel>(filterOptions ?? {});
  const [search, setSearch] = useState("")
  const { loading, fetchIncompleteOnboardingCustomersList, customers, paginations } = CustomerService();

  const filterInitialValues = filterOptions ?? {
    status: undefined
  };

  const handleFilterSubmit = (values: SortFilterModel) => {
    setFilters(prev => ({ ...prev, ...values }));
    handleFilterClose();
  };

  const handleFilterClose = () => setIsFilterShown(false)

  const handlePageChange = (page:number) => {
    location.state = null
    setPage(page)
    fetchIncompleteOnboardingCustomersList({page,  ...sortOptions, search, ...filters})
  }

  useEffect(() => {
    objectHasKeys(filters) &&
    fetchIncompleteOnboardingCustomersList({...filters, ...sortOptions});
  }, [filters]);

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              location.state = null;
              filterOptions = null;
              setPage(1);
              handleFilterClose();
              setFilters(() => ({ ...filterInitialValues, search: filters?.search }));
              resetForm({values: {}});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <DropdownField
                    options={[
                      {
                        value: BadgeTypes.APPROVED,
                        label: BadgeTypesDefinition[BadgeTypes.APPROVED],
                      },
                      {
                        value: BadgeTypes.REJECTED,
                        label: BadgeTypesDefinition[BadgeTypes.REJECTED],
                      },
                      {
                        value: BadgeTypes.PENDING_VERIFICATION,
                        label: BadgeTypesDefinition[BadgeTypes.PENDING_VERIFICATION],
                      }
                    ]}
                    label="KYC status"
                    name="status"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("status", value)}
                    value={values?.status}
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );


  useEffect(() => {
    fetchIncompleteOnboardingCustomersList({page, ...sortOptions, search})
  }, [])

  useEffect(() => {
    location.hash === "#incomplete-onboarding-customers" &&
    fetchIncompleteOnboardingCustomersList({page, ...sortOptions, search});
  }, [location?.hash]);

  useEffect(() => {
    setCount(paginations?.totalCount as number);
  }, [paginations]);

  return (
    <div className="incomplete-onboarding-customers">
      <TableComponent
      pageNumber={pageNumber ?? page}
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      onChange={fetchIncompleteOnboardingCustomersList}
      onSort={(data)=>{
        setSortOptions(data)
      }}
      filter={filter}
      filtersSorts={{page, search, ...sortOptions, ...filters}}
      onRow={(record) =>
        navigate({
          pathname: generatePath(AppRoutes.INCOMPLETE_ONBOARDING_CUSTOMERS_VIEW, {
            customerId: record?.id?.toString(),
          })}, 
          {state: page}
        )
      }
        search={{
          placeholder: "Search customer by name or phone number",
          onSearch: (value) => {
            setSearch(value)
            fetchIncompleteOnboardingCustomersList({
              search: value
            });
          },
        }}
        loading={loading}
        columns={[
          {
            title: "Customer Name",
            dataIndex: "fullName",
            key: SortColumnTypes.CUSTOMER_NAME,
            sorter: true,
            render: (text, record) => <span>{text} {record?.rejectedAt ? <BadgeComponent type={BadgeTypes.REAPPLY} className="reapply" /> : ""}</span>,
            width: 400,
            ellipsis: true,
          },
          {
            title: "Phone Number",
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            render: (text, record) => `${record?.countryCode?.isdCode ?? ""} ${text}`
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: 250,
            ellipsis: true,
          },
          {
            title: "Onboarding Start Date",
            dataIndex: "onboardingStartedAt",
            key: SortColumnTypes.ONBOARDING_START_DATE,
            render: (_value: string, record: CustomerModel) => record.onboardingDetail?.onboardingStartedAt && moment(record.onboardingDetail?.onboardingStartedAt)?.format("DD MMM YYYY"),
            sorter: true,
          },
          {
            title: "Kyc Status",
            dataIndex: "kycStatus",
            key: "kycStatus",
            render: (text) => <BadgeComponent type={(text === BadgeTypes.APPROVED ? BadgeTypes.KYC_APPROVED : text === BadgeTypes.FAILED ? BadgeTypes.KYC_FAILED : text === BadgeTypes.KYC_PENDING && BadgeTypes.KYC_PENDING) as BadgeTypes} />
          }
        ]}
        data={customers}
        empty={{
          img: NoCustomersImg,
          text: "No customer data",
        }}
      />
    </div>
  );
};

export default PendingKycCustomers;
