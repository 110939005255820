
import {UpcomingPlanActions} from '../UpcomingPlanActions/upcomingPlanActions.model';

import {ActivePlanActions} from '../ActivePlanActions/activePlanActions.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class Actions { 

	@serializable(alias('active_plan_actions', list(object(ActivePlanActions))))
	activePlanActions?: ActivePlanActions[] = [];

	@serializable(alias('upcoming_plan_actions', list(object(UpcomingPlanActions))))
	upcomingPlanActions?: UpcomingPlanActions[] = [];
	
	@serializable(alias('percentage_of_completion', primitive()))
	percentageOfCompletion?: number;

}