import React, { FC, useEffect, useState } from "react";
import TableComponent from "../../../../../../shared/components/TableComponent";
import faker from "faker";
import { BadgeTypes } from "../../../../../../enums/badgeTypes";
import BadgeComponent from "../../../../../../shared/components/BadgeComponent";
import CustomerService from "../../../../../../services/CustomerService/customer.service";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CustomerPurchaseModel } from "../../../../../../models/Customer/customer.model";
import { PurchaseModeTypesDefinition } from "../../../../../../definitions/purchaseModeTypesDefinition";
import { PurchaseModeTypes } from "../../../../../../enums/purchaseModeTypes";
import { currencyString } from "../../../..";
import { tableSorter } from "../../../../../../shared/utils/tableSorter";
import moment from "moment";
import { VendorTypes } from "../../../../../../enums/vendorTypes";
import { VendorTypesDefinition } from "../../../../../../definitions/vendorTypesDefinition";
import { PurchaseListModel } from "../../../../../../models/Purchase/purchase.model";
import { SortFilterModel } from "../../../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../../../shared/types/SortColumn.type";
import { AppRoutes } from "../../../../../../routes/routeConstants/appRoutes";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { calculatePurchaseTotal } from "../../../../../../shared/utils/calculatePurchaseTotal";

export const CustomerPurchases: FC = () => {
  const { fetchCustomerPurchases, loading, customerPurchases, paginations } =
    CustomerService();
  const [page, setPage] = useState(1)
  const navigate = useNavigate();
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const { customerId } = useParams();
  const showTest = LocalStorage.getItem("IS_TEST")

  const handlePageChange = (page: number) => {
    setPage(page)
    customerId &&
      fetchCustomerPurchases({ page, ...sortOptions, showTest }, customerId)
  }

  useEffect(() => {
    if (customerId) fetchCustomerPurchases({showTest}, customerId);
  }, []);

  return (
    <TableComponent
      onChange={fetchCustomerPurchases}
      onChangeParam={customerId}
      onSort={(data) => {
        setSortOptions(data)
      }}
      onRow={(record)=>
        navigate(
          generatePath(AppRoutes.PURCHASES_VIEW, {
            purchaseId: record?.id,
          })
        )
      }
      filtersSorts={{ page, showTest }}
      handlePageChange={handlePageChange}
      paginationMeta={paginations}
      search={{
        placeholder: "Search purchase by id",
        onSearch: (value) => {
          if (customerId) fetchCustomerPurchases({ search: value, ...sortOptions, showTest }, customerId)
        },
      }}
      columns={[
        {
          dataIndex: "purchaseId",
          title: "Purchase ID",
          key: SortColumnTypes.PURCHASE_ID,
          sorter: true,
        },
        {
          title: "POS Purchase ID",
          dataIndex: "customerReferenceNumber",
          key: "customerReferenceNumber"
        },
        {
          dataIndex: "createdAt",
          title: "Purchase Date",
          key: SortColumnTypes.PURCHASE_DATE,
          sorter: true,
          render: (text) => moment(text)?.format("DD MMM YYYY")
        },
        {
          title: "Purchase Time",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (text) => moment(text)?.local().format("HH:mm")
        },
        {
          dataIndex: "vendor",
          title: "Vendor",
          key: SortColumnTypes.VENDOR_NAME,
          render: (text: CustomerPurchaseModel["vendor"]) => (
            <span className="name-display-picture">
              <img src={text?.logo?.s3Url} />
              {text?.name}
            </span>
          ),
          sorter: true,
        },
        {
          dataIndex: "vendor",
          title: "Mode",
          key: "vendor",
          render: (record: PurchaseListModel["vendor"]) =>
            record?.mode === VendorTypes.ONLINE ? VendorTypesDefinition[VendorTypes.ONLINE] : record?.instore?.name,
        },
        {
          title: "POS Type",
          dataIndex: "posType",
          key: "posType",
        },
        {
          title: "Purchase Amount",
          dataIndex: "totalAmount",
          key: SortColumnTypes.TOTAL_AMOUNT,
          render: (text, record) => (
            <span className="vendor__name">
              {currencyString}
              {text}
            </span>
          ),
          sorter: true,
        },
        {
          title: "Purchase Total",
          dataIndex: "totalAmount",
          key: SortColumnTypes.TOTAL_AMOUNT,
          render: (text, record: CustomerPurchaseModel) => (
            <span className="vendor__name">
              {currencyString}
              {calculatePurchaseTotal(text, Number(record?.customerProcessingFee), Number(record?.promotionDiscount), Number(record?.cashDiscount), Number(record?.totalLateFee))}
            </span>
          ),
        },
        {
          dataIndex: "customerPaymentStatus",
          title: "Customer Payment",
          key: SortColumnTypes.CUSTOMER_PAYMENT_STATUS,
          sorter: true,
          render: (text) => <BadgeComponent type={text} />,
        },
        {
          dataIndex: "purchaseStatus",
          title: "Purchase Status",
          key: SortColumnTypes.PURCHASE_STATUS,
          sorter: true,
          render: (text) => <BadgeComponent type={text} />
        },
      ]}
      loading={loading}
      data={customerPurchases}
    />
  );
};
