import React, { FC } from 'react'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import "./checkboxcomponent.scss"

interface CheckboxComponentProps {
    label?: string;
    title: string;
    onChecked?: (value: CheckboxChangeEvent) => void;
    value: boolean;
 }

const CheckboxComponent: FC<CheckboxComponentProps> = (props) => {

    const { label, title, onChecked, value } = props

    return (
        <div className="checkboxcomponent">
            <div className='title'><Checkbox value={value} checked={value} onChange={onChecked} >{title}</Checkbox></div>
           {label && <div className='label'>{label}</div>}
        </div>
    )
}

export default CheckboxComponent