import React, { Fragment } from "react";
import { Checkbox, Col, Row, Select } from "antd";
import { ErrorMessage, Form, Formik, FormikProps, FormikValues } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DocumentTypes } from "../../../../../enums/documentTypes";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";
import S3AttachmentService from "../../../../../services/S3UploadService/s3upload.service";
import DatePickerField from "../../../../../shared/components/DatePickerField";
import InputField from "../../../../../shared/components/InputField";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import UploadComponent from "../../../../../shared/components/UploadComponent";
import { createRefundFormValidation } from "./createRefundFormValidation";
import CreateRefundConfirmationModal from "./CreateRefundConfirmationModal";
import { RefundDetails } from "../../../../../models/RefundDetails/refundDetails.model";
import "./createRefundForm.scss"
import RadioComponent from "../../../../../shared/components/RadioComponent";
import { RefundModeTypes } from "../../../../../enums/refundTypes";
import { calculateRefundTotal } from "../../../../../shared/utils/calculateRefundTotal";
interface CreateRefundFormProps {
  visible: boolean;
  closeHandler: () => void;
  purchase?: PurchaseModel;
  refreshHandler?: Function
}
const CreateRefundForm: FC<CreateRefundFormProps> = (props) => {
  const { visible, closeHandler, purchase, refreshHandler } = props;
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)
  const [refundDetails, setRefundDetails] = useState<RefundDetails>(new RefundDetails())
  const { uploadAttachment, loading: attachmentLoading } = S3AttachmentService()
  const [initialValues, setInitialValues] = useState<RefundDetails>(new RefundDetails());
  const maxRefundAmount = calculateRefundTotal(Number(purchase?.totalAmount),Number(purchase?.customerProcessingFee), Number(purchase?.promotionDiscount), Number(purchase?.cashDiscount))
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const resetFormAndClose = () => {
    formRef?.current?.resetForm();
    closeHandler()
  }

  const openConfirmationModal = () => setConfirmationModalVisible(true)
  const closeConfirmationModal = () => setConfirmationModalVisible(false)

  const onSubmit = (values: RefundDetails) => {
    setRefundDetails(values)
    closeHandler()
    resetFormAndClose()
    openConfirmationModal()
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        innerRef={formRef}
        validationSchema={createRefundFormValidation(maxRefundAmount)}
      >
        {({ values, setFieldValue, handleSubmit, errors }) => {
          return (
            <Form>
              <ModalComponent
                visible={visible}
                closeHandler={resetFormAndClose}
                type="small"
                title="Create Refund"
                successProps={{
                  title: "Confirm",
                  clickHandler: handleSubmit,
                  loading: attachmentLoading,
                }}
                cancelProps={{
                  title: "Close",
                  clickHandler: resetFormAndClose,
                }}
                className="create-refund-form-modal"
              >
                <div className="create-refund-form">
                  <Row gutter={16}>
                    <Col span={24}>
                      <RadioComponent
                        name="refundMode"
                        label="Refund receipt ID"
                        value={values?.refundMode}
                        data={[
                          {
                            value: RefundModeTypes.PARTIAL_REFUND,
                            label: "Partial refund"
                          },
                          {
                            value: RefundModeTypes.FULL_REFUND,
                            label: "Full refund"
                          },
                        ]}
                        onChange={(event) => {
                          setFieldValue("refundMode", event?.target?.value);
                          setFieldValue("refundAmount", event?.target?.value === RefundModeTypes.FULL_REFUND ? purchase?.totalAmount : 0)
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <InputField
                        label="Refund amount on the receipt"
                        name="refundAmount"
                        placeholder="0.00"
                        disabled={values?.refundMode === RefundModeTypes.FULL_REFUND}
                      />
                    </Col>
                    <Col span={24}>
                      <DatePickerField
                        format="DD MMM YYYY"
                        value={values?.returnedDate}
                        onChange={(date, dateString) => setFieldValue("returnedDate", dateString)}
                        label="Returned date"
                        name="returnedDate"
                        placeholder="Select"
                      />
                    </Col>
                    <Col span={24}>
                      <InputField
                        label="Refund receipt ID"
                        name="receiptId"
                        placeholder="Enter"
                        disabled={values?.receiptNotToBePresent}
                      />
                    </Col>
                    <Col span={24}>
                      <Checkbox name="receiptNotToBePresent" checked={values?.receiptNotToBePresent}
                        onChange={(e) => {
                          setFieldValue("receiptId", "")
                          setFieldValue("receiptNotToBePresent", e.target.checked)
                        }}>
                        <p className="checkbox-action-title">No refund receipt ID available</p>
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <UploadComponent
                        isUploading={false}
                        fileId={(values?.receiptAttachmentId ?? values?.receiptAttachment?.id) as string}
                        fileType={values?.receiptAttachment?.fileType as string}
                        fileName={values?.receiptAttachment?.filename as string}
                        fileUrl={values?.receiptAttachment?.s3Url as string}
                        accept={"image/png, image/jpg, image/jpeg"}
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("receiptAttachment.s3Url", fileUrl);
                          setFieldValue("receiptAttachment.filename", file?.name);
                          const _reciept = await uploadAttachment(file, DocumentTypes.REFUND_RECEIPT);
                          setFieldValue("receiptAttachmentId", _reciept);
                        }}
                        title="Upload Document"
                        type="document"
                        label="Refund receipt"
                        subLabel="(Optional)"
                      />
                    </Col>
                  </Row>
                </div>
              </ModalComponent>
            </Form>
          )
        }}
      </Formik>
      <CreateRefundConfirmationModal refundDetails={refundDetails && {...refundDetails, purchaseId: purchase?.purchaseId}} visible={confirmationModalVisible} closeHandler={closeConfirmationModal} refreshHandler={refreshHandler}/>
    </Fragment>
  );
};

export default CreateRefundForm;
