import { Col, Divider, Row } from "antd";
import React, { FC, useState } from "react";
import "./purchaseReturnSummaryCard.scss";
import faker from "faker";
import BadgeComponent from "../../../../../shared/components/BadgeComponent";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import { currencyString } from "../../..";
import { DocumentItemCard } from "../../../../../shared/components/DocumentsCard";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";

interface PurchaseReturnSummaryCardProps {
  purchase: PurchaseModel | undefined;
  loading: boolean;
}

const PurchaseReturnSummaryCard: FC<PurchaseReturnSummaryCardProps> = (
  props
) => {
  const {
    purchase,
    loading
  } = props;

  return (
    <div className="purchase-return-summary-card">
      <Row>
        <Col span={14}>
          <div className="return-summary__container">
            <div className="header">
              <div className="label">
                {`RETURN SUMMARY (${purchase?.returnSummary?.returnItems?.length} ITEM${purchase?.returnSummary?.returnItems?.length ? "S" : ""
                  }, 22 JAN 2022)`}
              </div>
              <BadgeComponent type={BadgeTypes.REFUND_COMPLETED} />
            </div>
            <div className="return-list__container">
              {purchase?.returnSummary?.returnItems?.map((value, index) => {
                return (
                  <div className="return-item">
                    <div className="product">{value?.name}</div>
                    <div className="quantity">x&nbsp;{value?.noOfItems}</div>
                    <div className="price">
                      {currencyString}
                      {value?.amount}
                    </div>
                  </div>
                );
              })}
            </div>
            <Divider />
            <div className="refund-total__container">
              <div className="total__label">Refund total</div>
              <div className="value">
                {currencyString}
                {purchase?.returnSummary?.totalAmount}
              </div>
            </div>
          </div>
        </Col>
        <Col span={2} />
        <Col span={8}>
          <div className="return-receipt__container">
            <div className="label">RETURN RECEIPT</div>
            <DocumentItemCard
              handleChange={() => { }}
              customerAttachmentId={purchase?.returnSummary?.returnReceipt?.id}
              fileType={purchase?.returnSummary?.returnReceipt?.fileType ?? ""}
              s3Url={purchase?.returnSummary?.returnReceipt?.s3Url ?? ""}
              filename={purchase?.returnSummary?.returnReceipt?.filename??""} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseReturnSummaryCard;
