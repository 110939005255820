import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize, serializeAll } from "serializr";
import {axiosInstance} from "../../interceptor/axiosInstance";
import {
  VendorStorePromotionCreationModel,
  VendorStorePromotionModel,
} from "../../models/Vendor/StorePromotion/storePromotion.model";
import { VendorPurchaseConfigModel } from "../../models/Vendor/PurchaseConfig/purchaseConfig.model";
import { VendorListModel, VendorModel } from "../../models/Vendor/vendor.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { ConfigurationListModel, ConfigurationProcessingFeeModel } from "../../models/Configuration/configuration.model";
import { DeallPurchaseConfig } from "../../models/DeallPurchaseConfig/deallPurchaseConfig.model";
import {OrderListMetaModel} from "../../models/Meta/meta.model";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import { CreditScoreCriterions } from "../../models/CreditScoreCriterions/creditScoreCriterions.model";
import { Questions } from "../../models/Questions/questions.model";

const ConfigurationService = () => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [configrationList, setConfigrationList] = useState<ConfigurationListModel[]>([]);
  const [vendorOrdered, setVendorOrdered] = useState<OrderListMetaModel[]>([]);
  const [configurationProcessingFee, setConfigurationProcessingFee] = useState<ConfigurationProcessingFeeModel>();
  const [vendor, setVendor] = useState<VendorModel>();
  const [purchaseConfig, setPurchaseConfig] = useState<
    DeallPurchaseConfig
  >({});
  const [vendorStorePromotions, setVendorStorePromotions] = useState<
    VendorStorePromotionModel[]
  >([]);
  const [creditScore, setCreditScore] = useState<CreditScoreCriterions[]>()
  const [questions, setQuestions] = useState<Questions[]>()

  const fetchConfigurationList = () => {
    setLoading(true);
    return axiosInstance
    .get(ApiRoutes.CONFIGURATION_LIST)
    .then((response) => {
        const list = deserialize(
          ConfigurationListModel,
          response?.data?.["customer_purchase_configs"]
        ) as ConfigurationListModel[]

        setConfigrationList(list);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchConfigurationProcessingFee = () => {
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.VENDOR_PROCESSING_FEE)
      .then((response) => {

        const fee = deserialize(
          ConfigurationProcessingFeeModel,
          response?.data?.["vendor_processing_fee_config"]
        )

        setConfigurationProcessingFee(fee);

      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchVendorListWithOrder = (showTest: boolean) => {
    setLoading(true);
    return axiosInstance
        .get(ApiRoutes.VENDOR_LIST_WITH_ORDER, { params: {show_test: showTest} })
        .then((response) => {
          let vendorOrderModel: OrderListMetaModel[] = response?.data?.vendors.map(({id, name, order}: VendorModel) => ({
            id,
            label: name,
            order,
          }));
          setVendorOrdered(vendorOrderModel);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const updateVendorOrder = (vendorOrderModel: OrderListMetaModel[]) => {
    setLoading(true);
    let vendors = {
      vendors: vendorOrderModel.map(({id, order}) => ({
        id,
        order
      }))
    };
    return axiosInstance
        .put(ApiRoutes.UPDATE_VENDOR_ORDER, vendors)
        .then((response) => {
          Notification({
            description: 'Vendor Order Has Been Updated',
            type: NotificationTypes.SUCCESS,
          });
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const updateConfigurationList = (configureId:any,body:any) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CONFIGURATION_LIST_UPDATE, { configureId });
    return axiosInstance
      .put(API_URL,body)
      .then((response) => {
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const updateConfigurationProcessingFee = (body:any) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_PROCESSING_FEE);
    return axiosInstance
      .put(API_URL,body)
      .then((response) => {
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPurchaseConfig = () => {
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.PURCHASE_CONFIG)
      .then((response) => {
        const _purchaseConfig = deserialize(
          DeallPurchaseConfig,
          response?.data
        )
        setPurchaseConfig(_purchaseConfig);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePurchaseConfig = (purchaseConfig: DeallPurchaseConfig) => {
    let payload = {
      deall_purchase_config: serialize(DeallPurchaseConfig,purchaseConfig)
    }
    setLoading(true);
    return axiosInstance
      .put(ApiRoutes.PURCHASE_CONFIG, payload)
      .then((response) => {
        const _purchaseConfig = deserialize(
          DeallPurchaseConfig,
          response?.data
        )
        setPurchaseConfig(_purchaseConfig);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCreditScoreCriteria = () => {
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.CREDIT_SCORE)
      .then((response) => {
        const creditScore = deserialize(
          CreditScoreCriterions,
          response?.data?.credit_score_criteria
        ) as CreditScoreCriterions[]
        setCreditScore(creditScore)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCreditScore = (creditScoreConfig: CreditScoreCriterions[]) => {
    let payload = {
      credit_score_criteria: creditScoreConfig.map((config) =>
      serialize(CreditScoreCriterions, config))
    }
    setLoading(true);
    return axiosInstance
      .put(ApiRoutes.CREDIT_SCORE, payload)
      .then((response) => {
        const _creditScoreConfig = deserialize(
          CreditScoreCriterions,
          response?.data?.credit_score_criteria
        ) as CreditScoreCriterions[]
        setCreditScore(_creditScoreConfig);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCreditScoreQuestions = () => {
    setQuestions(questions)
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.GET_CREDIT_SCORE_QUESTIONS)
      .then((response) => {
        const questions = deserialize(
          Questions,
          response?.data?.questions
        ) as Questions[]
        setQuestions(questions)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const recalculateAllCreditScore = () => {
    setLoading(true)
    return axiosInstance
          .get(ApiRoutes.RECALCULATE_ALL_CREDIT_SCORE)
          .then(() => {
            Notification({
              description: "Credit score has been recalculated for all users!",
              type: NotificationTypes.SUCCESS,
            });
          })
          .catch((error) => {
            setError(error)
          })
          .finally(() => {
            setLoading(false)
          })
  }


  const recalculateCreditScoreForAUser = (customerId: string, onSuccess: () => void) => {
    setLoading(true)
    const API_URL = generatePath(ApiRoutes.RECALCULATE_CREDIT_SCORE_FOR_USER, {customer_id: customerId})
    return axiosInstance
          .get(API_URL)
          .then(onSuccess)
          .catch((error) => {
            setError(error)
          })
          .finally(() => {
            setLoading(false)
          })
  }

  return {
    loading,
    error,
    configrationList,
    configurationProcessingFee,
    purchaseConfig,
    vendor,
    vendorOrdered,
    setVendorOrdered,
    vendorStorePromotions,
    fetchConfigurationList,
    fetchConfigurationProcessingFee,
    updateConfigurationList,
    updateConfigurationProcessingFee,
    fetchPurchaseConfig,
    updatePurchaseConfig,
    fetchVendorListWithOrder,
    updateVendorOrder,
    fetchCreditScoreCriteria,
    creditScore,
    updateCreditScore,
    fetchCreditScoreQuestions,
    questions,
    recalculateAllCreditScore,
    recalculateCreditScoreForAUser
  };
};

export default ConfigurationService;
