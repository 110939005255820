import * as Yup from  "yup";

let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

export const customerInfoCardValidation = Yup.object().shape({
    processingFee: Yup.number()
    // TODO: Might need in the future
      // .test(
      //   "is-decimal",
      //   "The processing fee should be a decimal with maximum two digits",
      //   (val: number | null | undefined | string) => {
      //     if (val != undefined) return patternTwoDigisAfterComma.test(val as string);
      //     return true;
      //   }
      // )
      .min(0)
      .max(100, "Processing fee % must be between 0 and 100")
      .typeError('Processing fee must be a number')
      .required('Processing fee is required.')
  })