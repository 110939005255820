import { serializable, alias, primitive } from "serializr";
import { SortFilterModel } from "../SortFilterModel/SortFilterModel.model";

export class MetaModel {
    @serializable(alias('id', primitive()))
	value?: number | string;

    @serializable(alias('name', primitive()))
    label?: string;
    
    @serializable(alias('flag', primitive()))
    flag?: string;
    
    @serializable(alias('isd_code', primitive()))
    isdCode?: string;
}

export class NeighbourhoodModel {
    @serializable(alias('id', primitive()))
	value?: number;

    @serializable(alias('zone_keyword', primitive()))
    label?: string;
    
    @serializable(alias('zone_rank', primitive()))
    zoneRank?: string;
    
    @serializable(alias('zone_score', primitive()))
    zoneScore?: number;

	@serializable(alias('name_in_en', primitive()))
	nameInEn?: string;
}

export class CategoryModel {
	@serializable(alias('name', primitive()))
	label?: string;
	
	@serializable(alias('id', primitive()))
	value?: string;
}

export class OrderListMetaModel {
	@serializable(alias('id', primitive()))
	id: number | string;

	@serializable(alias('name', primitive()))
	label: string;

	@serializable(alias('order', primitive()))
	order: number;

	constructor(id: number | string, label: string = '', order: number) {
		this.id = id;
		this.label = label;
		this.order = order;
	}
}

export class PartnersMetaModel { 

	@serializable(alias('id', primitive()))
	value?: number | string;

	@serializable(alias('name', primitive()))
	label?: string;

	@serializable(alias('purchase_fee', primitive()))
	purchaseFee?: number;

	@serializable(alias('purchase_fee_surcharge', primitive()))
	purchaseFeeSurcharge?: number;

	@serializable(alias('cash_payment_percent_fee', primitive()))
	cashPaymentPercentFee?: number;

	@serializable(alias('cash_payment_surcharge', primitive()))
	cashPaymentSurcharge?: number;

	@serializable(alias('card_payment_percent_fee', primitive()))
	cardPaymentPercentFee?: number;

	@serializable(alias('card_payment_surcharge', primitive()))
	cardPaymentSurcharge?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('vat', primitive()))
	vat?: number;

	@serializable(alias('ewallet_payment_percent_fee', primitive()))
	ewalletPaymentPercentFee?: number;

	@serializable(alias('ewallet_payment_surcharge', primitive()))
	ewalletPaymentSurcharge?: number;

	@serializable(alias('collect_payments_separately', primitive()))
	collectPaymentsSeparately?: boolean;

}

export interface StateProps{
	page?: number;
	from?: string;
	filters?: SortFilterModel;
	pageNumber?: number;
	filterOptions?: SortFilterModel;
	returnsPage?: number;
}