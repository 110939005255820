import React, { FC, useEffect, useRef, useState } from 'react'
import ModalComponent from '../../../../../shared/components/ModalComponent';
import { Row, Col } from 'antd';
import { FormikProps, FormikValues, Formik, Form } from 'formik';
import { NotificationTypes } from '../../../../../enums/notificationTypes';
import { CreditScoreCriterions } from '../../../../../models/CreditScoreCriterions/creditScoreCriterions.model';
import ConfigurationService from '../../../../../services/ConfigurationService/configuration.service';
import DropdownField from '../../../../../shared/components/DropdownField';
import InputField from '../../../../../shared/components/InputField';
import SwitchComponent from '../../../../../shared/components/SwitchComponent';
import Notification from '../../../../../shared/components/Notification';
import { Questions } from '../../../../../models/Questions/questions.model';
import { ConfigurationFormTypes } from '../../../../../enums/configurationFormTypes';
import validationSchema from './creditScoreSchema';
import { DatasourceTypes } from '../../../../../enums/datasourceTypes';

interface answers{
    answer?: string | number;
    score?: number;
    answerId?: number;
}
  
class FormValue {
    datasource?: string;
    id?: number;
    active?: boolean;
    factor?: string;
    factorWeight?: number;
    questions?: string;
    answers?: answers[];
}

interface CreditScoreConfigurationFormProps {
    visible: boolean;
    closeHandler: () => void;
    data: CreditScoreCriterions;
    allData?: CreditScoreCriterions[];
    updateData: (data: CreditScoreCriterions[]) => void;
    type: string;
}

interface QuestionOption {
  label: string;
  value: number;
}

const CreditScoreConfigurationForm: FC<CreditScoreConfigurationFormProps> = (props) => {
  const { visible, closeHandler, data, updateData, allData, type } = props;
  const [initialValues, setInitialValues] = useState<FormValue>(new FormValue());
  const { loading, fetchCreditScoreQuestions, questions } = ConfigurationService()
  const [questionOption, setQuestionOption] = useState<QuestionOption[]>([])
  const [answers, setAnswers] = useState<Questions[]>()
  const [questionId, setQuestionId] = useState<number>()

  const isEditExistingCriteria = type === ConfigurationFormTypes.EDIT
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const dataSource = [
    {
        label: "Questionnaire Responses",
        value: "questionnaire_responses"
    },
    {
        label: "Customer Details",
        value: "customer_details"
    },
  ]

  const onSubmit = (values: FormikValues) => {
    const isQuestionnaire = values?.datasource === DatasourceTypes.QUESTIONNAIRE_RESPONSES
    let updatedData = [] as CreditScoreCriterions[] | undefined

    if(type === ConfigurationFormTypes.ADD) {
      const newCriteria: CreditScoreCriterions = {
        active: !!values?.active,
        criteria: {
          options: {},
          id: values?.id,
          datasource: values?.datasource,
          factor: values?.factor,
          factorWeight: Number(values?.factorWeight),
          questions: isQuestionnaire ? values?.questions : undefined,
          optionsArrays: isQuestionnaire ? values?.answers : undefined
        }
      };

      if(isQuestionnaire){
        values.answers.forEach((option: answers) => {
          newCriteria!.criteria!.options![option.answerId!] = Number(option.score);
        });
      }

      updatedData?.push(newCriteria)
      updatedData = [...allData!, ...updatedData!]
      updatedData?.map(data => {
        delete data?.id
      })
      updateData(updatedData)
    }

    if (type === ConfigurationFormTypes.EDIT){
      let optionsObject: { [key: string]: number } = {};
      if (isQuestionnaire) {
        optionsObject = values?.answers.reduce((acc: { [key: string]: number }, option: answers) => {
          if (option.score) {
            const key = option.answerId ?? option.answer;
            if (key) {
              acc[String(key)] = Number(option.score);
            }
          }
          return acc;
        }, {});
      }

      const updatedData = allData?.map(data => {
        if (values.id === data?.criteria?.id) {
          data['active'] = values.active;
          data['criteria']!['factorWeight'] = Number(values?.factorWeight);
          if(isQuestionnaire) data['criteria']!['options'] = optionsObject
        }
        return data;
      })

      updateData(updatedData!)
    }

    resetFormAndClose()

    const activeCriteria = updatedData?.filter(criteria => criteria?.active)     // Factor weight must be added only for active criteria
    const totalWeightage = Number(activeCriteria?.reduce((total, data) => total + (Number(data?.criteria?.factorWeight) ?? 0), 0))
    
    if(totalWeightage > 100){
      Notification({
        message: "Sum of factor weight must be 100",
        description: "Please update the factor weight for the criteria!",
        type: NotificationTypes.ERROR,
      });
    }
  };

  const resetFormAndClose = () =>{
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }

  useEffect(() => {
    fetchCreditScoreQuestions()
  }, [])

  useEffect(() => {
    const transformedData = questions?.map(item => ({
      label: item.question!.original ?? '',
      value: item.id ?? 0,
    })) ?? [];
    setQuestionOption(transformedData)
  }, [questions])

  useEffect(() => {
    if(questions){
      const answerObject = questions?.find(question => question.id === questionId)
      setAnswers(answerObject?.answers)
    }
  }, [questionId])

  useEffect(() => {
    if (data && data?.criteria && type === ConfigurationFormTypes.EDIT) {
      setInitialValues({
        id: data.criteria?.id!,
        active: data.active!,
        datasource: data.criteria?.datasource!,
        factor: data.criteria?.factor!,
        factorWeight: data.criteria?.factorWeight! ?? 0,
        questions: data.criteria?.questions!,
        answers: data.criteria?.optionsArrays?.map((option) => ({
          answerId: option.answerId,
          answer: option.answer,
          score: option.score ?? 0,
        }))
       })
    } else{
      setInitialValues({
        active: false,
        datasource: undefined,
        factor: undefined,
        factorWeight: 0
      })
    }
  }, [data, visible])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors }) => (
        <ModalComponent
          visible={visible}
          closeHandler={resetFormAndClose}
          type="medium"
          title="Credit Score Config"
          successProps={{
            title: "Save",
            clickHandler: handleSubmit,
            loading: loading,
          }}
          cancelProps={{
            title: "Discard",
            clickHandler: resetFormAndClose,
          }}
          className="purchase-config-form-modal"
        >
          <Form>
            <div className="purchase-config-form">
              <Row gutter={16}>
                <Col span={24}>
                    <DropdownField
                        label="Data source"
                        placeholder="Select"
                        name="datasource"
                        value={values?.datasource}
                        options={dataSource}
                        onChange={(value) => {
                          setFieldValue("datasource", value);
                        }}
                        disabled={isEditExistingCriteria}
                    />
                </Col>
                <Col span={16}>
                  <InputField
                    label="Factor name"
                    name="factor"
                    placeholder="Enter"
                    disabled={isEditExistingCriteria}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    label="Factor weight"
                    name="factorWeight"
                    placeholder="Enter"
                    disabled={!values?.active}
                  />
                </Col>
                {(values?.datasource === DatasourceTypes.QUESTIONNAIRE_RESPONSES) && 
                <Col span={24}>
                  {isEditExistingCriteria ? 
                    <InputField
                    label="Questions"
                    name="questions"
                    placeholder="Enter"
                    disabled={isEditExistingCriteria}
                    /> :           
                    <DropdownField
                          label="Questions"
                          placeholder="Select"
                          name="questions"
                          value={values?.questions}
                          options={questionOption}
                          onChange={(value, record) => {
                            setQuestionId(value)
                            setFieldValue("questions", record?.label);
                            setFieldValue("id", value);
                            const answerObject = questions?.find(question => question.id === value)
                            const ans = answerObject?.answers?.map((option) => ({
                              answerId: option.id,
                              answer: option.answer?.original,
                              score: 0,
                            }))
                            setFieldValue('answers', ans)
                          }}
                      />}
                </Col>}
                {isEditExistingCriteria && values?.datasource === DatasourceTypes.QUESTIONNAIRE_RESPONSES && data?.criteria?.optionsArrays?.map((answer, index) => 
                  <>
                    <Col span={16}>
                    <InputField
                        label={index === 0 ? "Answer" : ""}
                        name={`answers[${index}].answer`}
                        placeholder="Enter"
                        disabled
                      />
                    </Col>
                    <Col span={8}>
                      <InputField
                          label={index === 0 ? "Score" : ""}
                          name={`answers[${index}].score`}
                          placeholder="Enter"
                      />
                    </Col>
                  </>
                )}
                {!isEditExistingCriteria && values?.datasource === DatasourceTypes.QUESTIONNAIRE_RESPONSES && answers?.map((_,index: number) => 
                  <>
                    <Col span={16}>
                    <InputField
                        label={index === 0 ? "Answer" : ""}
                        name={`answers[${index}].answer`}
                        placeholder="Enter"
                        disabled
                      />
                    </Col>
                    <Col span={8}>
                      <InputField
                          label={index === 0 ? "Score" : ""}
                          name={`answers[${index}].score`}
                          placeholder="Enter"
                      />
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <SwitchComponent
                    value={values?.active}
                    onSwitch={(value) => {
                      setFieldValue( "active",value);
                      if(!value) setFieldValue("factorWeight", 0)
                    }
                    }
                    label={"  "}
                    checkedTitle={"Active"}
                    unCheckedTitle={"Inactive"}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default CreditScoreConfigurationForm