import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import { axiosInstance } from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { PendingPartnerFees, PastPartnerSettlements, MarkSettledPendingSettlement, MarkSettledPendingSettlementDetails } from '../../models/PartnerFees/partnerFees.model';
import { PaginationModel } from "../../models/pagination.model";
import { SortFilterModel } from '../../models/SortFilterModel/SortFilterModel.model';
import { generatePath } from 'react-router-dom';
import { MarkAsSettledDetails } from '../../models/Settlement/settlement.model';

export const PartnerfeeService = () => {
    const [pendingPartnerFees, setPendingPartnerFees] = useState<
    PendingPartnerFees[]
    >([]);
    const [pastPartnerFees, setPastPartnerFees] = useState<
    PastPartnerSettlements[]
    >([]);
    const [markAsSettledLoading, setMarkAsSettledLoading] = useState(false);
    const [partnerSettlement, setPartnerSettlement] = useState<PastPartnerSettlements>();
    const [pendingPartnerFeeIds, setPendingPartnerFeeIds] = useState([]);
    const [markAsSettledDetails, setMarkAsSettledDetails] = useState<MarkAsSettledDetails>();
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
  
    const [paginations, setPaginations] = useState<PaginationModel>()
  
    const fetchPendingPartnerFeeList = (params?: SortFilterModel) => {
      setLoading(true);
      const schema = Object.assign(new SortFilterModel(params), {...params})
      const _params = params && serialize(schema)
      axiosInstance
        .get(ApiRoutes.PENDING_PARTNER_FEES_LIST, { params: _params })
        .then((response) => {
          const data = deserialize(
            PendingPartnerFees,
            response?.data?.pending_partner_fees
          ) as PendingPartnerFees[];
          const meta = deserialize(PaginationModel,response?.data["meta"])
          setPaginations(meta);
          setPendingPartnerFees(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    const fetchPastPartnerFeeList = (params?:SortFilterModel) => {
      const schema = Object.assign(new SortFilterModel(params), {...params})
      const _params = params && serialize(schema)
      setLoading(true);
      axiosInstance
        .get(ApiRoutes.PAST_PARTNER_FEES_LIST, { params: _params })
        .then((response) => {
          const data = deserialize(
            PastPartnerSettlements,
            response?.data?.partner_settlements
          ) as PastPartnerSettlements[];
          const meta = deserialize(PaginationModel,response?.data["meta"])
          setPaginations(meta);
          setPastPartnerFees(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const markPendingPartnerSettlements = (
      data: MarkSettledPendingSettlement,
      onSuccess: Function
    ) => {
      const requestPayload = serialize(MarkSettledPendingSettlement, data)
      setMarkAsSettledLoading(true);
      
      return axiosInstance
      .post(ApiRoutes.PENDING_PARTNERFEE_MARK_SETTLED, requestPayload)
      .then((response) => {
          onSuccess();
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setMarkAsSettledLoading(false);
        });
    };

    const fetchPartnerSettlement = (settlementId: string) => {
      setLoading(true);
      const API_URL = generatePath(ApiRoutes.PARTNERFEE_DETAILS, {
        settlementId,
      });
      axiosInstance
        .get(API_URL)
        .then((response) => {
          const data = deserialize(
            PastPartnerSettlements,
            response?.data?.partner_settlement
          );
          setPartnerSettlement(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const updatePastPartnerSettlementDetails = (
      settlement: PastPartnerSettlements,
      onSuccess?: () => void,
      onError?: Function,
    ) => {
      setLoading(true);
      const API_URL = generatePath(ApiRoutes.PARTNERFEE_DETAILS, {
        settlementId: settlement?.id?.toString(),
      });
      const serializedPayload = serialize(PastPartnerSettlements, settlement);
      delete serializedPayload["id"];
      const requestPayload = { partner_settlement: serializedPayload };
      return axiosInstance
        .patch(API_URL, requestPayload)
        .then(onSuccess)
        .catch((error) => {
          setError(error);
          onError && onError()
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const fetchAllPendingSettlementForPartner = (params?: SortFilterModel) => {
      setDataLoading(true);
      const _params = params && serialize(SortFilterModel, params)
      axiosInstance
        .get(ApiRoutes.PENDING_PARTNER_FEES_LIST_SPECIFIC_PARTNER, { params: _params })
        .then((response) => {
          const data = response?.data
          setPendingPartnerFeeIds(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };

    const markPendingSettlementsDetails = (
      data: MarkSettledPendingSettlementDetails,
    ) => {
      const requestPayload = data && serialize(MarkSettledPendingSettlementDetails, data)
      setMarkAsSettledLoading(true);
      return axiosInstance
      .post(ApiRoutes.PENDING_PARTNERFEE_MARK_SETTLED_DETAILS, requestPayload)
      .then((response) => {
        const data = deserialize(MarkAsSettledDetails, response.data)
        setMarkAsSettledDetails(data)
        })
        .catch((error) => {
          setError(error);
          return error;
        })
        .finally(() => {
          setMarkAsSettledLoading(false);
        });
    };
  
    return {
      loading,
      error,
      pendingPartnerFees,
      pastPartnerFees,
      fetchPendingPartnerFeeList,
      fetchPastPartnerFeeList,
      markPendingPartnerSettlements,
      markAsSettledLoading,
      fetchPartnerSettlement,
      partnerSettlement,
      updatePastPartnerSettlementDetails,
      paginations,
      fetchAllPendingSettlementForPartner,
      dataLoading,
      pendingPartnerFeeIds,
      markPendingSettlementsDetails,
      markAsSettledDetails
    };
  };

export default PartnerfeeService